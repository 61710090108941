import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { User } from "../../users/_types/user";
import { CaseNote } from "../../cases/_types/case-note";

export class Task extends Resource {
    public override type = 'tasks';

    @prop()
    public userId!: string;

    public user!: User;

    @prop()
    public caseNoteId!: string;

    public caseNote!: CaseNote;

    @prop()
    @required()
    public description!: string;

    @prop()
    public date: Date = new Date();

    @prop()
    public isCompleted: boolean = false;


}