import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { Vehicle } from '../_types/vehicle';
import { VehiclesService } from '../_services/vehicles.service';
import { VehiclesTypesService } from '../../vehicles-types/_services/vehicles-types.service';
import { VehicleType } from '../../vehicles-types/_types/vehicle-type';
import { BusinessService } from '../../business/_services/business.service';
import { Business } from '../../business/_types/business';
import { WorkersService } from '../../workers/_services/workers.service';
import {Worker} from '../../workers/_types/worker';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-vehicles-detail',
  templateUrl: './vehicles-detail.component.html',
  styleUrls: ['./vehicles-detail.component.scss']
})
export class VehiclesDetailComponent {
  public vehicleFormGroup!: RxFormGroup;

  public vehicle: Vehicle = new Vehicle();
  public vehiclesTypes: Array<VehicleType> = new Array<VehicleType>;
  public ownerTypes: any = [
    {
      code: "COMPANY", name: "Sociedad"
    },
    {
      code: "WORKER", name: "Operario"
    }
  ];

  public business: Array<Business> = new Array<Business>;
  public workers: Array<Worker> = new Array<Worker>;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private vehiclesService: VehiclesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private vehiclesTypesService: VehiclesTypesService,
    private businessService: BusinessService,
    private workersService: WorkersService,
    /*public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,    */
  ) {
    this.topbarService.setTitle("Detalles de vehículo");
    this.topbarService.addBreadcrumb("Vehículos", "/vehicles");    

    this.vehicleFormGroup = this.formBuilder.formGroup<Vehicle>(this.vehicle) as RxFormGroup;
    delete this.vehicleFormGroup.controls['vehicleType'];
    delete this.vehicleFormGroup.controls['worker'];
    console.log(this.vehicleFormGroup);
    
  }

  async ngOnInit() {
    //console.log(this.config);
    //let isModal = this.config.data.clientPolicyId;
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadVehicle(id);      
    }

    this.allowWrite = true;//this.loginService.hasPermission("VEHICLES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("VEHICLES_DELETE");

    this.loadVehiclesTypes();
    this.loadBusiness();
    this.loadWorkers();
  }

  async loadVehicle(id: string) {
    this.vehicle = await firstValueFrom(this.vehiclesService.get(id));
    this.vehicleFormGroup.patchValue(this.vehicle);
  }

  async loadVehiclesTypes(){

    let response = await firstValueFrom(this.vehiclesTypesService.all());             
    this.vehiclesTypes = response.data;  

  }

  async loadBusiness() {

    let response = await firstValueFrom(this.businessService.all());             
    this.business = response.data; 
  }

  async loadWorkers(){
    let response = await firstValueFrom(this.workersService.all());             
    this.workers = response.data; 
  }
  
  onOwnerTypeChange(e: any) {    
    this.vehicle.ownerType = e.value;    
  }
  

  async save() {                
    console.log(this.vehicleFormGroup);
    let valid = this.formValidationService.validateForm(this.vehicleFormGroup);    
    console.log(valid);    
    if (valid) {
      this.vehicle = Object.assign(this.vehicle, this.vehicleFormGroup.value);
      
      let vehicle = await firstValueFrom(this.vehiclesService.save(this.vehicle));

      this.messageService.add({ closable: false, severity: 'success', summary: "Vehículo guardado correctamente." });

      
      this.router.navigate(['vehicles']);
            

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el vehículo "+this.vehicle.name+"?",
      header: "Eliminar vehículo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.vehiclesService.delete(this.vehicle.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Vehículo eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['vehicles']);
  }

}
