<form *ngIf="transactionFormGroup" [formGroup]="transactionFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-2 input-field">
                <label for="name">Código *</label>
                <input pInputText formControlName="code" d-form-field-error />
            </div>
            <div class="col-2 input-field">
                <label for="date">Fecha</label>
                <p-calendar formControlName="date" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                    [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error></p-calendar>
            </div>
            <div class="col-2 input-field">
                <label for="transactionStateId">Estado</label>
                <p-dropdown formControlName="transactionStateId" [options]="transactionStates" optionLabel="name" optionValue="id"
                    appendTo="body" [filter]="true" filterBy="name" placeholder="Selecciona un estado"
                    d-form-field-error></p-dropdown>
            </div>
            <div class="col-3 input-field">
                <label for="caseId">Expediente</label>
                <p-dropdown formControlName="caseId" [options]="cases" optionLabel="customName" optionValue="id"
                    appendTo="body" [filter]="true" filterBy="name" placeholder="Selecciona un expediente"
                    d-form-field-error></p-dropdown>
            </div>
            <div class="col-3 input-field">
                <label for="thirdPartyId">
                    {{transactionType.thirdPartyType == "Client"?"Cliente":
                    transactionType.thirdPartyType == "Company"?"Compañía":
                    transactionType.thirdPartyType == "Provider"?"Proveedor":"Operario"}}
                </label>
                <p-dropdown formControlName="thirdPartyId" [options]="thirdParties" optionLabel="name" optionValue="id"
                    appendTo="body" [filter]="true" filterBy="name" placeholder="Selecciona un cliente"
                    d-form-field-error></p-dropdown>
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>

    <p-card header="Líneas" *ngIf="transaction.id">     
        <div class="flex justify-content-end gap-2 mb-2" style="align-items: center;">            
            <p-checkbox *ngIf="allowWrite && transactionType.thirdPartyType == 'Company'" formControlName="groupByCapability" [binary]="true" d-form-field-error></p-checkbox>
            <label *ngIf="allowWrite && transactionType.thirdPartyType == 'Company'" for="isUrgent">Agrupar por especialidad</label>
            <p-button *ngIf="allowWrite && transactionFormGroup.get('caseId')?.value" label="Importar trabajos" icon="pi pi-plus"
                (click)="importJobs()" />
            <p-button *ngIf="allowWrite" label="Añadir grupo" icon="pi pi-plus"
                (click)="editTransactionLine(true, false,null)" />
            <p-button *ngIf="allowWrite && transactionType.thirdPartyType == 'Company'" label="Añadir baremo" icon="pi pi-plus"
                (click)="editTransactionLine(false, true ,null)" />
            <p-button *ngIf="allowWrite" label="Añadir línea" icon="pi pi-plus"
                (click)="editTransactionLine(false, false, null)" />
            <!--<p-button *ngIf="allowWrite" label="Añadir servicio 2" styleClass="ml-2" icon="pi pi-plus"
                (click)="editService(null)" />-->
        </div> 
        <p-table styleClass="m-1 mt-4" #grid [value]="transactionLines" [loading]="loadingTransactionLines"
                [totalRecords]="totalTransactionLines" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [scrollable]="true" scrollHeight="flex"                
                (onRowReorder)="onRowReorder($event)">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width:3rem"></th>
                        <!--<th pSortableColumn="lineNumber"> <p-sortIcon field="lineNumber"></p-sortIcon></th>                        -->                        
                        <th *ngIf="transactionType.thirdPartyType=='Company'">Baremo</th>                        
                        <th>Descripción</th>                        
                        <th style="text-align: right;">Cantidad</th>                        
                        <th style="text-align: right;">Precio unidad</th>      
                        <th style="text-align: right;">Descuento</th>                        
                        <th style="text-align: right;">Impuestos</th>                        
                        <th style="text-align: right;">Importe</th>                        
                        <th width="100"></th>
                    </tr>
                </ng-template> 
                <ng-template pTemplate="body" let-transactionLine let-i="rowIndex" let-columns="columns" let-index="rowIndex">
                    <tr *ngIf="transactionLine.isGroup" (dblclick)="editTransactionLine(transactionLine.isGroup, transactionLine.rateLineId!=null?true:false, transactionLine)" [pReorderableRow]="i" [class.rowGroup]="transactionLine.isGroup">
                        <td>
                            <span class="pi pi-bars" pReorderableRowHandle></span>
                        </td>
                        <td [attr.colspan]="transactionType.thirdPartyType=='Company'?7:6" class="rowGroup"><b>{{transactionLine.description}}</b></td>
                        <td>
                            <div class="flex gap-2 justify-content-end" [class.rowGroup]="transactionLine.isGroup">
                                <p-button *ngIf="allowDelete" (click)="deleteLine(transactionLine)" [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editTransactionLine(transactionLine.isGroup,transactionLine.rateLineId!=null?true:false, transactionLine)" [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!transactionLine.isGroup" (dblclick)="editTransactionLine(transactionLine.isGroup, transactionLine.rateLineId!=null?true:false, transactionLine)" [pReorderableRow]="i">
                        <td>
                            <span class="pi pi-bars" pReorderableRowHandle></span>
                        </td>
                        <td *ngIf="transactionType.thirdPartyType=='Company'">{{transactionLine.rateLine?.code}} {{transactionLine.rateLine?.description}}</td>
                        <td>{{transactionLine.description}}</td>
                        <td style="text-align: right;">{{transactionLine.quantity}}</td>
                        <td style="text-align: right;">{{transactionLine.price}}</td>
                        <td style="text-align: right;">{{'('+(transactionLine.discountPercent!=null?transactionLine.discountPercent:0)+'%) '}}{{transactionLine.discountAmount | currency: 'EUR' }}</td>                        
                        <td style="text-align: right;">{{'('+(transactionLine.taxPercent!=null?transactionLine.taxPercent:0)+'%) '}}{{transactionLine.taxAmount | currency: 'EUR' }}</td>
                        <td style="text-align: right;">{{transactionLine.subtotal | currency: 'EUR' }}</td>
                        <td>
                            <div class="flex gap-2 justify-content-end" [class.rowGroup]="transactionLine.isGroup">
                                <p-button *ngIf="allowDelete" (click)="deleteLine(transactionLine)" [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editTransactionLine(transactionLine.isGroup,transactionLine.rateLineId!=null?true:false, transactionLine)" [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>                      
                      <td [attr.colspan]="transactionType.thirdPartyType=='Company'?7:6" style="text-align: right;">Subtotal:</td>
                      <td style="text-align: right;">{{ transaction.subtotal | currency: 'EUR' }}</td>
                      <td></td>
                    </tr>
                    <tr *ngFor="let taxRate of (totalTax() | keyvalue)">                      
                      <td [attr.colspan]="transactionType.thirdPartyType=='Company'?7:6" style="text-align: right;">Impuesto ({{ taxRate.key }}%):</td>
                      <td style="text-align: right;">{{ taxRate.value | currency: 'EUR' }}</td>
                      <td></td>
                    </tr>
                    <tr>                      
                      <td [attr.colspan]="transactionType.thirdPartyType=='Company'?7:6" style="text-align: right;">Total:</td>
                      <td style="text-align: right;">{{ transaction.total | currency: 'EUR' }}</td>
                      <td></td>
                    </tr>
                  </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td style="text-align: center;" colspan="9">
                            No se han encontrado líneas.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
    </p-card>
</form>
<p-confirmDialog></p-confirmDialog>