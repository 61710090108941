import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Case } from '../../_types/case';
import { Company } from 'src/app/modules/companies/_types/company';
import { CasesService } from '../../_services/cases.service';
import { CompaniesService } from 'src/app/modules/companies/_services/companies.service';
import { ClientAddressesService } from 'src/app/modules/clients/_services/clients-addresses.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { ClientAddress } from 'src/app/modules/clients/_types/client-address';
import { first, firstValueFrom } from 'rxjs';
import { CaseState } from '../../_types/case-state';
import { Client } from 'src/app/modules/clients/_types/client';
import { Processor } from '../../_types/processor';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { CasesStatesService } from '../../_services/cases-states.service';
import { ClientsService } from 'src/app/modules/clients/_services/clients.service';
import { CasesDetailClientComponent } from '../client/client.component';
import { ClientsDetailComponent } from 'src/app/modules/clients/clients-detail/clients-detail.component';
import { CasesTypesService } from 'src/app/modules/cases-types/_services/cases-types.service';
import { CaseType } from 'src/app/modules/cases-types/_types/case-type';
import { ContractsService } from 'src/app/modules/companies/_services/contracts.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class CasesDetailGeneralComponent {
  public caseFormGroup!: RxFormGroup;

  public case: Case = new Case();
  public companies: Company[] = [];
  public caseTypes: CaseType[] = [];
  public caseStates: CaseState[] = [];
  public clients: Client[] = [];
  public contracts: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private casesService: CasesService,
    private companiesService: CompaniesService,
    private casesTypesService: CasesTypesService,
    private casesStatesService: CasesStatesService,
    private clientsService: ClientsService,
    private clientAddressesService: ClientAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private dialogService: DialogService,
    private contractsService: ContractsService
  ) {

    if (this.case.address == null) this.case.address = new ClientAddress();

    this.caseFormGroup = this.formBuilder.formGroup<Case>(this.case) as RxFormGroup;
    this.caseFormGroup.get('reference')?.disable();
  }

  async ngOnInit() {
    this.loadCompanies();
    this.loadCaseTypes();
    this.loadClients();
    this.loadCaseStates();

    let id = this.config.data.id;
    if (id != null && id != '0') {
      await this.loadCase(id);
      await this.loadContracts();
    } else {
      await this.loadInitialState();
      console.log(this.case);
    }


    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadCase(id: string) {
    this.case = await firstValueFrom(this.casesService.get(id, { include: ['company', 'client', 'processor', 'address', 'caseState'] }));
    this.caseFormGroup.patchValue(this.case);
  }

  async loadCompanies() {
    let response = await firstValueFrom(this.companiesService.all());
    this.companies = response.data;
  }

  async loadContracts(){      
    let filters = {
      companyId: new Filter(FilterOperation.equals, 'companyId',"", this.caseFormGroup.value.companyId)
    };
    
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    
    let response = await firstValueFrom(this.contractsService.all(params));
    this.contracts = response.data;                       
}

  async loadClients() {
    let response = await firstValueFrom(this.clientsService.all());
    this.clients = response.data;
  }

  async loadCaseTypes() {
    let response = await firstValueFrom(this.casesTypesService.all());
    this.caseTypes = response.data;
  }

  async loadCaseStates() {
    let response = await firstValueFrom(this.casesStatesService.all());
    this.caseStates = response.data
  }  

  async loadInitialState() {
    let params: IParamsCollection = {}
    let filters = {
      isInitial: new Filter(FilterOperation.equals, 'isInitial', '', true)
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesStatesService.all(params));
    if (response.data[0].id !== undefined) {
      this.case.caseStateId = response.data[0].id;
    }
  }

  async save() {
    console.log(this.caseFormGroup.getErrorSummary(false));
    let valid = this.formValidationService.validateForm(this.caseFormGroup);
    if (valid) {
      this.case = Object.assign(this.case, this.caseFormGroup.value);

      if (this.case.address != null) {
        let address: any = await firstValueFrom(this.clientAddressesService.save(this.case.address));
        this.case.addressId = address.id;
      }

      let caseItem = await firstValueFrom(this.casesService.save(this.case));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Expediente guardado correctamente." });
      this.dynamicDialogRef.close(caseItem);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el expediente?",
      header: "Eliminar expediente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesService.delete(this.case.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Expediente eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async editClient(clientId: string | null) {

    let ref = this.dialogService.open(ClientsDetailComponent, {
      header: 'Nuevo cliente',
      width: '900px',
      data: {
        id: clientId
      }
    });

    ref.onClose.subscribe(async (data: Client) => {
      console.log(data);
      if (data != null) {
        this.loadClients();
        this.case.clientId = data.id!;
        this.caseFormGroup.patchValue({ clientId: data.id });
      }
    });

    //ajuste para resolver error de foco
    const dynamicDialogComponent = this.dialogService.dialogComponentRefMap.get(this.dynamicDialogRef);
    if (dynamicDialogComponent) {
      // unbind tab/esc buttons listeners
      dynamicDialogComponent.instance.unbindGlobalListeners();

      // when the second modal is closed/destroyed then
      ref.onDestroy.pipe(first()).subscribe(() => {
        // reinitialize the first modal
        dynamicDialogComponent.instance.moveOnTop(); // we need this for properly Esc button handler
        dynamicDialogComponent.instance.bindGlobalListeners(); // bind tab/esc buttons listeners
        dynamicDialogComponent.instance.focus(); // apply [autofocus] if any
      });
    }

  }

}
