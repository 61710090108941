
import { LocalStorageService } from 'angular-web-storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LoginService } from '../../login/login.service';

import { Component, OnInit } from '@angular/core';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { firstValueFrom } from 'rxjs';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { RatesService } from '../_services/rates.service';

@Component({
  selector: 'app-rates-list',
  templateUrl: './rates-list.component.html',
})
export class RatesComponent implements OnInit{
  
  public rates: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any = {
    first: 0,
    rows: 0
  };
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public type: string = "";

  constructor(
    private storage: LocalStorageService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,                
    private router: Router,
    private topbarService: TopbarService,
    private loginService: LoginService,
    private ratesService: RatesService,
    private route: ActivatedRoute
  ) {
        
    this.allowWrite = true;//this.loginService.hasPermission("RATES_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("RATES_DELETE")?
  }

  ngOnInit(): void {        

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.type = params.get('type')??'';   
      this.topbarService.setTitle("Baremos de "+(this.type=="COMPANY"?"compañía":"operador"));   
      this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
        this.edit({});
      });
      this.loadData(this.lastGridEvent);
    });
    this.allowWrite = true;//this.loginService.hasPermission("RATES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("RATES_DELETE");
    
  }

  async loadData($event: any) {
    
    this.lastGridEvent = $event;

    let params:IParamsCollection = {      
      page: { number: $event.first+1, size: $event.rows },            
    }
    console.log(this.type);
    this.filters.typeOfRate = new Filter(FilterOperation.equals,  'typeOfRate', "", this.type=="COMPANY"?0:1)
    
    
    if(typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.ratesService.all(params));
    this.rates = response.data;
    this.totalRecords = response.meta['total'];
  }
  
  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(rate: any) {
    //if (this.loginService.hasPermission("RATES_WRITE")) {      
      this.router.navigate(['rates/'+this.type+'/' + (rate && Object.keys(rate).length > 0 ? rate.id : 0)]);
    //}
  }

  delete(rate: any) {    
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el baremo?",
        header: "Eliminar baremo",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.ratesService.delete(rate.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Baremo eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  getSeverity(active: boolean) {
    if(active){
      return 'success'; 
    }else{
      return 'danger';
    }    
  }
}
