import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { PublicSurveyResponseRoutingModule } from './public-survey-response-routing.module';
import { PublicSurveyResponseComponent } from './survey-response/public-survey-response.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PublicSurveyResponseThanksComponent } from './survey-response-thanks/public-survey-response-thanks.component';

@NgModule({
    declarations: [
        PublicSurveyResponseComponent,
        PublicSurveyResponseThanksComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        FormsModule,
        PublicSurveyResponseRoutingModule,
        CardModule,
        ButtonModule,
        SelectButtonModule,
        InputTextareaModule,
        RadioButtonModule
    ]
})
export class PublicSurveyResponseModule { }
