import { Component, ViewEncapsulation } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom} from 'rxjs';
import { TransactionsService } from '../_services/transactions.service';
import { TransactionsTypesService } from '../_services/transactions-types.service';
import { JobsService } from '../../cases/_services/jobs.service';
import { Job } from '../../cases/_types/job';
import { OperatorsService } from '../../companies/_services/operators.service';
import { Operator } from '../../companies/_types/operator';
import { BusinessService } from '../../business/_services/business.service';

@Component({
  selector: 'app-transactions-operators',
  templateUrl: './transactions-operators.component.html',
  styleUrls: ['./transactions-operators.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsOperatorsComponent {
  
  public loading: boolean = false;
  public type!: string;
  
  private lastGridEvent: any;
  public filters: any = {    
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public jobsCaseServices: any[] = [];
  public jobs: Job[] = [];

  public operators: Operator[] = [];
  public business: any = [];
  public selectedLines: Job[] = [];


  constructor(
    private route: ActivatedRoute,
    public topbarService: TopbarService,
    private jobsService: JobsService,
    private operatorsService: OperatorsService,
    private businessService: BusinessService
  ) {


    this.allowWrite = true;
    this.allowDelete = true;
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(() => {                                
      this.initialLoad();
    });

    this.loadOperators();

    this.selectedLines = [];

  }

  async initialLoad(){
    this.loading = true;
    let params: IParamsCollection = {

    }
    let filters: any = {
      isReview: new Filter(FilterOperation.equals, "isReview","",true),
      isBillable: new Filter(FilterOperation.equals, "isBillable","",true),
      transactionId: new Filter(FilterOperation.equals, "transactionId","",null)
    };
    
    
    params.filter = FilterBuilder.fromObject(filters).build();
    params.include = ['caseService', 'operator'];

    let response = await firstValueFrom(this.jobsService.all(params));
    this.jobs = response.data;
    this.selectedLines = response.data;
    this.jobsCaseServices = [];
    this.jobs.forEach((job: Job) => {
      const formatoPersonalizado = job.caseService?.date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      this.jobsCaseServices.push({
        id: job.caseServiceId,
        name: formatoPersonalizado+" - "+job.operator.name        
      });
    });
    // quitar duplicados
    this.jobsCaseServices = this.jobsCaseServices.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }

  async loadData() {
    

    this.loading = true;
    let params: IParamsCollection = {

    }
    let filters: any = {
      isReview: new Filter(FilterOperation.equals, "isReview","",true),
      isBillable: new Filter(FilterOperation.equals, "isBillable","",true),
      transactionId: new Filter(FilterOperation.equals, "transactionId","",null)
    };

    if(this.filters.operatorId != "null"){
      filters.operatorId = new Filter(FilterOperation.equals, "operatorId","",this.filters.operatorId);
    }
    
    params.filter = FilterBuilder.fromObject(filters).build();
    params.include = ['caseService', 'operator'];

    let response = await firstValueFrom(this.jobsService.all(params));
    this.jobs = response.data;    
    this.jobsCaseServices = [];
    this.jobs.forEach((job: Job) => {
      const formatoPersonalizado = job.caseService?.date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      this.jobsCaseServices.push({
        id: job.caseServiceId,
        name: formatoPersonalizado+" - "+job.operator.name        
      });
    });
    // quitar duplicados
    this.jobsCaseServices = this.jobsCaseServices.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }

  async loadOperators() {
    let allOperators = new Operator();
    allOperators.id = "null";
    allOperators.name = "Todos";
    let response = await firstValueFrom(this.operatorsService.all());
    this.operators = response.data;  
    this.operators.unshift(allOperators);      
  }  

  async loadBusiness(){
    let allBusiness = new Operator();
    allBusiness.id = "null";
    allBusiness.name = "Todos";
    let response = await firstValueFrom(this.businessService.all());
    this.business = response.data;  
    this.business.unshift(allBusiness);   
  }

  jobsByCaseService(caseServiceId: string) {
    return this.jobs.filter((job: Job) => job.caseServiceId == caseServiceId);
  }
  
  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {    
    this.loadData();
  } 

  save(){
    console.log(this.selectedLines);
  }

  getSubtotalSelected(): number {
    return this.selectedLines.reduce((acc, line) => acc + line.total, 0);    

  }

  totalTax(): { [key: string]: number } {
    const taxSummary: { [key: string]: number } = {};
    /*this.transaction.taxAmount = 0;
    this.transactionLines.filter(m => !m.isGroup && m.taxPercent != null).forEach(line => {
      const taxRateKey = line.taxPercent?.toString();
      const taxAmount = line.taxAmount;

      this.transaction.taxAmount += taxAmount;      
      if (!taxSummary[taxRateKey]) {
        taxSummary[taxRateKey] = 0;
      }
      taxSummary[taxRateKey] += taxAmount;
    });*/    
    return taxSummary;
  }
  
  getTotalSelected(): number {    
    return this.selectedLines.reduce((acc, line) => acc + line.total, 0);   
  }
}
