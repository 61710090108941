import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessListComponent } from './business-list/business-list.component';
import { BusinessDetailComponent } from './business-detail/business-detail.component';

const routes: Routes = [
    {
        path: 'business',
        children: [
            { path: '', component: BusinessListComponent }
        ]
    },
    {
        path: 'business/:id',
        children: [
            { path: '', component: BusinessDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessRoutingModule { }
