import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Operator } from "../../companies/_types/operator";
import { Client } from "../../clients/_types/client";
import { Processor } from "./processor";
import { Case } from "./case";

export class CaseNote extends Resource {
    public override type = 'caseNotes';

    @prop()
    @required()
    public date: Date = new Date();

    @prop()
    @required()
    public source: string = "User";

    @prop()
    @required()
    public caseId!: string;

    public case!: Case;

    @prop()
    public operatorId!: string;

    public operator!: Operator;

    @prop()
    public clientId!: string;

    public client!: Client;

    @prop()
    public userId!: string;

    public user!: Processor;

    @prop()
    public notes!: string;


}