import { EventEmitter, Injectable, Output } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Notification } from "../_types/notification";
import { AsyncSubject, firstValueFrom, Observable } from "rxjs";
import { ResponseResource } from "src/app/core/jsonapi/responses/response-resource";

@Injectable({
    providedIn: 'root',
})
export class NotificationsService extends Service<Notification> {
    public override type = 'notifications';
    public override url = 'communications/notifications';

    @Output() onShowSidebar = new EventEmitter<boolean>();
    @Output() onNotificationReaded = new EventEmitter<void>();

    openNotifications() {
        this.onShowSidebar.emit(true);
    }

    closeNotifications() {
        this.onShowSidebar.emit(false);
    }

    markAsReaded(notification: Notification): Observable<Notification> {
        let subject = new AsyncSubject<Notification>();
        notification.readedAt = new Date();
        this.save(notification).subscribe((response: Notification) => {
            subject.next(response);
            subject.complete();
            this.onNotificationReaded.emit();
        });
        return subject.asObservable();
    }

    notificationTypes() {
        let templateTypes = [
            { label: 'Chats', value: 'Chat' },
            { label: 'Tareas', value: 'Task' },
            { label: 'Expedientes', value: 'Case' }
        ];
        return templateTypes;
    }

    getNotificationTypeLabel(value: string) {
        let notificationTypes = this.notificationTypes();
        let notificationType = notificationTypes.find(x => x.value == value);
        return notificationType?.label;
    }

    notificationStates() {
        let templateTypes = [
            { label: 'Todos', value: 'All' },
            { label: 'Leídos', value: 'Readed' },
            { label: 'No leídos', value: 'NotReaded' }
        ];
        return templateTypes;
    }

}
