import { Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { RxFormGroup } from "@rxweb/reactive-form-validators"

@Injectable({
    providedIn: 'root',
})
export class FormValidatorService {
    validateForm(form:FormGroup|RxFormGroup): boolean {
        if (!form.valid) {
            for (let i in form.controls) {
                form.controls[i].markAsTouched();
                form.controls[i].updateValueAndValidity();
            }
            return false
        }
        else {
            return true
        }
    }
}