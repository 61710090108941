import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ClientPolicyGuarantee } from "../_types/client-policy-guarantee";

@Injectable({
    providedIn: 'root',
})
export class ClientPolicyGuaranteesService extends Service<ClientPolicyGuarantee> {
    public override type = 'clientPolicyGuarantees';
    public override url = 'attendance/clientPolicyGuarantees';
}
