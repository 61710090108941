import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { WorkersListComponent } from './workers-list/workers-list.component';
import { WorkersDetailComponent } from './workers-detail/workers-detail.component';
import { WorkersRoutingModule } from './workers-routing.module';
import { WorkersCapabiliyComponent } from './workers-capabiliy/workers-capabiliy.component';
import { WorkersZoneComponent } from './workers-zone/workers-zone.component';
import { WorkersTimeRangeComponent } from './workers-time-range/workers-time-range.component';
import { CoreModule } from 'src/app/core/core.module';
import { WorkersHolidaysModalComponent } from './workers-detail/holidays-modal/workers-holidays-modal.component';
import { RatingModule } from 'primeng/rating';
import { WorkersVehicleComponent } from './workers-vehicle/workers-vehicle.component';
import { WorkersEquipmentsComponent } from './workers-equipments/workers-equipments.component';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { WorkersVehiclesDetailComponent } from './workers-vehicles-detail/workers-vehicles-detail.component';
import { PasswordModule } from 'primeng/password';
import { WorkersFileComponent } from './workers-file/workers-file.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AddSubworkerComponent } from './workers-detail/add-subworker/add-subworker.component';


@NgModule({
    declarations: [
        WorkersListComponent,
        WorkersDetailComponent,
        WorkersCapabiliyComponent,
        WorkersZoneComponent,
        WorkersVehicleComponent,
        WorkersTimeRangeComponent,
        WorkersHolidaysModalComponent,
        WorkersEquipmentsComponent,
        WorkersVehiclesDetailComponent,
        WorkersFileComponent,
        AddSubworkerComponent
    ],
    imports: [
        WorkersRoutingModule,
        CoreModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        TableModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        CheckboxModule,
        TabViewModule,
        CalendarModule,
        TagModule,
        InputTextareaModule,
        DropdownModule,
        DialogModule,
        EditorModule,
        RatingModule,
        VehiclesModule,
        PasswordModule,
        FileUploadModule
    ],
    providers: []
})
export class WorkersModule { }