import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class WorkerTimeRange extends Resource {
    public override type = 'workerTimeRanges';

    @prop()
    @required()
    public typeOfTimeRange: string = 'Normal';

    @prop()
    @required()
    public workerId!: string;

    @prop()
    public endHour!: any;

    @prop()
    public startHour!: any;

    @prop()
    public monday: boolean = false;

    @prop()
    public tuesday: boolean = false;

    @prop()
    public wednesday: boolean = false;

    @prop()
    public thursday: boolean = false;

    @prop()
    public friday: boolean = false;

    @prop()
    public saturday: boolean = false;

    @prop()
    public sunday: boolean = false;

}