import { Resource } from "src/app/core/jsonapi/resource";
import { prop } from "@rxweb/reactive-form-validators";

export class ContractOperator extends Resource {
    public override type = 'contractOperators';
    
    @prop()
    public contractId!: string;
    @prop()
    public operatorId!: string;    
    
}