import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { User } from '../_types/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends Service<User> {
  public override type = 'users';
  public override url = 'auth/users'; 
}
