import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Affected } from "../_types/affected";

@Injectable({
    providedIn: 'root',
})
export class AffectedsService extends Service<Affected> {
    public override type = 'affecteds';
    public override url = 'attendance/affecteds';
}
