<div class="flex gap-2 cola-4">
    <span class="flex-grow-1 input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
    <div class="input-field" style="width: 150px;">
        <p-dropdown placeholder="Estado" [options]="casesStates" optionLabel="name" optionValue="id"
            [(ngModel)]="filters.caseStateId.value" (onChange)="search()" [showClear]="true" />
    </div>
</div>

<p-table styleClass="mt-4" #grid [value]="cases" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="reference">Referencia <p-sortIcon field="reference"></p-sortIcon></th>
            <th pSortableColumn="caseNumber">Ref. compañia <p-sortIcon field="caseNumber"></p-sortIcon></th>
            <th pSortableColumn="caseDate">Fecha <p-sortIcon field="caseDate"></p-sortIcon></th>
            <th pSortableColumn="caseType">Tipo <p-sortIcon field="caseType"></p-sortIcon></th>
            <th pSortableColumn="client.name">Asegurado <p-sortIcon field="client.name"></p-sortIcon></th>
            <th pSortableColumn="address.fullAddress">Dirección <p-sortIcon field="address.fullAddress"></p-sortIcon>
            </th>
            <th pSortableColumn="processor.name">Tramitador <p-sortIcon field="processor.name"></p-sortIcon></th>
            <th>Días abierto</th>
            <th pSortableColumn="caseState.name">Estado <p-sortIcon field="caseState.name"></p-sortIcon></th>
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-case let-columns="columns">
        <tr (dblclick)="edit(case)">
            <td>{{case.reference}}</td>
            <td>{{case.caseNumber}}</td>
            <td>{{case.caseDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{case.caseType?.name}}</td>
            <td>{{case.client?.name}}</td>
            <td>{{case.address?.fullAddress}}</td>
            <td>{{case.processor?.name}}</td>
            <td>{{getTimeAgo(case.caseDate)}}</td>
            <td>
                <span class="p-tag" [style.backgroundColor]="case.caseState?.backgroundColor" [style.foregroundColor]="case.caseState?.foregroundColor">
                    <span class="p-tag-value">{{case.caseState?.name}}</span>
                </span>
            </td>
            <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowDelete" (click)="delete(case)" [rounded]="true" [text]="false"
                        severity="danger"><i class="pi pi-trash"></i></p-button>
                    <p-button *ngIf="allowWrite" (click)="edit(case)" [rounded]="true" [text]="false"><i
                            class="pi pi-pencil"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>