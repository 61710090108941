import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerBankAccount } from "../_types/worker-bank-account";

@Injectable({
  providedIn: 'root',
})
export class WorkerBankAccountsService extends Service<WorkerBankAccount> {
  public override type = 'workerBankAccounts';
  public override url = 'providers/workerBankAccounts';
}