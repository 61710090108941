<div class="grid">
    <div class="col-4 input-field">
        <label for="operatorId">Operario</label>
        <p-dropdown [options]="operators" [(ngModel)]="filters.operatorId" optionLabel="name" optionValue="id"
            [filter]="true" filterBy="name" appendTo="body" placeholder="Selecciona un operador"
            (onChange)="search()"></p-dropdown>
    </div>
    <div class="col-4 input-field">
        <label for="operatorId">Sociedad</label>
        <p-dropdown [options]="business" [(ngModel)]="filters.businessId" optionLabel="name" optionValue="id"
            [filter]="true" filterBy="name" appendTo="body" placeholder="Selecciona un operador"
            (onChange)="search()"></p-dropdown>
    </div>
    <div class="col-2 input-field">
        <label for="dateStart">Desde</label>
        <p-calendar [(ngModel)]="filters.dateFrom" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
            [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
    </div>
    <div class="col-2 input-field">
        <label for="dateEnd">Hasta</label>
        <p-calendar [(ngModel)]="filters.dateTo" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
            [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
    </div>
</div>

<p-card *ngFor="let caseService of jobsCaseServices" [header]="caseService.name" styleClass="mt-4">
    <div class="p-1">
        <p-table #grid [value]="jobsByCaseService(caseService.id)" [scrollable]="true" scrollHeight="flex"
            [(selection)]="selectedLines" dataKey="id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                    <th>Fecha</th>
                    <th>Baremo</th>
                    <th style="text-align: right;">Cantidad</th>
                    <th style="text-align: right;">Precio</th>
                    <th style="text-align: right;">Impuestos</th>
                    <th style="text-align: right;">Total</th>
                    <th width="120"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-job let-columns="columns">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="job" />
                    </td>
                    <td>
                        {{job.date | date: 'dd/MM/YY HH:mm'}}
                    </td>
                    <td>
                        {{job.rateLine?.code}} - {{job.rateLine?.description}}
                    </td>
                    <td style="text-align: right;">
                        {{job.quantity}}
                    </td>
                    <td style="text-align: right;">
                        {{job.unitPrice}}
                    </td>
                    <td style="text-align: right;">
                        {{'('+(job.taxPercent!=null?job.taxPercent:0)+'%) '}}{{job.taxAmount | currency: 'EUR' }}
                    </td>
                    <td style="text-align: right;">
                        {{job.total}}
                    </td>
                    <td>
                        <div class="flex gap-2 justify-content-end" style="width: 100%;">
                            <!--<p-button *ngIf="allowDelete && job.transactionId == null" (click)="deleteJob(job)" [rounded]="true" [text]="false"
                            severity="danger"><i class="pi pi-trash"></i></p-button>-->
                            <!--<p-button *ngIf="allowWrite" (click)="editJob('OPERATOR', job)" [rounded]="true"
                                [text]="false"><i class="pi pi-pencil"></i></p-button>-->
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template pTemplate="footer">
        <!--<tr>                      
            <td [attr.colspan]="transactionType.thirdPartyType=='Company'?7:6" style="text-align: right;">Subtotal:</td>
            <td style="text-align: right;">{{ getSubtotal() | currency: 'EUR' }}</td>
            <td></td>
          </tr>
          <tr *ngFor="let taxRate of (totalTax() | keyvalue)">                      
            <td [attr.colspan]="7" style="text-align: right;">Impuesto ({{ taxRate.key }}%):</td>
            <td style="text-align: right;">{{ taxRate.value | currency: 'EUR' }}</td>
            <td></td>
          </tr>
          <tr>                      
            <td [attr.colspan]="7" style="text-align: right;">Total:</td>
            <td style="text-align: right;">{{ getTotal() | currency: 'EUR' }}</td>
            <td></td>
          </tr>-->
    </ng-template>
</p-card>
<div *ngIf="jobsCaseServices.length > 0" class="mt-4 flex gap-2 justify-content-end">
    <div>
        <h3><b>Subtotal:</b> {{ getSubtotalSelected() | currency: 'EUR' }}</h3>
        <h3><b>Impuestos:</b> </h3>
        <h2><b>Total:</b> {{ getTotalSelected() | currency: 'EUR' }}</h2>
    </div>
</div>
<div *ngIf="jobsCaseServices.length > 0" class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Generar factura" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
</div>
<span *ngIf="jobsCaseServices.length == 0">
    No se han encontrado líneas de baremo
</span>