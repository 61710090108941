import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { UsersService } from 'src/app/modules/users/_services/users.service';
import { User } from 'src/app/modules/users/_types/user';
import { CaseTechnicalCreateNewComponent } from './create-new/create-new.component';

@Component({
  selector: 'app-technical',
  templateUrl: './technical.component.html',
  styleUrls: ['./technical.component.scss']
})
export class CaseTechnicalComponent {
  public technicals: User[] = [];
  public selectedTechnical: any;

  public existenCaseTechnicals: any[] = [];
  public caseId: string = '';

  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
    userType: new Filter(FilterOperation.contains, 'userType.code')
  };

  constructor(
    private usersService: UsersService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) { }

  async ngOnInit() {
    if (this.config.data && this.config.data.existentCaseTechnicals) {
      this.existenCaseTechnicals = this.config.data.existentCaseTechnicals;
    }

    this.caseId = this.config.data.caseId;
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows }
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    // filtrar solo técnicos
    this.filters.userType.value = 'technical';

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.usersService.all(params));
    this.technicals = response.data;

    if (this.existenCaseTechnicals.length > 0) {
      this.technicals = this.technicals.filter(technical => {
        return !this.existenCaseTechnicals.some(existentCaseTechnical => existentCaseTechnical.user.id === technical.id);
      });
    }

    this.totalRecords = this.technicals.length;
  }


  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  save() {
    this.dynamicDialogRef.close(this.selectedTechnical);
  }
}
