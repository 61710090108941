import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContractOperator } from '../../_types/contract-operator';
import { ContractOperatorsService } from '../../_services/contracts-operators.service';
import { Operator } from '../../_types/operator';
import { firstValueFrom } from 'rxjs';
import { OperatorsService } from '../../_services/operators.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-contract-operators-detail',
  templateUrl: './operators-detail.component.html',
  styleUrls: ['./operators-detail.component.css']
})
export class ContractOperatorsDetailComponent implements OnInit {
  
  public operatorFormGroup!: RxFormGroup;

  public contractOperator: ContractOperator = new ContractOperator();  
  public loading: boolean = false;  
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;  
  public operators: Array<Operator> = new Array<Operator>;
  public operatorsIds: Array<string> = [];
  

  constructor(
    private config: DynamicDialogConfig,
    private contractOperatorsService: ContractOperatorsService,
    private operatorsService: OperatorsService,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,              
  ) {
    this.operatorFormGroup = this.formBuilder.formGroup<ContractOperator>(this.contractOperator) as RxFormGroup;
  }
  

  ngOnInit(): void {    

    this.contractOperator.contractId = this.config.data.contractId;
    this.operatorsIds = this.config.data.operatorsIds;
            
    if(this.config.data.id!=null && this.config.data.id!='0'){
      this.loadContractOperator(this.config.data.id);
    }
         
    this.allowWrite = true;//this.loginService.hasPermission("ZONES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("ZONES_DELETE");

    this.loadOperators();
  }
  
  async loadContractOperator(id: string) {
    this.contractOperator = await firstValueFrom(this.contractOperatorsService.get(id));
    this.operatorFormGroup.patchValue(this.contractOperator);   
  }

  async loadOperators(){

    let response = await firstValueFrom(this.operatorsService.all());         
    console.log(this.operatorsIds);       
    this.operators = response.data.filter((x: any) => x.id == this.contractOperator.operatorId || !this.operatorsIds.some((y: any) => y == x.id));  

  }
      

  async save() {    
    let valid = this.formValidationService.validateForm(this.operatorFormGroup);
    if (valid) {

      this.contractOperator = Object.assign(this.contractOperator, this.operatorFormGroup.value);  
      let contractOperator:any = await firstValueFrom(this.contractOperatorsService.save(this.contractOperator));
      
      this.dynamicDialogRef.close();      

      this.messageService.add({ closable: false, severity: 'success', detail: "Operario preferente guardado correctamente" });
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Quieres eliminar el operario preferente?",
      header: "Eliminar opeario preferente",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractOperatorsService.delete(this.contractOperator.id).subscribe(
          (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Operario preferente eliminado correctamente" });

            this.cancel();
          },
          (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }    

}
