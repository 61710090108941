import { prop } from '@rxweb/reactive-form-validators';
import { Links } from './links';
import { Relationship } from './relationship';
import { RelationshipArray } from './relationship-array';

export class Resource {
  @prop()
  public type: string = ''; 

  @prop()
  public id?: string = '';

  public attributes: { [key: string]:any; } = {};
  
  public relationships: { [key: string]:Relationship<any>|RelationshipArray<any>; } = {};

  public links: Links = new Links();

}
