import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientsDetailComponent } from './clients-detail/clients-detail.component';

const routes: Routes = [
    {
        path: 'clients',
        children: [
            { path: '', component: ClientsListComponent }
        ]
    },
    {
        path: 'clients/:id',
        children: [
            { path: '', component: ClientsDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule { }
