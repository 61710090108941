import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { MenuItem } from 'primeng/api';
import { LoginService } from 'src/app/modules/login/login.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PanelMenu } from 'primeng/panelmenu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  @ViewChild('menu')
  public menu!: PanelMenu;

  public items: MenuItem[] = [
    { label: 'Inicio', icon: 'pi pi-home', routerLink: '/home', command: () => this.collapseAll() },
    {
      label: 'Asistencia', icon: 'pi pi-globe', items: [
        { label: 'Expedientes', icon: 'pi pi-clipboard', routerLink: '/cases', command: () => this.collapseAll() },
        { label: 'Tipos de expedientes', icon: 'pi pi-clipboard', routerLink: '/casesTypes', command: () => this.collapseAll() },
        { label: 'Clientes', icon: 'pi pi-user', routerLink: '/clients', command: () => this.collapseAll() },
        { label: 'Compañías aseguradoras', icon: 'pi pi-building', routerLink: '/companies', command: () => this.collapseAll() },
        { label: 'Coberturas', icon: 'pi pi-shield', routerLink: '/guarantees', command: () => this.collapseAll() },
        { label: 'Peritos', icon: 'pi pi-shield', routerLink: '/experts', command: () => this.collapseAll() },
      ]
    },
    {
      label: 'Proveedores', icon: 'pi pi-building', items: [
        { label: 'Proveedores', icon: 'pi pi-building', routerLink: '/providers', command: () => this.collapseAll() },
        { label: 'Operarios', icon: 'pi pi-user', routerLink: '/workers', command: () => this.collapseAll() },
        { label: 'Calendarios', icon: 'pi pi-calendar', routerLink: '/calendars', command: () => this.collapseAll() },
        { label: 'Turnos de guardia', icon: 'pi pi-calendar', routerLink: '/guards-shifts', command: () => this.collapseAll() }
      ]
    },
    {
      label: 'Facturación', icon: 'pi pi-euro', items: [
        {
          label: 'Presupuestos a clientes', icon: 'pi pi-receipt', routerLink: '/transactions/outgoing_budget', command: () => this.collapseAll()
        },
        {
          label: 'Presupuestos a companías', icon: 'pi pi-receipt', routerLink: '/transactions/company_outgoing_budget', command: () => this.collapseAll()
        },
        {
          label: 'Pedidos a proveedores', icon: 'pi pi-receipt', routerLink: '/transactions/outgoing_order', command: () => this.collapseAll()
        },
        {
          label: 'Facturas de operario', icon: 'pi pi-receipt', routerLink: '/transactions/incoming_invoice', command: () => this.collapseAll()
        },
      ]
    },
    {
      label: 'Tareas', icon: 'pi pi-clipboard', items: [
        { label: 'Tareas', icon: 'pi pi-pen-to-square', routerLink: '/tasks', command: () => this.collapseAll() },
        { label: 'Notificaciones', icon: 'pi pi-bell', routerLink: '/notifications', command: () => this.collapseAll() }
      ]
    },
    {
      label: 'Configuración', icon: 'pi pi-cog', items: [
        { label: 'Usuarios', icon: 'pi pi-users', routerLink: '/users', command: () => this.collapseAll() },
        { label: 'Roles', icon: 'pi pi-shield', routerLink: '/roles', command: () => this.collapseAll() },
        { label: 'Sociedades', icon: 'pi pi-warehouse', routerLink: '/business', command: () => this.collapseAll() },
        { label: 'Bancos', icon: 'pi pi-euro', routerLink: '/banks', command: () => this.collapseAll() },
        { label: 'Servicios de autenticación', icon: 'pi pi-verified', routerLink: '/authentications-services', command: () => this.collapseAll() },
        { label: 'Baremos de compañía', icon: 'pi pi-sliders-h', routerLink: '/rates/COMPANY', command: () => this.collapseAll() },
        { label: 'Baremos de operario', icon: 'pi pi-sliders-h', routerLink: '/rates/OPERATOR', command: () => this.collapseAll() },
        { label: 'Encuestas', icon: 'pi pi-check-square', routerLink: '/surveys', command: () => this.collapseAll() },
        { label: 'Plantillas email/sms', icon: 'pi pi-sitemap', routerLink: '/templates', command: () => this.collapseAll() },
        { label: 'Especialidades', icon: 'pi pi-list', routerLink: '/capabilities', command: () => this.collapseAll() },
        { label: 'Equipamiento', icon: 'pi pi-wrench', routerLink: '/equipments', command: () => this.collapseAll() },
        { label: 'Tipos de vehículos', icon: 'pi pi-car', routerLink: '/vehiclesTypes', command: () => this.collapseAll() },
        { label: 'Vehículos', icon: 'pi pi-car', routerLink: '/vehicles', command: () => this.collapseAll() },
      ]
    },
    { label: 'Cerrar sesión', icon: 'pi pi-sign-out', command: () => { this.collapseAll(); this.logout(); } },

  ];

  constructor(
    private router: Router,
    public sidebarService: SidebarService,
    private loginService: LoginService
  ) {
  }

  collapseAll() {
    if (!this.sidebarService.isExpanded()) {
      this.menu.collapseAll();
      //this.sidebarService.toogle();
    } else {
    }
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  toogleMenu(element: ElementRef) {
    console.log(element);
  }
}
