<div class="topbar p-4 pb-3 flex align-items-center gap-4">
    <a class="pi pi-bars" (click)="sidebarService.toogle()"></a>
    <div class="title flex-grow-1">{{topbarService.getTitle()}}</div>
    <div>
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Buscar..." class="search-input" />
        </span>
    </div>
    <div class="flex justify-content-end align-items-center gap-2">
        <div class="notifications-area">
            <p-button (click)="showNotifications()" [rounded]="true" [text]="false" severity="secondary" styleClass="topbar-button-gray" [class.blink_me]="notificationsCount>0">
                <i class="pi pi-bell"></i>
            </p-button>
            <span class="bullet" *ngIf="notificationsCount>0">{{notificationsCount}}</span>
        </div>
        <div class="flex user-area">
            <img src="assets/images/no-user-image.png" class="user-image" />
            <div class="flex flex-column justify-content-center">
                <div class="user-name">{{user.name}}</div>
                <div class="user-role">{{user.userName}}</div>
            </div>
        </div>
    </div>
</div>
<div class="breadcrumb pl-4 pr-4 pb-2 flex">
    <div class="items flex-grow-1">
        <div class="item">
            <i class="pi pi-home"></i>
            <a [routerLink]="['/home']" class="pl-2">Inicio</a>
            <span class="separator" *ngIf="topbarService.getBreadcrumb().length>0">/</span>
        </div>
        <div *ngFor="let item of topbarService.getBreadcrumb(); let last = last" class="item">
            <a *ngIf="!last" [routerLink]="item.url">{{item.label}}</a>
            <span *ngIf="!last" class="separator">/</span>
            <span *ngIf="last && item.url==''" class="no-link">{{item.label}}</span>
            <a *ngIf="last && item.url!=''" [routerLink]="item.url">{{item.label}}</a>
        </div>
    </div>
    <div class="actions flex">
        <p-button class="ml-2" *ngFor="let action of topbarService.getActions()" (click)="topbarService.doAction(action)" [rounded]="true" [text]="action.show_text" severity="{{action.severity}}" label="{{(action.show_text?action.label:'')}}"><i [ngClass]="action.icon"></i></p-button>
    </div>
</div>
