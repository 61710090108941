import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Client } from "../_types/client";

@Injectable({
    providedIn: 'root',
})
export class ClientsService extends Service<Client> {
    public override type = 'clients';
    public override url = 'attendance/clients';
}
