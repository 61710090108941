import { Injectable } from "@angular/core";
import { ProviderAddress } from "../_types/provider-address";
import { Service } from "src/app/core/jsonapi/service";

@Injectable({
    providedIn: 'root',
})
export class ProviderAddressesService extends Service<ProviderAddress> {
  public override type = 'providerAddresses';
  public override url = 'providers/providerAddresses';
}
