import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
/** loader component*/
export class LoaderComponent implements OnInit, OnDestroy {

  private subscription!: Subscription;
  public pendingRequests: number = 0;
  public loading: boolean = false;
  public loadingPercent: number = 0;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.onPendingRequest.subscribe((requestSubscription) => {
      this.pendingRequests = requestSubscription.pendingRequests;
      this.loadingPercent = requestSubscription.loadingPercent;
      Promise.resolve(null).then(() => this.loading = requestSubscription.pendingRequests > 0);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
