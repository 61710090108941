
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Company } from '../_types/company';
import { CompaniesService } from '../_services/companies.service';
import { CompanyAddressesService } from '../_services/companies-addresses.service';
import { CompanyAddress } from '../_types/company-address';
import { ContractsService } from '../_services/contracts.service';
import { Contract } from '../_types/contract';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Country } from '../../common/_types/country';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CountriesService } from '../../common/_services/countries.service';

@Component({
  selector: 'app-companies-detail',
  templateUrl: './companies-detail.component.html',
  styleUrls: ['./companies-detail.component.css']
})
export class CompaniesDetailComponent implements OnInit {

  public companyFormGroup!: RxFormGroup;
  
  public company: Company = new Company();
  public totalRecords: number = 0;
  public loading: boolean = false;  
  public activeIndex: number = 1;    
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;    
  public banks: any = [];    
  public contracts: Array<Contract> = new Array<Contract>();       
  public countries: Array<Country> = new Array<Country>();

  constructor(
    private companiesService: CompaniesService,    
    private companyAddressesService: CompanyAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private contractsService: ContractsService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService
  ) {
    this.topbarService.setTitle("Detalles de compañía aseguradora");    
    this.topbarService.addBreadcrumb("Compañías aseguradoras", "/companies");    

    this.companyFormGroup = this.formBuilder.formGroup<Company>(this.company) as RxFormGroup;

  }

  ngOnInit(): void {
    

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      if(id!=null && id!='0'){
        this.loadCompany(id);         
      }          

      this.loadCountries();
  
      //si no tiene address, la creamos
      if(this.company.address==null) this.company.address = new CompanyAddress();
    });
    this.allowWrite = true;//this.loginService.hasPermission("COMPANIES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("COMPANIES_DELETE");

    
  }  
  
  async loadCompany(id: string) {
    this.company = await firstValueFrom(this.companiesService.get(id, { include: ['address'] }));    
    this.companyFormGroup.patchValue(this.company);
    this.loadContracts();
  }

  async loadContracts(){
  
    let filters = {
      companyId: new Filter(FilterOperation.equals, 'companyId',"", this.company.id)
    };
    
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    
    let response = await firstValueFrom(this.contractsService.all(params));
    this.contracts = response.data;        
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }
  

  async save() {        
    let valid = this.formValidationService.validateForm(this.companyFormGroup);
    if (valid) {

      this.company = Object.assign(this.company, this.companyFormGroup.value);      
       
        //guardamos direccion      
        if(this.company.address!=null){
          let address:any = await firstValueFrom(this.companyAddressesService.save(this.company.address));
          this.company.address.id = address.id;                
        }
        
        //guardamos company
        let company = await firstValueFrom(this.companiesService.save(this.company, {include:['address']}));
                      

        if(this.company.id != "undefined" && this.company.id != ""){
          this.router.navigate(['companies']);
        }        
        
        this.messageService.add({ closable: false, severity: 'success', summary: "Compañía aseguradora guardada correctamente." });

        this.loadCompany(company.id!);
      
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la compañía aseguradora "+this.company.name+"?",
        header: "Eliminar compañía aseguradora",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.companiesService.delete(this.company.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Compañía aseguradora eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['companies']);
  }


  editContract(contract: any) {
    //if (this.loginService.hasPermission("COMPANIES_WRITE")) {      
      this.router.navigate(['companies/'+this.company.id+'/contracts/' + (contract && Object.keys(contract).length > 0 ? contract.id : 0)]);
    //}
  }

  deleteContract(contract: Contract) {    
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el contrato "+contract.name+"?",
        header: "Eliminar contrato",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.contractsService.delete(contract.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Contrato eliminado correctamente" });
              this.loadContracts();
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
    
}
