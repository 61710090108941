import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseFile } from "../_types/case-file";

@Injectable({
    providedIn: 'root',
})
export class CasesFilesService extends Service<CaseFile> {
    public override type = 'caseFiles';
    public override url = 'attendance/caseFiles';
}
