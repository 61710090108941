import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerAddress } from "../_types/worker-address";

@Injectable({
  providedIn: 'root',
})
export class WorkerAddressesService extends Service<WorkerAddress> {
  public override type = 'workerAddresses';
  public override url = 'providers/workerAddresses';
}
