import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { File } from "../../common/_types/file";

export class WorkerFile extends Resource {
    public override type = 'workerFiles';

    @prop()
    @required()
    public workerId!: string;

    public worker!: Worker;

    @prop()
    public fileId!: string;

    public file!: File;

    @prop()
    public name!: string;

    @prop()
    public notes!: string;
}