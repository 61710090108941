import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Case } from "./case";
import { Expert } from "../../experts/_types/expert";

export class CaseExpert extends Resource {
    public override type = 'caseExperts';

    @prop()
    @required()
    public caseId!: string;

    public case: Case = new Case();

    @prop()
    @required()
    public expertId!: string;

    public expert: Expert = new Expert();
}