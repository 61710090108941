import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Guarantee } from '../_types/guarantee';
import { GuaranteesService } from '../_services/guarantees.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-guarantees-detail',
  templateUrl: './guarantees-detail.component.html',
  styleUrls: ['./guarantees-detail.component.scss']
})
export class GuaranteesDetailComponent {
  public guaranteeFormGroup!: RxFormGroup;

  public guarantee: Guarantee = new Guarantee();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private guaranteesService: GuaranteesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.guaranteeFormGroup = this.formBuilder.formGroup<Guarantee>(this.guarantee) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.config.data.id;
    if (id != null && id != '0') {
      await this.loadGuarantee(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadGuarantee(id: string) {
    this.guarantee = await firstValueFrom(this.guaranteesService.get(id));
    this.guaranteeFormGroup.patchValue(this.guarantee);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.guaranteeFormGroup);
    if (valid) {
      this.guarantee = Object.assign(this.guarantee, this.guaranteeFormGroup.value);

      let caseItem = await firstValueFrom(this.guaranteesService.save(this.guarantee));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Cobertura guardada correctamente." });
      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la cobertura?",
      header: "Eliminar cobertura",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.guaranteesService.delete(this.guarantee.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Cobertura eliminada correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
