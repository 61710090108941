import { minLength, prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class ClientBankAccount extends Resource {
    public override type = 'clientBankAccounts';

    @prop()
    public bankId!: string;

    @prop()
    public accountNumber!: string;

    @prop()
    public swift!: string;

}