import { Component } from '@angular/core';
import { CasesNotesService } from '../../_services/cases-notes.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CaseNote } from '../../_types/case-note';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { LocalStorageService } from 'angular-web-storage';
import { UsersService } from 'src/app/modules/users/_services/users.service';
import { User } from 'src/app/modules/users/_types/user';
import { TasksService } from '../../../tasks/_services/tasks.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { Task } from 'src/app/modules/tasks/_types/task';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class CasesDetailNoteComponent {
  public noteFormGroup!: RxFormGroup;

  public note: CaseNote = new CaseNote();
  public users: User[] = [];
  public selectedUser: string = "";
  public asociatedTask!: Task;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public generateTask: boolean = false;

  constructor(
    private caseNotesService: CasesNotesService,
    private usersService: UsersService,
    private tasksService: TasksService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private storage: LocalStorageService
  ) {
    this.noteFormGroup = this.formBuilder.formGroup<CaseNote>(this.note) as RxFormGroup;
  }

  async ngOnInit() {
    let noteId = this.config.data.noteId;
    if (noteId) {
      await this.loadNote(noteId);
      // Ver si la nota tiene una tarea asociada
      let params: IParamsCollection = {};
      let filters: any = {
        caseNoteId: new Filter(FilterOperation.equals, "caseNoteId"),
      };
      filters.caseNoteId.value = noteId;
      params.filter = FilterBuilder.fromObject(filters).build();

      let response = await firstValueFrom(this.tasksService.all(params));

      if (response.data.length > 0) {
        this.asociatedTask = response.data[0];
      }
    } else {
      this.note.caseId = this.config.data.caseId;
    }
    this.loadUsers();
    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadNote(noteId: string) {
    this.note = await firstValueFrom(this.caseNotesService.get(noteId));
    this.noteFormGroup.patchValue(this.note);
  }

  async loadUsers() {
    let response = await firstValueFrom(this.usersService.all());
    this.users = response.data;
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.noteFormGroup);
    if (valid) {
      this.note = Object.assign(this.note, this.noteFormGroup.value);
      // processorId = userId conectado
      this.note.userId = this.storage.get("userId");

      let note = await firstValueFrom(this.caseNotesService.save(this.note));

      // Si tiene marcado generar tarea, se crea la tarea con la nota, caseId y userId
      if (this.generateTask) {
        let task = new Task();
        task.description = this.note.notes;
        task.caseNoteId = note.id || "";
        task.userId = this.selectedUser;
        await firstValueFrom(this.tasksService.save(task));
      }

      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Nota guardada correctamente." });
      this.dynamicDialogRef.close(this.note.id ? null : note.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la nota?",
      header: "Eliminar nota",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.caseNotesService.delete(this.note.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Nota eliminada correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  getUserName(userId: string) {
    let user = this.users.find(x => x.id == userId);
    return user ? user.name : "";
  }

}
