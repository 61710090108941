import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Capability } from '../../common/_types/capability';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CapabilitiesService } from '../../common/_services/capabilities.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-capabilities-detail',
  templateUrl: './capabilities-detail.component.html',
  styleUrls: ['./capabilities-detail.component.scss']
})
export class CapabilitiesDetailComponent {
  public capabilityFormGroup!: RxFormGroup

  public capability: Capability = new Capability();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private capabilitiesService: CapabilitiesService,
    private router: Router,
    private messageService: MessageService,
    private topbarService: TopbarService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de servicio");
    this.topbarService.addBreadcrumb("Especialidades", "/capabilities");

    this.capabilityFormGroup = this.formBuilder.formGroup<Capability>(this.capability) as RxFormGroup;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id: string | null = params.get('guid');
      if (id != null && id != '0') {
        this.loadCapability(id);
      }
    });
    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadCapability(id: string) {
    this.capability = await firstValueFrom(this.capabilitiesService.get(id));
    this.capabilityFormGroup.patchValue(this.capability);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.capabilityFormGroup);
    if (valid) {
      this.capability = Object.assign(this.capability, this.capabilityFormGroup.value);

      await firstValueFrom(this.capabilitiesService.save(this.capability));

      this.messageService.add({ closable: false, severity: 'success', summary: "Servicio guardado correctamente." });
      this.router.navigate(['capabilities']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio?",
      header: "Eliminar servicio",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.capabilitiesService.delete(this.capability.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['capabilities']);
  }
}
