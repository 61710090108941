<form *ngIf="caseFileFormGroup" [formGroup]="caseFileFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="caseServiceId">Servicio</label>
            <p-dropdown formControlName="caseServiceId" [options]="caseServices" optionLabel="label" optionValue="id"
                appendTo="body" [filter]="true" filterBy="label" [showClear]="true" placeholder="Selecciona una visita"
                d-form-field-error></p-dropdown>
        </div>
        <div class="col-12" *ngIf="!caseFile.fileId">
            <p-fileUpload mode="basic" chooseIcon="pi pi-upload" (onSelect)="onFileSelect($event)"
                chooseLabel="Seleccionar fichero" />
        </div>
        <div class="col-12 input-field">
            <label for="caseFileCategoryId">Tipo</label>
            <p-dropdown formControlName="caseFileCategoryId" [options]="caseFileCategories" optionLabel="name"
                [showClear]="true" optionValue="id" appendTo="body" [filter]="true" filterBy="name"
                placeholder="Selecciona un tipo" [showClear]="true" d-form-field-error></p-dropdown>
        </div>
        <div class="col-12 input-field">
            <label for="notes">Notas</label>
            <textarea formControlName="notes" pInputTextarea rows="5" d-form-field-error></textarea>
        </div>
    </div>
    <div class=" mt-4 flex gap-2 justify-content-end">
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>