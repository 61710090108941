import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { SurveyQuestionOption } from "./survey-question-option";

export class SurveyQuestion extends Resource {
    public override type = 'surveyQuestions';

    @prop()
    public surveyId!: string;

    @prop()
    @required()
    public title!: string;

    @prop()
    @required()
    public surveyQuestionType: string = 'text';

    @prop()
    @required()
    public active: boolean = true;

    @prop()
    public isForWorkerEvaluation: boolean = false;

    @prop()
    public workerEvaluationWeightPercent!: number;

}