import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { Processor } from '../_types/processor';
import { Operator } from '../_types/operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService extends Service<Operator> {
  public override type = 'operators';
  public override url = 'attendance/operators'; 
}
