import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import { WorkersHolidaysService } from 'src/app/modules/workers/_services/workers-holidays.service';
import { WorkerHoliday } from 'src/app/modules/workers/_types/worker-holiday';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-workers-holidays-modal.component',
  templateUrl: './workers-holidays-modal.component.html'
})
export class WorkersHolidaysModalComponent {

  public workerHolidayFormGroup!: RxFormGroup;
    
  public selectedHoliday: WorkerHoliday = new WorkerHoliday;  
  
  constructor(
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,    
    private config: DynamicDialogConfig,    
    private confirmationService: ConfirmationService,
    private workersHolidaysServices: WorkersHolidaysService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.workerHolidayFormGroup = this.formBuilder.formGroup<WorkerHoliday>(this.selectedHoliday) as RxFormGroup;
  }


  ngOnInit(): void {      
         
      if(typeof this.config.data.event !="undefined"){
        this.selectedHoliday = this.config.data.event;
        this.workerHolidayFormGroup.patchValue(this.selectedHoliday);
      }else{
        this.selectedHoliday.workerId = this.config.data.workerId;
        this.selectedHoliday.date = moment(this.config.data.date).toDate().toISOString();     
        this.workerHolidayFormGroup.patchValue(this.selectedHoliday);
      }    
  }

  async save(){                
      this.selectedHoliday = Object.assign(this.selectedHoliday, this.workerHolidayFormGroup.value)      
      this.dynamicDialogRef.close(this.selectedHoliday);            
  }

  delete(){    
    this.confirmationService.confirm({
      message: "¿Quieres eliminar el evento del "+moment(this.config.data.date).format("DD/MM/YYYY")+"?",
      header: "Eliminar evento",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {        
        this.workersHolidaysServices.delete(this.selectedHoliday.id).subscribe({
          next: (data: any) => {
            this.dynamicDialogRef.close();
            this.messageService.add({ closable: false, severity: 'success', detail: "Vacaciones eliminada correctamente" });   
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });                        
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });    
  }

  dismiss(){
    //this.activeModal.dismiss();
  }  
}
