import { Resource } from "src/app/core/jsonapi/resource";
import { AuthenticationService } from "../../authentications-services/_types/authentication-service";
import { prop } from "@rxweb/reactive-form-validators";

export class UserAuthenticationService extends Resource {
    public override type = 'userAuthenticationServices';

    @prop()
    public userId!: string;

    @prop()
    public authenticationServiceId!: string;

    @prop()
    public active:boolean = false;

    @prop()
    public userIdentifier!: string;

    @prop()
    public authenticationService!: AuthenticationService;

}