import { Component, ViewChild } from '@angular/core';
import { LoginService } from './modules/login/login.service';
import { slideInAnimation } from './animations';
import { NavigationStart, Router } from '@angular/router';
import { ChatService } from './modules/chat/_services/chat.service';
import { NotificationsService } from './modules/notifications/_services/notifications.service';
import { Sidebar } from 'primeng/sidebar';
import { ChatDetailComponent } from './modules/chat/detail/chat-detail.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'Gestor de Operaciones';

  @ViewChild('chat') chat!: ChatDetailComponent;

  public is_public_route: boolean = false;  
  public is_user_logged: boolean = false;    
  public notificationsSidebarVisible: boolean = false;
  public chatSidebarVisible: boolean = false;
  public chatTitle : string = '';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private chatService:ChatService,
    private notificationService: NotificationsService
  ) {
    this.router.events.subscribe((e) => {
      this.is_public_route = this.isPublicRoute();
    });

    this.loginService.onLogin.subscribe((logged:boolean) => {
      this.is_user_logged = logged;
    });
    this.loginService.checkSavedToken();

    this.notificationService.onShowSidebar.subscribe((visible:boolean) => {
      this.notificationsSidebarVisible = visible;
    });

    this.chatService.onOpenChat.subscribe((info:any) => {
      this.chat.loadChatRoom(info.code, info.title);
      this.chatSidebarVisible = true;
      this.chatTitle = info.title;
    });

    this.chatService.onCloseChat.subscribe(() => {
      this.chatSidebarVisible = false;
    });
  }

  ngAfterViewInit() {
    
  }

  isPublicRoute(){
    return this.router.url.includes('public/');
  }

  onChatHide(){
    this.chatService.onCloseChat.emit();
  }

}
