import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { SurveyQuestionOption } from '../../../_types/survey-question-option';
import { SurveysQuestionsOptionsService } from '../../../_services/surveys-questions-options.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-question-option',
  templateUrl: './question-option.component.html',
  styleUrls: ['./question-option.component.scss']
})
export class QuestionOptionComponent {
  public surveyQuestionOptionFormGroup!: RxFormGroup;

  public surveyQuestionOption: SurveyQuestionOption = new SurveyQuestionOption();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private surveysQuestionsOptionsService: SurveysQuestionsOptionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.surveyQuestionOptionFormGroup = this.formBuilder.formGroup<SurveyQuestionOption>(this.surveyQuestionOption) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.config.data.surveyQuestionOptionId;
    if (id != null && id != '0') {
      await this.loadSurveyQuestionOption(id);
    } else {
      this.surveyQuestionOption.surveyQuestionId = this.config.data.surveyQuestionId;
      this.surveyQuestionOptionFormGroup.patchValue(this.surveyQuestionOption);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadSurveyQuestionOption(id: string) {
    this.surveyQuestionOption = await firstValueFrom(this.surveysQuestionsOptionsService.get(id));
    this.surveyQuestionOptionFormGroup.patchValue(this.surveyQuestionOption);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.surveyQuestionOptionFormGroup);
    if (valid) {
      this.surveyQuestionOption = Object.assign(this.surveyQuestionOption, this.surveyQuestionOptionFormGroup.value);

      let surveyQuestionOptionItem = await firstValueFrom(this.surveysQuestionsOptionsService.save(this.surveyQuestionOption));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Opción guardada correctamente." });
      this.cancel();
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la opción?",
      header: "Eliminar opción",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.surveysQuestionsOptionsService.delete(this.surveyQuestionOption.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Opción eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
