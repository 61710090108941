<form *ngIf="workerEquipmentFormGroup" [formGroup]="workerEquipmentFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="capabilityId">Equipamiento</label>
            <p-dropdown [options]="equipments" formControlName="equipmentId" [required]="true" optionValue="id"
                [filter]="true" filterBy="name" optionLabel="name" placeholder="Seleccione equipamiento" appendTo="body"
                d-form-field-error />
        </div>    
        <div class="col-12 input-field">
            <label for="description">Notas</label>
            <textarea pInputTextarea rows="3" formControlName="notes" d-form-field-error></textarea>
        </div>    
    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete && workerEquipment.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
            (click)="save()"></p-button>
    </div>

</form>