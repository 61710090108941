import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Transaction } from "../_types/transaction";

@Injectable({
    providedIn: 'root',
})

export class TransactionsService extends Service<Transaction> {
  public override type = 'transactions';
  public override url = 'invoicing/transactions'; 
    
}
