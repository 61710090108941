import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Capability } from "../../common/_types/capability";

export class ContractCapability extends Resource {
    public override type = 'contractCapabilities';
    
    @prop()
    public contractId!: string;
    @prop()
    @required()
    public capabilityId!: string;

    @propObject()
    public capability!: Capability;
}