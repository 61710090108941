import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RolesService } from '../_services/roles.service';
import { Role } from '../_types/role';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { firstValueFrom } from 'rxjs';
import { PermissionsService } from '../_services/permissions.service';
import { Permission } from '../_types/permission';
import { RolesPermissionsService } from '../_services/roles-permissions.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ValidateForm } from 'src/app/core/decorators/validate-form.decorator';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss']
})
export class RolesDetailComponent {
  
  public roleFormGroup!: RxFormGroup;

  public role: Role = new Role();
  public permissions: Array<Permission> = new Array<Permission>();
  public permissionsByFeature: any = [];
  public selectedPermissions: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private router: Router,
    private messageService: MessageService,
    private topbarService: TopbarService,
    private confirmationService: ConfirmationService,
    private permissionsService: PermissionsService,
    private rolesPermissionsService: RolesPermissionsService,
    private formBuilder: RxFormBuilder,
    private formValidationService:FormValidatorService
  ) { 
    this.topbarService.setTitle("Detalles de rol");
    this.topbarService.addBreadcrumb("Roles", "/roles");
    this.roleFormGroup = this.formBuilder.formGroup<Role>(this.role) as RxFormGroup;
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id: string | null = params.get('guid');
      if (id != null && id != '0') {
        this.loadRole(id);
      }
      this.loadPermissions();
    });
    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadRole(id: string) {
    this.role = await firstValueFrom(this.rolesService.get(id));
    this.roleFormGroup.patchValue(this.role);
    this.loadRolePermissions();
  }

  async loadPermissions() {
    let response = await firstValueFrom(this.permissionsService.all({ include: ['feature'] }));
    this.permissions = response.data;
    this.permissionsByFeature = await this.dividePermissions();
  }

  async loadRolePermissions() {
    let filters = {
      roleId: new Filter(FilterOperation.equals, 'roleId', '', this.role.id)
    };
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    let response = await firstValueFrom(this.rolesPermissionsService.all(params));

    let rolePermissions = response.data;

    this.selectedPermissions = rolePermissions.map((rolePermission: any) => rolePermission.permissionId);
  }

  async dividePermissions() {
    // dividir permisos según la featureId
    let features = this.permissions.map((permission: Permission) => [permission.feature.name, permission.featureId]);
    features = features.filter((feature: any, index: number, self: any) => index === self.findIndex((t: any) => (t[0] === feature[0] && t[1] === feature[1])));

    let permissions = this.permissions.map((permission: Permission) => [permission.title, permission.code, permission.description, permission.featureId, permission.id]);

    let permissionsByFeature = features.map((feature: any) => {
      return {
        feature: feature[0],
        permissions: permissions.filter((permission: any) => permission[3] === feature[1])
      }
    });
    return permissionsByFeature;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.roleFormGroup);
    if (valid) {

      this.role = Object.assign(this.role, this.roleFormGroup.value);
      this.role = await firstValueFrom(this.rolesService.save(this.role));
      this.roleFormGroup.patchValue(this.role);

      await firstValueFrom(this.rolesService.updatePermissions(this.role.id!, this.selectedPermissions));

      this.messageService.add({ closable: false, severity: 'success', summary: "Correcto", detail: "Rol guardado correctamente." });

      this.router.navigate(['roles']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "El formulario contiene errores." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el rol?",
      header: "Eliminar rol",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.rolesService.delete(this.role.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Rol eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['roles']);
  }
}
