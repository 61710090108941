import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { ResponseCollection } from './responses/response-collection';
import { Resource } from './resource';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseResource } from './responses/response-resource';
import { ParamsBuilder } from './params/params-builder';
import { IParamsResource } from './interfaces/params-resource';
import { IParamsCollection } from './interfaces/params-collection';
import { DocumentResource } from './interfaces/document-resource';
import { environment } from 'src/environment/environment';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class Service<R extends Resource = Resource> {
  public type: string = '';
  public url: string = '';
  public http!: HttpClient;
  public loaderService!: LoaderService;

  constructor(
    private _http: HttpClient,
    private _loaderService: LoaderService
  ) {
    this.http = _http;
    this.loaderService = _loaderService;
  }

  public getAPIUrl(): string {
    return environment.api_gw + this.url;
  }

  public all(params: IParamsCollection = {}, hideLoader: boolean = false): Observable<ResponseCollection<R>> {

    let url = this.getAPIUrl();

    //añadimos parametros a url
    url += ParamsBuilder.paramsCollectionToQueryString(params);

    //si debemos ocultar el cargados
    if (hideLoader) this.loaderService.skipRequest(url);

    let subject = new AsyncSubject<ResponseCollection<R>>();
    this.http.get<ResponseCollection<R>>(url).subscribe((response: ResponseCollection<R>) => {
      response.data.forEach((data: R) => {
        this.fillRelationships(data, response.included);
        this.toLocalObject(data);
      });
      subject.next(response);
      subject.complete();
    });
    return subject.asObservable();
  }

  public get(id: string, params: IParamsResource = {}): Observable<R> {
    let url = this.getAPIUrl() + '/' + id;
    if (params != null) url += ParamsBuilder.paramsResourceToQueryString(params);
    let subject = new AsyncSubject<R>();
    this.http.get<ResponseResource<R>>(url).subscribe((response: ResponseResource<R>) => {
      let data = <R>response.data;
      this.fillRelationships(data, response.included);
      this.toLocalObject(data);
      subject.next(data);
      subject.complete();
    });
    return subject.asObservable();
  }

  public save(resource: Resource, params?: IParamsResource): Observable<R> {
    let subject = new AsyncSubject<R>();
    resource = this.toServerObject(resource);
    

    //preparamos documento
    var document: DocumentResource<R> = new DocumentResource<R>();
    document.data = <R>{
      id: resource.id,
      type: resource.type,
      attributes: resource.attributes,
      relationships: resource.relationships
    };

    //quitamos relationship no incluidas
    for (const relationship in document.data.relationships) {
      if (params?.include == null || params?.include?.indexOf(relationship) < 0) delete document.data.relationships[relationship];
    }

    //obtenemos su path
    if (typeof resource.id == 'undefined' || resource.id == '') {
      let url = this.getAPIUrl();
      if (params != null) url += ParamsBuilder.paramsResourceToQueryString(params);
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };
      delete document.data.id;
      this.http.post<ResponseResource<R>>(url, document, { headers: headers }).subscribe((response: ResponseResource<R>) => {
        subject.next(this.toLocalObject(response.data));
        subject.complete();
      });
    } else {
      let url = this.getAPIUrl() + '/' + resource.id;
      if (params != null) url += ParamsBuilder.paramsResourceToQueryString(params);
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };
      this.http.patch(url, document, { headers: headers }).subscribe((response: any) => {
        subject.next(this.toLocalObject(document.data));
        subject.complete();
      });
    }

    return subject.asObservable();
  }

  public delete(id: string | undefined): Observable<void> {
    let url = this.getAPIUrl() + '/' + id;
    let subject = new AsyncSubject<void>();
    this.http.delete(url).subscribe((response: any) => {
      subject.next();
      subject.complete();
    });
    return subject.asObservable();
  }

  private fillRelationships(data: R, included: Array<Resource>) {
    let finalRelationships: any = {};
    for (let relationshipKey in data.relationships) {
      let relationship = data.relationships[relationshipKey];
      //buscamos datos en included
      if (relationship.data != null) {
        let includedData = included.find((r: Resource) => r.type == relationship.data!.type && r.id == relationship.data!.id);
        if (includedData != null){
          relationship.data = includedData;
          this.fillRelationships(relationship.data, included);
        }
      }
      finalRelationships[relationshipKey] = relationship;
    }
    data.relationships = finalRelationships;
  }

  private toLocalObject(data:any):R {

    //pasamos los atributos a las propiedades del objeto local
    for (let key in data.attributes) {
      data[key] = data.attributes[key];
    }

    //eliminamos attributes
    delete data['attributes'];

    //pasamos relaciones a propiedades del objeto
    for (let key in data.relationships) {
      if(data.relationships[key].data!=null) data[key] = this.toLocalObject(data.relationships[key].data);
    }

    //eliminamos relationships
    delete data['relationships'];

    return data;
  }

  private toServerObject(data:any):Resource {
    //pasamos las propiedades del objeto local a los atributos
    //console.log("toServerObject", data);
    let o = new Resource();
    o.type = data.type;
    o.id = data.id;
    for (let key in data) {
      if (key != 'attributes' && key != 'relationships' && key != 'links' && key != 'type' && key != 'id') {
        let isResource = /*data[key]==null || */(data[key]!=null && data[key].type!=null);
        if(!isResource) {
          o.attributes[key] = data[key];
        }
      }
    }
    //console.log("toServerObject", o);
    return o;
  }

}
