import { Component } from '@angular/core';
import { ExpertsService } from '../_services/experts.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CountriesService } from '../../common/_services/countries.service';
import { Expert } from '../_types/expert';
import { ExpertAddressesService } from '../_services/experts-addresses.service';
import { ExpertAddress } from '../_types/expert-address';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';

@Component({
  selector: 'app-experts-detail',
  templateUrl: './experts-detail.component.html',
  styleUrls: ['./experts-detail.component.scss']
})
export class ExpertsDetailComponent {
  public expertFormGroup!: RxFormGroup;

  public expert: Expert = new Expert();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public countries: any = [];

  constructor(
    private expertsService: ExpertsService,
    private expertAddressesService: ExpertAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService,
    private dialogService: DialogService
  ) {
    this.topbarService.setTitle("Detalles de perito");
    this.topbarService.addBreadcrumb("Peritos", "/experts");

    this.expertFormGroup = this.formBuilder.formGroup<Expert>(this.expert) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCountries();

    let id = this.route.snapshot.params['guid'];
    if (id != null && id != '0') {
      await this.loadExpert(id);
    }

    if (this.expert.address == null) this.expert.address = new ExpertAddress();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadExpert(id: string) {
    this.expert = await firstValueFrom(this.expertsService.get(id, { include: ['address'] }));
    this.expertFormGroup.patchValue(this.expert);
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.expertFormGroup);
    if (valid) {
      this.expert = Object.assign(this.expert, this.expertFormGroup.value);

      if (this.expert.address != null) {
        let address: any = await firstValueFrom(this.expertAddressesService.save(this.expert.address));
        this.expert.addressId = address.id;
      }

      let expertItem = await firstValueFrom(this.expertsService.save(this.expert));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Perito guardado correctamente." });

      if (this.expert.id != "undefined" && this.expert.id != "") {
        this.router.navigate(['experts/' + expertItem.id]);
      }
      this.loadExpert(expertItem.id!);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el perito?",
      header: "Eliminar perito",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.expertsService.delete(this.expert.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Perito eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['experts']);
  }
}
