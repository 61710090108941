<form *ngIf="transactionLineFormGroup" [formGroup]="transactionLineFormGroup">    
    <div class="grid">        
        <div class="col-12 input-field" *ngIf="isRate && !transactionLine.isGroup">
            <label for="capabilityId">Especialidad</label>
            <p-dropdown [(ngModel)]="selectedCapability" [ngModelOptions]="{standalone:true}" [options]="capabilities"
                (onChange)="selectCapability($event)" optionLabel="name" optionValue="id" [filter]="true" filterBy="name"
                appendTo="body" placeholder="Selecciona una especialidad" d-form-field-error></p-dropdown>
        </div>
        <div class="col-12 input-field" *ngIf="isRate && !transactionLine.isGroup">
            <label for="rateLineId">Baremo</label>
            <p-dropdown formControlName="rateLineId" [options]="rateLines" optionValue="id" optionLabel="displayName"
                appendTo="body" placeholder="Selecciona un baremo" (onChange)="onRateLineChange($event)"
                [showClear]="true" [filter]="true" d-form-field-error>                
            </p-dropdown>
        </div>
        <div class="col-12 input-field">
            <label for="notes">Descripción</label>
            <textarea pInputTextarea rows="4" [autoResize]="true" formControlName="description" 
                d-form-field-error></textarea>
        </div> 
    </div>
    <div class="grid">
        <div class="col input-field" *ngIf="!transactionLine.isGroup">
            <label for="quantity">Cantidad</label>
            <input formControlName="quantity" pInputText type="number" d-form-field-error (keyup)="calculatePrices($event)">
        </div>
        <div class="col input-field" *ngIf="!transactionLine.isGroup">
            <label for="price">Precio</label>
            <input formControlName="price" pInputText type="number" d-form-field-error (keyup)="calculatePrices($event)">
        </div>
        <div class="col input-field" *ngIf="!transactionLine.isGroup">
            <label for="discountPercent">Descuento (%)</label>
            <input formControlName="discountPercent" pInputText type="number" d-form-field-error (keyup)="calculatePrices($event)">                       
            <label class="mt-2">
                {{ transactionLineFormGroup.get('discountAmount')?.value | currency:'EUR':'symbol':'1.2-2' }}
            </label>            
        </div>        
        <div class="col input-field" *ngIf="!transactionLine.isGroup">
            <label for="code">Impuestos(%)</label>
            <p-dropdown formControlName="taxPercent" [options]="taxes"
                optionLabel="name" optionValue="percent" appendTo="body" filterBy="name"
                placeholder="Seleccione opción" (onChange)="calculatePrices($event)" d-form-field-error></p-dropdown>                            
            <label class="mt-2">
                {{ transactionLineFormGroup.get('taxAmount')?.value | currency:'EUR':'symbol':'1.2-2' }}
            </label>
        </div>
        <div class="col input-field" *ngIf="!transactionLine.isGroup">
            <label class="mb-3" for="total">Importe</label>
            <span class="mt-2">
                {{ transactionLineFormGroup.get('subtotal')?.value | currency:'EUR':'symbol':'1.2-2' }}
            </span>
        </div>
    </div>                                                                        
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>    
</form>