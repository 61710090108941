import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { CasesListComponent } from './cases-list/cases-list.component';
import { CasesDetailComponent } from './cases-detail/cases-detail.component';
import { CasesRoutingModule } from './cases-routing.module';
import { CasesDetailGeneralComponent } from './cases-detail/general/general.component';
import { CaseStateComponent } from './cases-detail/case-state/case-state.component';
import { CasesDetailClientComponent } from './cases-detail/client/client.component';
import { CasesDetailAffectedComponent } from './cases-detail/affected/affected.component';
import { CasesDetailProcessorComponent } from './cases-detail/processor/processor.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CasesDetailNoteComponent } from './cases-detail/note/note.component';
import { CasesDetailServiceComponent } from './cases-detail/service/service.component';
import { JobComponent } from './cases-detail/job/job.component';
import { RatingModule } from 'primeng/rating';
import { FileUploadModule } from 'primeng/fileupload';
import { CasesDetailFileComponent } from './cases-detail/file/file.component';
import { MenuModule } from 'primeng/menu';
import { CaseServiceDetailComponent } from './cases-detail/service/case-service-detail/case-service-detail.component';
import { CaseServiceJobComponent } from './cases-detail/service/case-service-detail/case-service-job/case-service-job.component';
import { MessagesModule } from 'primeng/messages';
import { AccordionModule } from 'primeng/accordion';
import { CaseExpertComponent } from './cases-detail/expert/expert.component';
import { CaseExpertCreateNewComponent } from './cases-detail/expert/create-new/create-new.component';
import { CommonModule } from '@angular/common';
import { CaseServiceTransactionLinesComponent } from './cases-detail/service/case-service-detail/case-service-transaction-lines/case-service-transaction-lines.component';
import { CaseTechnicalComponent } from './cases-detail/technical/technical.component';
import { CaseTechnicalCreateNewComponent } from './cases-detail/technical/create-new/create-new.component';
import { CasesDetailLogsComponent } from './cases-detail/logs/logs.component';
import { BadgeModule } from 'primeng/badge';
import { EditorModule } from '@tinymce/tinymce-angular';


@NgModule({
    declarations: [
        CasesListComponent,
        CasesDetailComponent,
        CasesDetailGeneralComponent,
        CaseStateComponent,
        CasesDetailClientComponent,
        CasesDetailAffectedComponent,
        CasesDetailProcessorComponent,
        CasesDetailNoteComponent,
        CasesDetailServiceComponent,
        JobComponent,
        CasesDetailFileComponent,
        CaseServiceDetailComponent,
        CaseServiceJobComponent,
        CaseExpertComponent,
        CaseExpertCreateNewComponent,
        CaseServiceTransactionLinesComponent,
        CaseTechnicalComponent,
        CaseTechnicalCreateNewComponent,
        CasesDetailLogsComponent
    ],
    imports: [
        CommonModule,
        EditorModule,
        CasesRoutingModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        TableModule,
        TagModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        CheckboxModule,
        TabViewModule,
        CalendarModule,
        TagModule,
        InputTextareaModule,
        DropdownModule,
        DialogModule,
        ReactiveFormsModule,
        OverlayPanelModule,
        TagModule,
        RatingModule,
        FileUploadModule,
        MenuModule,
        MessagesModule,
        AccordionModule,
        BadgeModule,
        EditorModule
    ],
    providers: []
})
export class CasesModule { }
