import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Company } from "../../companies/_types/company";

export class ClientPolicy extends Resource {
    public override type = 'clientPolicies';

    @prop()
    public clientId!: string;

    @prop()
    public companyId!: string;

    public company!: Company;

    @prop()
    @required()
    public policyNumber!: string;

    @prop()
    @required()
    public description!: string;


}