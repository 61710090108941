import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { Equipment } from '../_types/equipment';
import { EquipmentsService } from '../_services/equipments.service';

@Component({
  selector: 'app-equipments-detail',
  templateUrl: './equipments-detail.component.html',
  styleUrls: ['./equipments-detail.component.scss']
})
export class EquipmentsDetailComponent {
  public equipmentFormGroup!: RxFormGroup;

  public equipment: Equipment = new Equipment();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private equipmentsService: EquipmentsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de equipamiento");
    this.topbarService.addBreadcrumb("Equipamientos", "/equipments");

    this.equipmentFormGroup = this.formBuilder.formGroup<Equipment>(this.equipment) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadEquipment(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadEquipment(id: string) {
    this.equipment = await firstValueFrom(this.equipmentsService.get(id));
    this.equipmentFormGroup.patchValue(this.equipment);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.equipmentFormGroup);
    if (valid) {
      this.equipment = Object.assign(this.equipment, this.equipmentFormGroup.value);

      //guardamos operario
      let equipment = await firstValueFrom(this.equipmentsService.save(this.equipment));

      this.messageService.add({ closable: false, severity: 'success', summary: "Equipamiento guardado correctamente." });

      
      this.router.navigate(['equipments']);
    

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el equipamiento "+this.equipment.name+"?",
      header: "Eliminar equipamiento",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.equipmentsService.delete(this.equipment.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Equipamiento eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['equipments']);
  }

}
