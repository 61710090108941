export const environment = {
    production: false,
    api_gw: 'https://guai-gw.test.diaple.com/api/',
    url_files: 'https://guai-gw.test.diaple.com/api/common/files/',
    mqtt: {
        server: 'dev-02.diaple.com',
        port: 8080,
        protocol: "wss",
        username: "admin",
        password: "su2xu1"
    }
};
