<div class="grid">
    <div class="col-5 input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.description.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.description.value" class="pi pi-times cursor-pointer"
            (click)="filters.description.value = null; search()"></i>
    </div>
    <div class="col-5 input-field">
        <p-dropdown placeholder="Usuario a filtrar" [options]="users" optionLabel="name" optionValue="id"
            [(ngModel)]="filters.userId.value" (onChange)="search()" [showClear]="true" [filter]="true"
            filterBy="name" />
    </div>
    <div class="col-2 input-field">
        <p-dropdown placeholder="Estado" [options]="taskStatus" optionLabel="label" optionValue="value"
            [(ngModel)]="filters.isCompleted.value" (onChange)="search()" [showClear]="true" />
    </div>
</div>

<p-table styleClass="mt-4" #grid [value]="tasks" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="description">Título <p-sortIcon field="description"></p-sortIcon></th>
            <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon></th>
            <th pSortableColumn="user.name">Usuario asignado <p-sortIcon field="user.name"></p-sortIcon></th>
            <th pSortableColumn="isCompleted">Completado <p-sortIcon field="isCompleted"></p-sortIcon></th>
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task let-columns="columns">
        <tr (dblclick)="edit(task)">
            <td>{{task.description}}</td>
            <td>{{task.date | date: 'dd/MM/yyyy'}}</td>
            <td>{{task.user?.name}}</td>
            <td>
                <i class="pi"
                    [ngClass]="{ 'text-green-500 pi-check-circle': task.isCompleted, 'text-red-500 pi-times-circle': !task.isCompleted }"></i>
            </td>
            <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowDelete" (click)="delete(task)" [rounded]="true" [text]="false"
                        severity="danger"><i class="pi pi-trash"></i></p-button>
                    <p-button *ngIf="allowWrite" (click)="edit(task)" [rounded]="true" [text]="false"><i
                            class="pi pi-pencil"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>