import { Component } from '@angular/core';
import { Notification } from '../_types/notification';
import { NotificationsService } from '../_services/notifications.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { LocalStorageService } from 'angular-web-storage';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Router } from '@angular/router';
import { ChatService } from '../../chat/_services/chat.service';
import { UsersService } from '../../users/_services/users.service';
import { User } from '../../users/_types/user';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {

  public notifications: Notification[] = [];
  public notificationTypes: any[] = [];
  public users: User[] = [];
  public user: any = {};
  public selectedState: string = "NotReaded";
  public notificationStates: any[] = [];

  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    content: new Filter(FilterOperation.contains, 'content'),
    source: new Filter(FilterOperation.equals, 'source')
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    public notificationsService: NotificationsService,
    private topbarService: TopbarService,
    private router: Router,
    private storage: LocalStorageService,
    private chatService: ChatService,
    private usersService: UsersService
  ) {
    this.loadUserData();
    this.loadUsers();
    this.loadNotificationTypes();
    this.loadNotificationStates();
    this.topbarService.setTitle("Notificaciones");
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")
  }

  ngOnInit(): void {
    this.filters.userId = new Filter(FilterOperation.equals, 'userId', '', this.user.id);
  }

  loadUserData() {
    let user = this.storage.get("user");
    if (user) this.user = this.storage.get("user");
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    if (this.selectedState == "Readed") {
      this.filters.readedAt = new Filter(FilterOperation.equals, 'readedAt', '', null, false);
    } else if (this.selectedState == "NotReaded") {
      this.filters.readedAt = new Filter(FilterOperation.equals, 'readedAt', '', null, true);
    } else {
      delete this.filters.readedAt;
    }

    let params: IParamsCollection = {
      sort: ['createdAt'],
      include: ['user'],
      filter: FilterBuilder.fromObject(this.filters).build()
    };

    let response = await firstValueFrom(this.notificationsService.all(params));
    this.notifications = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadUsers() {
    let response = await firstValueFrom(this.usersService.all());
    this.users = response.data;
  }

  loadNotificationTypes() {
    this.notificationTypes = this.notificationsService.notificationTypes();
  }

  loadNotificationStates() {
    this.notificationStates = this.notificationsService.notificationStates();
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  async executeAction(notification: Notification) {
    let parts = notification.link.split(":");
    let actionType = parts[0];
    let action = parts.slice(1).join(":");
    if (actionType == "function") {
      this.run.call(this, "this." + action);
      firstValueFrom(this.notificationsService.markAsReaded(notification));
      this.notificationsService.closeNotifications();
    } else if (actionType == "route") {
      this.router.navigate([action]);
      firstValueFrom(this.notificationsService.markAsReaded(notification));
      this.notificationsService.closeNotifications();
    }
  }

  private run(pString: string) {
    return eval(pString)
  }

  async markAsReaded(notification: Notification) {
    await firstValueFrom(this.notificationsService.markAsReaded(notification));
  }

  async openChat(code: string) {
    this.chatService.openChatRoom(code);
  }

}
