
import { Resource } from "src/app/core/jsonapi/resource";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class Equipment extends Resource {
    public override type = 'equipment';

    @prop()
    @required()
    public name!: string;      

    @prop()    
    public active: boolean = true;   
    
}