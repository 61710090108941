<form *ngIf="companyFormGroup" [formGroup]="companyFormGroup">
  <p-card class="p-3" header="Datos generales">
    <div class="grid">
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error/>
      </div>
      <div class="col-3 input-field">
        <label for="name">CIF *</label>
        <input pInputText name="vatnumber" formControlName="vatNumber" d-form-field-error/>
      </div>      
      <div class="col-2 input-field">
        <label for="active" class="mr-1">Activo</label>
        <div class="flex h-full">
          <p-inputSwitch id="active" formControlName="active" d-form-field-error></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="grid pt-2">
      <div class="col input-field ">
        <label for="name">Razón social</label>
        <input pInputText id="legalName" formControlName="legalName" d-form-field-error/>
      </div>
      <div class="col input-field ">
        <label for="name">Teléfono</label>
        <input pInputText id="phone1" name="phone1" formControlName="phone1" d-form-field-error/>
      </div>
      <div class="col input-field ">
        <label for="name">Email</label>
        <input pInputText id="email" name="email" formControlName="email" d-form-field-error/>
      </div>

    </div>
    <div class="grid pt-2" formGroupName="address">
      <div class="col input-field col-3">
        <label for="name">Dirección *</label>
        <input pInputText name="addressLine1" id="addressLine1"
        formControlName="addressLine1" d-form-field-error/>
      </div>
      <div class="col input-field col-3">
        <label for="name">Código postal</label>
        <input pInputText id="zipcode" name="zipcode"
        formControlName="zipCode" d-form-field-error/>
      </div>
      <div class="col input-field col-3">
        <label for="name">Ciudad</label>
        <input pInputText id="city" name="city" formControlName="city" d-form-field-error/>
      </div>
      <div class="col input-field col-3">
        <label for="name">País</label>
        <p-dropdown [options]="countries"
                    formControlName="countryCode" name="countryCode"
                    optionValue="code" [filter]="true" filterBy="name"
                    optionLabel="name" placeholder="Seleccione país" d-form-field-error/>     
      </div>
    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
    </div>
  </p-card>

  <p-card class="p-3" header="Contratos" *ngIf="company.id">  
    <div class="flex justify-content-end pb-1">
      <p-button label="Nuevo contrato" (click)="editContract({})" styleClass="p-button-sm" *ngIf="allowWrite"></p-button>
    </div>
    <p-table styleClass="ml-1 mt-2 mr-1" #grid  [value]="contracts" [loading]="loading" [paginator]="false" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header" let-columns>
      <tr>
      <th pSortableColumn="code">Código <p-sortIcon field="code"></p-sortIcon></th>                
      <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>                
      <th pSortableColumn="phone1">Teléfono <p-sortIcon field="phone1"></p-sortIcon></th>                
      <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>                
      <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>                
      <th width="100"></th>
      </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contract let-columns="columns">
      <tr (dblclick)="editContract(contract)">
      <td>{{contract.code}}</td>                
      <td>{{contract.name}}</td>                
      <td>{{contract.phone1}}</td>                
      <td>{{contract.email}}</td>
      <!--<td>
        <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': contract.active, 'text-red-500 pi-times-circle': !company.active }"></i>
      </td>-->
      <td>
        <div class="flex gap-2 justify-content-end">
          <p-button *ngIf="allowDelete" (click)="deleteContract(contract)" [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
          <p-button *ngIf="allowWrite" (click)="editContract(contract)" [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
        </div>
      </td>
      </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
      <tr>
      <td [attr.colspan]="5">        
        No se han encontrado contratos
      </td>
      </tr>
      </ng-template>

    </p-table>
  </p-card>

  <!--<p-tabView *ngIf="company.id">   
    <p-tabPanel header="Servicios" [disabled]="!company.id">
      <div class="grid">
        <div *ngFor="let capability of capabilities" class="col-4">
          <p-checkbox [(ngModel)]="selectedCapabilities" [label]="capability?.name" name="capability"
            [value]="capability.id" />
        </div>
      </div>
    </p-tabPanel>        
    <p-tabPanel header="Tramitadores" [disabled]="!company.id">
      <div class="grid">
        En construcción
      </div>
    </p-tabPanel>        
    <p-tabPanel header="Configuración" [disabled]="!company.id">
      <div class="grid">
        En construcción
      </div>
    </p-tabPanel>   
  </p-tabView>-->
</form>
<p-confirmDialog></p-confirmDialog>