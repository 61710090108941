import { Filter } from "./filter";

export class FilterBuilder {

    private filters:Filter[] = [];

    public static fromObject(obj: any): FilterBuilder {
        let _instance = new FilterBuilder();
        _instance.filters = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let filter:any = obj[key];
                if(filter instanceof Filter) {
                    if(filter.value!=null || filter.allowNull) _instance.filters.push(filter);
                }
            }
        }
        return _instance;
    }

    public build(): Filter[] {
        return this.filters;
    }

}