import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Vehicle } from "../_types/vehicle";

@Injectable({
    providedIn: 'root',
})

export class VehiclesService extends Service<Vehicle> {
  public override type = 'vehicles';
  public override url = 'providers/vehicles'; 
    
}
