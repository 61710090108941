import { Component, ViewEncapsulation } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';

import { RateLinesService } from 'src/app/modules/rates/_services/rates-lines.service';
import { CaseService } from '../../../_types/case-service';
import { CasesServicesService } from '../../../_services/cases-services.service';
import { OperatorsService } from 'src/app/modules/companies/_services/operators.service';
import { Operator } from 'src/app/modules/companies/_types/operator';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { JobsService } from '../../../_services/jobs.service';
import { Job } from '../../../_types/job';
import { CaseServiceJobComponent } from './case-service-job/case-service-job.component';
import { CaseServiceTransactionLinesComponent } from './case-service-transaction-lines/case-service-transaction-lines.component';
import { Transaction } from 'src/app/modules/transactions/_types/transaction';
import { TransactionsDetailSummaryComponent } from 'src/app/modules/transactions/transactions-detail-summary/transactions-detail-summary.component';
import { TransactionsLinesService } from 'src/app/modules/transactions/_services/transactions-lines.service';
import { TransactionLine } from 'src/app/modules/transactions/_types/transaction-line';
import { TransactionsService } from 'src/app/modules/transactions/_services/transactions.service';
import { FilesService } from 'src/app/modules/common/_services/files.service';

@Component({
  selector: 'app-case-service-detail',
  templateUrl: './case-service-detail.component.html',
  styleUrls: ['./case-service-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseServiceDetailComponent {
  public caseServiceFormGroup!: RxFormGroup;

  public caseService : CaseService = new CaseService();

  public operators: Operator[] = [];
  public operatorJobs: Job[] = [];
  public companyJobs: Job[] = [];  
  public transactionsOperator: TransactionLine[] = [];
  public companyJobsTotal: number = 0;
  public operatorJobsTotal: number = 0;
  public transactionLinesTotal: number = 0;

  public loadingTransactionsOperator: boolean = false;
  public totalTransactionsOperatorRecords: number = 0;  

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(    
    private casesServicesService: CasesServicesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private operatorsService: OperatorsService,
    private jobsService: JobsService,
    private dialogService: DialogService,
    private transactionLinesService: TransactionsLinesService,
    private transactionsService: TransactionsService,
    private filesService: FilesService
  ) {
    this.caseServiceFormGroup = this.formBuilder.formGroup(CaseService) as RxFormGroup;
    
  }

  async ngOnInit() {
    let caseServiceId = this.config.data.caseServiceId;
    if (caseServiceId) {
      await this.loadCaseService(caseServiceId);
      await this.loadJobs();
      await this.loadTransactionsOperator();
    } else {
      this.caseService.caseId = this.config.data.caseId;
      this.caseServiceFormGroup.patchValue(this.caseService);
    }

    this.loadOperators();

    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");    
  }

  async loadCaseService(caseServiceId: string) {
    let params: IParamsCollection = {  
      include: ['case', 'operator']    
    }
    this.caseService = await firstValueFrom(this.casesServicesService.get(caseServiceId, params));
    console.log(this.caseService);
    this.caseServiceFormGroup.patchValue(this.caseService);
    this.caseServiceFormGroup.get('date')?.disable();
    this.caseServiceFormGroup.get('operatorId')?.disable();
    this.caseServiceFormGroup.get('dateStart')?.disable();
    this.caseServiceFormGroup.get('dateEnd')?.disable();
    this.caseServiceFormGroup.get('isUrgent')?.disable();
    this.caseServiceFormGroup.get('pendingTasksDescription')?.disable();
    this.caseServiceFormGroup.get('realizedTasksDescription')?.disable();
  }  

  async loadOperators() {
    let response = await firstValueFrom(this.operatorsService.all());
    this.operators = response.data;    
  }  
  
  async loadJobs() {    
    let params: IParamsCollection = {  
      include: ['rateLine']    
    }
    let filters: any = {
      caseServiceId: new Filter(FilterOperation.equals, "caseServiceId",'',this.caseService.id),      
    };

    
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.jobsService.all(params));
    let jobs = response.data

    this.operatorJobs = jobs.filter(m => !m.isForCompany);
    this.companyJobs = jobs.filter(m => m.isForCompany);

    this.operatorJobsTotal = this.operatorJobs.reduce((sum, item) => sum + item.total, 0);
    this.companyJobsTotal = this.companyJobs.reduce((sum, item) => sum + item.total, 0);

    console.log(jobs);
  }

  async loadTransactionsOperator() {
    let params: IParamsCollection = {  
      include: ['transaction.transactionState']    
    }
    let filters: any = {
      thirdPartyId: new Filter(FilterOperation.equals, "transaction.thirdPartyId",'',this.caseService.operatorId),      
    };

    
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.transactionLinesService.all(params));    

    this.transactionsOperator = response.data;

    console.log(this.transactionsOperator);

    this.transactionLinesTotal += this.transactionsOperator.reduce((sum, item) => sum + item.subtotal, 0);

    this.totalTransactionsOperatorRecords = response.data.length;
    
  }

  editJob(type:string, job: any){    
    console.log(job);
    let ref = this.dialogService.open(CaseServiceJobComponent, {
      header: 'Datos generales',
      width: '50%',
      data: {        
        type: type,
        companyId: this.caseService.case.companyId,        
        caseId: this.caseService.caseId,
        caseServiceId: this.caseService.id,
        operatorId: this.caseService.operatorId,
        id: job!=null?job.id:null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadJobs();
    });

  }

  deleteJob(job:any) {
    console.log(job);    
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el trabajo "+job.rateLine.code+"?",
      header: "Eliminar trabajo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.jobsService.delete(job.id).subscribe({
          next: () => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Trabajo eliminado correctamente." });                        
            this.loadJobs();                        
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {        
      }
    });
  }  
  
  importTransactionLines(type:string){        
    let ref = this.dialogService.open(CaseServiceTransactionLinesComponent, {
      header: 'Datos generales',
      width: '50%',
      data: {        
        type: type,
        companyId: this.caseService.case.companyId,        
        caseId: this.caseService.caseId,
        caseServiceId: this.caseService.id,
        operatorId: this.caseService.operatorId,        
      }
    });

    ref.onClose.subscribe((data: any) => {
      console.log(data);
      this.loadJobs();
    });

  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.caseServiceFormGroup);
    if (valid) {
      this.caseService = Object.assign(this.caseService, this.caseServiceFormGroup.value);      
      
      let caseService:any = await firstValueFrom(this.casesServicesService.save(this.caseService));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Servicio guardado correctamente." });
      this.dynamicDialogRef.close(this.caseService.id ? null : caseService.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio?",
      header: "Eliminar servicio",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesServicesService.delete(this.caseService.id).subscribe({
          next: () => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio eliminado correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }    


  editTransaction(transactionLineId: any){        
    let ref = this.dialogService.open(TransactionsDetailSummaryComponent, {
      header: 'Datos generales',
      width: '50%',
      data: {        
        thirdPartyId: this.caseService.operatorId,    
        thirdPartyType: "Worker",
        transactionType: "incoming_invoice",
        caseId: this.caseService.caseId,
        caseServiceId: this.caseService.id,
        operatorId: this.caseService.operatorId,
        transactionLineId: transactionLineId
      }
    });

    ref.onClose.subscribe((data: any) => {
      console.log(data);
      this.loadTransactionsOperator();
    });

  }


  deleteTransaction(transactionLine:any) {
    console.log("asdasdasdasd");
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la factura "+transactionLine.transaction.code+"?",
      header: "Eliminar factura",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.transactionsService.delete(transactionLine.transaction.id).subscribe({
          next: () => {
            this.transactionLinesService.delete(transactionLine.id).subscribe({
              next: () => {
                this.messageService.add({ closable: false, severity: 'success', detail: "Factura eliminada correctamente." });                        
                this.loadTransactionsOperator();                        
              },
              error: (error: any) => {
                this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {        
      }
    });
  }  

  downloadFile(id: any) {
    this.filesService.download(id);
  }

  getProfitPercent():number{
    return this.companyJobsTotal>0?((this.companyJobsTotal - this.operatorJobsTotal) * 100 / this.companyJobsTotal):0;
  }
  getProfit():number{
    return (this.companyJobsTotal - this.operatorJobsTotal);
  }

}
