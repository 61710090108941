import { Component } from '@angular/core';
import { date, required, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Job } from '../../_types/job';
import { JobsService } from '../../_services/jobs.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { CasesServicesService } from '../../_services/cases-services.service';
import { Capability } from 'src/app/modules/common/_types/capability';
import { CaseService } from '../../_types/case-service';

import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Validators } from '@angular/forms';
import { RateLine } from 'src/app/modules/rates/_types/rate-line';
import { RateLinesService } from 'src/app/modules/rates/_services/rates-lines.service';
import { Tax } from 'src/app/modules/transactions/_types/tax';
import { TaxesService } from 'src/app/modules/transactions/_services/taxes.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent {
  public jobFormGroup!: RxFormGroup;

  public job: Job = new Job();

  public capabilities: Capability[] = [];
  public caseServices: CaseService[] = [];
  public rateLines: RateLine[] = [];
  public selectedCapability: string | undefined;
  public hasSubmitInvoice: boolean = false;
  public taxes: Tax[] = [];
  public loadingTaxes: boolean = false;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private jobsService: JobsService,
    private capabilitiesService: CapabilitiesService,
    private casesServicesService: CasesServicesService,
    private rateLinesService: RateLinesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private taxesService: TaxesService
  ) {
    this.jobFormGroup = this.formBuilder.formGroup(Job) as RxFormGroup;
  }

  async ngOnInit() {

    await this.loadTaxes();

    let jobId = this.config.data.jobId;
    if (jobId) {
      await this.loadJob(jobId);
    } else {
      this.job.caseId = this.config.data.caseId;
      
      this.job.taxPercent = this.taxes.filter(m => m.default)[0].percent;
      this.jobFormGroup.patchValue(this.job);
    }

    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");

    await this.loadCapabilities();
    await this.loadCaseServices();
    await this.loadRateLines();
  }

  async loadJob(jobId: string) {
    this.job = await firstValueFrom(this.jobsService.get(jobId, { include: ['rateLine'] }));
    if (this.job.rateLine) {
      this.selectedCapability = this.job.rateLine.capabilityId;
    }
    this.jobFormGroup.patchValue(this.job);
  }

  async loadCapabilities() {
    let response = await firstValueFrom(this.capabilitiesService.all());
    this.capabilities = response.data;
  }

  async loadCaseServices() {
    let params: IParamsCollection = {
      include: ['operator']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    filters.caseId.value = this.job.caseId;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data;
    console.log(this.caseServices);

    // añadir atributo que sea 'operator.name' - 'date'
    this.caseServices.forEach((caseService: any) => {
      caseService.jobLabel = `${caseService.operator.name} - ${caseService.date.toLocaleString()}`;
    });
  }

  async loadRateLines() {
    let params: IParamsCollection = {}
    let filters: any = {
      capabilityId: new Filter(FilterOperation.equals, "capabilityId"),
    };

    filters.capabilityId.value = this.selectedCapability;
    params.filter = FilterBuilder.fromObject(filters).build();
    let response = await firstValueFrom(this.rateLinesService.all(params));
    this.rateLines = response.data;
  }

  async loadTaxes() {
    this.loadingTaxes = true;
    let params: IParamsCollection = {
    }

    let response = await firstValueFrom(this.taxesService.all(params));
    this.taxes = response.data;

    this.loadingTaxes = false;
  }

  selectRateLine(e: any) {
    this.job.unitPrice = this.rateLines.find((rl: any) => rl.id == e.value)?.price || 0;
    this.jobFormGroup.patchValue({ unitPrice: this.job.unitPrice });
  }


  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.jobFormGroup);
    if (valid) {
      this.job = Object.assign(this.job, this.jobFormGroup.value);

      // asignar el precio unitario de la línea de tarifa
      if (this.job.rateLineId) {
        this.job.rateLineUnitPrice = this.rateLines.find((rl: any) => rl.id == this.job.rateLineId)?.price || 0;
      }

      //asignar operario desde el servicio
      this.job.operatorId = this.caseServices.find((cs: any) => cs.id == this.job.caseServiceId)?.operatorId || '';

      //asignar el total
      this.job.total = this.job.unitPrice * this.job.quantity;

      let job = await firstValueFrom(this.jobsService.save(this.job));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Trabajo guardado correctamente." });
      this.dynamicDialogRef.close(this.job.id ? null : job.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el trabajo?",
      header: "Eliminar trabajo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.jobsService.delete(this.job.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Trabajo eliminado correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  // Asignar el precio unitario de la línea de tarifa al cambiar la línea de tarifa
  onRateLineChange(e: any) {
    this.jobFormGroup.controls['unitPrice'].setValue(this.rateLines.find((rl: any) => rl.id == e.value)?.price || 0);
  }

  // Controlar si el operario del servicio seleccionado tiene que emitir factura
  onCaseServiceChange() {
    let caseServiceId = this.jobFormGroup.controls['caseServiceId'].value;
    this.hasSubmitInvoice = this.caseServices.find((cs: any) => cs.id == caseServiceId)?.operator.hasSubmitInvoice || false;
  }

  calculatePrices(event: any) {    
    const precioTotalSinImpuestos = this.jobFormGroup.controls["quantity"].value * this.jobFormGroup.controls["unitPrice"].value;    
    console.log(precioTotalSinImpuestos);
    const impuesto = precioTotalSinImpuestos * (this.jobFormGroup.controls["taxPercent"].value / 100);
    this.jobFormGroup.controls["taxAmount"].setValue(impuesto);
    console.log(impuesto);
    const precioFinal = precioTotalSinImpuestos + impuesto;
    console.log(precioFinal);
    this.jobFormGroup.controls["total"].setValue(precioFinal);
    
  }

}
