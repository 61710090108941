import { Resource } from "src/app/core/jsonapi/resource";
import { prop, required } from "@rxweb/reactive-form-validators";

export class Rate extends Resource {
    public override type = 'rates';  

    @prop()
    @required()
    public typeOfRate!: string;   

    @prop()
    @required()
    public name!: string;   
    
    @prop()
    @required()
    public fromDate!: Date;

    @prop()    
    public toDate!: Date;
    
}