<form *ngIf="workerZoneFormGroup" [formGroup]="workerZoneFormGroup">
    <div class="card mt-2 ">
        <div class="grid">
            <div class="col-12">
                <div class="input-field">
                    <label for="name">Nombre *</label>
                    <input pInputText id="name" formControlName="name" d-form-field-error />
                </div>
            </div>
        </div>
        <div class="mt-4 grid" *ngIf="zone.id">
            <h3 class="col-4 flex align-items-center justify-content-start m-0">Líneas</h3>
            <div class="col-4 col-offset-4 text-align flex flex-row-reverse flex-wrap">
                <p-button label="Nueva línea" (click)="editZoneLine({})" styleClass="p-button-sm"
                    *ngIf="allowWrite"></p-button>
            </div>
            <p-table [value]="zoneLines" dataKey="id" class="col-12" [tableStyle]=" { 'min-width' : '100%' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Código postal</th>
                        <th>Horario normal</th>
                        <th>Horario de guardia</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-zoneLine>
                    <tr (dblclick)="editZoneLine(zoneLine)">
                        <td>
                            {{zoneLine.value}}
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': zoneLine.normalServiceAllowed, 'text-red-500 pi-times-circle': !zoneLine.normalServiceAllowed }"></i>
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': zoneLine.guardServiceAllowed, 'text-red-500 pi-times-circle': !zoneLine.guardServiceAllowed }"></i>
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowWrite" (click)="editZoneLine(zoneLine)" styleClass="p-button-xs"><i
                                        class="pi pi-pencil"></i></p-button>
                                <p-button *ngIf="allowDelete" (click)="deleteZoneLine(zoneLine)"
                                    styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="mt-6 flex gap-2 justify-content-end ">
            <p-button label="Eliminar" *ngIf="zone.id&&allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>


    </div>
</form>

<p-dialog header="Nueva línea" [modal]="true" [(visible)]="visibleZoneLine" [style]="{ width: '25rem' }">
    <form *ngIf="zoneLineFormGroup" [formGroup]="zoneLineFormGroup">
        <div class="grid">
            <div class="col-12 input-field">
                <label for="value">Código postal</label>
                <input pInputText id="value" formControlName="value" class="flex-auto" autocomplete="off"
                d-form-field-error />
            </div>
        </div>
        <div class="grid">
            <div class="col-6 input-field">
                <label for="normalServiceAllowed" class="mr-1">Horario normal</label>
                <div class="flex h-full">
                    <p-inputSwitch id="normalServiceAllowed" formControlName="normalServiceAllowed"
                        d-form-field-error></p-inputSwitch>
                </div>
            </div>
            <div class="col-6 input-field">
                <label for="guardServiceAllowed" class="mr-1">Horario de guardia</label>
                <div class="flex h-full">
                    <p-inputSwitch id="guardServiceAllowed" formControlName="guardServiceAllowed"
                        d-form-field-error></p-inputSwitch>
                </div>
            </div>
        </div>

        <div class="flex justify-content-end gap-2">
            <p-button label="Cancelar" severity="secondary" (click)="visibleZoneLine = false" />
            <p-button label="Guardar" (click)="saveZoneLine()" />
        </div>
    </form>
</p-dialog>