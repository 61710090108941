import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  onPendingRequest: EventEmitter<any> = new EventEmitter<any>();
  requestsToSkip: string[] = [];

  constructor() {
  }

  skipRequest(url: string, params: any|null = null) {
    let urlWithParams = url;
    if (params != null) {
      urlWithParams += "?" + (new URLSearchParams(params)).toString();
      urlWithParams = decodeURIComponent(urlWithParams);
    }
    this.requestsToSkip.push(urlWithParams);  
  }

  removeSkipRequest(request: HttpRequest<any>) {
    let idx = this.requestsToSkip.indexOf(request.urlWithParams);
    if (idx >= 0) {
      delete this.requestsToSkip[idx];
    }
  }

  isRequestSkiped(request: HttpRequest<any>) {
    let skiped = this.requestsToSkip.indexOf(request.urlWithParams) >= 0;
    return skiped;
  }

}
