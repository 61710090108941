import { Resource } from "src/app/core/jsonapi/resource";
import { Capability } from "../../common/_types/capability";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class WorkerEquipment extends Resource {
    public override type = 'workerEquipments';

    @prop()
    @required()
    public workerId!: string;

    @prop()
    @required()
    public equipmentId!: string;

    @prop()
    public notes!: string;
    
}