import { Role } from "../../roles/_types/role";
import { minLength, prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class UserType extends Resource {
    public override type = 'userTypes';

    @prop()
    @required()
    public code!: string;

    @prop()
    @required()
    public name!: string;

    @prop()
    public enableServiceEvaluations: boolean = false;

}