import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { BusinessBankAccount } from "../_types/business-bank-account";

@Injectable({
    providedIn: 'root',
})

export class BusinessBankAccountsService extends Service<BusinessBankAccount> {
    public override type = 'companyBankAccounts';
    public override url = 'common/companyBankAccounts';
}