import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerCapability } from "../_types/worker-capability";
import { WorkerVehicle } from "../_types/worker-vehicle";

@Injectable({
    providedIn: 'root',
})
export class WorkerVehiclesService extends Service<WorkerVehicle> {
    public override type = 'workerVehicles';
    public override url = 'providers/workerVehicles';
}
