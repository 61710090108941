import { Component } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { TasksService } from '../_services/tasks.service';
import { Task } from '../_types/task';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { UsersService } from '../../users/_services/users.service';
import { User } from '../../users/_types/user';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent {
  public tasks: Task[] = [];
  public users: User[] = [];
  public taskStatus: any[] = [
    { label: 'Pendiente', value: false },
    { label: 'Completada', value: true }
  ];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    description: new Filter(FilterOperation.contains, 'description'),
    userId: new Filter(FilterOperation.equals, 'userId'),
    isCompleted: new Filter(FilterOperation.equals, 'isCompleted'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private tasksService: TasksService,
    private usersService: UsersService,
    private topbarService: TopbarService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.topbarService.setTitle("Tareas");
    this.topbarService.addAction("Nueva", "pi pi-plus", false, () => {
      this.edit({} as Task);
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows },
      include: ['user', 'caseNote'],
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();


    let response = await firstValueFrom(this.tasksService.all(params));
    this.tasks = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadUsers() {
    let response = await firstValueFrom(this.usersService.all());
    this.users = response.data;
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }


  edit(task: Task) {
    this.router.navigate(['tasks/' + (task && Object.keys(task).length > 0 ? task.id : 0)]);
  }

  delete(task: Task) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la tarea?",
        header: "Eliminar tarea",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.tasksService.delete(task.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Tarea eliminada correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        }
      });
    }
  }
}
