import { Resource } from "src/app/core/jsonapi/resource";
import { Capability } from "../../common/_types/capability";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Vehicle } from "../../vehicles/_types/vehicle";

export class WorkerVehicle extends Resource {
    public override type = 'workerVehicles';

    @prop()
    @required()
    public workerId!: string;

    @prop()
    @required()
    public vehicleId!: string;

    public vehicle!: Vehicle;

    @prop()
    @required()
    public fromDate!: Date;

    @prop()
    public toDate!: Date;

}