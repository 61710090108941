<form *ngIf="contractMailAccountFormGroup" [formGroup]="contractMailAccountFormGroup">
    <div class="grid">
        <div class="col-6 input-field">
            <label for="name">Nombre</label>
            <input pInputText name="name" formControlName="name" d-form-field-error />
        </div>
        <div class="col-6 input-field">
            <label for="username">Nombre de usuario</label>
            <input pInputText name="username" formControlName="username" d-form-field-error />
        </div>
        <div class="col-12 input-field">
            <label for="password">Contraseña</label>
            <input pInputText type="password" formControlName="password" autocomplete="new-password"
                d-form-field-error />
        </div>
        <div class="col-6">
            <div class="grid">
                <div class="col-12 input-field">
                    <label for="SMTPEnabled"><b>Habilitar SMTP</b></label>
                    <p-inputSwitch id="SMTPEnabled" formControlName="SMTPEnabled" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('SMTPEnabled')?.value">
                    <label for="SMTPFrom">(SMTP) Desde</label>
                    <input pInputText name="SMTPFrom" formControlName="SMTPFrom" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('SMTPEnabled')?.value">
                    <label for="SMTPHost">(SMTP) Host</label>
                    <input pInputText name="SMTPHost" formControlName="SMTPHost" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('SMTPEnabled')?.value">
                    <label for="SMTPPort">(SMTP) Puerto</label>
                    <input pInputText name="SMTPPort" formControlName="SMTPPort" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('SMTPEnabled')?.value">
                    <label for="SMTPUseSSL">(SMPT) Usar SSL</label>
                    <p-inputSwitch id="SMTPUseSSL" formControlName="SMTPUseSSL" d-form-field-error />
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="grid">
                <div class="col-12 input-field">
                    <label for="IMAPEnabled"><b>Habilitar IMAP</b></label>
                    <p-inputSwitch id="IMAPEnabled" formControlName="IMAPEnabled" d-form-field-error />
                </div>

                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('IMAPEnabled')?.value">
                    <label for="IMAPHost">(IMAP) Host</label>
                    <input pInputText name="IMAPHost" formControlName="IMAPHost" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('IMAPEnabled')?.value">
                    <label for="IMAPPort">(IMAP) Puerto</label>
                    <input pInputText name="IMAPPort" formControlName="IMAPPort" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="contractMailAccountFormGroup.get('IMAPEnabled')?.value">
                    <label for="IMAPUseSSL">(IMAP) Usar SSL</label>
                    <p-inputSwitch id="IMAPUseSSL" formControlName="IMAPUseSSL" d-form-field-error />
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 flex justify-content-between">
        <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"
            *ngIf="contractMailAccount.id && allowDelete"></p-button>
        <div class="flex gap-2">
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </div>
</form>