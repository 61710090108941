import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { RateLine } from 'src/app/modules/rates/_types/rate-line';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { firstValueFrom } from 'rxjs';
import { TransactionsLinesService } from 'src/app/modules/transactions/_services/transactions-lines.service';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { TransactionsService } from 'src/app/modules/transactions/_services/transactions.service';
import { TransactionsStatesService } from 'src/app/modules/transactions/_services/transactions-states.service';
import { TransactionsTypesService } from 'src/app/modules/transactions/_services/transactions-types.service';
import { Job } from 'src/app/modules/cases/_types/job';
import { JobsService } from 'src/app/modules/cases/_services/jobs.service';
import { TransactionLine } from 'src/app/modules/transactions/_types/transaction-line';
import { TasksRoutingModule } from 'src/app/modules/tasks/tasks-routing.module';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-case-service-transaction-lines',
  templateUrl: './case-service-transaction-lines.component.html',
  styleUrls: ['./case-service-transaction-lines.component.scss']
})
export class CaseServiceTransactionLinesComponent {

  public transactionLines: any;
  public totalTransactionLines!: number;
  public transactions: any = [];
  public transactionType: any = {};

  public companyId!: string;
  public operatorId!: string;
  public caseServiceId!: string;
  public caseId!: string;
  public rateLines: RateLine[] = [];
  public hasSubmitInvoice: boolean = false;
  public allRateLines: any;
  public type!: string;
  public loadingTransactionLines: boolean = false;
  public selectedLines: any[] = [];
  public selectedTransaction: any;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private transactionsService: TransactionsService,
    private transactionsStatesService: TransactionsStatesService,
    private transactionsTypesService: TransactionsTypesService,
    private transactionsLinesService: TransactionsLinesService,
    private jobsService: JobsService,
    private messageService: MessageService
  ) {
  }

  async ngOnInit() {
    let jobId = this.config.data.id;
    this.companyId = this.config.data.companyId;
    this.operatorId = this.config.data.operatorId;
    this.type = this.config.data.type;    
    this.caseServiceId = this.config.data.caseServiceId;
    this.caseId = this.config.data.caseId;


    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");        

    this.loadData();

  }

  async loadData() {


    await this.loadTransactionType();

    let params: IParamsCollection = {
      include: ['transactionState', 'thirdParty']
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject({ transactionTypeId: new Filter(FilterOperation.equals, 'transactionTypeId', '', this.transactionType.id) }).build();

    let response = await firstValueFrom(this.transactionsService.all(params));
    this.transactions = response.data;

    this.transactions.forEach((option:any) => {
    
        const formatoPersonalizado = option.date.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }); 
      option.customName = (formatoPersonalizado??"")+" - "+option.code;
    });


  }

  async loadTransactionType() {

    let params: IParamsCollection = {
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject({ code: new Filter(FilterOperation.equals, 'code', '', "company_outgoing_budget") }).build();

    let response = await firstValueFrom(this.transactionsTypesService.all(params));
    this.transactionType = response.data[0];
    console.log(this.transactionType);
  }

  async loadTransactionLines() {

    this.selectedLines = [];

    let params: IParamsCollection = {
      sort: ["lineNumber"],
      include: ["rateLine"]
    }
    let filters: any = {
      transactionId: new Filter(FilterOperation.equals, "transactionId"),
    };
        
    
    filters.transactionId.value = this.selectedTransaction;
    params.filter = FilterBuilder.fromObject(filters).build();     

    let response = await firstValueFrom(this.transactionsLinesService.all(params));

      console.log(response);

     this.transactionLines = response.data;
      

    /*this.transactionLines = response.data.map(line => ({
      ...line,           // Copia todas las propiedades originales
      selected: false // Añade la nueva propiedad
    }));*/

    
    this.totalTransactionLines = response.meta['total'];
    this.loadingTransactionLines = false;

  }

  // Función para generar el label concatenado
  getOptionLabel(option: any) {
    console.log(option);
    return option.code + " - " + option.description;
  }


  cancel() {
    this.dynamicDialogRef.close();
  }


  async save() {    
    //Creamos job por cada linea
    try {
      const promesas = this.selectedLines.map((line, index) => {
        let newJob: Job = new Job();              
        newJob.caseServiceId = this.caseServiceId;
        newJob.caseId = this.caseId;
        newJob.date = new Date();
        newJob.operatorId = this.operatorId;
        newJob.rateLineId = line.rateLineId;
        newJob.unitPrice = line.price;
        newJob.quantity = line.quantity;
        newJob.total = line.subtotal;
        newJob.isForCompany = true;        
        console.log(newJob);

        return firstValueFrom(this.jobsService.save(newJob));  
      });
  
      // Espera que todas las promesas se resuelvan en paralelo
      await Promise.all(promesas);
      
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Trabajos guardados correctamente." });

      this.dynamicDialogRef.close();
    } catch (error) {
      console.error(`Error al guardar alguna de las líneas: ${error}`);
    }    
  }


}
