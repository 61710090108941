import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersDetailComponent } from './users-detail/users-detail.component';
import { UsersRoutingModule } from './users-routing.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AuthenticationServiceComponent } from './authentication-service/authentication-service.component';
import { CoreModule } from 'src/app/core/core.module';
import { TabViewModule } from 'primeng/tabview';
import { InputNumberModule } from 'primeng/inputnumber';


@NgModule({
    declarations: [
        UsersListComponent,
        UsersDetailComponent,
        AuthenticationServiceComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        CardModule,
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        PasswordModule,
        HttpClientModule,
        FormsModule,
        TableModule,
        CardModule,
        TabViewModule,
        UsersRoutingModule,
        ReactiveFormsModule,
        InputTextModule,
        InputSwitchModule,
        ConfirmDialogModule,
        DropdownModule,
        DynamicDialogModule,
        InputNumberModule
    ]
})
export class UsersModule { }
