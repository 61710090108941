import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { VehicleType } from "../_types/vehicle-type";

@Injectable({
    providedIn: 'root',
})

export class VehiclesTypesService extends Service<VehicleType> {
  public override type = 'vehicleTypes';
  public override url = 'providers/vehicleTypes'; 
    
}
