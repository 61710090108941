<div class="flex gap-2 cola-4">
    <span class="w-full input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
</div>

<p-table styleClass="mt-4" #grid [value]="business" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[5,10,25,50]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="legalName">Nombre legal <p-sortIcon field="legalName"></p-sortIcon></th>
            <th pSortableColumn="VATNumber">VAT <p-sortIcon field="VATNumber"></p-sortIcon></th>
            <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-bus let-columns="columns">
        <tr (dblclick)="edit(bus)">
            <td>{{bus.name}}</td>
            <td>{{bus.legalName}}</td>
            <td>{{bus.vatNumber}}</td>
            <td>
                <i class="pi"
                    [ngClass]="{ 'text-green-500 pi-check-circle': bus.active, 'text-red-500 pi-times-circle': !bus.active }"></i>
            </td>
            <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowDelete" (click)="delete(bus)" [rounded]="true" [text]="false" severity="danger"><i
                            class="pi pi-trash"></i></p-button>
                    <p-button *ngIf="allowWrite" (click)="edit(bus)" [rounded]="true" [text]="false"><i
                            class="pi pi-pencil"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>