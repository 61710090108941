import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { GuardsShiftsComponent } from './list/guards-shifts-list.component';
import { GuardsShiftsDetailComponent } from './detail/guards-shifts-detail.component';
import { CoreModule } from 'src/app/core/core.module';
import { GuardsShiftsRoutingModule } from './guards-shifts-routing.module';
import { DialogModule } from 'primeng/dialog';
import { ColorPickerModule } from 'primeng/colorpicker';


@NgModule({
  declarations: [
    GuardsShiftsComponent,
    GuardsShiftsDetailComponent,
    //CalendarEventModalComponent   
  ],
  imports: [
    GuardsShiftsRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    TableModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    CheckboxModule,
    TabViewModule,    
    CalendarModule,
    CoreModule,
    ReactiveFormsModule,
    DialogModule,
    ColorPickerModule 
  ],
  providers: []
})
export class GuardsShiftsModule { }
