import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Country } from "../_types/country";
@Injectable({
  providedIn: 'root',
})

export class CountriesService extends Service<Country> {
  public override type = 'countries';
  public override url = 'common/countries';
}
