import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { BusinessAddress } from "../_types/business-address";

@Injectable({
    providedIn: 'root',
})

export class BusinessAddressesService extends Service<BusinessAddress> {
    public override type = 'companyAddresses';
    public override url = 'common/companyAddresses';
}