<form *ngIf="noteFormGroup" [formGroup]="noteFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="notes">Notas</label>
            <textarea pInputTextarea formControlName="notes" style="field-sizing: content;"
                d-form-field-error></textarea>
        </div>
        <div class="col-3 input-field" *ngIf="!asociatedTask?.id">
            <label for="generateTask">Generar tarea</label>
            <p-inputSwitch id="generateTask" [ngModelOptions]="{standalone: true}"
                [(ngModel)]=" generateTask"></p-inputSwitch>
        </div>
        <div class="col-9 input-field" *ngIf="generateTask && !asociatedTask.id">
            <label for="selectedUser">Asignar a</label>
            <p-dropdown id="selectedUser" [options]="users" optionLabel="name" optionValue="id" appendTo="body"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUser" [showClear]="true" [filter]="true"
                filterBy="name"></p-dropdown>
        </div>
        <div class="col-12" *ngIf="asociatedTask?.id">
            <p-messages [value]="[{
                    severity: 'info', 
                    summary: 'Tarea asociada:', 
                    detail: 'Se ha generado una tarea asociada a esta nota y se ha asignado a ' + getUserName(asociatedTask.userId) + '.'
                    }]" [closable]="false" />
            <p-button label="Ver tarea" icon="pi pi-eye" />
        </div>
    </div>
    <div class="mt-4 flex justify-content-between">
        <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"
            *ngIf="note.id && allowDelete"></p-button>
        <div class="flex gap-2">
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </div>
</form>