import { Injectable } from "@angular/core";
import { Worker } from "../_types/worker";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerHoliday } from "../_types/worker-holiday";

@Injectable({
    providedIn: 'root',
})
export class WorkersHolidaysService extends Service<WorkerHoliday> {
  public override type = 'workerHolidays';
  public override url = 'providers/workerHolidays'; 
}
