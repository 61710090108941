import { Component } from '@angular/core';
import { Survey } from '../_types/survey';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { SurveysService } from '../_services/surveys.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrls: ['./surveys-list.component.scss']
})
export class SurveysListComponent {
  public surveys: Survey[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private surveysService: SurveysService,
    private topbarService: TopbarService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.topbarService.setTitle("Encuestas");
    this.topbarService.addAction("Nueva", "pi pi-plus", false, () => {
      this.edit({} as Survey);
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")
  }

  ngOnInit(): void {
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows },
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();


    let response = await firstValueFrom(this.surveysService.all(params));
    this.surveys = response.data;
    this.totalRecords = response.meta['total'];
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }


  edit(survey: Survey) {
    this.router.navigate(['surveys/' + (survey && Object.keys(survey).length > 0 ? survey.id : 0)]);
  }

  delete(survey: Survey) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la encuesta?",
        header: "Eliminar encuesta",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.surveysService.delete(survey.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Encuesta eliminada correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        }
      });
    }
  }
}
