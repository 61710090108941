import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Calendar } from "../_types/calendar";

@Injectable({
    providedIn: 'root',
})

export class CalendarsService extends Service<Calendar> {
  public override type = 'calendars';
  public override url = 'providers/calendars';   
  
}
