import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RolesListComponent } from './roles-list/roles-list.component';
import { RolesRoutingModule } from './roles-routing.module';
import { TableModule } from 'primeng/table';
import { RolesDetailComponent } from './roles-detail/roles-detail.component';
import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CoreModule } from 'src/app/core/core.module';
import { InputSwitchModule } from 'primeng/inputswitch';


@NgModule({
    declarations: [
        RolesListComponent,
        RolesDetailComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        CardModule,
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        PasswordModule,
        HttpClientModule,
        FormsModule,
        TableModule,
        CardModule,
        PanelModule,
        RolesRoutingModule,
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
        InputSwitchModule,
        ConfirmDialogModule
    ]
})
export class RolesModule { }
