
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { ContractsService } from '../_services/contracts.service';
import { Contract } from '../_types/contract';
import { ContractZonesService } from '../_services/contracts-zones.service';
import { ContractZonesDetailComponent } from './zones-detail/zones-detail.component';
import { ContractZone } from '../_types/contract-zone';
import { ContractCapabilitiesService } from '../_services/contracts-capabilities.service';
import { ContractProcessor } from '../_types/contract-processor';
import { ContractProcessorsService } from '../_services/contracts-processors.service';
import { ContractProcessorsDetailComponent } from './processors-detail/processors-detail.component';
import { ContractOperator } from '../_types/contract-operator';
import { ContractOperatorsDetailComponent } from './operators-detail/operators-detail.component';
import { ContractOperatorsService } from '../_services/contracts-operators.service';
import { BusinessBankAccountsService } from '../../business/_services/business-bank-accounts.service';
import { BusinessBankAccount } from '../../business/_types/business-bank-account';
import { Business } from '../../business/_types/business';
import { BusinessService } from '../../business/_services/business.service';
import { PaymentMethod } from '../../common/_types/payment-method';
import { PaymentsMethodsService } from '../../common/_services/payments-methods.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { ContractCapabilitiesDetailComponent } from './capabilities-detail/capabilities-detail.component';
import { ContractCapability } from '../_types/contract-capability';
import { ContractSla } from '../_types/contract-sla';
import { ContractSlasService } from '../_services/contracts-slas.service';
import { ContractSlasDetailComponent } from './slas-detail/slas-detail.component';
import { ContractMailAccountsService } from '../_services/contracts-mails-accounts.service';
import { ContractMailAccount } from '../_types/contract-mail-account';
import { ContractMailAccountsDetailComponent } from './mails-detail/mails-detail.component';

@Component({
  selector: 'app-contracts-detail',
  templateUrl: './contracts-detail.component.html',
  styleUrls: ['./contracts-detail.component.css']
})
export class ContractsDetailComponent implements OnInit {

  public contractFormGroup!: RxFormGroup;

  public contract: Contract = new Contract();
  public loading: boolean = false;
  public activeIndex: number = 1;
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public contractZones: Array<ContractZone> = new Array<ContractZone>();
  public contractProcessors: Array<ContractProcessor> = new Array<ContractProcessor>();
  public contractOperators: Array<ContractOperator> = new Array<ContractOperator>();
  public contractCapabilities: Array<ContractCapability> = new Array<ContractCapability>();
  public contractSlas: Array<ContractSla> = new Array<ContractSla>();
  public contractMailAccounts: Array<ContractMailAccount> = new Array<ContractMailAccount>();
  public totalCapabilitiesRecords: number = 0;

  public billingCompaniesBanksAccounts: Array<BusinessBankAccount> = new Array<BusinessBankAccount>();
  public paymentsMethods: Array<PaymentMethod> = new Array<PaymentMethod>();
  public billingCompanies: Array<Business> = new Array<Business>();
  public companyId: string = "";



  constructor(
    private businessService: BusinessService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private contractsService: ContractsService,
    private contractZonesService: ContractZonesService,
    private contractProcessorsService: ContractProcessorsService,
    private contractSlasService: ContractSlasService,
    private contractMailAccountsService: ContractMailAccountsService,
    private dialogService: DialogService,
    private contractCapabilitiesService: ContractCapabilitiesService,
    private contractOperatorsService: ContractOperatorsService,
    private companiesBankAccountsService: BusinessBankAccountsService,
    private paymentsMethodsService: PaymentsMethodsService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
  ) {

    this.topbarService.setTitle("Detalles de contrato");
    this.topbarService.addBreadcrumb("Compañías aseguradoras", "/companies");

    this.contractFormGroup = this.formBuilder.formGroup<Contract>(this.contract) as RxFormGroup;
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      let companyId: string = params.get('id')!;
      let contractId: string | null = params.get('contractId');
      this.contract.companyId = companyId;
      if (contractId != null && contractId != '0') {
        this.loadContract(contractId);
      }

      this.topbarService.addBreadcrumb("Detalles de compañía aseguradora", "/companies/" + params.get('id'));
      this.loadBillingCompanies();

    });
    this.allowWrite = true;//this.loginService.hasPermission("CONTRACTS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("CONTRACTS_DELETE");


  }

  async loadContract(id: string) {
    this.contract = await firstValueFrom(this.contractsService.get(id));
    this.contractFormGroup.patchValue(this.contract);
    this.loadContractCapabilities();
    this.loadContractZones();
    this.loadContractProcessors();
    this.loadContractOperators();
    this.loadContractSlas();
    this.loadContractMailAccounts();
    this.loadBillingCompaniesBankAccounts();
    this.loadPaymentsMethods();
  }

  async loadContractZones() {

    let filters = {
      companyId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      page: { size: 0 },
      filter: FilterBuilder.fromObject(filters).build()
    };


    let response = await firstValueFrom(this.contractZonesService.all(params));
    this.contractZones = response.data;
  }

  async loadContractProcessors() {
    let filters = {
      contractId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      include: ['processor']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.contractProcessorsService.all(params));
    this.contractProcessors = response.data;
  }

  async loadContractOperators() {
    let filters = {
      contractId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      include: ['operator']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.contractOperatorsService.all(params));
    this.contractOperators = response.data;

  }

  async loadContractCapabilities() {
    let filters = {
      contractId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      //page: {size:0},
      include: ['capability']
    };

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.contractCapabilitiesService.all(params));
    this.contractCapabilities = response.data;
  }

  async loadContractSlas() {
    let filters = {
      contractId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build(),
      include: ['caseEventType']
    };

    let response = await firstValueFrom(this.contractSlasService.all(params));
    this.contractSlas = response.data;
  }

  async loadBillingCompanies() {

    let response = await firstValueFrom(this.businessService.all({ page: { number: 1, size: 10 } }));
    this.billingCompanies = response.data;

  }

  async loadContractMailAccounts() {
    let filters = {
      contractId: new Filter(FilterOperation.equals, 'contractId', "", this.contract.id)
    };

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };

    let response = await firstValueFrom(this.contractMailAccountsService.all(params));
    this.contractMailAccounts = response.data;
  }

  async loadBillingCompaniesBankAccounts() {

    let filters: any = {};
    if (this.contract.billingCompanyId != "" && this.contract.billingCompanyId != null) {
      filters.companyId = new Filter(FilterOperation.equals, 'companyId', "", this.contract.billingCompanyId)
    }



    let params: IParamsCollection = {
      page: { size: 0 },
      filter: FilterBuilder.fromObject(filters).build()
    };
    let response = await firstValueFrom(this.companiesBankAccountsService.all(params));
    this.billingCompaniesBanksAccounts = response.data;
  }

  async onChangeCompany(event: any) {
    this.loadBillingCompaniesBankAccounts();
  }


  async loadPaymentsMethods() {

    let response = await firstValueFrom(this.paymentsMethodsService.all({ page: { number: 1, size: 10 } }));
    this.paymentsMethods = response.data;

  }

  async save() {
    let valid = this.formValidationService.validateForm(this.contractFormGroup);
    if (valid) {

      this.contract = Object.assign(this.contract, this.contractFormGroup.value);

      //Obtenemos todos los códigos para comparar al guardar
      let filters = {
        code: new Filter(FilterOperation.equals, 'code', "", this.contract.code)
      };


      let params: IParamsCollection = {
        //page: {size:0},
        filter: FilterBuilder.fromObject(filters).build()
      };


      let response = await firstValueFrom(this.contractsService.all(params));
      if (response.meta['total'] == 0 || this.contract.id == response.data[0].id) {

        console.log(this.contract);
        //guardamos contract
        let contract = await firstValueFrom(this.contractsService.save(this.contract));


        if (this.contract.id != "undefined" && this.contract.id != "") {
          this.router.navigate(['companies/' + this.contract.companyId]);
        }

        this.messageService.add({ closable: false, severity: 'success', summary: "Contrato guardado correctamente." });

        this.loadContract(contract.id!);

      } else {
        this.messageService.add({ closable: false, severity: 'error', detail: "El código introducido ya existe" });
      }
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el contrato " + this.contract.name + "?",
      header: "Eliminar contrato",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractsService.delete(this.contract.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Contrato eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['companies/' + this.contract.companyId]);
  }


  editContractCapability(contractCapability: any) {
    let ref = this.dialogService.open(ContractCapabilitiesDetailComponent, {
      header: 'Editar especialidad del contrato',
      width: '500px',
      data: {
        contractCapabilityId: contractCapability ? contractCapability.id : null,
        contractId: this.contract.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadContractCapabilities();
    });
  }

  deleteContractCapability(contractCapability: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la especialiad del contrato?",
      header: "Eliminar especialidad",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractCapabilitiesService.delete(contractCapability.id).subscribe({
          next: (data: any) => {
            this.loadContractCapabilities();
            this.messageService.add({ closable: false, severity: 'success', detail: "Especialidad eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  editContractZone(contractZone: any) {
    const ref = this.dialogService.open(ContractZonesDetailComponent, {
      data: {
        contractId: this.contract.id,
        id: contractZone.id
      },
      header: contractZone.id ? contractZone.name : "Nueva zona",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadContractZones();
    });

  }

  deleteContractZone(contractZone: ContractZone) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la zona " + contractZone.name + "?",
      header: "Eliminar zona",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractZonesService.delete(contractZone.id).subscribe({
          next: (data: any) => {
            this.loadContractZones();
            this.messageService.add({ closable: false, severity: 'success', detail: "Zona eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  editContractProcessor(contractProcessor: any) {

    const ref = this.dialogService.open(ContractProcessorsDetailComponent, {
      data: {
        contractId: this.contract.id,
        id: contractProcessor.id,
        processorsIds: this.contractProcessors.map(m => m.processorId)
      },
      header: contractProcessor.id ? "Modificar tramitador" : "Nuevo tramitador",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadContractProcessors();
    });

  }

  deleteContractProcessor(contractProcessor: ContractProcessor) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar al tramitador " + contractProcessor.processor?.name + "?",
      header: "Eliminar tramitador",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractProcessorsService.delete(contractProcessor.id).subscribe({
          next: (data: any) => {
            this.loadContractProcessors();
            this.messageService.add({ closable: false, severity: 'success', detail: "Tramitador eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }


  editContractOperator(contractOperator: any) {

    const ref = this.dialogService.open(ContractOperatorsDetailComponent, {
      data: {
        contractId: this.contract.id,
        id: contractOperator.id,
        operatorsIds: this.contractOperators.map(m => m.operatorId)
      },
      header: contractOperator.id ? "Modificar operario preferente" : "Nuevo operario preferente",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadContractOperators();
    });

  }

  deleteContractOperator(contractOperator: ContractOperator) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar al operario preferente?",
      header: "Eliminar operario preferente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractOperatorsService.delete(contractOperator.id).subscribe({
          next: (data: any) => {
            this.loadContractOperators();
            this.messageService.add({ closable: false, severity: 'success', detail: "Operario preferente eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  editContractSla(contractSla: ContractSla | null) {
    const ref = this.dialogService.open(ContractSlasDetailComponent, {
      data: {
        contractId: this.contract.id,
        contractSlaId: contractSla ? contractSla.id : null
      },
      header: contractSla?.id ? "Modificar SLA" : "Nuevo SLA",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadContractSlas();
    });
  }

  deleteContractSla(contractSla: ContractSla) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el SLA?",
      header: "Eliminar SLA",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractSlasService.delete(contractSla.id).subscribe({
          next: (data: any) => {
            this.loadContractSlas();
            this.messageService.add({ closable: false, severity: 'success', detail: "SLA eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  editContractMailAccount(contractMailAccount: ContractMailAccount | null) {
    const ref = this.dialogService.open(ContractMailAccountsDetailComponent, {
      data: {
        contractId: this.contract.id,
        contractMailAccountId: contractMailAccount ? contractMailAccount.id : null
      },
      header: contractMailAccount?.id ? "Modificar cuenta de correo" : "Nueva cuenta de correo",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadContractMailAccounts();
    });
  }

  deleteContractMailAccount(contractMailAccount: ContractMailAccount) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la cuenta de correo?",
      header: "Eliminar cuenta de correo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractMailAccountsService.delete(contractMailAccount.id).subscribe({
          next: (data: any) => {
            this.loadContractMailAccounts();
            this.messageService.add({ closable: false, severity: 'success', detail: "Cuenta de correo eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

}
