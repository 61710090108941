<p-card class="p-3" header="Datos generales">
    <form *ngIf="templateFormGroup" [formGroup]="templateFormGroup">

        <div class="grid">
            <div class="col-8 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-4 input-field">
                <label for="templateType">Tipo *</label>
                <p-dropdown formControlName="templateType" [options]="templateTypes" optionLabel="label"
                    optionValue="value" [filter]="true" filterBy="label" placeholder="Selecciona un tipo"
                    d-form-field-error></p-dropdown>
            </div>
            <div class="col-12 input-field" *ngIf="templateFormGroup.get('templateType')?.value=='Email'">
                <label for="subject">Asunto</label>
                <input pInputText formControlName="subject" d-form-field-error />
            </div>
            <div class="col-12 input-field" *ngIf="templateFormGroup.get('templateType')?.value=='Email'">
                <label for="content">Mensaje</label>
                <editor [init]="init" formControlName="content" d-form-field-error />
            </div>
            <div class="col-12 input-field" *ngIf="templateFormGroup.get('templateType')?.value=='SMS'">
                <label for="content">Mensaje</label>
                <textarea pInputTextarea formControlName="content" d-form-field-error></textarea>
            </div>
        </div>
        <div class="mt-4 flex gap-2">
            <p-button label="Eliminar" *ngIf="allowDelete && template?.id" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </form>
</p-card>

<p-confirmDialog></p-confirmDialog>