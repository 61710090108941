import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { TransactionType } from "../_types/transaction-type";

@Injectable({
    providedIn: 'root',
})
export class TransactionsTypesService extends Service<TransactionType> {
    public override type = 'transactionTypes';
    public override url = 'invoicing/transactionTypes';
}
