import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Bank } from '../../common/_types/bank';
import { BanksService } from '../../common/_services/banks.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-banks-detail',
  templateUrl: './banks-detail.component.html',
  styleUrls: ['./banks-detail.component.scss']
})
export class BanksDetailComponent {
  public bankFormGroup!: RxFormGroup;

  public bank: Bank = new Bank();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private banksService: BanksService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de banco");
    this.topbarService.addBreadcrumb("Bancos", "/banks");

    this.bankFormGroup = this.formBuilder.formGroup<Bank>(this.bank) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadBank(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadBank(id: string) {
    this.bank = await firstValueFrom(this.banksService.get(id));
    this.bankFormGroup.patchValue(this.bank);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.bankFormGroup);
    if (valid) {
      this.bank = Object.assign(this.bank, this.bankFormGroup.value);

      let bank = await firstValueFrom(this.banksService.save(this.bank));

      this.messageService.add({ closable: false, severity: 'success', summary: "Operario guardado correctamente." });

      this.router.navigate(['banks']);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el banco?",
      header: "Eliminar banco",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.banksService.delete(this.bank.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Banco eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['banks']);
  }

}
