import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Case } from '../../_types/case';
import { CasesStatesService } from '../../_services/cases-states.service';
import { CasesService } from '../../_services/cases.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { MessageService } from 'primeng/api';
import { CaseState } from '../../_types/case-state';
import { ClientAddress } from 'src/app/modules/clients/_types/client-address';
import { firstValueFrom } from 'rxjs';
import { ClientAddressesService } from 'src/app/modules/clients/_services/clients-addresses.service';

@Component({
  selector: 'app-case-state',
  templateUrl: './case-state.component.html',
  styleUrls: ['./case-state.component.scss']
})
export class CaseStateComponent {
  public caseFormGroup!: RxFormGroup;

  public case: Case = new Case();
  public caseStates: CaseState[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private casesService: CasesService,
    private casesStatesService: CasesStatesService,
    private clientAddressesService: ClientAddressesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private messageService: MessageService
  ) {
    this.caseFormGroup = this.formBuilder.formGroup<Case>(this.case) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCaseStates();

    let id = this.config.data.id;
    if (id != null && id != '0') {
      await this.loadCase(id);
    }

    if (this.case.address == null) this.case.address = new ClientAddress();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadCase(id: string) {
    this.case = await firstValueFrom(this.casesService.get(id, { include: ['company', 'client', 'processor', 'address', 'caseState'] }));
    this.caseFormGroup.patchValue(this.case);
  }

  async loadCaseStates() {
    let response = await firstValueFrom(this.casesStatesService.all());
    this.caseStates = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.caseFormGroup);
    if (valid) {
      this.case = Object.assign(this.case, this.caseFormGroup.value);

      if (this.case.address != null) {
        let address: any = await firstValueFrom(this.clientAddressesService.save(this.case.address));
        this.case.addressId = address.id;
      }

      let caseItem = await firstValueFrom(this.casesService.save(this.case));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Expediente guardado correctamente." });
      this.dynamicDialogRef.close(this.case.id ? null : caseItem.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

}
