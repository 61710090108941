import { Injectable } from "@angular/core";
import { ProviderBankAccount } from "../_types/provider-bank-account.model";
import { Service } from "src/app/core/jsonapi/service";

@Injectable({
    providedIn: 'root',
})
export class ProviderBankAccountsService extends Service<ProviderBankAccount> {
  public override type = 'providerBankAccounts';
  public override url = 'providers/providerBankAccounts'; 
}