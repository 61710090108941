import { Component, OnInit } from '@angular/core';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LoginService } from '../../login/login.service';
import { CaseService } from '../../cases/_types/case-service';
import { DialogService } from 'primeng/dynamicdialog';
import { CaseServiceDetailComponent } from '../../cases/cases-detail/service/case-service-detail/case-service-detail.component';
import { ChatService } from '../../chat/_services/chat.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation, NotFilter } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { CasesServicesService } from '../../cases/_services/cases-services.service';
import { DatePipe } from '@angular/common';
import { User } from '../../users/_types/user';
import { LocalStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-dashboard',  
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public loadingServices: boolean = false;
  public caseServices: CaseService[] = [];
  public totalServicesRecords: number = 0;
  public allowWriteService: boolean = false;
  public allowDeleteService: boolean = false;
  public user!:User;

  constructor(
    private topbarService: TopbarService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private chatService: ChatService,
    private storage: LocalStorageService,
    private casesServicesService: CasesServicesService
  ) {
    this.topbarService.setTitle("Dashboard");
  }

  ngOnInit(): void {
    this.user = this.storage.get('user');
    this.loadServices();    
    this.allowWriteService = true;//this.loginService.hasPermission("SERVICES_WRITE");
    this.allowDeleteService = true;//this.loginService.hasPermission("SERVICS_DELETE");
  }

  async loadServices() {
    this.loadingServices = true;
    let params: IParamsCollection = {
      include:['operator','case.company','case.client']
    }
    let filters: any = {
      dateEnd: new NotFilter(FilterOperation.equals, "dateEnd",'',null,true),
      isValidated: new Filter(FilterOperation.equals, "isValidated",'',false),
      processorId: new Filter(FilterOperation.equals, 'case.processorId', '', this.user.id)
    };
    
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data;
    console.log(this.caseServices);
    this.totalServicesRecords = response.meta['total'];
    this.loadingServices = false;
  }
    
  async editCaseService(caseService: CaseService | null) {
    let ref = this.dialogService.open(CaseServiceDetailComponent, {
      header: 'Ficha de servicio',
      width: '80%',
      data: {        
        caseServiceId: caseService ? caseService.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      //this.loadCase(this.case.id!);
    });
  }

  async openChat(service: CaseService) {
    let titleParts:string[] = [];
    if(service.case) titleParts.push(service.case.caseNumber);
    let datePipe = new DatePipe('es-ES');
    titleParts.push('Servicio ' + datePipe.transform(service.date, 'dd/MM/yyyy')!);
    if(service.operator) titleParts.push(service.operator.name);
    let title = titleParts.join(" - ");
    this.chatService.openChatCaseService(service, title);
  }

}
