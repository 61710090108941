import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { SurveyQuestionOption } from "../_types/survey-question-option";

@Injectable({
    providedIn: 'root',
})
export class SurveysQuestionsOptionsService extends Service<SurveyQuestionOption> {
    public override type = 'surveyQuestionOptions';
    public override url = 'attendance/surveyQuestionOptions';
}
