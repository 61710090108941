import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { ExpertsService } from 'src/app/modules/experts/_services/experts.service';
import { Expert } from 'src/app/modules/experts/_types/expert';
import { ExpertsDetailComponent } from 'src/app/modules/experts/experts-detail/experts-detail.component';
import { CaseExpertCreateNewComponent } from './create-new/create-new.component';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class CaseExpertComponent {
  public experts: Expert[] = [];
  public selectedExpert: any;

  public existentCaseExperts: any[] = [];
  public caseId: string = '';

  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };

  constructor(
    private expertsService: ExpertsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) { }

  async ngOnInit() {
    if (this.config.data && this.config.data.existentCaseExperts) {
      this.existentCaseExperts = this.config.data.existentCaseExperts;
    }

    this.caseId = this.config.data.caseId;
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows }
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.expertsService.all(params));
    this.experts = response.data;

    if (this.existentCaseExperts.length > 0) {
      this.experts = this.experts.filter(expert => {
        return !this.existentCaseExperts.some(existentCaseExpert => existentCaseExpert.expert.id === expert.id);
      });
    }

    this.totalRecords = this.experts.length;
  }

  addExpert() {
    const ref = this.dialogService.open(CaseExpertCreateNewComponent, {
      header: 'Nuevo perito',
      width: '70%'
    });

    ref.onClose.subscribe((expert: Expert) => {
      if (expert) {
        this.dynamicDialogRef.close(expert);
      }
    });
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  save() {
    this.dynamicDialogRef.close(this.selectedExpert);
  }
}
