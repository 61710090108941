import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Bank } from "../_types/bank";
@Injectable({
    providedIn: 'root',
})

export class BanksService extends Service<Bank> {
  public override type = 'banks';
  public override url = 'common/banks'; 
}
