import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { Processor } from '../_types/processor';

@Injectable({
  providedIn: 'root'
})
export class ProcessorsService extends Service<Processor> {
  public override type = 'processors';
  public override url = 'attendance/processors'; 
}
