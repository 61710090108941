import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractMailAccount } from "../_types/contract-mail-account";

@Injectable({
  providedIn: 'root',
})
export class ContractMailAccountsService extends Service<ContractMailAccount> {
  public override type = 'contractMailAccounts';
  public override url = 'attendance/contractMailAccounts';
}
