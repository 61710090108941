import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { Guarantee } from '../_types/guarantee';

@Injectable({
    providedIn: 'root'
})
export class GuaranteesService extends Service<Guarantee> {
    public override type = 'guarantees';
    public override url = 'attendance/guarantees';
}
