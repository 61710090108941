import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { GuaranteesListComponent } from './guarantees-list/guarantees-list.component';
import { GuaranteesDetailComponent } from './guarantees-detail/guarantees-detail.component';
import { GuaranteesRoutingModule } from './guarantees-routing.module';


@NgModule({
    declarations: [
        GuaranteesListComponent,
        GuaranteesDetailComponent
    ],
    imports: [
        GuaranteesRoutingModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        TableModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        CheckboxModule,
        TabViewModule,
        CalendarModule,
        TagModule,
        InputTextareaModule,
        DropdownModule,
        DialogModule,
        EditorModule,
        ReactiveFormsModule
    ],
    providers: []
})
export class GuaranteesModule { }
