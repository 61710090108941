import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { User } from "../../users/_types/user";
import { CaseNote } from "../../cases/_types/case-note";

export class Template extends Resource {
    public override type = 'templates';

    @prop()
    @required()
    public templateType: string = 'Email';

    @prop()
    @required()
    public name!: string;

    @prop()
    public subject!: string;

    @prop()
    @required()
    public content!: string;


}