import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionsDetailComponent } from './transactions-detail/transactions-detail.component';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TransactionsOperatorsComponent } from './transactions-operators/transactions-operators.component';

const routes: Routes = [
    {
        path: 'transactions/:type',
        children: [
            { path: '', component: TransactionsListComponent }
        ]
    },
    {
        path: 'transactions/:type/:id',
        children: [
            { path: '', component: TransactionsDetailComponent }
        ]
    },
    {
        path: 'transactions/:type/transactions-operator/incoming_invoice',
        children: [
            { path: '', component: TransactionsOperatorsComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TransactionsRoutingModule { }
