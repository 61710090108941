<p-card class="p-3" header="Datos generales">
    <form *ngIf="surveyFormGroup" [formGroup]="surveyFormGroup">

        <div class="grid">
            <div class="col-9 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error>
            </div>
            <div class="col-3 input-field">
                <label for="active">Activa</label>
                <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
            </div>
            <div class="col-4 input-field">
                <label for="targetType">Dirigida a</label>
                <p-dropdown formControlName="targetType" [options]="targetTypes" optionLabel="label" optionValue="value"
                    placeholder="Selecciona a quién va dirigida" d-form-field-error></p-dropdown>
            </div>
            <div class="col-4 input-field" *ngIf="surveyFormGroup.get('targetType')?.value === 'User'">
                <label for="userTypeId">Tipo de usuario</label>
                <p-dropdown formControlName="userTypeId" [options]="userTypes" optionLabel="name" optionValue="id"
                    placeholder="Selecciona el tipo de usuario" d-form-field-error></p-dropdown>
            </div>
        </div>
        <div class="mt-4 flex gap-2">
            <p-button label="Eliminar" *ngIf="allowDelete && survey?.id" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </form>

</p-card>

<p-tabView>
    <p-tabPanel header="Preguntas de la encuesta">
        <div class="flex justify-content-end">
            <p-button label="Nueva pregunta" (click)="editSurveyQuestion(null)" styleClass="p-button-sm"
                *ngIf="allowWrite"></p-button>
        </div>

        <p-table styleClass="mt-4" #grid [value]="surveyQuestions" [loading]="loading" [paginator]="true" [rows]="25"
            [totalRecords]="totalRecords" [showCurrentPageReport]="true"
            currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
            [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
            paginatorDropdownAppendTo="body">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pSortableColumn="title">Pregunta <p-sortIcon field="title"></p-sortIcon></th>
                    <th pSortableColumn="surveyQuestionType">Tipo de pregunta <p-sortIcon
                            field="surveyQuestionType"></p-sortIcon></th>
                    <th pSortableColumn="active">Activa <p-sortIcon field="active"></p-sortIcon></th>
                    <th width="100"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-surveyQuestion let-columns="columns">
                <tr (dblclick)="editSurveyQuestion(surveyQuestion)">
                    <td>{{surveyQuestion.title}}</td>
                    <td>{{surveysQuestionsService.getQuestionTypeLabel(surveyQuestion.surveyQuestionType)}}</td>
                    <td>
                        <i class="pi"
                            [ngClass]="{ 'text-green-500 pi-check-circle': surveyQuestion.active, 'text-red-500 pi-times-circle': !surveyQuestion.active }"></i>
                    </td>
                    <td>
                        <div class="flex gap-2 justify-content-end">
                            <p-button *ngIf="allowDelete" (click)="deleteSurveyQuestion(surveyQuestion)"
                                [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                            <p-button *ngIf="allowWrite" (click)="editSurveyQuestion(surveyQuestion)" [rounded]="true"
                                [text]="false"><i class="pi pi-pencil"></i></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
</p-tabView>

<p-confirmDialog></p-confirmDialog>