<form *ngIf="providerFormGroup" [formGroup]="providerFormGroup">
  <p-card class="p-3" header="Datos generales">
    <div class="grid">
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error/>
      </div>
      <div class="col-3 input-field">
        <label for="name">CIF *</label>
        <input pInputText name="vatNumber" formControlName="vatNumber" d-form-field-error/>
      </div>      
      <div class="col-2 input-field">
        <label for="active" class="mr-1">Activo</label>
        <div class="flex h-full">
          <p-inputSwitch id="active" name="active" formControlName="active" d-form-field-error></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="grid pt-2">
      <div class="col input-field ">
        <label for="name">Razón social *</label>
        <input pInputText id="legalName" name="legalName" formControlName="legalName" d-form-field-error/>
      </div>
      <div class="col input-field ">
        <label for="name">Teléfono</label>
        <input pInputText id="phone1" name="phone1" formControlName="phone1" d-form-field-error/>
      </div>
      <div class="col input-field ">
        <label for="name">Email</label>
        <input pInputText id="email" name="email" formControlName="email" d-form-field-error/>
      </div>

    </div>
    <div class="grid pt-2" formGroupName="address">
      <div class="col input-field col-3">
        <label for="name">Dirección</label>
        <input pInputText name="addressLine1" id="addressLine1"
        formControlName="addressLine1" d-form-field-error/>
      </div>
      <div class="col input-field col-3">
        <label for="name">Código postal</label>
        <input pInputText id="zipcode" name="zipcode" formControlName="zipCode" d-form-field-error/>                  
      </div>
      <div class="col input-field col-3">
        <label for="name">Ciudad</label>
        <input pInputText id="city" name="city" formControlName="city" d-form-field-error/>
      </div>
      <div class="col input-field col-3">
        <label for="name">País</label>
        <p-dropdown [options]="countries"
                    formControlName="countryCode" name="countryCode"
                    optionValue="code" [filter]="true" filterBy="name"
                    optionLabel="name" placeholder="Seleccione país" d-form-field-error/>                       
      </div>
    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete && provider.id" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
    </div>
  </p-card>

  <p-tabView>
    <p-tabPanel header="Datos bancarios">
      <div class="formgrid grid pt-2" formGroupName="bankAccount">
        <div class="col input-field ">
          <label for="name">Banco *</label>
          <p-dropdown id="bank" name="bank" [options]="banks"
            formControlName="bankId" d-form-field-error optionLabel="name"
            optionValue="id" [filter]="true" filterBy="name" placeholder="Selecciona un banco"
            [required]="true" class="ng-dirty ng-invalid" appendTo="body"/>
        </div>
        <div class="col input-field ">
          <label for="name">Nº Cuenta *</label>
          <input pInputText name="accountNumber" required id="accountNumber"
              formControlName="accountNumber" d-form-field-error/>                  
        </div>
        <div class="col input-field ">
          <label for="name">Swift *</label>
          <input pInputText id="swift" name="swift" required
            formControlName="swift" d-form-field-error/>                  
        </div>
      </div>
    </p-tabPanel>    
    
    <p-tabPanel header="Notas" [disabled]="!provider.id">
      <div class="">
        <p-editor name="name" formControlName="notes" d-form-field-error [style]="{ height: '320px' }">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Negrita"></button>
              <button type="button" class="ql-italic" aria-label="Cursiva"></button>
              <button type="button" class="ql-underline" aria-label="Subrayado"></button>
            </span>
            <span class="ql-formats">
              <select class="ql-color" aria-label="Color"></select>
              <select class="ql-background" aria-label="Color de fondo"></select>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-list" value="ordered" aria-label="Lista de números"></button>
              <button type="button" class="ql-list" value="bullet" aria-label="Lista de puntos"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-link" aria-label="Enlace"></button>
              <select class="ql-align" aria-label="Alineación"></select>
            </span>
          </ng-template>
        </p-editor>
      </div>
    </p-tabPanel>
  </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>