import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractZone } from "../_types/contract-zone";

@Injectable({
    providedIn: 'root',
})
export class ContractZonesService extends Service<ContractZone> {
  public override type = 'contractZones';  
  public override url = 'attendance/contractZones'; 
}
