import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { UserAuthenticationService } from '../_types/userAuthenticationService';

@Injectable({
    providedIn: 'root'
})
export class UserAuthenticationServicesService extends Service<UserAuthenticationService> {
    public override type = 'userAuthenticationServices';
    public override url = 'auth/userAuthenticationServices';
}
