import { Resource } from "src/app/core/jsonapi/resource";
import { prop } from "@rxweb/reactive-form-validators";
import { Capability } from "../../common/_types/capability";
import { ContractSla } from "./contract-sla";

export class ContractSlaCapability extends Resource {
    public override type = 'contractSLACapabilities';

    @prop()
    public contractSLAId!: string;

    @prop()
    public capabilityId!: string;

    public capability!: Capability;

    public contractSLA!: ContractSla;



}