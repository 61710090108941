import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Affected } from '../../_types/affected';
import { AffectedsService } from '../../_services/affecteds.service';
import { AffectedAddressesService } from '../../_services/affecteds-addresses.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CountriesService } from 'src/app/modules/common/_services/countries.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AffectedAddress } from '../../_types/affected-address';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';

@Component({
  selector: 'app-affected',
  templateUrl: './affected.component.html',
  styleUrls: ['./affected.component.scss']
})
export class CasesDetailAffectedComponent {
  public affectedFormGroup!: RxFormGroup;

  public affected: Affected = new Affected();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public countries: any = [];

  constructor(
    private affectedsService: AffectedsService,
    private affectedAddressesService: AffectedAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) {
    this.affectedFormGroup = this.formBuilder.formGroup<Affected>(this.affected) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCountries();

    let id = this.config.data.affectedId;
    if (id != null && id != '0') {
      await this.loadAffected(id);
    } else {
      this.affected.caseId = this.config.data.caseId;
    }

    if (this.affected.address == null) this.affected.address = new AffectedAddress();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadAffected(id: string) {
    this.affected = await firstValueFrom(this.affectedsService.get(id, { include: ['address', 'case'] }));
    this.affectedFormGroup.patchValue(this.affected);
  }

  async loadCountries() {
    let params: IParamsCollection= {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }


  async save() {
    let valid = this.formValidationService.validateForm(this.affectedFormGroup);
    if (valid) {
      this.affected = Object.assign(this.affected, this.affectedFormGroup.value);

      if (this.affected.address != null) {
        let address: any = await firstValueFrom(this.affectedAddressesService.save(this.affected.address));
        this.affected.addressId = address.id;
      }

      let affected = await firstValueFrom(this.affectedsService.save(this.affected));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Perjudicado guardado correctamente." });

      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
