import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RatesDetailComponent } from './detail/rates-detail.component';
import { RatesComponent } from './list/rates-list.component';

const routes: Routes = [
  {
    path: 'rates/:type',
    children: [
      { path: '', component: RatesComponent }
    ]
  },
  {
    path: 'rates/:type/:id',
    children: [
      { path: '', component: RatesDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RatesRoutingModule { }
