<form *ngIf="businsessBankAccountFormGroup" [formGroup]="businsessBankAccountFormGroup">
    <p-card class="p-3" header="Datos generales">

        <div class="grid">
            <div class="col-12 input-field">
                <label for="authenticationServiceId">Banco</label>
                <p-dropdown [options]="banks" formControlName="bankId" optionValue="id" [filter]="true" filterBy="name"
                    optionLabel="name" placeholder="Seleccione banco" d-form-field-error />
            </div>
            <div class="col-12 input-field">
                <label for="accountNumber">IBAN</label>
                <p-inputMask mask="aa99  9999  9999  9999  9999  9999" [style]="{'text-transform': 'uppercase'}"
                    formControlName="accountNumber" id="accountNumber" placeholder="XX00 0000 0000 0000 0000 0000"
                    d-form-field-error />
            </div>
            <div class="col-12 input-field">
                <label for="swift">SWIFT</label>
                <input pInputText formControlName="swift" id="swift" d-form-field-error />
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete && businessBankAccount.id" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>

    </p-card>

</form>