import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ContractSla } from '../../_types/contract-sla';
import { ContractSlasService } from '../../_services/contracts-slas.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { CaseEventType } from '../../_types/case-event-type';
import { CaseEventTypesService } from '../../_services/cases-events-types.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { ContractSlaCapabilitiesService } from '../../_services/contracts-slas-capabilities.service';
import { ContractSlaCapability } from '../../_types/contract-sla-capability';
import { ContractSlaCapabilitiesComponent } from './sla-capabilities/sla-capabilities.component';
import { ContractSlaNotificationsService } from '../../_services/contracts-slas-notifications.service';
import { ContractSlaNotification } from '../../_types/contract-sla-notification';
import { ContractSlaNotificationComponent } from './sla-notification/sla-notification.component';

@Component({
  selector: 'app-slas-detail',
  templateUrl: './slas-detail.component.html',
  styleUrls: ['./slas-detail.component.scss']
})
export class ContractSlasDetailComponent {
  public contractSlaFormGroup!: RxFormGroup;

  public contractSla: ContractSla = new ContractSla();
  public caseEventTypes: CaseEventType[] = [];
  public contractSlaCapabilities: ContractSlaCapability[] = [];
  public contractSlaNotifications: ContractSlaNotification[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private contractSlasService: ContractSlasService,
    private caseEventTypesService: CaseEventTypesService,
    private contractSlaCapabilitiesService: ContractSlaCapabilitiesService,
    private contractSlaNotificationsService: ContractSlaNotificationsService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.contractSlaFormGroup = this.formBuilder.formGroup<ContractSla>(this.contractSla) as RxFormGroup;
  }

  async ngOnInit() {
    let contractSlaId = this.config.data.contractSlaId;
    if (contractSlaId) {
      await this.loadContractSla(contractSlaId);
    } else {
      this.contractSla.contractId = this.config.data.contractId;
      this.contractSlaFormGroup.patchValue(this.contractSla);
    }
    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");

    this.loadCaseEventTypes();
  }

  async loadContractSla(contractSlaId: string) {
    this.contractSla = await firstValueFrom(this.contractSlasService.get(contractSlaId));
    this.contractSlaFormGroup.patchValue(this.contractSla);

    this.loadSLACapabilities();
    this.loadSLANotifications();
  }

  async loadCaseEventTypes() {
    let filters = {
      isSLAEnabled: new Filter(FilterOperation.equals, 'isSLAEnabled', "", true),
      isActive: new Filter(FilterOperation.equals, 'isActive', "", true)
    };

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };

    let response = await firstValueFrom(this.caseEventTypesService.all(params));
    this.caseEventTypes = response.data;
  }

  async loadSLACapabilities() {
    let filters = {
      contractSLAId: new Filter(FilterOperation.equals, 'contractSLAId', "", this.contractSla.id),
    }

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build(),
      include: ['capability']
    };

    let response = await firstValueFrom(this.contractSlaCapabilitiesService.all(params));
    this.contractSlaCapabilities = response.data;
  }

  async loadSLANotifications() {
    let filters = {
      contractSLAId: new Filter(FilterOperation.equals, 'contractSLAId', "", this.contractSla.id),
    }

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build(),
      include: ['user']
    };

    let response = await firstValueFrom(this.contractSlaNotificationsService.all(params));
    this.contractSlaNotifications = response.data;
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.contractSlaFormGroup);
    if (valid) {
      this.contractSla = Object.assign(this.contractSla, this.contractSlaFormGroup.value);

      let contractSla = await firstValueFrom(this.contractSlasService.save(this.contractSla));


      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "SLA guardado correctamente." });
      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el SLA?",
      header: "Eliminar SLA",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractSlasService.delete(this.contractSla.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "SLA eliminado correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  editCapabilities() {
    const ref = this.dialogService.open(ContractSlaCapabilitiesComponent, {
      header: 'Ficha de especialidades del SLA',
      width: '60%',
      data: {
        contractSlaId: this.contractSla.id
      }
    })

    ref.onClose.subscribe(() => {
      this.loadSLACapabilities();
    });
  }

  editNotifications() {
    const ref = this.dialogService.open(ContractSlaNotificationComponent, {
      header: 'Ficha de notificaciones del SLA',
      width: '60%',
      data: {
        contractSlaId: this.contractSla.id
      }
    })

    ref.onClose.subscribe(() => {
      this.loadSLANotifications();
    });
  }
}
