import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Worker } from "./worker";

export class Zone extends Resource {
    public override type = 'zones';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public workerId!: string;


}