import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { Filter, FilterOperation } from "src/app/core/jsonapi/filter";
import { FilterBuilder } from "src/app/core/jsonapi/filters-builder";
import { IParamsCollection } from "src/app/core/jsonapi/interfaces/params-collection";
import { SurveysAnswersService } from "src/app/modules/surveys/_services/surveys-answers.service";
import { SurveysQuestionAnswersService } from "src/app/modules/surveys/_services/surveys-questions-answers.service";
import { SurveysQuestionsOptionsService } from "src/app/modules/surveys/_services/surveys-questions-options.service";
import { SurveysQuestionsService } from "src/app/modules/surveys/_services/surveys-questions.service";
import { SurveysService } from "src/app/modules/surveys/_services/surveys.service";
import { Survey } from "src/app/modules/surveys/_types/survey";
import { SurveyAnswer } from "src/app/modules/surveys/_types/survey-answer";
import { SurveyQuestionAnswer } from "src/app/modules/surveys/_types/survey-question-answer";

@Component({
    selector: 'app-public-survey-response',
    templateUrl: './public-survey-response.component.html',
    styleUrls: ['./public-survey-response.component.scss']
})
export class PublicSurveyResponseComponent {

    //id de la encuesta
    public surveyId: string | null;
    //id de la entidad que esta respondiendo la encuesta (ejemplo el usuario id)
    public sourceId: string | null;
    //tipo de entidad que esta respondiendo la encuesta
    public sourceType: string | null;
    //id de la entidad a la que se le esta respondiendo la encuesta
    public targetId: string | null;
    //tipo de entidad a la que se le esta respondiendo la encuesta
    public targetType: string | null;

    public scale5Options: any[] = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' }
    ];

    public survey: Survey = new Survey();
    public questions: any[] = []

    constructor(
        private surveysService: SurveysService,
        private surveysQuestionsService: SurveysQuestionsService,
        private surveysAnswersService: SurveysAnswersService,
        private surveysQuestionsAnswersService: SurveysQuestionAnswersService,
        private surveysQuestionsOptionsService: SurveysQuestionsOptionsService,
        private route: ActivatedRoute,
        private router: Router
    ) {

        //url de entrada de ejemplo para encuesta de satisfaccion de servicio 379e04a5-9189-4992-af44-856ca337dfdc que responde el cliente fdb73a9d-7c13-492b-cd71-08dc9f444441
        //http://localhost:4200/public/surveys/dc3e7d85-856c-4849-8ba4-08dce86bd009/caseservice/379e04a5-9189-4992-af44-856ca337dfdc/client/fdb73a9d-7c13-492b-cd71-08dc9f444441


        this.surveyId = route.snapshot.paramMap.get('surveyId');
        this.targetType = route.snapshot.paramMap.get('targetType');
        this.targetId = route.snapshot.paramMap.get('targetId');
        this.sourceType = route.snapshot.paramMap.get('sourceType');
        this.sourceId = route.snapshot.paramMap.get('sourceId');

        //cargar encuesta de api
        this.loadSurvey();

        //cargar preguntas de encuesta de api
        this.loadSurveyQuestions();
    }

    async loadSurvey() {
        this.survey = await firstValueFrom(this.surveysService.get(this.surveyId as string));
    }

    async loadSurveyQuestions() {
        let params: IParamsCollection = {}

        let filters: any = {
            surveyId: new Filter(FilterOperation.equals, "surveyId"),
        };

        filters.surveyId.value = this.surveyId;
        params.filter = FilterBuilder.fromObject(filters).build();


        let response = await firstValueFrom(this.surveysQuestionsService.all(params));
        this.questions = response.data;

        this.questions.forEach((question: any) => {
            if (question.surveyQuestionType == 'Options') {
                this.addOptionsToQuestion(question);
            }
        });
    }

    async loadSurveyQuestionOptions(questionId: string) {
        let params: IParamsCollection = {}

        let filters: any = {
            surveyQuestionId: new Filter(FilterOperation.equals, "surveyQuestionId"),
        };

        filters.surveyQuestionId.value = questionId;
        params.filter = FilterBuilder.fromObject(filters).build();

        let response = await firstValueFrom(this.surveysQuestionsOptionsService.all(params));
        return response.data;
    }

    addOptionsToQuestion(question: any) {
        this.loadSurveyQuestionOptions(question.id).then((options) => {
            question.options = options;
        });
    }

    async sendResponse() {
        //TODO: guardar SurveyAnswer y SurveyAnswerQuestion
        let surveyAnswer = await this.saveSurveyAnswer();

        try {
            for (let question of this.questions) {
                await this.saveSurveyQuestionAnswer(question, surveyAnswer);
            }
        } catch (error) {
            console.error(error);
        }

        //si todo ok, redirigir a pagina de agradecimiento
        this.router.navigate(['/public/surveys/thanks']);
    }

    async saveSurveyAnswer() {
        let surveyAnswer = new SurveyAnswer();

        surveyAnswer.surveyId = this.surveyId as string;
        surveyAnswer.sourceType = this.sourceType as string;
        surveyAnswer.sourceId = this.sourceId as string;
        surveyAnswer.targetType = this.targetType as string;
        surveyAnswer.targetId = this.targetId as string;
        surveyAnswer.date = new Date();

        let response = await firstValueFrom(this.surveysAnswersService.save(surveyAnswer));
        return response;
    }

    async saveSurveyQuestionAnswer(question: any, answer: any) {
        let surveyQuestionAnswer = new SurveyQuestionAnswer();

        surveyQuestionAnswer.surveyAnswerId = answer.id;
        surveyQuestionAnswer.surveyQuestionId = question.id;
        surveyQuestionAnswer.answer = question.answer;

        let response = await firstValueFrom(this.surveysQuestionsAnswersService.save(surveyQuestionAnswer));
    }

}