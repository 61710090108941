<form *ngIf="guaranteeFormGroup" [formGroup]="guaranteeFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="name">Nombre *</label>
            <input pInputText formControlName="name" d-form-field-error />
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>