import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { RateLine } from 'src/app/modules/rates/_types/rate-line';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { firstValueFrom } from 'rxjs';
import { TransactionsLinesService } from 'src/app/modules/transactions/_services/transactions-lines.service';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { TransactionsService } from 'src/app/modules/transactions/_services/transactions.service';
import { TransactionsStatesService } from 'src/app/modules/transactions/_services/transactions-states.service';
import { TransactionsTypesService } from 'src/app/modules/transactions/_services/transactions-types.service';
import { Job } from 'src/app/modules/cases/_types/job';
import { JobsService } from 'src/app/modules/cases/_services/jobs.service';
import { MessageService } from 'primeng/api';
import { CasesServicesService } from 'src/app/modules/cases/_services/cases-services.service';
import { TransactionLine } from '../../_types/transaction-line';

@Component({
  selector: 'app-transaction-lines-jobs',
  templateUrl: './transaction-lines-jobs.component.html',
  styleUrls: ['./transaction-lines-jobs.component.scss']
})
export class TransactionLinesJobsComponent {

  public jobs: any;
  public totalJobs!: number;
  public caseServices: any = [];
  public transactionType: any = {};

  public companyId!: string;
  public operatorId!: string;
  public caseServiceId!: string;
  public caseId!: string;
  public transactionId!: string;
  public lineNumber!: number;
  public rateLines: RateLine[] = [];
  public hasSubmitInvoice: boolean = false;
  public allRateLines: any;
  public type!: string;
  public loadingJobs: boolean = false;
  public selectedLines: any[] = [];
  public selectedCaseService: any;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private caseServicesService: CasesServicesService,
    private jobsService: JobsService,
    private messageService: MessageService,
    private TransactionsLinesService: TransactionsLinesService
  ) {
  }

  async ngOnInit() {
    this.caseId = this.config.data.caseId;
    this.transactionId = this.config.data.transactionId;
    this.lineNumber = this.config.data.lineNumber;

    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");        

    this.loadData();

  }

  async loadData() {


    let params: IParamsCollection = {
      include: ["operator"]
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject({ caseId: new Filter(FilterOperation.equals, 'caseId', '', this.caseId) }).build();

    let response = await firstValueFrom(this.caseServicesService.all(params));
    this.caseServices = response.data;

    console.log(this.caseServices);
    this.caseServices.forEach((option: any) => {

      const formatoPersonalizado = option.date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      option.customName = (formatoPersonalizado ?? "") + " - " + option.operator?.name;
    });


  }

  async loadJobs() {

    this.selectedLines = [];

    let params: IParamsCollection = {
      include: ["rateLine"]
    }

    let filters: any = {
      caseServiceId: new Filter(FilterOperation.equals, "caseServiceId"),
    };


    filters.caseServiceId.value = this.selectedCaseService;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.jobsService.all(params));


    this.jobs = response.data;


    this.totalJobs = response.meta['total'];
    this.loadingJobs = false;

  }

  cancel() {
    this.dynamicDialogRef.close();
  }


  async save() {
    //Creamos job por cada linea
    try {
      const promesas = this.selectedLines.map((line:Job) => {        
        let transactionLine: TransactionLine = new TransactionLine();
        transactionLine.transactionId = this.transactionId;
        transactionLine.lineNumber = this.lineNumber;
        transactionLine.isGroup = false;
        transactionLine.rateLineId = line.rateLineId;
        transactionLine.description = line.description;
        transactionLine.quantity = line.quantity;
        transactionLine.price = line.unitPrice;
        transactionLine.subtotal = line.total;
        
        this.lineNumber = this.lineNumber+1;
        
        return firstValueFrom(this.TransactionsLinesService.save(transactionLine));

        
      });

      // Espera que todas las promesas se resuelvan en paralelo
      await Promise.all(promesas);

      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Trabajos guardados correctamente." });

      this.dynamicDialogRef.close();
    } catch (error) {
      console.error(`Error al guardar alguna de las líneas: ${error}`);
    }
  }


}
