import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Bank extends Resource {
    public override type = 'banks';

    @prop()
    @required()
    public name!: string;

    @prop()
    public code!: string;


}