import { Injectable } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { RxFormGroup } from "@rxweb/reactive-form-validators"
import { Md5 } from "ts-md5";

@Injectable({
    providedIn: 'root',
})
export class StringUtilsService {
    RandomString(length:number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
        }
        return result;
    }
    
    ToMD5(value:string): string {
        return Md5.hashStr(value);
    }
}