<form *ngIf="workerFileFormGroup" [formGroup]="workerFileFormGroup">
  <div class="grid">
     
      <div class="col-12" *ngIf="!workerFile.fileId">
          <p-fileUpload mode="basic" chooseIcon="pi pi-upload" (onSelect)="onFileSelect($event)"
              chooseLabel="Seleccionar fichero" />
      </div>
      <div class="col-12 input-field" >
        <label for="name">Nombre</label>
        <textarea formControlName="name" pInputText d-form-field-error></textarea>
    </div>
      <div class="col-12 input-field">
          <label for="notes">Notas</label>
          <textarea formControlName="notes" pInputTextarea rows="5" d-form-field-error></textarea>
      </div>
  </div>
  <div class=" mt-4 flex gap-2 justify-content-end">
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
  </div>
</form>
<p-confirmDialog></p-confirmDialog>