import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { VehiclesService } from '../../vehicles/_services/vehicles.service';
import { Vehicle } from '../../vehicles/_types/vehicle';
import { WorkerVehiclesService } from '../_services/workers-vehicles.service';
import { WorkerVehicle } from '../_types/worker-vehicle';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-workers-vehicle',
  templateUrl: './workers-vehicle.component.html',
  styleUrls: ['./workers-vehicle.component.scss']
})
export class WorkersVehicleComponent {
  
  public workerVehicleFormGroup!: RxFormGroup;

  public workerVehicle: WorkerVehicle = new WorkerVehicle();
  public vehicles: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private workerVehiclesService: WorkerVehiclesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private vehiclesService: VehiclesService
  ) {
    this.workerVehicleFormGroup = this.formBuilder.formGroup<WorkerVehicle>(this.workerVehicle) as RxFormGroup;
  }

  ngOnInit(): void {
    console.log(this.config.data);
    if (this.config.data.workerVehicleId) {
      this.loadWorkerVehicle(this.config.data.workerVehicleId);
    } else {
      this.workerVehicle.workerId = this.config.data.workerId;
    }
    this.loadVehicles();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadWorkerVehicle(id: string) {
    this.workerVehicle = await firstValueFrom(this.workerVehiclesService.get(id));
    this.workerVehicleFormGroup.patchValue(this.workerVehicle);
  }

  async loadVehicles() {
    let response = await firstValueFrom(this.vehiclesService.all());

    this.vehicles = response.data.map((vehicle: any) => 
      { return { id: vehicle.id, customName: vehicle.name+" - "+vehicle.plate }; }      
    );
  }

  async save() {
    console.log(this.workerVehicleFormGroup);
    let valid = this.formValidationService.validateForm(this.workerVehicleFormGroup);
    console.log(valid);
    if (valid) {
      this.workerVehicle = Object.assign(this.workerVehicle, this.workerVehicleFormGroup.value);

      await this.workerVehiclesService.save(this.workerVehicle).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Vehículo asignado correctamente' });
          this.dynamicDialogRef.close();
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al asignar el vehículo' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar el vehículo?",
      header: "Desasignar vehículo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerVehiclesService.delete(this.workerVehicle.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Vehículo desasignado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
