import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { OperatorRate } from "../_types/operator-rate";

@Injectable({
    providedIn: 'root',
})
export class OperatorRatesService extends Service<OperatorRate> {
  public override type = 'operatorRates';
  public override url = 'attendance/operatorRates';
}
