import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Business } from '../_types/business';
import { BusinessService } from '../_services/business.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { first, firstValueFrom } from 'rxjs';
import { BusinessBankAccountComponent } from '../business-bank-account/business-bank-account.component';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { BusinessBankAccountsService } from '../_services/business-bank-accounts.service';
import { BusinessAddressesService } from '../_services/business-addresses.service';
import { BusinessAddress } from '../_types/business-address';
import { CountriesService } from '../../common/_services/countries.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.scss']
})
export class BusinessDetailComponent {
  public businessFormGroup!: RxFormGroup

  public business: Business = new Business();
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public businessBankAccounts: any = [];
  public businessAddresses: any = [];
  public countries: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;

  constructor(
    private businessService: BusinessService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private businessBankAccountService: BusinessBankAccountsService,
    private businessAddressesService: BusinessAddressesService,
    private countriesService: CountriesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de sociedad");
    this.topbarService.addBreadcrumb("Sociedades", "/business");

    this.businessFormGroup = this.formBuilder.formGroup<Business>(this.business) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadBusiness(id);
    }
    if (this.business.address == null) this.business.address = new BusinessAddress();
    this.loadBusinessAddresses();
    this.loadCountries();
    this.allowWrite = true;
    this.allowDelete = true;


  }

  async loadBusiness(id: string) {
    this.business = await firstValueFrom(this.businessService.get(id, { include: ['address'] }));
    this.businessFormGroup.patchValue(this.business);

    this.loadBusinessBankAccounts();
  }

  async loadBusinessAddresses() {
    let response = await firstValueFrom(this.businessAddressesService.all());
    this.businessAddresses = response.data;
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async loadBusinessBankAccounts() {
    let filters = {
      companyId: new Filter(FilterOperation.equals, 'companyId', "", this.business.id)
    };


    let params: IParamsCollection = {
      include: ['bank']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.businessBankAccountService.all(params));
    this.businessBankAccounts = response.data;
    this.totalRecords = response.meta['total'];
  }



  async save() {
    let valid = this.formValidationService.validateForm(this.businessFormGroup);
    if (valid) {
      this.business = Object.assign(this.business, this.businessFormGroup.value);
      if (this.business.address != null) {
        let address: any = await firstValueFrom(this.businessAddressesService.save(this.business.address));
        this.business.addressId = address.id;
      }

      await firstValueFrom(this.businessService.save(this.business));

      this.messageService.add({ closable: false, severity: 'success', summary: "Sociedad guardada correctamente." });
      this.router.navigate(['business']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la sociedad?",
      header: "Eliminar sociedad",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.businessService.delete(this.business.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Sociedad eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['business']);
  }

  async editBankAccount(businessBankAccount: any) {
    let ref = this.dialogService.open(BusinessBankAccountComponent, {
      header: 'Editar cuenta bancaria de la sociedad',
      width: '500px',
      data: {
        businessBankAccountId: businessBankAccount ? businessBankAccount.id : null,
        companyId: this.business.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadBusinessBankAccounts();
    });
  }

  async deleteBankAccount(businessBankAccount: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la cuenta bancaria de la sociedad?",
      header: "Eliminar cuenta bancaria",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.businessBankAccountService.delete(businessBankAccount.id).subscribe({
          next: (data: any) => {
            this.loadBusinessBankAccounts();
            this.messageService.add({ closable: false, severity: 'success', detail: "Cuenta bancaria eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }


}
