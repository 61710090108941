import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class WorkerHoliday extends Resource {
    public override type = 'workerHolidays';
    
    @prop()  
    @required()  
    public workerId!: string;  

    @prop()    
    @required()
    public date!: string;  

    @prop()    
    public notes!: string;  
    
}