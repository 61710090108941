
import { Resource } from "src/app/core/jsonapi/resource";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { VehicleType } from "../../vehicles-types/_types/vehicle-type";
import { Worker } from "../../workers/_types/worker";
import { Company } from "../../companies/_types/company";

export class Vehicle extends Resource {
    public override type = 'vehicles';

    @prop()
    @required()
    public name!: string;      

    @prop()    
    public workerId!: string;    

    @propObject(Worker)
    public worker: Worker = new Worker();
    
    @prop()    
    @required()
    public vehicleTypeId!: string;                

    @propObject(VehicleType)
    public vehicleType: VehicleType = new VehicleType();

    @prop()
    @required()
    public plate!: string;      
    
    @prop()
    @required()
    public ownerType!: string;

    @prop()
    public companyId!: string;

    @propObject()
    public company!: Company;    

    @prop()
    public notes!: string;        

    @prop()
    public active: boolean = false;    

}