import { Resource } from "src/app/core/jsonapi/resource";
import { prop } from "@rxweb/reactive-form-validators";
import { Capability } from "../../common/_types/capability";
import { ContractSla } from "./contract-sla";
import { User } from "../../users/_types/user";

export class ContractSlaNotification extends Resource {
    public override type = 'contractSLAnotificationUsers';

    @prop()
    public contractSLAId!: string;

    @prop()
    public userId!: string;

    public contractSLA!: ContractSla;

    public user!: User

}