import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { FilesService } from 'src/app/modules/common/_services/files.service';
import { File } from 'src/app/modules/common/_types/file';
import { CaseFile } from '../../_types/case-file';
import { CasesFilesService } from '../../_services/cases-files.service';
import { CaseService } from '../../_types/case-service';
import { CasesServicesService } from '../../_services/cases-services.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { CaseFileCategory } from '../../_types/case-file-category';
import { CasesFilesCategoriesService } from '../../_services/cases-files-categories.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class CasesDetailFileComponent {
  public caseFileFormGroup!: RxFormGroup;

  public caseFile: CaseFile = new CaseFile();

  public files: any[] = [];
  public caseServices: CaseService[] = [];
  public caseFileCategories: CaseFileCategory[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private filesService: FilesService,
    private casesFilesService: CasesFilesService,
    private casesServicesService: CasesServicesService,
    private casesFilesCategoriesService: CasesFilesCategoriesService,
    private config: DynamicDialogConfig,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.caseFileFormGroup = this.formBuilder.formGroup(CaseFile) as RxFormGroup;
  }

  async ngOnInit() {
    let caseFileId = this.config.data.caseFileId;
    if (caseFileId) {
      await this.loadCaseFile(caseFileId);
    } else {
      this.caseFile.caseId = this.config.data.caseId;
      this.caseFileFormGroup.patchValue(this.caseFile);
    }

    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");

    this.loadCaseServices();
    this.loadCaseFileCategories();
  }

  async loadCaseFile(caseFileId: string) {
    this.caseFile = await firstValueFrom(this.casesFilesService.get(caseFileId));
    this.caseFileFormGroup.patchValue(this.caseFile);
  }

  async loadCaseServices() {
    let params: IParamsCollection = {
      include: ['operator']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    filters.caseId.value = this.config.data.caseId;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data

    // añadir atributo que sea 'operator.name' - 'date'
    this.caseServices.forEach((caseService: any) => {
      caseService.label = `${caseService.operator.name} - ${caseService.date.toLocaleString()}`;
    });
  }

  async loadCaseFileCategories() {
    let response = await firstValueFrom(this.casesFilesCategoriesService.all());
    this.caseFileCategories = response.data
  }

  async onFileSelect(event: any) {
    let files = event.files;
    for (let file of files) {
      this.files.push(file);
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.caseFileFormGroup);
    if (valid && (this.files.length > 0 || this.caseFile.fileId)) {
      this.caseFile = Object.assign(this.caseFile, this.caseFileFormGroup.value);
      if (this.files.length > 0) {
        try {
          for (let file of this.files) {
            let data: File = new File();
            data.fileName = file.name;
            data.size = file.size;
            data.contentType = file.type;
            //data.path = file.name;

            //creamos el file
            let fileResponse = await firstValueFrom(this.filesService.save(data));

            //subimos el fichero al file
            let response = await firstValueFrom(this.filesService.upload(fileResponse.id as string, file));

            //guardamos el file en el expediente
            this.caseFile.fileId = fileResponse.id as string;
            await firstValueFrom(this.casesFilesService.save(this.caseFile));
          }
        } catch (error) {
          this.messageService.add({ closable: false, severity: 'severity', summary: "Error", detail: "Error al guardar los ficheros." });
        }
      } else {
        //guardamos el file en el expediente
        await firstValueFrom(this.casesFilesService.save(this.caseFile));
      }

      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Ficheros guardados correctamente." });
      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

}
