<p-card class="p-3" header="Datos generales">
    <form *ngIf="surveyQuestionFormGroup" [formGroup]="surveyQuestionFormGroup">
        <div class="grid">
            <div class="col-8 input-field">
                <label for="title">Pregunta *</label>
                <input pInputText formControlName="title" d-form-field-error>
            </div>
            <div class="col-4 input-field">
                <label for="active">Activa</label>
                <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
            </div>
            <div class="col-6 input-field">
                <label for="surveyQuestionType">Tipo de pregunta *</label>
                <p-dropdown formControlName="surveyQuestionType" d-form-field-error [options]="surveyQuestionTypes"
                    optionLabel="label" optionValue="value"></p-dropdown>
            </div>
            <div class="col-3 input-field"
                *ngIf="surveyQuestionFormGroup.get('surveyQuestionType')?.value === 'Scale5'">
                <label for="isForWorkerEvaluation">Evalua al operario</label>
                <p-inputSwitch formControlName="isForWorkerEvaluation" d-form-field-error></p-inputSwitch>
            </div>
            <div class="col-3 input-field"
                *ngIf="surveyQuestionFormGroup.get('surveyQuestionType')?.value === 'Scale5' && surveyQuestionFormGroup.get('isForWorkerEvaluation')?.value">
                <label for="workerEvaluationWeightPercent">Porcentaje (%) de peso</label>
                <p-inputNumber inputId="integeronly" formControlName="workerEvaluationWeightPercent" [min]="0"
                    [max]="100" suffix=" %" placeholder="0% - 100%" d-form-field-error />
            </div>
        </div>
        <div class="mt-4 flex gap-2">
            <p-button label="Eliminar" *ngIf="allowDelete && surveyQuestion?.id" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </form>
</p-card>

<p-tabView *ngIf="surveyQuestionFormGroup.get('surveyQuestionType')?.value === 'Options'">
    <p-tabPanel header="Opciones">
        <div class="flex justify-content-end">
            <p-button label="Nueva opción" (click)="editOption(null)" styleClass="p-button-sm"
                *ngIf="allowWrite"></p-button>
        </div>

        <p-table styleClass="mt-4" #grid [value]="surveyQuestionOptions" [loading]="loading" [paginator]="true"
            [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
            currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
            [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
            paginatorDropdownAppendTo="body">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pSortableColumn="name">Opción <p-sortIcon field="name"></p-sortIcon></th>
                    <th pSortableColumn="active">Activa <p-sortIcon field="active"></p-sortIcon></th>
                    <th width="100"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-surveyQuestionOption let-columns="columns">
                <tr (dblclick)="editOption(surveyQuestionOption)">
                    <td>{{surveyQuestionOption.name}}</td>
                    <td>
                        <i class="pi"
                            [ngClass]="{ 'text-green-500 pi-check-circle': surveyQuestionOption.active, 'text-red-500 pi-times-circle': !surveyQuestionOption.active }"></i>
                    </td>
                    <td>
                        <div class="flex gap-2 justify-content-end">
                            <p-button *ngIf="allowDelete" (click)="deleteOption(surveyQuestionOption)" [rounded]="true"
                                [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                            <p-button *ngIf="allowWrite" (click)="editOption(surveyQuestionOption)" [rounded]="true"
                                [text]="false"><i class="pi pi-pencil"></i></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
</p-tabView>