<p-card class="p-3">
    <form *ngIf="surveyQuestionOptionFormGroup" [formGroup]="surveyQuestionOptionFormGroup">
        <div class="grid">
            <div class="col-8 input-field">
                <label for="name">Opción *</label>
                <input pInputText formControlName="name" d-form-field-error>
            </div>
            <div class="col-4 input-field">
                <label for="active">Activa</label>
                <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
            </div>
        </div>
        <div class="mt-4 flex gap-2">
            <p-button label="Eliminar" *ngIf="allowDelete && surveyQuestionOption?.id" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </form>
</p-card>