import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WorkerCapability } from '../_types/worker-capability';
import { WorkerCapabilitiesService } from '../_services/workers-capabilities.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CapabilitiesService } from '../../common/_services/capabilities.service';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { WorkerEquipment } from '../_types/worker-equipment';
import { WorkerEquipmentsService } from '../_services/workers-equipments.service';
import { EquipmentsService } from '../../equipments/_services/equipments.service';

@Component({
  selector: 'app-workers-equipments',
  templateUrl: './workers-equipments.component.html',
  styleUrls: ['./workers-equipments.component.scss']
})
export class WorkersEquipmentsComponent {
  public workerEquipmentFormGroup!: RxFormGroup;

  public workerEquipment: WorkerEquipment = new WorkerEquipment();
  public equipments: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private workerEquipmentsService: WorkerEquipmentsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private equipmentsService: EquipmentsService
  ) {
    this.workerEquipmentFormGroup = this.formBuilder.formGroup<WorkerEquipment>(this.workerEquipment) as RxFormGroup;
  }

  ngOnInit(): void {
    if (this.config.data.workerEquipmentId) {
      this.loadWorkerEquipment(this.config.data.workerEquipmentId);
    } else {
      this.workerEquipment.workerId = this.config.data.workerId;
    }
    this.loadEquipments();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadWorkerEquipment(id: string) {
    this.workerEquipment = await firstValueFrom(this.workerEquipmentsService.get(id));
    this.workerEquipmentFormGroup.patchValue(this.workerEquipment);
  }

  async loadEquipments() {
    let response = await firstValueFrom(this.equipmentsService.all());
    this.equipments = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.workerEquipmentFormGroup);
    if (valid) {
      this.workerEquipment = Object.assign(this.workerEquipment, this.workerEquipmentFormGroup.value);

      await this.workerEquipmentsService.save(this.workerEquipment).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Equipamiento guardado correctamente' });
          this.dynamicDialogRef.close();
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar el servicio' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el equipamiento?",
      header: "Eliminar equipamiento",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerEquipmentsService.delete(this.workerEquipment.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Equipamiento eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
