import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Template } from '../_types/template';
import { TemplatesService } from '../_services/templates.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Editor } from 'tinymce';

@Component({
  selector: 'app-templates-detail',
  templateUrl: './templates-detail.component.html',
  styleUrls: ['./templates-detail.component.scss']
})
export class TemplatesDetailComponent {
  public templateFormGroup!: RxFormGroup;

  public template: Template = new Template();
  public templateTypes: any = [];

  init: EditorComponent['init'] = {
    promotion: false,
    content_css: '/assets/tinymce/content.css',
    plugins: 'lists link image table code variables',
    custom_elements: 'variable',
    extended_valid_elements: 'span[variable]',
    toolbar: 'undo redo | formatselect | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | link image table | code | variables-menu',
    external_plugins: {
      variables: '/assets/tinymce/variables-plugin.js'
    },
    variable_mapper: {
      Code: 'Código',
      Date: 'Fecha',
      TaxAmount: 'IVA',
      Subtotal: 'Subtotal',
      Total: 'Total'
    },
    setup: (ed:Editor) => {
      console.log(ed);
      ed.on('variableClick', (e:any) => {
         console.log('click', e);
      });
    },
  };


  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private templatesService: TemplatesService,
    private topbarService: TopbarService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de plantilla");
    this.topbarService.addBreadcrumb("Plantillas", "/templates");

    this.templateFormGroup = this.formBuilder.formGroup<Template>(this.template) as RxFormGroup;
    this.templateTypes = this.templatesService.templateTypes();
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['guid'];
    if (id != null && id != '0') {
      await this.loadTemplate(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadTemplate(id: string) {
    this.template = await firstValueFrom(this.templatesService.get(id));
    this.templateFormGroup.patchValue(this.template);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.templateFormGroup);
    if (valid) {
      this.template = Object.assign(this.template, this.templateFormGroup.value);

      let templateItem = await firstValueFrom(this.templatesService.save(this.template));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Plantilla guardada correctamente." });
      this.router.navigate(['templates']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la plantilla?",
      header: "Eliminar plantilla",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.templatesService.delete(this.template.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Plantilla eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['templates']);
  }
}
