<form *ngIf="userFormGroup" [formGroup]="userFormGroup">
    <p-card class="p-3" header="Datos generales">

        <div class="grid">
            <div class="col-5 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-5 input-field">
                <label for="userName">Nombre de usuario *</label>
                <input pInputText formControlName="userName" inputId="userName" d-form-field-error />
            </div>

            <div class="col-2 input-field">
                <label for="active">Activo</label>
                <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
            </div>

            <div class="col-6 input-field">
                <label for="email">Email</label>
                <input pInputText type="email" formControlName="email" autocomplete="new-password" d-form-field-error />
            </div>

            <div class="col-4 input-field">
                <label for="userTypeId">Tipo de usuario</label>
                <p-dropdown [options]="userTypes" (onChange)="changeUserType($event)" formControlName="userTypeId"
                    optionLabel="name" optionValue="id" [filter]="true" filterBy="name"
                    placeholder="Seleccione tipo de usuario" d-form-field-error />
            </div>
            <div class="col-2 input-field" *ngIf="userTypeSelected.enableServiceEvaluations">
                <label for="maxServiceEvaluatedPercent">Porcentaje (%) de servicios a evaluar</label>
                <p-inputNumber inputId="integeronly" formControlName="maxServiceEvaluatedPercent" [min]="0" [max]="100"
                    suffix=" %" placeholder="0% - 100%" d-form-field-error />
            </div>
            <div class="col-5 input-field" *ngIf="!user.id">
                <label for="password">Contraseña</label>
                <input pPassword type="password" formControlName="password" autocomplete="new-password"
                    d-form-field-error />
            </div>
            <div class="col-6 input-field">
                <label for="languageId">Idioma</label>
                <p-dropdown [options]="languages" formControlName="languageId" optionLabel="name" optionValue="id"
                    [filter]="true" filterBy="name" placeholder="Seleccione idioma" d-form-field-error />
            </div>
            <div class="col-6 input-field">
                <label for="roleId">Rol</label>
                <p-dropdown [options]="roles" formControlName="roleId" optionLabel="title" optionValue="id"
                    [filter]="true" filterBy="title" placeholder="Seleccione rol" d-form-field-error />
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete && user.id" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>

    </p-card>

    <p-tabView>
        <p-tabPanel header="Servicios de autenticación">
            <div class="flex justify-content-end mb-2">
                <p-button label="Añadir servicio de autenticación" *ngIf="allowWrite" icon="pi pi-plus"
                    (click)="editAuthService(null)"></p-button>
            </div>

            <p-table styleClass="m-1 mt-4" #grid [value]="userAuthenticationServices" [loading]="loading"
                [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name" /></th>
                        <th pSortableColumn="active" width="150">Activo <p-sortIcon field="active" /></th>
                        <th pSortableColumn="userIdentifier">Identificador <p-sortIcon
                                field="userIdentifier"></p-sortIcon>
                        </th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-userAuthenticationService let-columns="columns">
                    <tr (dblclick)="editAuthService(userAuthenticationService)">
                        <td>{{userAuthenticationService.authenticationService.name}}</td>
                        <td><i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': user.active, 'text-red-500 pi-times-circle': !userAuthenticationService.active }"></i>
                        </td>
                        <td>{{userAuthenticationService.userIdentifier}}</td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteAuthService(userAuthenticationService)"
                                    [rounded]="true" [text]="false" severity="danger"><i
                                        class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editAuthService(userAuthenticationService)"
                                    [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Centralita">
            <div class="grid">
                <div class="col-6 input-field">
                    <label for="enableSIPExtension">Activar extensión</label>
                    <p-inputSwitch formControlName="enableSIPExtension" d-form-field-error></p-inputSwitch>
                </div>
                <div class="col-6 input-field" *ngIf="userFormGroup.get('enableSIPExtension')?.value">
                    <label for="sipExtension">Extensión</label>
                    <input pInputText formControlName="sipExtension" d-form-field-error />
                </div>
                <div class="col-12 input-field" *ngIf="userFormGroup.get('enableSIPExtension')?.value">
                    <label for="outboundDDIId">Número
                        asociado</label>
                    <p-dropdown [options]="ddis" formControlName="outboundDDIId" optionLabel="name" optionValue="id"
                        [filter]="true" filterBy="name" placeholder="Seleccione número" d-form-field-error />
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>