<div *ngIf="notifications.length>0">
    <p-tabView styleClass="p-tabview-panels-no-padding">
        <p-tabPanel *ngFor="let type of notificationTypes">
            <ng-template pTemplate="header">
                <i class="{{type.icon}}"></i>
                <span class="ml-2">{{type.label}}</span>
                <span class="bullet" *ngIf="type.count>0">{{type.count}}</span>
            </ng-template>
            <div *ngFor="let notification of getNotificationsForType(type)">
                <div class="notification">
                    <div class="flex">
                        <div class="level level-info"><i class="pi pi-info-circle"></i></div>
                        <div class="flex-grow-1">
                            <div class="subject">{{notification.subject}}</div>
                            <div class="date">{{notification.createdAt | date:'dd/MM/yyyy HH:mm'}}</div>
                            <div class="content" [innerHTML]="notification.content"></div>
                            <div class="mt-2">
                                <a *ngIf="notification.link" (click)="executeAction(notification)" class="mr-2">Abrir</a>
                                <a (click)="markAsReaded(notification)">Marcar como leida</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="getNotificationsForType(type).length==0" class="p-3">
                No tienes notificaciones de {{type.label.toLowerCase()}} pendientes
            </div>
        </p-tabPanel>
    </p-tabView>
    <div class="flex justify-content-center">
        <a (click)="goToNotifications()">Ver todas las notificaciones</a>
    </div>
</div>
<div *ngIf="notifications.length==0" class="m-4">
    No tienes notifications pendientes
</div>