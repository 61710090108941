import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class ChatRoom extends Resource {
    public override type = 'chatRooms';

    @prop()
    @required()
    public code!: string;

    @prop()
    @required()
    public title!: string;

}