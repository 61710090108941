import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {catchError, throwError} from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService:MessageService,
    private router: Router
  ) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        console.log(error);

        //TODO: creara ventana mas completa para mostrar errores
        //procesamos errores
        var details = "";
        if(error.error?.errors){
          for(var key in error.error.errors){
            details += error.error.errors[key].detail;
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.errors[key].detail });
          }
        }else if(error.error?.detail){
          //rfc9110
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error?.detail });
        }else {
          let errorTitle = 'Error';
          let errorMessage = 'Error desconocido';
          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              errorMessage = 'Error de conexión, intentelo de nuevo por favor.';
            }else if(error.status === 401){
              errorTitle += ' 401';
              errorMessage = 'No autorizado: ' + req.url;
              //setTimeout(() => {
                this.router.navigate(['/login']);
              //}, 2000);
            }else if(error.status === 403){
              errorTitle += ' 403';
              errorMessage = 'Prohibido: ' + req.url;
            }else if(error.status === 404){
              errorTitle += ' 404';
              errorMessage = 'No encontrado: ' + req.url;
            }
          }
          this.messageService.add({ closable: false, severity: 'error', summary: errorTitle, detail: errorMessage });
        }
        
        return throwError(error);
      })
    );
  }
}