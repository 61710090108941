import { Component } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { UsersService } from 'src/app/modules/users/_services/users.service';
import { User } from 'src/app/modules/users/_types/user';
import { ContractSlaNotificationsService } from '../../../_services/contracts-slas-notifications.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { ContractSlaNotification } from '../../../_types/contract-sla-notification';

@Component({
  selector: 'app-sla-notification',
  templateUrl: './sla-notification.component.html',
  styleUrls: ['./sla-notification.component.scss']
})
export class ContractSlaNotificationComponent {
  public users: User[] = [];
  public selectedUsers: any[] = [];
  public oldSelectedUsers: any[] = [];
  public contractSlaNotifications: any[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };

  constructor(
    private usersService: UsersService,
    private contractSlaNotificationsService: ContractSlaNotificationsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  async ngOnInit() {
    let contractSlaId = this.config.data.contractSlaId;
    this.loadSelectedUsers(contractSlaId);
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows }
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.usersService.all(params));
    this.users = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadSelectedUsers(contractSLAId: string) {
    let params: IParamsCollection = {
      include: ['user', 'contractSLA'],
    }

    let filters: any = {
      contractSLAId: new Filter(FilterOperation.equals, "contractSLAId"),
    };

    filters.contractSLAId.value = contractSLAId;

    params.filter = FilterBuilder.fromObject(filters).build();
    let response = await firstValueFrom(this.contractSlaNotificationsService.all(params));
    this.selectedUsers = response.data.map(x => x.user);
    this.oldSelectedUsers = response.data.map(x => x.user);
    this.contractSlaNotifications = response.data;
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let userIds = this.selectedUsers.map(x => x.id);
    let oldUserIds = this.oldSelectedUsers.map(x => x.id);

    let toAdd = userIds.filter(x => !oldUserIds.includes(x));
    let toRemove = oldUserIds.filter(x => !userIds.includes(x));

    // Crear una lista de promesas para agregar utilizando map en lugar de forEach
    const addPromises = toAdd.map(userId => {
      let contractSlaNotification = new ContractSlaNotification();
      contractSlaNotification.contractSLAId = this.config.data.contractSlaId;
      contractSlaNotification.userId = userId;
      return firstValueFrom(this.contractSlaNotificationsService.save(contractSlaNotification));
    });

    // Crear una lista de promesas para eliminar utilizando map en lugar de forEach
    const removePromises = toRemove.map(userId => {
      let contractSlaNotification = this.contractSlaNotifications.find(x => x.userId == userId);
      return firstValueFrom(this.contractSlaNotificationsService.delete(contractSlaNotification.id));
    });

    // Esperar a que todas las promesas se completen
    await Promise.all([...addPromises, ...removePromises]);

    // Una vez que todas las operaciones asíncronas hayan terminado, mostrar el mensaje y cerrar el diálogo
    this.messageService.add({ severity: 'success', summary: 'Guardado', detail: 'Cambios guardados correctamente' });
    this.dynamicDialogRef.close();

  }
}
