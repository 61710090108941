import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Operator } from "../../companies/_types/operator";
import { Client } from "../../clients/_types/client";
import { Processor } from "./processor";
import { File } from "../../common/_types/file";
import { Case } from "./case";

export class CaseFileCategory extends Resource {
    public override type = 'caseFileCategories';

    @prop()
    @required()
    public name!: string;


}