import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WorkerTimeRange } from '../_types/worker-time-range';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WorkersTimeRangesService } from '../_services/workers-time-ranges.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-workers-time-range',
  templateUrl: './workers-time-range.component.html',
  styleUrls: ['./workers-time-range.component.scss']
})
export class WorkersTimeRangeComponent {
  public workerTimeRangeFormGroup!: RxFormGroup;

  public workerTimeRange: WorkerTimeRange = new WorkerTimeRange();
  public timeRangeTypes: any[] = [
    { label: 'Normal', value: 'Normal' },
    { label: 'Guardia', value: 'Guard' }
  ];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public workerId: string = "";

  constructor(
    private config: DynamicDialogConfig,
    private workerTimeRangesService: WorkersTimeRangesService,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.workerTimeRangeFormGroup = this.formBuilder.formGroup<WorkerTimeRange>(this.workerTimeRange) as RxFormGroup;
  }


  ngOnInit(): void {

    this.workerTimeRange.workerId = this.config.data.workerId;

    if (this.config.data.id != null && this.config.data.id != '0') {
      this.loadTimeRange(this.config.data.id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("ZONES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("ZONES_DELETE");

  }

  async loadTimeRange(id: string) {
    this.workerTimeRange = await firstValueFrom(this.workerTimeRangesService.get(id));
    let start = new Date();
    start.setHours(this.workerTimeRange.startHour.split(":")[0], this.workerTimeRange.startHour.split(":")[1]);
    this.workerTimeRange.startHour = start;
    let end = new Date();
    end.setHours(this.workerTimeRange.endHour.split(":")[0], this.workerTimeRange.endHour.split(":")[1]);
    this.workerTimeRange.endHour = end;
    this.workerTimeRangeFormGroup.patchValue(this.workerTimeRange);
  }


  async save() {
    let valid = this.formValidationService.validateForm(this.workerTimeRangeFormGroup);
    if (valid) {
      this.workerTimeRange = Object.assign(this.workerTimeRange, this.workerTimeRangeFormGroup.value);            
      this.workerTimeRange.startHour = new Date(this.workerTimeRange.startHour).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second: '2-digit'});
      this.workerTimeRange.endHour = new Date(this.workerTimeRange.endHour).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second: '2-digit'});
            
      console.log(this.workerTimeRange);
      await this.workerTimeRangesService.save(this.workerTimeRange).subscribe({
        next: (data) => {
          this.messageService.add({ closable: false, severity: 'success', detail: "Franja horaria guardada correctamente" });
          this.dynamicDialogRef.close();
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar el servicio de autenticación' });
        }
      });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Quieres eliminar la franja horaria?",
      header: "Eliminar franja horaria",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerTimeRangesService.delete(this.workerTimeRange.id).subscribe(
          (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Franja horaria eliminada correctamente" });
            this.cancel();
          },
          (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
}
