import { Resource } from "src/app/core/jsonapi/resource";
import { Relationship } from "src/app/core/jsonapi/relationship";
import { CompanyAddress } from "./company-address";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class Company extends Resource {
    public override type = 'companies';    

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public legalName!: string;

    @prop()
    @required()
    public typeOfBusiness: number = 1;

    @prop()
    public email!: string;

    @prop()
    public phone1!: string;
    
    @prop()
    public active: boolean = true;
    
    @prop()
    public vatNumber!: string;

    @prop()
    public addressId!: string;

    @propObject(CompanyAddress)
    public address: CompanyAddress = new CompanyAddress();
            
    
}