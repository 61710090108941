import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { VehicleType } from '../_types/vehicle-type';
import { VehiclesTypesService } from '../_services/vehicles-types.service';

@Component({
  selector: 'app-vehicles-types-detail',
  templateUrl: './vehicles-types-detail.component.html',
  styleUrls: ['./vehicles-types-detail.component.scss']
})
export class VehiclesTypesDetailComponent {
  public vehicleTypeFormGroup!: RxFormGroup;

  public vehicleType: VehicleType = new VehicleType();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private vehiclesTypesService: VehiclesTypesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de equipamiento");
    this.topbarService.addBreadcrumb("Equipamientos", "/vehiclesTypes");

    this.vehicleTypeFormGroup = this.formBuilder.formGroup<VehicleType>(this.vehicleType) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadVehicleType(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("VEHICLES_TYPES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("VEHICLES_TYPES_DELETE");


  }

  async loadVehicleType(id: string) {
    this.vehicleType = await firstValueFrom(this.vehiclesTypesService.get(id));
    this.vehicleTypeFormGroup.patchValue(this.vehicleType);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.vehicleTypeFormGroup);
    if (valid) {
      this.vehicleType = Object.assign(this.vehicleType, this.vehicleTypeFormGroup.value);
      
      let vehicleType = await firstValueFrom(this.vehiclesTypesService.save(this.vehicleType));

      this.messageService.add({ closable: false, severity: 'success', summary: "Tipo de vehículo guardado correctamente." });

      
      this.router.navigate(['vehiclesTypes']);
            

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el tipo de vehículo "+this.vehicleType.name+"?",
      header: "Eliminar tipo de vehículo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.vehiclesTypesService.delete(this.vehicleType.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Tipo de vehículo eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['vehiclesTypes']);
  }

}
