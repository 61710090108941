import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { NgControl } from "@angular/forms";
import { RxFormControl } from "@rxweb/reactive-form-validators";

@Directive({
    selector: '[d-form-field-error]',
})
export class FormFieldErrorDirective {

    private errorElement!: any;

    constructor(
        private ele: ElementRef,
        private renderer : Renderer2,
        private control: NgControl
    ) {
    }

    ngOnInit() {

        //creamos el elemento div para el error
        this.errorElement = this.renderer.createElement('div');
        this.errorElement.classList.add('p-form-field-error');

        //obtenemos el parent
        let parent = this.renderer.parentNode(this.ele.nativeElement);

        //añadimos el elemento para mostrar errores
        this.renderer.appendChild(parent, this.errorElement);


        if(this.control!=null){
            //console.log('form-field-error', this.control.name);
            this.control.statusChanges?.subscribe(() => {                
                let message:string = '';
                if(this.control.errors){
                    Object.keys(this.control.errors).forEach((key: string) => {
                        let error = this.control.errors![key];
                        message += error.message;
                    });                    
                }
                //console.log(message);
                this.errorElement.innerText = message;
                this.errorElement.style.display = message!='' ? 'block' : 'none';
                
            });
        }
    }
}