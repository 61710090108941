import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseUser } from "../_types/case-user";

@Injectable({
    providedIn: 'root',
})
export class CasesUsersService extends Service<CaseUser> {
    public override type = 'caseUsers';
    public override url = 'attendance/caseUsers';
}
