import { minLength, prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class ProviderBankAccount extends Resource {
    public override type = 'providerBankAccounts';
    
    @prop()
    @required()
    public bankId!: string;

    @prop()
    @required()
    @minLength({ value: 24 })
    public accountNumber!: string;
    
    @prop()
    @required()
    @minLength({ value: 10 })
    public swift!: string;

}