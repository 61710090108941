import { Component, OnInit } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { CaseLog, CaseLogType } from '../../_types/case-log';
import { CasesLogsService } from '../../_services/cases-logs.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Template } from 'src/app/modules/templates/_types/template';
import { TemplatesService } from 'src/app/modules/templates/_services/templates.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Editor } from 'tinymce';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class CasesDetailLogsComponent implements OnInit {
  public logFormGroup!: RxFormGroup;
  public log: CaseLog = new CaseLog();
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public logTypes: CaseLogType[] = Object.values(CaseLogType);
  public templates: Template[] = [];

  init: EditorComponent['init'] = {
    promotion: false,
    content_css: '/assets/tinymce/content.css',
    plugins: 'lists link image table code variables',
    custom_elements: 'variable',
    extended_valid_elements: 'span[variable]',
    toolbar: 'undo redo | formatselect | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | link image table | code | variables-menu',
    external_plugins: {
      variables: '/assets/tinymce/variables-plugin.js'
    },
    variable_mapper: {
      Code: 'Código',
      Date: 'Fecha',
      TaxAmount: 'IVA',
      Subtotal: 'Subtotal',
      Total: 'Total'
    },
    setup: (ed:Editor) => {
      console.log(ed);
      ed.on('variableClick', (e:any) => {
         console.log('click', e);
      });
    },
  };
  
  constructor(
    private caseLogService: CasesLogsService, 
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    public templatesService: TemplatesService
  ) {
    this.logFormGroup = this.formBuilder.formGroup<CaseLog>(this.log) as RxFormGroup;
   }

  async ngOnInit() {
    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; 
    
    let logId = this.config.data.logId;
    if (logId) {
      await this.loadLog(logId);

    } else {
      this.log.caseId = this.config.data.caseId;
      console.log(this.logTypes);
    }
    console.log(this.logFormGroup);
  }
  async loadLog(logId: string) {
    this.log = await firstValueFrom(this.caseLogService.get(logId));
    this.logFormGroup.patchValue(this.log);    

  }

  async changeTemplate(event: any){
    //TODO: falta pasar la plantilla con la inforamción del case.
    console.log("se pasa por Guau.Core/Service/TemplateBuilder pasándole el case para sustituir variabeles si fuese necesario", event);
    if(event != null && event.value !=null){
      let infoTemplate = this.templates.filter(m => m.id == event.value)[0];
      this.caseLogService.parseHtml(event.value, this.log.caseId).subscribe({
        next: (data: any) => {
                    console.log(data);
          this.logFormGroup.patchValue({subject: infoTemplate.subject, message: data.html })
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });      
    }

  }

  async changeLogType(event: any){
    console.log(event);
    if(event.value == "Mail" || event.value == "SMS"){
      let type = "Email";
      if(event.value == "SMS") type="SMS";
      let filters = {
        templateType: new Filter(FilterOperation.equals, 'templateType', "", type)
      };
      let params: IParamsCollection = {
        sort: ['name'],
        filter: FilterBuilder.fromObject(filters).build()
      }
      //cargamos las plantillas 
      let response = await firstValueFrom(this.templatesService.all(params));
      console.log(response);
      this.templates = response.data;
    }

  }

  async save() {
    let valid = this.formValidationService.validateForm(this.logFormGroup);
    if (valid) {
      this.log = Object.assign(this.log, this.logFormGroup.value);

      let log = await firstValueFrom(this.caseLogService.save(this.log));

      //Ahora creamos la comunicación
      /*let task = new Task();
      task.description = this.note.notes;
      task.caseNoteId = note.id || "";
      task.userId = this.selectedUser;
      await firstValueFrom(this.tasksService.save(task));
*/
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Log guardado correctamente." });
      this.dynamicDialogRef.close(this.log.id ? null : log.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }


  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el evento?",
      header: "Eliminar nota",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.caseLogService.delete(this.log.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Evento eliminado correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
}
