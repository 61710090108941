
import { LocalStorageService } from 'angular-web-storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LoginService } from '../../login/login.service';
import { ProvidersService } from '../_services/providers.service';
import { Component } from '@angular/core';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { firstValueFrom } from 'rxjs';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';

@Component({
  selector: 'app-providers-list',
  templateUrl: './providers-list.component.html',
})
export class ProvidersComponent {
  
  public providers: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private storage: LocalStorageService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,                
    private router: Router,
    private topbarService: TopbarService,
    private loginService: LoginService,
    private providersService: ProvidersService
  ) {
    this.topbarService.setTitle("Proveedores");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({});
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")?
  }

  async loadData($event: any) {

    this.lastGridEvent = $event;

    let params:IParamsCollection = {      
      page: { number: $event.first+1, size: $event.rows },
      include: ['address']
    }
    
    if(typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.providersService.all(params));
    this.providers = response.data;
    this.totalRecords = response.meta['total'];
  }
  
  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(provider: any) {
    //if (this.loginService.hasPermission("PROVIDERS_WRITE")) {      
      this.router.navigate(['providers/' + (provider && Object.keys(provider).length > 0 ? provider.id : 0)]);
    //}
  }

  delete(provider: any) {    
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el proveedor?",
        header: "Eliminar proveedor",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.providersService.delete(provider.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Proveedor eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  getSeverity(active: boolean) {
    if(active){
      return 'success'; 
    }else{
      return 'danger';
    }    
  }
}
