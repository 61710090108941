import { Resource } from "src/app/core/jsonapi/resource";
import { Capability } from "../../common/_types/capability";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class WorkerCapability extends Resource {
    public override type = 'workerCapabilities';

    @prop()
    @required()
    public workerId!: string;

    @prop()
    @required()
    public capabilityId!: string;

    @propObject()
    public capability!: Capability;

    @prop()
    public normalServiceAllowed: boolean = false;

    @prop()
    public guardServiceAllowed: boolean = false;
}