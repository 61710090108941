import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { Capability } from 'src/app/modules/common/_types/capability';

import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation, NotFilter } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RateLine } from 'src/app/modules/rates/_types/rate-line';
import { RateLinesService } from 'src/app/modules/rates/_services/rates-lines.service';
import { Job } from 'src/app/modules/cases/_types/job';
import { CaseService } from 'src/app/modules/cases/_types/case-service';
import { CasesServicesService } from 'src/app/modules/cases/_services/cases-services.service';
import { JobsService } from 'src/app/modules/cases/_services/jobs.service';
import { CompanyRatesService } from 'src/app/modules/companies/_services/companies-rates.service';
import { RatesService } from 'src/app/modules/rates/_services/rates.service';
import { Tax } from 'src/app/modules/transactions/_types/tax';
import { TaxesService } from 'src/app/modules/transactions/_services/taxes.service';

@Component({
  selector: 'app-case-service-job',
  templateUrl: './case-service-job.component.html',
  styleUrls: ['./case-service-job.component.scss']
})
export class CaseServiceJobComponent {
  public jobFormGroup!: RxFormGroup;
 
  public job: Job = new Job();

  public capabilities: Capability[] = [];
  public caseServices: CaseService[] = [];
  public companyId!: string;
  public operatorId!: string;
  public rateLines: RateLine[] = [];
  public selectedCapability: string | undefined;
  public rateLineSelected: any = {
    freePrice: true
  };
  public hasSubmitInvoice: boolean = false;
  public allRateLines: any;
  public jobType!: string;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public taxes: Tax[] = [];
  public loadingTaxes: boolean = false;

  constructor(
    private jobsService: JobsService,
    private capabilitiesService: CapabilitiesService,
    private companiesRatesService: CompanyRatesService,
    private casesServicesService: CasesServicesService,
    private rateLinesService: RateLinesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private ratesService: RatesService,
    private taxesService: TaxesService
  ) {
    this.jobFormGroup = this.formBuilder.formGroup(Job) as RxFormGroup;        
  }

  async ngOnInit() {    
    await this.loadTaxes();
    let jobId = this.config.data.id;
    this.companyId = this.config.data.companyId;
    this.operatorId = this.config.data.operatorId;
    this.jobType = this.config.data.type;    
    
    if (jobId) {
      await this.loadJob(jobId);
    } else {      
      this.job.caseId = this.config.data.caseId;
      this.job.caseServiceId = this.config.data.caseServiceId;
      this.job.taxPercent = this.taxes.filter(m => m.default)[0].percent;
      this.jobFormGroup.patchValue(this.job);
      await this.loadRateLines();
    }    

    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");
    
    await this.loadCaseServices();
    
  }

  async loadJob(jobId: string) {    
    this.job = await firstValueFrom(this.jobsService.get(jobId, { include: ['rateLine'] }));
    //Si tiene transactionId bloqueamos todo
    if(this.job.transactionId != null){
      this.jobFormGroup.get('date')?.disable();
      this.jobFormGroup.get('rateLineId')?.disable();
      this.jobFormGroup.get('description')?.disable();
      this.jobFormGroup.get('quantity')?.disable();
      this.jobFormGroup.get('unitPrice')?.disable();
      this.jobFormGroup.get('isReview')?.disable();
      this.jobFormGroup.get('isBillable')?.disable();

    }
    
    if (this.job.rateLine) {
      await this.loadRateLines();
      this.selectedCapability = this.job.rateLine.capabilityId;
      this.selectCapability({
        value: this.selectedCapability
      });      
    }
    this.jobFormGroup.patchValue(this.job);
    
  }
  

  async loadCaseServices() {
    let params: IParamsCollection = {
      include: ['operator']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    filters.caseId.value = this.job.caseId;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response:any = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data;
    console.log(this.caseServices);

    // añadir atributo que sea 'operator.name' - 'date'
    /*this.caseServices.forEach((caseService: any) => {
      caseService.jobLabel = `${caseService.operator.name} - ${caseService.date.toLocaleString()}`;
    });*/
  }

  async loadRateLines() {
    console.log(this.jobType);
    this.allRateLines = await firstValueFrom(this.ratesService.rateLinesCompany(this.jobType, this.jobType=="COMPANY"?this.companyId:this.operatorId, this.jobFormGroup.value.date));
    console.log(this.allRateLines);
    this.capabilities = this.allRateLines.map((m:any) => m.capability).filter((capability:any, index:any, self:any) =>
      index === self.findIndex((p:any) => p.id === capability.id)
    );            
  }

  async loadTaxes() {
    this.loadingTaxes = true;
    let params: IParamsCollection = {
    }

    let response = await firstValueFrom(this.taxesService.all(params));
    this.taxes = response.data;

    this.loadingTaxes = false;
  }
    
  selectCapability(capability: any){     
    console.log(capability);
    this.rateLines = this.allRateLines.filter((m:any) => m.capabilityId == capability.value)
    this.rateLines.forEach((rateLine) => {
      rateLine.displayName = rateLine.code+(rateLine.description != null && rateLine.description != ""?" - "+ rateLine.description:"");
    });
    console.log(this.rateLines);
    if(this.job.rateLineId!=null){
      this.rateLineSelected = this.allRateLines.find((m:any) => m.id == this.job.rateLineId);         
      if(!this.rateLineSelected.freePrice){
        this.jobFormGroup.get('unitPrice')?.disable();
      }
      if(this.rateLineSelected.fixedAmount != null){
        this.jobFormGroup.get('quantity')?.disable();
      }
    }
    
  }

  selectRateLine(e: any) {
    this.rateLineSelected = e;
    console.log(this.rateLineSelected);
    this.job.unitPrice = this.rateLines.find((rl: any) => rl.id == e.value)?.price || 0;    
    this.jobFormGroup.patchValue({ unitPrice: this.job.unitPrice });
  }

  // Función para generar el label concatenado
  getOptionLabel(option: any){
    console.log(option);
    return option.code+" - "+option.description;
  }


  cancel() {
    this.dynamicDialogRef.close();
  }

  async preSave(){
    
    let valid = this.formValidationService.validateForm(this.jobFormGroup);
    if (valid) {
      this.job = Object.assign(this.job, this.jobFormGroup.getRawValue());      
      //Buscamos el resto de líneas para verficar códigos incompatibles y servicios únicos.
      if((this.rateLineSelected.incompatibleCodes != "" && this.rateLineSelected.incompatibleCodes != null) || this.rateLineSelected.uniqueService){
        let params: IParamsCollection = {
          include: ['rateLine']
        }
        
        let filters: any = {          
          caseServiceId: new Filter(FilterOperation.equals, "caseServiceId"),          
        };

        if(this.job.id != ""){
          filters.id = new NotFilter(FilterOperation.equals,'id','',this.job.id, true);
        }
    
        filters.caseServiceId.value = this.job.caseServiceId;
        params.filter = FilterBuilder.fromObject(filters).build();
          console.log(params);
        let otherJobs:any = await firstValueFrom(this.jobsService.all(params));   
        
        console.log(otherJobs);

        if(this.rateLineSelected.incompatibleCodes !="" && this.rateLineSelected.incompatibleCodes != null){
          let incompatibleCodesFound:string = "";

          let incompatibleCodes = this.rateLineSelected.incompatibleCodes.split(',');

          const promesas = incompatibleCodes.map((incompatibleCode:string) => {            
            this.checkCode(otherJobs,incompatibleCode).then(value => {
                if(value.length > 0){
                  incompatibleCodesFound += value[0].rateLine.code+";";                  
                }
            });
          });
          
          await Promise.all(promesas);  // Espera que todas las promesas se resuelvan
          
          if(incompatibleCodesFound != ""){
            this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "El baremo indicado no es compatible con los siguientes códigos: "+incompatibleCodesFound});
          }else{
             //Comprobamos si es servicio único
             if(this.rateLineSelected.uniqueService) {
              if(this.checkUniqueService(otherJobs).length > 0){
                this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Ya existe un baremo que es único en el servicio"});
              }else{
                this.save();
              }
             }else{
              this.save();
             }
          }
        }else{
          if(this.checkUniqueService(otherJobs).length > 0){
            this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Ya exsiste un baremo que es único en el servicio"});
          }else{
            this.save();
          }
        }

      }else{
        this.save();
      }                  

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }

  }

  checkUniqueService(otherJobs:any){
    console.log(otherJobs);
    return otherJobs.data.filter((m:any) => m.rateLine.uniqueService);
  }

  async checkCode(otherJobs:any, code: string): Promise<any> {
    return new Promise(resolve => {
      let result = otherJobs.data.filter((m:any) => m.rateLineId == code);            
      resolve(result);
    });
  }
  

  async save() {

    // asignar el precio unitario de la línea de tarifa
    if (this.job.rateLineId) {
      this.job.rateLineUnitPrice = this.rateLines.find((rl: any) => rl.id == this.job.rateLineId)?.price || 0;
    }

    //asignar operario desde el servicio
    this.job.operatorId = this.caseServices.find((cs: any) => cs.id == this.job.caseServiceId)?.operatorId || '';
        
    if(this.jobType == "COMPANY") this.job.isForCompany = true;

    let job:any = await firstValueFrom(this.jobsService.save(this.job));
    this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Trabajo guardado correctamente." });
    this.dynamicDialogRef.close(this.job.id ? null : job.id);
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el trabajo?",
      header: "Eliminar trabajo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.jobsService.delete(this.job.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Trabajo eliminado correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  // Asignar el precio unitario de la línea de tarifa al cambiar la línea de tarifa
  onRateLineChange(e: any) {
    this.rateLineSelected = this.allRateLines.find((rateLine: any) => rateLine.id == e.value);
    console.log(this.rateLineSelected);
    this.jobFormGroup.controls['unitPrice'].setValue(this.rateLineSelected?.price || 0);    
    if(!this.rateLineSelected.freePrice){
      this.jobFormGroup.get('unitPrice')?.disable();
    }else{
      this.jobFormGroup.get('unitPrice')?.enable();
    }
    if(this.rateLineSelected.fixedAmount != null){
      this.jobFormGroup.controls['quantity'].setValue(this.rateLineSelected.fixedAmount);
      this.jobFormGroup.get('quantity')?.disable();
    }else{
      this.jobFormGroup.controls['quantity'].setValue(null);
      this.jobFormGroup.get('quantity')?.enable();
    }
  }

  // Controlar si el operario del servicio seleccionado tiene que emitir factura
  onCaseServiceChange() {
    let caseServiceId = this.jobFormGroup.controls['caseServiceId'].value;
    this.hasSubmitInvoice = this.caseServices.find((cs: any) => cs.id == caseServiceId)?.operator.hasSubmitInvoice || false;
  }


  calculatePrices(event: any) {    
    const precioTotalSinImpuestos = this.jobFormGroup.controls["quantity"].value * this.jobFormGroup.controls["unitPrice"].value;    
    console.log(precioTotalSinImpuestos);
    const impuesto = precioTotalSinImpuestos * (this.jobFormGroup.controls["taxPercent"].value / 100);
    this.jobFormGroup.controls["taxAmount"].setValue(impuesto);
    console.log(impuesto);
    const precioFinal = precioTotalSinImpuestos + impuesto;
    console.log(precioFinal);
    this.jobFormGroup.controls["total"].setValue(precioFinal);
    
  }

}
