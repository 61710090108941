
import { Resource } from "src/app/core/jsonapi/resource";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { TransactionType } from "./transaction-type";
import { TransactionState } from "./transaction-state";

export class ThirdParty extends Resource {
    public override type = 'thirdParties';
    
    @prop()
    @required()
    public thirdPartyType!: string;      

    @prop()
    @required()
    public name!: string;      

    @prop()    
    public legalName!: string;   
    
    @prop()    
    public vatNumber!: string;      
    
    
}