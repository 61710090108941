<form *ngIf="workerFormGroup" [formGroup]="workerFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-10 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-2 input-field">
                <label for="active" class="mr-1">Activo</label>
                <div class="flex h-full">
                    <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="mt-4 flex gap-2 justify-content-end">
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>
</form>