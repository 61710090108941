import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ChatRoomMessage } from "../_types/chat-room-message";

@Injectable({
    providedIn: 'root',
})
export class ChatRoomMessagesService extends Service<ChatRoomMessage> {
    public override type = 'chatRoomMessages';
    public override url = 'communications/chatroommessages';
}
