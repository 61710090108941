import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { PaymentMethod } from "../_types/payment-method";
@Injectable({
    providedIn: 'root',
})

export class PaymentsMethodsService extends Service<PaymentMethod> {
  public override type = 'paymentMethods';
  public override url = 'common/paymentMethods'; 
}
