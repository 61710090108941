import { Resource } from "src/app/core/jsonapi/resource";
import { prop, required } from "@rxweb/reactive-form-validators";

export class Guarantee extends Resource {
    public override type = 'guarantees';

    @prop()
    @required()
    public name!: string;


}