import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WorkerCapability } from '../_types/worker-capability';
import { WorkerCapabilitiesService } from '../_services/workers-capabilities.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CapabilitiesService } from '../../common/_services/capabilities.service';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-workers-capabiliy',
  templateUrl: './workers-capabiliy.component.html',
  styleUrls: ['./workers-capabiliy.component.scss']
})
export class WorkersCapabiliyComponent {
  public workerCapabilityFormGroup!: RxFormGroup;

  public workerCapability: WorkerCapability = new WorkerCapability();
  public capabilities: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private workerCapabilitiesService: WorkerCapabilitiesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private capabilitiesService: CapabilitiesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.workerCapabilityFormGroup = this.formBuilder.formGroup<WorkerCapability>(this.workerCapability) as RxFormGroup;
  }

  ngOnInit(): void {
    if (this.config.data.workerCapabilityId) {
      this.loadWorkerCapability(this.config.data.workerCapabilityId);
    } else {
      this.workerCapability.workerId = this.config.data.workerId;
    }
    this.loadCapabilities();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadWorkerCapability(id: string) {
    this.workerCapability = await firstValueFrom(this.workerCapabilitiesService.get(id));
    this.workerCapabilityFormGroup.patchValue(this.workerCapability);
  }

  async loadCapabilities() {
    let response = await firstValueFrom(this.capabilitiesService.all());
    this.capabilities = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.workerCapabilityFormGroup);
    if (valid) {
      this.workerCapability = Object.assign(this.workerCapability, this.workerCapabilityFormGroup.value);

      await this.workerCapabilitiesService.save(this.workerCapability).subscribe({
        next: (data) => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Servicio guardado correctamente' });
          this.dynamicDialogRef.close();
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar el servicio' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio?",
      header: "Eliminar servicio",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerCapabilitiesService.delete(this.workerCapability.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
