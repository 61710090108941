<div class="flex gap-2 cola-4">
  <span class="w-full input-field p-input-icon-right">
    <input pInputText  placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()" class="w-full"/>
    <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer" (click)="filters.name.value = null; search()"></i>
  </span>
</div>

<p-table styleClass="mt-4" #grid [value]="authenticationsServices" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading" [paginator]="true"
  [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
  currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
  [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>        
      <th pSortableColumn="ClientId">Id Cliente <p-sortIcon field="ClientId"></p-sortIcon></th>        
      <th pSortableColumn="Secret">Secret <p-sortIcon field="Secret"></p-sortIcon></th>        
      
      <th width="100"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-authenticationService let-columns="columns">
    <tr (dblclick)="edit(authenticationService)">
      <td>{{authenticationService.name}}</td>        
      <td>{{authenticationService.clientId}}</td>        
      <td>{{authenticationService.secret}}</td>        
      <td>
        <div class="flex gap-2 justify-content-end">
          <p-button *ngIf="allowDelete" (click)="delete(authenticationService)" [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
          <p-button *ngIf="allowWrite" (click)="edit(authenticationService)" [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
        </div>
      </td>      
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog></p-confirmDialog>