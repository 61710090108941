import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CasesTypesListComponent } from './cases-types-list/cases-types-list.component';
import { CasesTypesDetailComponent } from './cases-types-detail/cases-types-detail.component';

const routes: Routes = [
    {
        path: 'casesTypes',
        children: [
            { path: '', component: CasesTypesListComponent }
        ]
    },
    {
        path: 'casesTypes/:id',
        children: [
            { path: '', component: CasesTypesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CasesTypesRoutingModule { }
