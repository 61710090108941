import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProvidersComponent } from './list/providers-list.component';
import { ProvidersDetailComponent } from './detail/providers-detail.component';

const routes: Routes = [
  {
    path: 'providers',
    children: [
      { path: '', component: ProvidersComponent }
    ]
  },
  {
    path: 'providers/:id',
    children: [
      { path: '', component: ProvidersDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProvidersRoutingModule { }
