import { Component, HostListener } from '@angular/core';
import { Case } from '../_types/case';
import { CasesService } from '../_services/cases.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { ClientAddress } from '../../clients/_types/client-address';
import { firstValueFrom } from 'rxjs';
import { CaseState } from '../_types/case-state';
import { Company } from '../../companies/_types/company';
import { DialogService } from 'primeng/dynamicdialog';
import { CasesDetailGeneralComponent } from './general/general.component';
import { CaseStateComponent } from './case-state/case-state.component';
import { CasesDetailClientComponent } from './client/client.component';
import { Client } from '../../clients/_types/client';
import { Affected } from '../_types/affected';
import { AffectedsService } from '../_services/affecteds.service';
import { CasesDetailAffectedComponent } from './affected/affected.component';
import { CasesDetailProcessorComponent } from './processor/processor.component';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { CaseNote } from '../_types/case-note';
import { CasesDetailNoteComponent } from './note/note.component';
import { CasesNotesService } from '../_services/cases-notes.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CaseService } from '../_types/case-service';
import { CasesServicesService } from '../_services/cases-services.service';
import { CasesDetailServiceComponent } from './service/service.component';
import { Job } from '../_types/job';
import { JobsService } from '../_services/jobs.service';
import { JobComponent } from './job/job.component';
import { FilesService } from '../../common/_services/files.service';
import { File } from '../../common/_types/file'
import { CasesFilesService } from '../_services/cases-files.service';
import { CaseFile } from '../_types/case-file';
import { CasesDetailFileComponent } from './file/file.component';
import { environment } from 'src/environment/environment';
import { CasesFilesCategoriesService } from '../_services/cases-files-categories.service';
import { CaseFileCategory } from '../_types/case-file-category';
import { ChatDetailComponent } from '../../chat/detail/chat-detail.component';
import { ChatService } from '../../chat/_services/chat.service';
import { TabViewChangeEvent } from 'primeng/tabview';
import { CaseServiceDetailComponent } from './service/case-service-detail/case-service-detail.component';
import { DatePipe } from '@angular/common';
import { AccordionTabOpenEvent } from 'primeng/accordion';
import { Expert } from '../../experts/_types/expert';
import { ExpertsService } from '../../experts/_services/experts.service';
import { ExpertsDetailComponent } from '../../experts/experts-detail/experts-detail.component';
import { CasesExpertsService } from '../_services/cases-experts.service';
import { CaseExpert } from '../_types/case-expert';
import { CaseExpertComponent } from './expert/expert.component';
import { TransactionsService } from '../../transactions/_services/transactions.service';
import { CasesUsersService } from '../_services/cases-users.service';
import { CaseUser } from '../_types/case-user';
import { CaseTechnicalComponent } from './technical/technical.component';
import { CasesLogsService } from '../_services/cases-logs.service';
import { CasesDetailLogsComponent } from './logs/logs.component';
import { CaseLog } from '../_types/case-log';

@Component({
  selector: 'app-cases-detail',
  templateUrl: './cases-detail.component.html',
  styleUrls: ['./cases-detail.component.scss']
})
export class CasesDetailComponent {
  public case: Case = new Case();
  public companies: Company[] = [];
  public caseStates: CaseState[] = [];
  public affecteds: Affected[] = [];
  public caseExperts: CaseExpert[] = [];
  public caseTechnicals: CaseUser[] = [];
  public caseNotes: CaseNote[] = [];
  public caseJobs: Job[] = [];
  public caseFiles: CaseFile[] = [];
  public caseServices: CaseService[] = [];
  public caseJobsOperators: any[] = [];
  public caseFilesServices: any[] = [];
  public caseFileCategories: CaseFileCategory[] = [];
  public caseFilesWithoutService: any[] = [];
  public caseFileCategoryFilter: string = "all";
  public caseTransactions: any = [];
  public caseLogs: any = [];

  public loadingNotes: boolean = false;
  public totalNotesRecords: number = 0;
  public loadingServices: boolean = false;
  public totalServicesRecords: number = 0;
  public loadingAffecteds: boolean = false;
  public totalAffectedsRecords: number = 0;
  public loadingExperts: boolean = false;
  public totalExpertsRecords: number = 0;
  public loadingTechnicals: boolean = false;
  public totalTechnicalsRecords: number = 0;
  public loadingTransactions: boolean = false;
  public totalTransactionsRecords: number = 0;
  public loadingLogs: boolean = false;
  public totalLogsRecords: number = 0;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public fileActions: any;
  public imagesUrl: string = environment.url_files;


  constructor(
    private casesService: CasesService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private dialogService: DialogService,
    private affectedsService: AffectedsService,
    private casesExpertsService: CasesExpertsService,
    private caseUsersService: CasesUsersService,
    private casesNotesService: CasesNotesService,
    private casesLogsService: CasesLogsService,
    private jobsService: JobsService,
    private casesServicesService: CasesServicesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private filesService: FilesService,
    private casesFilesService: CasesFilesService,
    private chatService: ChatService,
    private caseFileCategoriesService: CasesFilesCategoriesService,
    private transactionsService: TransactionsService
  ) {
    this.topbarService.setTitle("Detalles de expediente");
    this.topbarService.addBreadcrumb("Expedientes", "/cases");

  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadCase(id);
    }

    if (this.case.address == null) this.case.address = new ClientAddress();


    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");

    this.fileActions = (file: any) => [
      {
        label: 'Opciones',
        items: [
          {
            label: 'Descargar',
            icon: 'pi pi-download',
            command: () => this.downloadFile(file.fileId)
          },
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => this.editCaseFile(file)
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => this.deleteCaseFile(file)
          }
        ]
      }
    ];

    this.loadCaseFileCategories();

  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    let element = document.getElementById('case-header');
    if (element) {
      let currentTop = element.getBoundingClientRect().top;
      if (currentTop <= 0) {
        element.classList.add('is-sticky');
        element.classList.add('p-card-header-d-none');
      } else {
        element.classList.remove('is-sticky');
        element.classList.remove('p-card-header-d-none');
      }
    }
  }

  /*determineStickyState(element:any) {
    console.log(element);
    const currentTop = element.getBoundingClientRect().top;
    element.classList.toggle('is-sticky', currentTop <= stickyElementTop);
  
  }*/


  async loadCase(id: string) {
    this.case = await firstValueFrom(this.casesService.get(id, { include: ['company', 'client', 'processor', 'address', 'caseState', 'caseType', 'contract'] }));
    this.onTabChange({ index: 0, originalEvent: new Event('') });
  }

  async onTabChange(event: AccordionTabOpenEvent) {
    if (event.index == 0) {
      //services
      this.loadServices();
    } else if (event.index == 1) {
      //affecteds
      this.loadAffecteds();
    } else if (event.index == 2) {
      //experts
      this.loadCaseExperts();
    } else if (event.index == 3) {
      // technicals
      this.loadCaseTechnicals();
    } else if (event.index == 4) {
      //communications
      this.loadLogs();
    } else if (event.index == 5) {
      //notes
      this.loadNotes();
    } else if (event.index == 6) {
      //presupuestos
      this.loadTransactions()
    } else if (event.index == 7) {
      //Adjuntos
      this.loadCaseFiles();
    }
  }

  async loadAffecteds() {
    this.loadingAffecteds = true;
    let params: IParamsCollection = {
      include: ['address']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId", "", this.case.id),
    };
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.affectedsService.all(params));
    this.affecteds = response.data;
    console.log(this.affecteds);
    this.totalAffectedsRecords = response.meta['total'];
    this.loadingAffecteds = false;
  }

  async loadCaseExperts() {
    this.loadingExperts = true;
    let params: IParamsCollection = {
      include: ['expert']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId", "", this.case.id),
    };
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesExpertsService.all(params));
    this.caseExperts = response.data;
    this.totalExpertsRecords = response.meta['total'];
    this.loadingExperts = false;
  }

  async loadCaseTechnicals() {
    this.loadingTechnicals = true;
    let params: IParamsCollection = {
      include: ['user']
    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId", "", this.case.id),
    };
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.caseUsersService.all(params));
    this.caseTechnicals = response.data;
    this.totalTechnicalsRecords = response.meta['total'];
    this.loadingTechnicals = false;
  }


  async loadNotes() {
    this.loadingNotes = true;
    let params: IParamsCollection = {

    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    filters.caseId.value = this.case.id;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesNotesService.all(params));
    this.caseNotes = response.data;
    this.totalNotesRecords = response.meta['total'];
    this.loadingNotes = false;
  }

  async loadServices() {
    this.loadingServices = true;
    let params: IParamsCollection = {

    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    filters.caseId.value = this.case.id;
    params.filter = FilterBuilder.fromObject(filters).build();
    params.include = ['operator'];

    let response = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data;
    this.totalServicesRecords = response.meta['total'];
    this.loadingServices = false;
  }

  async loadJobs() {
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId", "", this.case.id),
    };
    let params: IParamsCollection = {
      include: ['rateLine', 'operator'],
      filter: FilterBuilder.fromObject(filters).build()
    }
    let response = await firstValueFrom(this.jobsService.all(params));
    this.caseJobs = response.data

    this.caseJobsOperators = [];
    this.caseJobs.forEach((job: Job) => {
      this.caseJobsOperators.push({
        id: job.operatorId,
        name: job.operator?.name
      });
    });
    // quitar duplicados
    this.caseJobsOperators = this.caseJobsOperators.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }

  async loadTransactions() {
    this.loadingTransactions = true;
    let params: IParamsCollection = {
      include: ['transactionState', 'thirdParty', 'transactionType']
    }
    let filters: any = {
      //caseId: new Filter(FilterOperation.equals, "caseId"),
    };

    //filters.caseId.value = this.case.id;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.transactionsService.all(params));
    this.caseTransactions = response.data;
    this.totalTransactionsRecords = response.meta['total'];
    this.loadingTransactions = false;
  }

  async loadCaseFiles() {
    let params: IParamsCollection = {
      include: ['file'],
      sort: ['-createdAt']

    }
    let filters: any = {
      caseId: new Filter(FilterOperation.equals, "caseId"),
      caseFileCategoryId: new Filter(FilterOperation.equals, "caseFileCategoryId")
    };

    filters.caseId.value = this.case.id;
    if (this.caseFileCategoryFilter != "all") {
      filters.caseFileCategoryId.value = this.caseFileCategoryFilter;
    }

    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.casesFilesService.all(params));
    this.caseFiles = response.data;

    this.caseFilesServices = [];
    this.caseFilesWithoutService = [];
    this.caseFiles.forEach((caseFile: CaseFile) => {
      if (caseFile.caseServiceId) {
        let caseService = this.caseServices.find((service: CaseService) => service.id == caseFile.caseServiceId);
        this.caseFilesServices.push({
          id: caseService?.id,
          name: (caseService?.operator?.name + " - " + caseService?.date.toLocaleString(
            'es-ES',
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            }
          ))
        });
      } else {
        this.caseFilesWithoutService.push(caseFile);
      }
    });
    // quitar duplicados
    this.caseFilesServices = this.caseFilesServices.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }

  async loadCaseFileCategories() {
    let allCategories = new CaseFileCategory();
    allCategories.id = "all";
    allCategories.name = "Todos";
    let response = await firstValueFrom(this.caseFileCategoriesService.all());
    this.caseFileCategories = response.data;
    this.caseFileCategories.unshift(allCategories);
  }

  onFileCategoryFilterChange() {
    this.loadCaseFiles();
  }


  cancel() {
    this.router.navigate(['cases']);
  }

  editGeneral() {
    let ref = this.dialogService.open(CasesDetailGeneralComponent, {
      header: 'Datos generales',
      width: '50%',
      data: {
        id: this.case.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadCase(this.case.id as string);
    });
  }

  editState() {
    let ref = this.dialogService.open(CaseStateComponent, {
      header: 'Estado del expediente',
      width: '500px',
      data: {
        id: this.case.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadCase(this.case.id as string);
    });
  }

  async editProcessor() {
    let ref = this.dialogService.open(CasesDetailProcessorComponent, {
      header: 'Tramitador',
      width: '700px',
      data: {
        id: this.case.processorId
      }
    });

    ref.onClose.subscribe(async (data: Client) => {
      if (data != null) {
        this.case.processorId = data.id as string;
        await firstValueFrom(this.casesService.save(this.case));
        this.loadCase(this.case.id as string);
      }
    });
  }

  async deleteProcessor() {
    this.case.processorId = "";
    await firstValueFrom(this.casesService.save(this.case));
    this.loadCase(this.case.id as string);
  }

  async editClient() {
    let ref = this.dialogService.open(CasesDetailClientComponent, {
      header: 'Cliente asegurado',
      width: '700px',
      data: {
        id: this.case.clientId
      }
    });

    ref.onClose.subscribe(async (data: Client) => {
      if (data != null) {
        this.case.clientId = data.id as string;
        await firstValueFrom(this.casesService.save(this.case));
        this.loadCase(this.case.id as string);
      }
    });
  }

  async deleteClient() {
    this.case.clientId = "";
    await firstValueFrom(this.casesService.save(this.case));
    this.loadCase(this.case.id as string);
  }

  viewClient() {
    this.router.navigate(['clients', this.case.clientId]);
  }

  async editAffected(affected: Affected | null) {
    let ref = this.dialogService.open(CasesDetailAffectedComponent, {
      header: 'Ficha de afectado',
      width: '80%',
      data: {
        caseId: this.case.id,
        affectedId: affected ? affected.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadAffecteds();
    });
  }

  async deleteAffected(id: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el perjudicado?",
      header: "Eliminar perjudicado",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.affectedsService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Perjudicado eliminado correctamente." });
            this.loadAffecteds();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  async editTransaction(transaction: any) {
    console.log(transaction)
    this.router.navigate(['transactions/' + transaction.transactionType.code + '/' + (transaction && Object.keys(transaction).length > 0 ? transaction.id : 0)]);
  }

  async deleteTransaction(transaction: any) {
    console.log("asdasdasdasd*********************************************************");

    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el transacción " + transaction.code + "?",
        header: "Eliminar transacción",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.transactionsService.delete(transaction.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Transacción eliminada correctamente" });
              this.loadTransactions();
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  async editCaseExpert() {
    let ref = this.dialogService.open(CaseExpertComponent, {
      header: 'Añadir perito',
      width: '80%',
      data: {
        existentCaseExperts: this.caseExperts,
        caseId: this.case.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      if (data) {
        let caseExpert = new CaseExpert();
        caseExpert.caseId = this.case.id as string;
        caseExpert.expertId = data.id;
        this.casesExpertsService.save(caseExpert).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Perito añadido correctamente." });
            this.loadCaseExperts();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  async editCaseTechnical() {
    let ref = this.dialogService.open(CaseTechnicalComponent, {
      header: 'Añadir técnico',
      width: '80%',
      data: {
        existentCaseTechnicals: this.caseTechnicals,
        caseId: this.case.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      if (data) {
        let caseTechnical = new CaseUser();
        caseTechnical.caseId = this.case.id as string;
        caseTechnical.userId = data.id;
        this.caseUsersService.save(caseTechnical).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Técnico añadido correctamente." });
            this.loadCaseTechnicals();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  async deleteCaseExpert(id: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el perito del expediente?",
      header: "Eliminar perito del expediente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesExpertsService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Perito eliminado correctamente del expediente." });
            this.loadCaseExperts();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  async deleteCaseTechnical(id: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el técnico del expediente?",
      header: "Eliminar técnico del expediente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.caseUsersService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Técnico eliminado correctamente del expediente." });
            this.loadCaseTechnicals();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  async editNote(note: CaseNote | null) {
    let ref = this.dialogService.open(CasesDetailNoteComponent, {
      header: 'Ficha de nota',
      width: '700px',
      data: {
        caseId: this.case.id,
        noteId: note ? note.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadNotes();
    });
  }

  async deleteNote(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la nota?",
      header: "Eliminar nota",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesNotesService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Nota eliminada correctamente." });
            this.loadNotes();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  async editService(service: CaseService | null) {
    let ref = this.dialogService.open(CasesDetailServiceComponent, {
      header: 'Ficha de servicio',
      width: '80%',
      contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
      data: {
        caseId: this.case.id,
        serviceId: service ? service.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadServices();
    });
  }

  async deleteService(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio?",
      header: "Eliminar servicio",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesServicesService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio eliminado correctamente." });
            this.loadServices();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  async editCaseService(caseService: CaseService | null) {
    let ref = this.dialogService.open(CaseServiceDetailComponent, {
      header: 'Ficha de servicio',
      width: '80%',
      data: {
        caseId: this.case.id,
        caseServiceId: caseService ? caseService.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadCase(this.case.id!);
    });
  }

  async editJob(job: Job | null) {
    let ref = this.dialogService.open(JobComponent, {
      header: 'Ficha de trabajo',
      width: '500px',
      data: {
        caseId: this.case.id,
        jobId: job ? job.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadJobs();
    });
  }

  async deleteJob(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el trabajo?",
      header: "Eliminar trabajo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.jobsService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Trabajo eliminado correctamente." });
            this.loadJobs();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  jobsByOperator(operatorId: string) {
    return this.caseJobs.filter((job: Job) => job.operatorId == operatorId);
  }


  async editCaseFile(caseFile: CaseFile | null) {
    let ref = this.dialogService.open(CasesDetailFileComponent, {
      header: 'Ficha de archivo',
      width: '800px',
      data: {
        caseId: this.case.id,
        caseFileId: caseFile ? caseFile.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadCaseFiles();
    });
  }

  deleteCaseFile(caseFile: CaseFile) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el archivo?",
      header: "Eliminar archivo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesFilesService.delete(caseFile.id as string).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Archivo eliminado correctamente." });
            this.loadCaseFiles();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  filesByService(serviceId: string) {
    return this.caseFiles.filter((caseFile: CaseFile) => caseFile.caseServiceId == serviceId);
  }

  fileIsAnImage(file: File) {
    return file.contentType.startsWith("image");
  }

  downloadFile(fileId: string) {
    this.filesService.download(fileId);
  }

  async openChat(service: CaseService) {
    let titleParts: string[] = [];
    if (service.case) titleParts.push(service.case.caseNumber);
    let datePipe = new DatePipe('es-ES');
    titleParts.push('Servicio ' + datePipe.transform(service.date, 'dd/MM/yyyy')!);
    if (service.operator) titleParts.push(service.operator.name);
    let title = titleParts.join(" - ");
    this.chatService.openChatCaseService(service, title);
  }

  async loadLogs(){
    this.loadingLogs = true;
    let filters = {
      caseId: new Filter(FilterOperation.equals, 'caseId', "", this.case.id)
    };
    let params: IParamsCollection = {
      sort: ['-date'],
      filter: FilterBuilder.fromObject(filters).build()
    }
    
    let response = await firstValueFrom(this.casesLogsService.all(params));
    this.caseLogs = response.data;
    this.totalNotesRecords = response.meta['total'];
    this.loadingLogs = false;
  }

  addCaseLog(log: CaseLog | null) {
    let ref = this.dialogService.open(CasesDetailLogsComponent, {
      header: 'Ficha del evento',
      width: '60%',
      data: {
        caseId: this.case.id,
        logId: log ? log.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadLogs();
    });
  }



}
