<form *ngIf="caseFormGroup" [formGroup]="caseFormGroup">
    <div class="grid">
        <div class="col-3 input-field">
            <label for="caseNumber">Referencia *</label>
            <input pInputText formControlName="reference" placeholder="(Automático)" d-form-field-error />
        </div>
        <div class="col-3 input-field">
            <label for="caseDate">Fecha apertura *</label>
            <p-calendar formControlName="caseDate" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error></p-calendar>
        </div>
        <div class="col-3 input-field">
            <label for="caseTypeId">Tipo de expediente *</label>
            <p-dropdown formControlName="caseTypeId" [options]="caseTypes" optionLabel="name" optionValue="id" appendTo="body"
                [filter]="true" filterBy="name" placeholder="Selecciona un tipo" d-form-field-error></p-dropdown>
        </div>
        <div class="col-3 input-field">
            <label for="caseDate">Estado *</label>
            <p-dropdown formControlName="caseStateId" [options]="caseStates" optionLabel="name" optionValue="id" appendTo="body"
                [filter]="true" filterBy="name" placeholder="Selecciona un estado" d-form-field-error></p-dropdown>
        </div>
        <div class="col-6 input-field">
            <label for="companyId">Compañía</label>
            <p-dropdown formControlName="companyId" [options]="companies" optionLabel="name" optionValue="id" appendTo="body"
                [filter]="true" filterBy="name" placeholder="Selecciona una compañía" (onChange)="loadContracts()" d-form-field-error></p-dropdown>
        </div>
        <div class="col-3 input-field">
            <label for="contractId">Contrato</label>
            <p-dropdown formControlName="contractId" [options]="contracts" optionLabel="name" optionValue="id" appendTo="body"
                [filter]="true" filterBy="name" placeholder="Selecciona un contrato" d-form-field-error></p-dropdown>
        </div>
        <div class="col-3 input-field">
            <label for="caseNumber">Ref. compañia *</label>
            <input pInputText formControlName="caseNumber" d-form-field-error />
        </div>
        <div class="col-12 input-field">
            <label for="clientId">Asegurado *</label>
            <div class="p-inputgroup">
                <p-dropdown formControlName="clientId" [options]="clients" optionLabel="name" optionValue="id" appendTo="body"
                    [filter]="true" filterBy="name" placeholder="Selecciona un cliente" d-form-field-error></p-dropdown>
                <button [disabled]="case.clientId==null" type="button" pButton icon="pi pi-pencil" (click)="editClient(case.clientId)" styleClass="p-button-warn"></button>
                <button type="button" pButton icon="pi pi-plus" (click)="editClient(null)" styleClass="p-button-warn"></button>
            </div>
        </div>
        <div class="col-12 input-field">
            <label for="description">Descripción</label>
            <textarea pInputTextarea formControlName="description" style="field-sizing: content;"
                d-form-field-error></textarea>
        </div>
        <div class="col-12 input-field">
            <label for="notes">Observaciones</label>
            <textarea pInputTextarea formControlName="notes" style="field-sizing: content;"
                d-form-field-error></textarea>
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>