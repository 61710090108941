import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Processor extends Resource {
    public override type = 'processors';

    @prop()
    @required()
    public name!: string;


}