import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractSlaNotification } from "../_types/contract-sla-notification";

@Injectable({
  providedIn: 'root',
})
export class ContractSlaNotificationsService extends Service<ContractSlaNotification> {
  public override type = 'contractSLAnotificationUsers';
  public override url = 'attendance/contractSLAnotificationUsers';
}
