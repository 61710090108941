import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarsComponent } from './list/calendars-list.component';
import { CalendarsDetailComponent } from './detail/calendars-detail.component';
import { CalendarsRoutingModule } from './calendars-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { CalendarEventModalComponent } from './detail/event-modal/calendar-event-modal.component';


@NgModule({
  declarations: [
    CalendarsComponent,
    CalendarsDetailComponent,
    CalendarEventModalComponent   
  ],
  imports: [
    CalendarsRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    TableModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    CheckboxModule,
    TabViewModule,    
    CalendarModule,
    CoreModule,
    ReactiveFormsModule
  ],
  providers: []
})
export class CalendarsModule { }
