<div class="grid">
    <div class="col-12">
        <div id="case-header" class="card-general-data mb-3">
            <p-card styleClass="p-card-content-pt-0 p-card-content-pb-0">
                <ng-template pTemplate="header">
                    <div class="flex justify-content-center align-items-center pb-0 gap-2">
                        <div class="p-card-title">Datos generales</div>
                        <div class="flex-grow-1"></div>
                        <p-button label="Asignar tramitador" severity="secondary" icon="pi pi-plus"
                            (click)="editProcessor()" />
                        <p-button label="Editar" icon="pi pi-user" (click)="editGeneral()" />
                    </div>
                </ng-template>
                <div class="grid">
                    <div class="col-3">
                        <strong>Referencia</strong>
                        <div class="mt-2">{{case.reference}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Fecha de apertura</strong>
                        <div class="mt-2">{{case.caseDate | date: 'dd/MM/yyyy'}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Tramitador</strong>
                        <div class="mt-2">{{case.processor?.name}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Estado</strong>
                        <div>
                            <span class="p-tag" [style.backgroundColor]="case.caseState?.backgroundColor"
                                [style.color]="case.caseState?.foregroundColor">
                                <span class="p-tag-value">{{case.caseState?.name}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-3">
                        <strong>Ref. compañia</strong>
                        <div class="mt-2">{{case.caseNumber}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Compañía</strong>
                        <div class="mt-2">{{case.company?.name}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Contrato</strong>
                        <div class="mt-2">{{case.contract?.name}}</div>
                    </div>
                    <div class="col-3">
                        <strong>Tipo de expediente</strong>
                        <div class="mt-2">{{case.caseType?.name}}</div>
                    </div>
                    <div class="col-12">
                        <strong>Asegurado</strong>
                        <div class="mt-2 flex gap-4">
                            <span>{{case.client?.name}}</span>
                            <span>{{case.address?.fullAddress}}</span>
                            <a *ngIf="case.client?.phone1" href="tel:{{case.client?.phone1}}" class="p-button-link"><i
                                    class="pi pi-phone"></i> {{case.client?.phone1}}</a>
                            <a *ngIf="case.client?.email" href="mailto:{{case.client?.email}}" class="p-button-link"><i
                                    class="pi pi-envelope"></i>{{case.client?.email}}</a>
                            <!--<p-overlayPanel #op>
                                <div class=" flex flex-column gap-2">
                                    <span>Llamar</span>
                                    <span>Enviar enlace</span>
                                </div>
                            </p-overlayPanel>-->
                        </div>
                    </div>
                    <div class="col-12">
                        <strong>Descripción</strong>
                        <div class="mt-2">{{case.description}}</div>
                    </div>
                    <div class="col-12">
                        <strong>Observaciones</strong>
                        <div class="mt-2">{{case.notes}}</div>
                    </div>
                </div>
            </p-card>
        </div>
        <p-accordion [multiple]="true" (onOpen)="onTabChange($event)">
            <p-accordionTab header="Servicios" [selected]="true">
                <div class="flex justify-content-end mb-2">
                    <!--<p-button *ngIf="allowWrite" label="Añadir servicio" icon="pi pi-plus"
                        (click)="editCaseService(null)" />-->
                    <p-button *ngIf="allowWrite" label="Añadir servicio" styleClass="ml-2" icon="pi pi-plus"
                        (click)="editService(null)" />
                </div>

                <p-table #grid [value]="caseServices" [loading]="loadingServices" [paginator]="true" [rows]="5"
                    [totalRecords]="totalServicesRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="date" width="120">Fecha/Hora <p-sortIcon field="date"></p-sortIcon>
                            </th>
                            <th pSortableColumn="operatorId">Operario <p-sortIcon field="operatorId"></p-sortIcon></th>
                            <th pSortableColumn="isUrgent" width="80">Urgente <p-sortIcon field="isUrgent"></p-sortIcon>
                            </th>
                            <th pSortableColumn="dateStart" width="120">Inicio <p-sortIcon
                                    field="dateStart"></p-sortIcon></th>
                            <th pSortableColumn="dateEnd" width="120">Fin <p-sortIcon field="dateEnd"></p-sortIcon></th>
                            <th pSortableColumn="dateStart" width="90">Estado</th>
                            <th pSortableColumn="dateStart" width="90">Validado</th>
                            <th width="120"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-service let-columns="columns">
                        <tr (dblclick)="editCaseService(service)">
                            <td>{{service.date | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>
                                <div>{{service.operator?.name}}</div>
                                <div>
                                    <a *ngIf="service.operator?.phone1!=null"
                                        (click)="op.toggle($event)">{{service.operator?.phone1}}</a>
                                    <a *ngIf="service.operator?.phone2!=null"
                                        (click)="op.toggle($event)">{{service.operator?.phone2}}</a>
                                    <a *ngIf="service.operator?.mail!=null"
                                        href="mailto:{{service.operator?.mail}}">{{service.operator?.mail}}</a>
                                </div>
                                <p-overlayPanel #op>
                                    <div class=" flex flex-column gap-2">
                                        <span>Llamar</span>
                                        <span>Enviar enlace</span>
                                    </div>
                                </p-overlayPanel>
                            </td>
                            <td>
                                <p-tag *ngIf="!service.isUrgent" severity="info" value="Normal" />
                                <p-tag *ngIf="service.isUrgent" severity="danger" value="Urgente" />
                            </td>
                            <td>{{service.dateStart | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{service.dateEnd | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>
                                <p-tag *ngIf="service.dateStart==null && service.dateEnd==null" severity="warning"
                                    value="Pendiente" />
                                <p-tag *ngIf="service.dateStart!=null && service.dateEnd==null" severity="warning"
                                    value="En curso" />
                                <p-tag *ngIf="service.dateEnd!=null" severity="success" value="Finalizado" />
                            </td>
                            <td>
                                <i class="pi"
                                    [ngClass]="{ 'text-green-500 pi-check-circle': service.isValidated, 'text-red-500 pi-times-circle': !service.isValidated }"></i>
                            </td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowWrite" (click)="editCaseService(service)" [rounded]="true"
                                        [text]="false">
                                        <i class="pi pi-pencil"></i></p-button>
                                    <p-button (click)="openChat(service)" [rounded]="true" [text]="false">
                                        <i class="pi pi-comments"></i>
                                    </p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab header="Afectados">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir afectado" icon="pi pi-plus"
                        (click)="editAffected(null)" />
                </div>
                <p-table #grid [value]="affecteds" [loading]="loadingAffecteds" [paginator]="true" [rows]="5"
                    [totalRecords]="totalAffectedsRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="date">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                            <th pSortableColumn="notes">Dirección <p-sortIcon field="address"></p-sortIcon></th>
                            <th pSortableColumn="notes">Teléfonos <p-sortIcon field="phone1"></p-sortIcon></th>
                            <th pSortableColumn="notes">Correo electronico <p-sortIcon field="email"></p-sortIcon></th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-affected let-columns="columns">
                        <tr (dblclick)="editAffected(affected)">
                            <td>{{affected.name}}</td>
                            <td>{{affected.address?.fullAddress}}</td>
                            <td>
                                <div class="flex gap-2">
                                    <a *ngIf="affected.phone1" href="tel:{{affected.phone1}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{affected.phone1}}</a>
                                    <a *ngIf="affected.phone2" href="tel:{{affected.phone2}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{affected.phone2}}</a>
                                </div>
                            </td>
                            <td>
                                <a *ngIf="affected.email" href="mailto:{{affected.email}}"
                                    class="flex gap-1 align-items-center"><i
                                        class="pi pi-envelope"></i>{{affected.email}}</a>
                            </td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowDelete" (click)="deleteAffected(affected.id)" [rounded]="true"
                                        [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                    <p-button *ngIf="allowWrite" (click)="editAffected(affected)" [rounded]="true"
                                        [text]="false"><i class="pi pi-pencil"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>

            <p-accordionTab header="Peritos">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir perito" icon="pi pi-plus" (click)="editCaseExpert()" />
                </div>
                <p-table #grid [value]="caseExperts" [loading]="loadingExperts" [paginator]="true" [rows]="5"
                    [totalRecords]="totalExpertsRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="expert.name">Nombre <p-sortIcon field="expert.name"></p-sortIcon></th>
                            <th pSortableColumn="expert.address">Dirección <p-sortIcon
                                    field="expert.address"></p-sortIcon></th>
                            <th pSortableColumn="expert.phone1">Teléfonos <p-sortIcon
                                    field="expert.phone1"></p-sortIcon></th>
                            <th pSortableColumn="expert.email">Correo electrónico <p-sortIcon
                                    field="expert.email"></p-sortIcon></th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-caseExpert let-columns="columns">
                        <tr>
                            <td>{{caseExpert.expert.name}}</td>
                            <td>{{caseExpert.expert.address?.fullAddress}}</td>
                            <td>
                                <div class="flex gap-2">
                                    <a *ngIf="caseExpert.expert.phone1" href="tel:{{caseExpert.expert.phone1}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{caseExpert.expert.phone1}}</a>
                                    <a *ngIf="caseExpert.expert.phone2" href="tel:{{caseExpert.expert.phone2}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{caseExpert.expert.phone2}}</a>
                                </div>
                            </td>
                            <td>
                                <a *ngIf="caseExpert.expert.email" href="mailto:{{caseExpert.expert.email}}"
                                    class="flex gap-1 align-items-center"><i
                                        class="pi pi-envelope"></i>{{caseExpert.expert.email}}</a>
                            </td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowDelete" (click)="deleteCaseExpert(caseExpert.id)"
                                        [rounded]="true" [text]="false" severity="danger"><i
                                            class="pi pi-trash"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>

            <p-accordionTab header="Técnicos">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir técnico" icon="pi pi-plus"
                        (click)="editCaseTechnical()" />
                </div>
                <p-table #grid [value]="caseTechnicals" [loading]="loadingTechnicals" [paginator]="true" [rows]="5"
                    [totalRecords]="totalTechnicalsRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="expert.name">Nombre <p-sortIcon field="expert.name"></p-sortIcon></th>
                            <th pSortableColumn="expert.address">Dirección <p-sortIcon
                                    field="expert.address"></p-sortIcon></th>
                            <th pSortableColumn="expert.phone1">Teléfonos <p-sortIcon
                                    field="expert.phone1"></p-sortIcon></th>
                            <th pSortableColumn="expert.email">Correo electrónico <p-sortIcon
                                    field="expert.email"></p-sortIcon></th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-caseTechnical let-columns="columns">
                        <tr>
                            <td>{{caseTechnical.user.name}}</td>
                            <td>{{caseTechnical.user.address?.fullAddress}}</td>
                            <td>
                                <div class="flex gap-2">
                                    <a *ngIf="caseTechnical.user.phone1" href="tel:{{caseTechnical.user.phone1}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{caseTechnical.user.phone1}}</a>
                                    <a *ngIf="caseTechnical.user.phone2" href="tel:{{caseTechnical.user.phone2}}"
                                        class="flex gap-1 align-items-center"><i
                                            class="pi pi-phone"></i>{{caseTechnical.user.phone2}}</a>
                                </div>
                            </td>
                            <td>
                                <a *ngIf="caseTechnical.user.email" href="mailto:{{caseTechnical.user.email}}"
                                    class="flex gap-1 align-items-center"><i
                                        class="pi pi-envelope"></i>{{caseTechnical.user.email}}</a>
                            </td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowDelete" (click)="deleteCaseTechnical(caseTechnical.id)"
                                        [rounded]="true" [text]="false" severity="danger"><i
                                            class="pi pi-trash"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>

            <p-accordionTab header="Eventos">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir nueva comunicacion" icon="pi pi-plus" (click)="addCaseLog(null)" />
                </div>
                <p-table #grid [value]="caseLogs" [loading]="loadingLogs" [paginator]="true" [rows]="15"
                    [totalRecords]="totalLogsRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th width="10">
                            </th>
                            <th pSortableColumn="log.date" width="150">Fecha <p-sortIcon field="log.date"></p-sortIcon></th>
                            <th width="50">Tipo </th>
                            <th>Mensaje </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-caseLogs let-columns="columns">
                        <tr>
                            <td>
                                <p-badge *ngIf="caseLogs.caseLogLevel=='Info'" [value]="" severity="info"/>
                                <p-badge *ngIf="caseLogs.caseLogLevel=='Warning'" [value]="" severity="warning"/>
                                <p-badge *ngIf="caseLogs.caseLogLevel=='Danger'" [value]="" severity="danger"/>                                
                            </td>
                            <td>{{caseLogs.date | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{caseLogs.caseLogType}}</td>
                            <td>{{caseLogs.message}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>

            <p-accordionTab header="Notas">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir nota" icon="pi pi-plus" (click)="editNote(null)" />
                </div>

                <p-table #grid [value]="caseNotes" [loading]="loadingNotes" [paginator]="true" [rows]="5"
                    [totalRecords]="totalNotesRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon>
                            </th>
                            <th pSortableColumn="notes">Notas <p-sortIcon field="notes"></p-sortIcon>
                            </th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-note let-columns="columns">
                        <tr (dblclick)="editNote(note)">
                            <td>{{note.date | date: 'dd/MM/yyyy'}}</td>
                            <td>{{note.notes}}</td>

                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowDelete" (click)="deleteNote(note.id)" [rounded]="true"
                                        [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                    <p-button *ngIf="allowWrite" (click)="editNote(note)" [rounded]="true"
                                        [text]="false"><i class="pi pi-pencil"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <!--<p-accordionTab header="Trabajos">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir trabajo" icon="pi pi-plus" (click)="editJob(null)" />
                </div>

                <p-card *ngFor="let operator of caseJobsOperators" [header]="operator.name">
                    <p-table #grid [value]="jobsByOperator(operator.id)" [scrollable]="true" scrollHeight="flex">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pSortableColumn="rateLineId">Trabajo <p-sortIcon field="rateLineId"></p-sortIcon>
                                </th>
                                <th pSortableColumn="description">Descripción <p-sortIcon
                                        field="description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon>
                                </th>
                                <th pSortableColumn="quantity">Cantidad <p-sortIcon field="quantity"></p-sortIcon>
                                </th>
                                <th pSortableColumn="unitPrice">Precio <p-sortIcon field="unitPrice"></p-sortIcon>
                                </th>
                                <th pSortableColumn="total">Total <p-sortIcon field="total"></p-sortIcon>
                                </th>
                                <th pSortableColumn="isReview">Revisado <p-sortIcon field="isReview"></p-sortIcon>
                                </th>
                                <th width=" 100"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-job let-columns="columns">
                            <tr (dblclick)="editJob(job)">
                                <td>{{job.rateLine?.description}}</td>
                                <td>{{job.description}}</td>
                                <td>{{job.date | date: 'dd/MM/yyyy'}}</td>
                                <td>{{job.quantity }}</td>
                                <td>{{job.unitPrice | currency:'EUR'}}</td>
                                <td>{{job.total | currency:'EUR' }}</td>
                                <td><i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': job.isReview, 'text-red-500 pi-times-circle': !job.isReview}"></i>
                                </td>
                                <td>
                                    <div class="flex gap-2 justify-content-end">
                                        <p-button *ngIf="allowDelete" (click)="deleteJob(job.id)" [rounded]="true"
                                            [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                        <p-button *ngIf="allowWrite" (click)="editJob(job)" [rounded]="true"
                                            [text]="false"><i class="pi pi-pencil"></i></p-button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-card>

            </p-accordionTab>-->
            <p-accordionTab header="Presupuestos">
                <div class="flex justify-content-end mb-2">
                    <p-button *ngIf="allowWrite" label="Añadir presupuesto" icon="pi pi-plus"
                        (click)="editAffected(null)" />
                </div>
                <p-table styleClass="mt-4" #grid [value]="caseTransactions" [lazy]="true"
                    [loading]="loadingTransactions" [paginator]="true" [rows]="25"
                    [totalRecords]="totalTransactionsRecords" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="code">Código <p-sortIcon field="code"></p-sortIcon></th>
                            <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon></th>
                            <th pSortableColumn="transactionState.name">Estado <p-sortIcon
                                    field="transactionState.name"></p-sortIcon></th>
                            <th pSortableColumn="thirdPartyId">Tercero <p-sortIcon field="thirdPartyId"></p-sortIcon>
                            </th>
                            <th pSortableColumn="total">Total <p-sortIcon field="total"></p-sortIcon></th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transaction let-columns="columns">
                        <tr>
                            <td>{{transaction.code}}</td>
                            <td>{{transaction.date | date:'dd/MM/yy'}}</td>
                            <td>{{transaction.transactionState?.name}}</td>
                            <td>{{transaction.thirdParty.name}}</td>
                            <td>{{transaction.total | currency:'EUR'}}</td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowDelete" (click)="deleteTransaction(transaction)"
                                        [rounded]="true" [text]="false" severity="danger"><i
                                            class="pi pi-trash"></i></p-button>
                                    <p-button *ngIf="allowWrite" (click)="editTransaction(transaction)" [rounded]="true"
                                        [text]="false"><i class="pi pi-pencil"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="6">
                                No se ha encontrado transacciones
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab header="Adjuntos">
                <div class="flex justify-content-between mb-2">
                    <p-dropdown [(ngModel)]="caseFileCategoryFilter" [options]="caseFileCategories" optionLabel="name"
                        optionValue="id" appendTo="body" placeholder="Selecciona una categoría a filtrar"
                        (onChange)="onFileCategoryFilterChange()" [showClear]="true"></p-dropdown>
                    <p-button *ngIf="allowWrite" label="Añadir archivo" icon="pi pi-plus"
                        (click)="editCaseFile(null)" />
                </div>
                <p-card header="Sin servicio" *ngIf="caseFilesWithoutService.length > 0">
                    <div class="grid">
                        <div *ngFor="let file of caseFilesWithoutService" class="col-3">
                            <div *ngIf="fileIsAnImage(file.file)"
                                style="border: 1px solid #e9e9e9; padding: 10px; border-radius: 10px;">
                                <img [src]="imagesUrl + file.fileId + '/download'" alt="{{file.file.fileName}}"
                                    class="w-full" />
                                <div class="flex justify-content-between align-items-center mt-1">
                                    <span>{{file.file.fileName}}</span>
                                    <div>
                                        <p-menu #menu [model]="fileActions(file)" [popup]="true" />
                                        <p-button icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"></p-button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!fileIsAnImage(file.file)"
                                style="border: 1px solid #e9e9e9; padding: 10px; border-radius: 10px;">
                                <i class="pi pi-file" style="font-size: 80px;"></i>
                                <div class="flex justify-content-between align-items-center mt-1">
                                    <span>{{file.file.fileName}}</span>
                                    <div>
                                        <p-menu #menu [model]="fileActions(file)" [popup]="true" />
                                        <p-button icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-card>
                <p-card *ngFor="let service of caseFilesServices" [header]="service.name">
                    <div class="grid">
                        <div *ngFor="let file of filesByService(service.id)" class="col-3">
                            <div *ngIf="fileIsAnImage(file.file)"
                                style="border: 1px solid #e9e9e9; padding: 10px; border-radius: 10px;">
                                <img [src]="imagesUrl + file.fileId + '/download'" alt="{{file.file.fileName}}"
                                    class="w-full" />
                                <div class="flex justify-content-between align-items-center mt-1">
                                    <span>{{file.file.fileName}}</span>
                                    <div>
                                        <p-menu #menu [model]="fileActions(file)" [popup]="true" />
                                        <p-button icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"></p-button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!fileIsAnImage(file.file)"
                                style="border: 1px solid #e9e9e9; padding: 10px; border-radius: 10px;">
                                <i class="pi pi-file" style="font-size: 80px;"></i>
                                <div class="flex justify-content-between align-items-center mt-1">
                                    <span>{{file.file.fileName}}</span>
                                    <div>
                                        <p-menu #menu [model]="fileActions(file)" [popup]="true" />
                                        <p-button icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-card>
            </p-accordionTab>
        </p-accordion>
    </div>
    <!--<div class="col-4">
        <p-card class="p-3">
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center p-3 pb-0">
                    <div class="p-card-title">Estado</div>
                    <p-button label="Cambiar estado" severity="secondary" icon="pi pi-pencil" (click)="editState()" />
                </div>
            </ng-template>
            <p-tag severity="warning" value={{case.caseState?.name}} />
        </p-card>
        <p-card class="p-3">
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center p-3 pb-0">
                    <div class="p-card-title">Tramitador</div>
                    <p-button severity="secondary" icon="pi pi-plus" (click)="editProcessor()" />
                </div>
            </ng-template>
            <div *ngIf="case.processorId" class="flex flex-column p-3 cursor-pointer"
                style="border: 1px solid rgb(223, 223, 223); border-radius: 5px;">
                <div class="flex justify-content-between mb-2">
                    <strong style="font-size: 14px;">{{case.processor?.name}}</strong>
                    <p-button icon="pi pi-trash" severity="danger" (click)="deleteProcessor()" />
                </div>
            </div>
            <div *ngIf="case.processorId==null">
                <span>Sin tramitador asignado.</span>
            </div>
        </p-card>
        <p-card class="p-3">
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center p-3 pb-0">
                    <div class="p-card-title">Asegurado</div>
                    <p-button severity="secondary" icon="pi pi-plus" (click)="editClient()" />
                </div>
            </ng-template>
            <div *ngIf="case.clientId" class="flex flex-column gap-2 p-3"
                style="border: 1px solid rgb(223, 223, 223); border-radius: 5px;">
                <div class="flex justify-content-between">
                    <strong style="font-size: 14px;">{{case.client?.name}}</strong>
                    <div class="flex gap-2">
                        <p-button icon=" pi pi-eye" (click)="viewClient()" />
                        <p-button icon="pi pi-trash" severity="danger" (click)="deleteClient()" />
                    </div>
                </div>
                <span>{{case.address.fullAddress}}</span>
                <span (click)="op.toggle($event)" class="cursor-pointer"
                    style="color: blue;">{{case.client?.phone1}}</span>
                <p-overlayPanel #op>
                    <div class=" flex flex-column gap-2">
                        <span>Llamar</span>
                        <span>Enviar enlace</span>
                    </div>
                </p-overlayPanel>
                <span>{{case.client.email}}</span>
            </div>
            <div *ngIf="!case.clientId">
                <span>Sin asegurado asignado.</span>
            </div>
        </p-card>
        <p-card class="p-3">
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center p-3 pb-0">
                    <div class="p-card-title">Perjudicados</div>
                    <p-button severity="secondary" icon="pi pi-plus" (click)="editAffected(null)" />
                </div>
            </ng-template>
            <div *ngIf="affecteds.length > 0">
                <div *ngFor="let affected of affecteds" class="flex flex-column p-3 cursor-pointer mb-2"
                    style="border: 1px solid rgb(223, 223, 223); border-radius: 5px;">
                    <div class="flex justify-content-between">
                        <strong style="font-size: 14px;">{{affected.name}}</strong>
                        <div class="flex gap-2">
                            <p-button icon=" pi pi-pencil" (click)="editAffected(affected)" />
                            <p-button icon="pi pi-trash" severity="danger" (click)="deleteAffected(affected.id)" />
                        </div>
                    </div>
                    <span>{{affected?.address?.fullAddress}}</span>
                    <span (click)="aff.toggle($event)" class="cursor-pointer"
                        style="color: blue;">{{affected.phone1}}</span>
                    <p-overlayPanel #aff>
                        <div class=" flex flex-column gap-2">
                            <span>Llamar</span>
                            <span>Enviar enlace</span>
                        </div>
                    </p-overlayPanel>
                    <span>{{affected.email}}</span>
                </div>
            </div>

            <div *ngIf="affecteds.length <= 0">
                <span>Sin asegurado asignado.</span>
            </div>
        </p-card>
    </div>-->
</div>

<p-confirmDialog></p-confirmDialog>