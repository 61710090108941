import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CountriesService } from 'src/app/modules/common/_services/countries.service';
import { ExpertAddressesService } from 'src/app/modules/experts/_services/experts-addresses.service';
import { ExpertsService } from 'src/app/modules/experts/_services/experts.service';
import { Expert } from 'src/app/modules/experts/_types/expert';

@Component({
  selector: 'app-create-new',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.scss']
})
export class CaseExpertCreateNewComponent {
  public expertFormGroup!: RxFormGroup;

  public expert: Expert = new Expert();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public countries: any = [];

  constructor(
    private expertsService: ExpertsService,
    private expertAddressesService: ExpertAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService,
    private dynamicDialogRef: DynamicDialogRef
  ) {
    this.expertFormGroup = this.formBuilder.formGroup<Expert>(this.expert) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCountries();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.expertFormGroup);
    console.log(this.expertFormGroup);
    if (valid) {
      this.expert = Object.assign(this.expert, this.expertFormGroup.value);

      if (this.expert.address != null) {
        let address: any = await firstValueFrom(this.expertAddressesService.save(this.expert.address));
        this.expert.addressId = address.id;
      }

      let expertItem = await firstValueFrom(this.expertsService.save(this.expert));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Perito guardado correctamente." });

      this.dynamicDialogRef.close(expertItem);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
