import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Case } from "./case";
import { User } from "../../users/_types/user";

export class CaseUser extends Resource {
    public override type = 'caseUsers';

    @prop()
    @required()
    public caseId!: string;

    public case: Case = new Case();

    @prop()
    @required()
    public userId!: string;

    public user: User = new User();
}