import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ClientPolicy } from "../_types/client-policy";

@Injectable({
    providedIn: 'root',
})
export class ClientPoliciesService extends Service<ClientPolicy> {
    public override type = 'clientPolicies';
    public override url = 'attendance/clientPolicies';
}
