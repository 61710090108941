import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Business } from "../_types/business";

@Injectable({
    providedIn: 'root',
})

export class BusinessService extends Service<Business> {
    public override type = 'companies';
    public override url = 'common/companies';
}