import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseState } from "../_types/case-state";

@Injectable({
    providedIn: 'root',
})
export class CasesStatesService extends Service<CaseState> {
    public override type = 'caseStates';
    public override url = 'attendance/caseStates';
}
