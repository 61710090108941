import { Resource } from "src/app/core/jsonapi/resource";
import { Relationship } from "src/app/core/jsonapi/relationship";
import { Role } from "../../roles/_types/role";
import { prop } from "@rxweb/reactive-form-validators";

export class Operator extends Resource {
    public override type = 'opeartors';

    @prop()
    public name!: string;
    @prop()
    public active!: boolean;

    @prop()
    public hasSubmitInvoice: boolean = false;


}