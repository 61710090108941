import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { CompaniesComponent } from './list/companies-list.component';
import { CompaniesDetailComponent } from './detail/companies-detail.component';
import { CompaniesRoutingModule } from './companies-routing.module';
import { ContractsDetailComponent } from './contracts/contracts-detail.component';
import { ContractZonesDetailComponent } from './contracts/zones-detail/zones-detail.component';
import { ContractProcessorsDetailComponent } from './contracts/processors-detail/processors-detail.component';
import { ContractOperatorsDetailComponent } from './contracts/operators-detail/operators-detail.component';
import { ContractCapabilitiesDetailComponent } from './contracts/capabilities-detail/capabilities-detail.component';
import { ContractSlasDetailComponent } from './contracts/slas-detail/slas-detail.component';
import { ContractSlaCapabilitiesComponent } from './contracts/slas-detail/sla-capabilities/sla-capabilities.component';
import { ContractSlaNotificationComponent } from './contracts/slas-detail/sla-notification/sla-notification.component';
import { ContractMailAccountsDetailComponent } from './contracts/mails-detail/mails-detail.component';


@NgModule({
  declarations: [
    CompaniesComponent,
    CompaniesDetailComponent,
    ContractsDetailComponent,
    ContractZonesDetailComponent,
    ContractProcessorsDetailComponent,
    ContractOperatorsDetailComponent,
    ContractCapabilitiesDetailComponent,
    ContractSlasDetailComponent,
    ContractSlaCapabilitiesComponent,
    ContractSlaNotificationComponent,
    ContractMailAccountsDetailComponent
  ],
  imports: [
    CompaniesRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    TableModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    CheckboxModule,
    TabViewModule,
    CalendarModule,
    TagModule,
    InputTextareaModule,
    DropdownModule,
    DialogModule,
    EditorModule,
    ReactiveFormsModule
  ],
  providers: []
})
export class CompaniesModule { }
