import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { UserType } from '../_types/user-type';

@Injectable({
  providedIn: 'root'
})
export class UserTypesService extends Service<UserType> {
  public override type = 'userTypes';
  public override url = 'auth/userTypes';
}
