import { Component, ElementRef, Input, QueryList, resolveForwardRef, ViewChild, ViewChildren } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { environment } from 'src/environment/environment';
import { ChatRoomMessagesService } from '../_services/chat-room-messages.service';
import { ChatRoomsService } from '../_services/chat-rooms.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { ChatRoom } from '../_types/chat-room';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { ChatRoomMessage } from '../_types/chat-room-message';
import { firstValueFrom } from 'rxjs';
import { User } from '../../users/_types/user';
import { LocalStorageService } from 'angular-web-storage';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { ChatService } from '../_services/chat.service';

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss']
})
export class ChatDetailComponent {
  @Input() public code!: string;
  public user!:User;
  public title: string;
  public message:string = "";
  public chatRoom!:ChatRoom;
  public messages:ChatRoomMessage[] = [];
  @ViewChild('chatMessages') public chatMessages!:ElementRef;

  private keepAliveTimeout:any;

  constructor(
    private chatService:ChatService,
    private chatRoomService: ChatRoomsService,
    private chatRoomMessageService: ChatRoomMessagesService,
    private storage:LocalStorageService,
    private mqttService:MqttService
  ) {
    this.title = '';
    this.chatService.onCloseChat.subscribe(() => {
      console.log("close chat");
      clearTimeout(this.keepAliveTimeout);
      this.disconnect();
    });
  }

  async ngOnInit() {
    if(this.code) this.loadChatRoom(this.code);
  }

  async loadChatRoom(code:string, title:string|null=null){
    this.code = code;
    this.title = title??'Chat';
    this.user = this.storage.get('user');
    let filters = {
      code: new Filter(FilterOperation.equals, 'code', "", code)
    };
    let params:IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    let response = await firstValueFrom(this.chatRoomService.all(params));
    console.log(response);
    if(response.data.length>0){
      this.chatRoom = response.data[0];
      if(title!=null && this.chatRoom.title != title){
        //si ha cambiado el titulo, lo actualizamos
        this.chatRoom.title = this.title;
        await firstValueFrom(this.chatRoomService.save(this.chatRoom));
      }
    }else{
      //si no existe, se crea la sala
      let newChatRoom = new ChatRoom();
      newChatRoom.code = code;
      newChatRoom.title = this.title;
      this.chatRoom = await firstValueFrom(this.chatRoomService.save(newChatRoom));
    }
    
    //cargamos mensajes
    if(this.chatRoom!=null){
      this.keepAlive();
      this.loadMessages();
    }

    //subscribimos a topic
    this.mqttService.observe('chat/'+this.chatRoom.id).subscribe((data:IMqttMessage) => {
      let message:ChatRoomMessage = JSON.parse(data.payload.toString());
      console.log(message);
      this.addMessage(message);
    });
  }

  async keepAlive(){
    this.chatRoomService.Keepalive(this.chatRoom).subscribe((response) => {
      console.log(response);
      this.keepAliveTimeout = setTimeout(() => { this.keepAlive(); }, 10 * 1000);
    }, (error) => {
      this.keepAliveTimeout = setTimeout(() => { this.keepAlive(); }, 10 * 1000);
    });
  }

  async disconnect(){
    this.chatRoomService.Disconnect(this.chatRoom).subscribe((response) => {
      console.log(response);
    });
  }

  async removeMe(){
    this.chatRoomService.RemoveMe(this.chatRoom).subscribe((response) => {
      //cerrar el chat
      this.chatService.closeChatRoom();
    });
  }

  async loadMessages(){
    let filters = {
      chatRoomId: new Filter(FilterOperation.equals, 'chatRoomId', "", this.chatRoom.id)
    };
    let params:IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build(),
      include: ['createdByUser']
    };
    let response = await firstValueFrom(this.chatRoomMessageService.all(params));
    this.messages = response.data;
    this.scrollChatMessages();
  }

  async sendMessage(){
    if(this.message=='' || this.message==null) return;
      
    let newMessage = new ChatRoomMessage();
    newMessage.chatRoomId = this.chatRoom.id!;
    newMessage.message = this.message;
    let response = await firstValueFrom(this.chatRoomMessageService.save(newMessage));

    //enviamos al topic
    this.mqttService.publish('chat/'+this.chatRoom.id, JSON.stringify(response)).subscribe({
      next: () => {
      },
      error: (error) => {
        console.error('publish error: ', error);
      }
    });

    //vacia el mensaje
    this.message = "";

    //añadimos el mensaje a la lista
    this.addMessage(response);
  }

  async addMessage(message:ChatRoomMessage){
    //si el mensaje no esta en ventana, lo añadimos
    if(this.messages.find(m => m.id == message.id)==null) this.messages.push(message);
    this.scrollChatMessages();
  }

  async scrollChatMessages(){
    setTimeout(() => {
      this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
    }, 300);
  }

}
