<div class="flex gap-2 cola-4">
    <span class="w-full input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
</div>

<p-table styleClass="mt-4" #grid [value]="clients" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [(selection)]="selectedClient" [paginator]="true" [rows]="25" [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 4rem"></th>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="phone1">Teléfono <p-sortIcon field="phone1"></p-sortIcon></th>
            <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
            <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-client let-columns="columns">
        <tr>
            <td>
                <p-tableRadioButton [value]="client" />
            </td>
            <td>{{client.name}}</td>
            <td>{{client.phone1}}</td>
            <td>{{client.email}}</td>
            <td>
                <i class="pi"
                    [ngClass]="{ 'text-green-500 pi-check-circle': client.active, 'text-red-500 pi-times-circle': !client.active }"></i>
            </td>

        </tr>
    </ng-template>
</p-table>

<div class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
    <p-button label="Guardar" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
</div>