import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Capability } from "../_types/capability";
@Injectable({
    providedIn: 'root',
})

export class CapabilitiesService extends Service<Capability> {
  public override type = 'capabilities';
  public override url = 'common/capabilities'; 
}
