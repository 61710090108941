import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { TopbarService } from './topbar.service';
import { LocalStorageService } from 'angular-web-storage';
import { LoginService } from 'src/app/modules/login/login.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NotificationsService } from 'src/app/modules/notifications/_services/notifications.service';
import { MqttService } from 'ngx-mqtt';
import { Filter, FilterOperation } from '../jsonapi/filter';
import { IParamsCollection } from '../jsonapi/interfaces/params-collection';
import { FilterBuilder } from '../jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { error } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @ViewChild('notificationSidebar') private notificationSidebar!: any;

  public user: any = {};
  public notificationsCount: number = 0;

  constructor(
    public topbarService: TopbarService,
    public sidebarService: SidebarService,
    private storage: LocalStorageService,
    private loginService: LoginService,
    private notificationService: NotificationsService,
    private mqttService: MqttService
  ) {
    this.loginService.onLogin.subscribe((logged) => {
      if(logged) this.loadUserData();
    });
  }

  ngOnInit() {
    this.loadUserData();
    this.loadNotificationsCount();
    
    //escuchamos eventos de notificaciones de mi usuario
    this.notificationService.onNotificationReaded.subscribe(() => {
      this.loadNotificationsCount();
    });

  }

  loadUserData(){
    let user = this.storage.get("user");
    if(user) this.user = this.storage.get("user");
  }

  async loadNotificationsCount(){
    if(this.user!=null){
      let filters = {
        readedAt: new Filter(FilterOperation.equals, 'readedAt', '', null, true),
        web: new Filter(FilterOperation.equals, 'web', '', true),
        userId: new Filter(FilterOperation.equals, 'userId', '', this.user.id)
      };
      let params:IParamsCollection = {
          page: { size: 1 },
          sort: ['createdAt'],
          filter: FilterBuilder.fromObject(filters).build()
      };
      this.notificationService.all(params, true).subscribe((response:any) => {
        if(response){
          this.notificationsCount = response.meta['total'];
        }
        setTimeout(() => { this.loadNotificationsCount(); }, 30 * 1000);
      },(error) => {
        setTimeout(() => { this.loadNotificationsCount(); }, 30 * 1000);
      });
    }else{
      setTimeout(() => { this.loadNotificationsCount(); }, 30 * 1000);
    }
  }

  showNotifications(){
    this.notificationService.openNotifications();
  }
}
