import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkersListComponent } from './workers-list/workers-list.component';
import { WorkersDetailComponent } from './workers-detail/workers-detail.component';

const routes: Routes = [
    {
        path: 'workers',
        children: [
            { path: '', component: WorkersListComponent }
        ]
    },
    {
        path: 'workers/:id',
        children: [
            { path: '', component: WorkersDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WorkersRoutingModule { }
