import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerTimeRange } from "../_types/worker-time-range";

@Injectable({
  providedIn: 'root',
})
export class WorkersTimeRangesService extends Service<WorkerTimeRange> {
  public override type = 'workerTimeRanges';
  public override url = 'providers/workerTimeRanges';
}
