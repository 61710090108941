import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ClientBankAccount } from "../_types/client-bank-account";

@Injectable({
    providedIn: 'root',
})
export class ClientBankAccountsService extends Service<ClientBankAccount> {
    public override type = 'clientBankAccounts';
    public override url = 'attendance/clientBankAccounts';
}