import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplatesListComponent } from './templates-list/templates-list.component';
import { TemplatesDetailComponent } from './templates-detail/templates-detail.component';

const routes: Routes = [
    {
        path: 'templates',
        children: [
            { path: '', component: TemplatesListComponent },
            { path: ':guid', component: TemplatesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TemplatesRoutingModule { }
