import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Template } from "../_types/template";

@Injectable({
    providedIn: 'root',
})
export class TemplatesService extends Service<Template> {
    public override type = 'templates';
    public override url = 'communications/templates';

    templateTypes() {
        let templateTypes = [
            { label: 'Plantilla de correo', value: 'Email' },
            { label: 'Plantilla de SMS', value: 'SMS' }
        ];
        return templateTypes;
    }

    getTemplateTypeLabel(value: string) {
        let templateTypes = this.templateTypes();
        let templateType = templateTypes.find(x => x.value == value);
        return templateType?.label;
    }
}
