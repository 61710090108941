import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { GuardShiftDate } from "../_types/guard-shift-date";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class GuardsShiftsDatesService extends Service<GuardShiftDate> {
  public override type = 'GuardsShiftsDates';
  public override url = 'providers/guardShiftDates';     

  public saveGuardShiftDates(guardShiftId:string, guardShiftDateRange: any): Observable<void> {
    let url = this.getAPIUrl()+"/" + guardShiftId + '/saveGuardShiftDates';    
    return this.http.post<void>(url, guardShiftDateRange);
  }

  public deleteGuardShiftDates(guardShiftId:string, guardShiftDateRange: any): Observable<void> {
    let url = this.getAPIUrl()+"/" + guardShiftId + '/deleteGuardShiftDates';    
    return this.http.post<void>(url, guardShiftDateRange);
  }
}
