<form (ngSubmit)="submitForm()" #transactionForm="ngForm">
    <div class="grid">
        <div class="col-6 input-field">
            <label for="name">Código *</label>
            <input pInputText name="code" [(ngModel)]="transaction.code" d-form-field-error required/>
        </div>
        <div class="col-6 input-field">
            <label for="date">Fecha</label>
            <p-calendar name="date" [(ngModel)]="transaction.date" [showIcon]="true" [showButtonBar]="true" [required]="true"
                [monthNavigator]="true" [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
        </div>        
        <div class="col-12 input-field">
            <label for="description">Concepto</label>
            <textarea name="description" [(ngModel)]="transactionLine.description" [autoResize]="true" pInputTextarea
                rows="4" d-form-field-error required></textarea>
        </div>
    </div>
    <div class="grid">
        <div class="col input-field">
            <label for="price">Base</label>
            <input name="price" [(ngModel)]="transactionLine.price" pInputText type="number" d-form-field-error required
                (keyup)="calculatePrices()">
        </div>
        <div class="col input-field">
            <label for="transactionLine.taxPercent">IVA (%)</label>
            <p-dropdown name="taxPercent" [(ngModel)]="transactionLine.taxPercent" [options]="taxes" optionLabel="name"
                optionValue="percent" appendTo="body" filterBy="name" placeholder="Seleccione opción"
                (onChange)="calculatePrices()" d-form-field-error [required]="true"></p-dropdown>
            <label class="mt-2">
                <!--{{ transactionFormGroup.get('transactionLines[0].taxPercent')?.value | currency:'EUR':'symbol':'1.2-2' }}-->
            </label>
        </div>
        <div class="col input-field">
            <label class="mb-3" for="subtotal">Total</label>
            <span class="mt-2">
                {{ transactionLine.subtotal | currency:'EUR':'symbol':'1.2-2' }}
            </span>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <p-fileUpload mode="basic" chooseIcon="pi pi-upload" (onSelect)="onFileSelect($event)"
                chooseLabel="Seleccionar fichero" />
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
            (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" type="submit" [disabled]="transactionForm.invalid!"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>