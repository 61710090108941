import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { CaseService } from "./case-service";
import { Operator } from "../../companies/_types/operator";
import { RateLine } from "../../rates/_types/rate-line";

export class Job extends Resource {
    public override type = 'jobs';

    @prop()
    @required()
    public caseId!: string;

    @prop()
    @required()
    public caseServiceId!: string;

    public caseService!: CaseService;

    @prop()
    @required()
    public date: Date = new Date();

    @prop()
    public operatorId!: string;

    public operator!: Operator;

    @prop()
    @required()
    public rateLineId!: string;

    public rateLine!: RateLine;

    @prop()
    public code!: string;

    @prop()
    public description!: string;

    @prop()
    public rateLineUnitPrice!: number;

    @prop()
    public unitPrice!: number;

    @prop()
    public quantity!: number;
    
    @prop()
    public taxPercent!: number;

    @prop()
    public taxAmount!: number;

    @prop()
    public total!: number;

    @prop()
    public isReview: boolean = false;

    @prop()
    public isBillable: boolean = false;

    @prop()
    public isForCompany: boolean = false;   
    
    @prop()
    public transactionId!: string;

}