import { Component, ViewChild } from '@angular/core';
import { FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { UsersService } from '../_services/users.service';
import { User } from '../_types/user';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LanguagesService } from '../../common/_services/languages.service';
import { RolesService } from '../../roles/_services/roles.service';
import { UserAuthenticationService } from '../_types/userAuthenticationService';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { UserAuthenticationServicesService } from '../_services/userAuthenticationService.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthenticationServiceComponent } from '../authentication-service/authentication-service.component';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { DDI } from '../_types/ddi';
import { DDIsService } from '../_services/ddis.service';
import { StringUtilsService } from 'src/app/core/services/string-utils.service';
import { UserType } from '../_types/user-type';
import { UserTypesService } from '../_services/users-types.service';


@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent {

  public userFormGroup!: RxFormGroup;

  public user: User = new User();
  public userTypes: UserType[] = [];
  public userTypeSelected: UserType = new UserType();

  public languages: any[] = [];
  public roles: any[] = [];
  public ddis: DDI[] = [];
  public userAuthenticationServices: UserAuthenticationService[] = [];
  public loading: boolean = false;
  public totalRecords: number = 0;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private router: Router,
    private messageService: MessageService,
    public topbarService: TopbarService,
    private confirmationService: ConfirmationService,
    private languagesService: LanguagesService,
    private userTypesService: UserTypesService,
    private rolesService: RolesService,
    private ddisService: DDIsService,
    private usersAuthenticationServicesService: UserAuthenticationServicesService,
    private dialogService: DialogService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private stringUtils: StringUtilsService
  ) {
    this.topbarService.setTitle("Detalles de usuario");
    this.topbarService.addBreadcrumb("Usuarios", "/users");
    this.userFormGroup = this.formBuilder.formGroup<User>(this.user) as RxFormGroup;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id: string | null = params.get('guid');
      if (id != null && id != '0') {
        this.loadUser(id);
      }
      this.loadLanguages();
      this.loadRoles();
      this.loadUserTypes();
      this.loadDDIs();
    });
    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadUser(id: string) {
    this.user = await firstValueFrom(this.usersService.get(id, { include: ['userType'] }));
    this.userFormGroup.patchValue(this.user);

    this.userTypeSelected = this.user.userType;
    this.loadUserAuthenticationServices();
  }

  async loadLanguages() {
    let response = await firstValueFrom(this.languagesService.all());
    this.languages = response.data;
  }

  async loadUserTypes() {
    let response = await firstValueFrom(this.userTypesService.all());
    this.userTypes = response.data;
  }

  async loadRoles() {
    let response = await firstValueFrom(this.rolesService.all());
    this.roles = response.data;
  }

  async loadUserAuthenticationServices() {
    let filters = {
      userId: new Filter(FilterOperation.equals, 'userId', "", this.user.id)
    };


    let params: IParamsCollection = {
      include: ['authenticationService']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.usersAuthenticationServicesService.all(params));
    this.userAuthenticationServices = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadDDIs() {
    let response = await firstValueFrom(this.ddisService.all());
    this.ddis = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.userFormGroup);
    if (valid) {
      this.user = Object.assign(this.user, this.userFormGroup.value);

      //si no tenia id, convertimos el password a md5
      if (this.user.id == null) {
        this.user.password = this.stringUtils.ToMD5(this.user.password);
      }

      await this.usersService.save(this.user);

      this.messageService.add({ closable: false, severity: 'success', detail: "Usuario guardado correctamente." });
    } else {
      this.messageService.add({ closable: false, severity: 'error', detail: "El formulario contiene errores." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el usuario?",
      header: "Eliminar usuario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.usersService.delete(this.user.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Usuario eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  async editAuthService(userAuthenticationService: any) {
    let unselectedAuthServices: any[] = [];
    if (!userAuthenticationService) {
      unselectedAuthServices = this.userAuthenticationServices.map((uas: any) => uas.authenticationServiceId);
    } else {
      unselectedAuthServices = this.userAuthenticationServices.map((uas: any) => uas.authenticationServiceId).filter((x: any) => x != userAuthenticationService.authenticationServiceId);
    }

    let ref = this.dialogService.open(AuthenticationServiceComponent, {
      header: 'Editar servicio de autenticación',
      width: '400px',
      data: {
        userAuthenticationServiceId: userAuthenticationService ? userAuthenticationService.id : null,
        userId: this.user.id,
        authenticationServicesExist: unselectedAuthServices
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadUserAuthenticationServices();
    });
  }

  async deleteAuthService(userAuthenticationService: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio de autenticación?",
      header: "Eliminar usuario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.usersAuthenticationServicesService.delete(userAuthenticationService.id).subscribe({
          next: (data: any) => {
            this.loadUserAuthenticationServices();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio de autenticación eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['/users']);
  }

  changeUserType(event: any) {
    this.userTypeSelected = this.userTypes.find((ut: UserType) => ut.id == event.value) as UserType;
  }

}
