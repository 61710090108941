<div>
    <div class="grid">
        <div class="col-5 input-field">
            <label for="capability">Especialidad</label>
            <p-dropdown [options]="capabilities" [(ngModel)]="selectedCapability" [showClear]="true" [filter]="true"
                filterBy="name" placeholder="Seleccione una especialidad" optionLabel="name" optionValue="id"
                d-form-field-error appendTo="body" (onChange)="loadOperators()"></p-dropdown>
        </div>
        <div class="col-2 input-field">
            <label for="duration">Duración</label>
            <p-dropdown [options]="durationOptions" [(ngModel)]="duration" placeholder="Seleccione una duración"
                d-form-field-error appendTo="body" optionLabel="label" optionValue="value"></p-dropdown>
        </div>
        <div class="col-1 input-field">
            <label for="isUrgent">Urgente</label>
            <p-checkbox [(ngModel)]="service.isUrgent" [binary]="true" d-form-field-error></p-checkbox>
        </div>
        <div class="col-4 input-field">
            <label for=" date">Fecha</label>
            <p-calendar [(ngModel)]="date" [showIcon]="true" [showTime]="false" [hourFormat]="'24'" dateFormat="dd/mm/yy"
                d-form-field-error appendTo="body" (onSelect)="onSelectDate()"></p-calendar>
        </div>
        <div class="col-5 input-field">
            <label for="vehicleType">Tipo de vehículo</label>
            <p-dropdown [options]="vehiclesTypes" [(ngModel)]="selectedVehicleType" [showClear]="true" [filter]="true"
                filterBy="name" placeholder="Seleccione un tipo de vehículo" optionLabel="name" optionValue="id"
                d-form-field-error appendTo="body" (onChange)="loadOperators()"></p-dropdown>
        </div>
        <div class="col-5 input-field">
            <label for="vehicleType">Equipamiento</label>
            <p-dropdown [options]="equipments" [(ngModel)]="selectedEquipment" [showClear]="true" [filter]="true"
                filterBy="name" placeholder="Seleccione un equipamiento" optionLabel="name" optionValue="id"
                d-form-field-error appendTo="body" (onChange)="loadOperators()"></p-dropdown>
        </div>
    </div>
    <p-table styleClass="mt-4" #grid [value]="workers" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>Operador</th>
                <th width="50" *ngFor="let timeSlot of timeSlots">{{timeSlot.label}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-worker let-columns="columns">
            <tr>
                <td>
                    {{worker.name}}<br>
                    <p-rating [(ngModel)]="worker.rating" [readonly]="true" [cancel]="false"/>
                </td>
                <td *ngFor="let timeSlot of timeSlots" style="position: relative;" (click)="selectWorkerTimeSlot(worker, timeSlot)" [class.service-slot-selected]="worker.id==selectedWorker?.id && timeSlot.hour == selectedTimeSlot?.hour && timeSlot.minute == selectedTimeSlot?.minute">
                    <div *ngFor="let caseService of getTimeSlotServices(worker, timeSlot)" class="service-slot">
                        <div>
                            {{caseService.case?.reference}}<br>
                            {{caseService.case?.client?.name}}<br>
                            {{caseService.case?.address?.fullAddress}}
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="mt-4 flex gap-2 justify-content-end">
        <button pButton severity="secondary" type="button" label="Cancelar" (click)="cancel()"></button>
        <button pButton type="button" label="Guardar" (click)="save()" [disabled]="!caseServiceFormGroup.valid"></button>
    </div>
</div>