import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Role } from "../_types/role";
import { Permission } from "../_types/permission";

@Injectable({
    providedIn: 'root',
})

export class PermissionsService extends Service<Permission> {
    public override type = 'permissions';
    public override url = 'auth/permissions';
}