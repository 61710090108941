import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerCapability } from "../_types/worker-capability";
import { WorkerEquipment } from "../_types/worker-equipment";

@Injectable({
    providedIn: 'root',
})
export class WorkerEquipmentsService extends Service<WorkerEquipment> {
    public override type = 'workerEquipments';
    public override url = 'providers/workerEquipments';
}
