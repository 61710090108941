<div class="grid">
    <div class="col-12">
        <p>Desde esta opción puede importar líneas del baremos desde un fichero excel con el formato adecuado.<br>
            Las líneas del baremo serán sustituidas por las que incluya dicho fichero<br>            
            Para descargar un ejemplo de fichero excel pulse <b><a (click)="downloadTemplate()" style="color:var(--color-primary);">aquí</a></b>.</p>
    </div>            
        <p-messages 
            [(value)]="messagesError" 
            [enableService]="false" 
            [closable]="false"
            class="messageError"
            />    
    <div class="col-12">
        <p-fileUpload 
            #fileUploader
            mode="basic" 
            chooseIcon="pi pi-upload"             
            (onSelect)="onFileSelect($event)"
            accept=".xlsx,.xls,.csv"            
            chooseLabel="Seleccionar fichero" />
    </div>
</div>    

<div class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Cerrar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>        
    <p-button label="Importar" icon="pi pi-times" *ngIf="file != null" (click)="importExcel()"></p-button>        
</div>