import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Calendar extends Resource {
    public override type = 'calendars';
    
    @prop()
    @required()
    public name!: string;  

    @prop()
    @required()
    public active: boolean = true;        
    
}