import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class SurveyAnswer extends Resource {
    public override type = 'surveyAnswers';

    @prop()
    public surveyId!: string;

    @prop()
    public date!: Date;

    @prop()
    @required()
    public sourceType: string = 'Anonymous';

    @prop()
    public sourceId!: string;

    @prop()
    @required()
    public targetType: string = 'None';

    @prop()
    public targetId!: string;

}