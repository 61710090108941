<form *ngIf="contractFormGroup" [formGroup]="contractFormGroup">
  <p-card class="p-3" header="Datos generales">
    <div class="grid">
      <div class="col-3 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error />
      </div>
      <div class="col-3 input-field">
        <label for="name">Código *</label>
        <input pInputText name="code" formControlName="code" d-form-field-error />
      </div>
      <div class="col-2 input-field ">
        <label for="startDate">Desde</label>
        <p-calendar inputId="startDate" appendTo="body" dateFormat="dd/mm/yy" formControlName="startDate"
          d-form-field-error />
      </div>
      <div class="col-2 input-field ">
        <label for="endDate">Hasta</label>
        <p-calendar inputId="endDate" appendTo="body" dateFormat="dd/mm/yy" formControlName="endDate"
          d-form-field-error />

      </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
  </p-card>
  <p-tabView>
    <p-tabPanel header="Configuración">
      <p-card class="p-3" header="Expedientes">
        <div class="grid">
          <div class="col-3 input-field">
            <label for="name">Prefijo</label>
            <input pInputText name="case_prefix" formControlName="case_prefix" d-form-field-error />
          </div>
        </div>
      </p-card>
      <p-card class="p-3 mt-4" header="Facturación">
        <div class="grid">
          <div class="col input-field">
            <label for="name">Sociedad *</label>
            <p-dropdown name="billingCompanyId" [options]="billingCompanies" optionLabel="name" optionValue="id"
              [filter]="true" filterBy="name" placeholder="Seleccione sociedad" appendTo="body"
              (onChange)="onChangeCompany($event)" formControlName="billingCompanyId" d-form-field-error />
          </div>
          <div class="col input-field">
            <label for="name">Cuenta bancaria</label>
            <p-dropdown name="billingCompanyBankAccountId" [options]="billingCompaniesBanksAccounts"
              optionLabel="accountNumber" optionValue="id" [filter]="true" filterBy="accountNumber"
              placeholder="Seleccione cuenta bancaria" appendTo="body" formControlName="billingCompanyBankAccountId"
              d-form-field-error />
          </div>
          <div class="col input-field">
            <label for="name">Forma de pago</label>
            <p-dropdown name="paymentMethodId" [options]="paymentsMethods" optionLabel="name" optionValue="id"
              [filter]="true" filterBy="name" placeholder="Seleccione forma de pago" appendTo="body"
              formControlName="paymentMethodId" d-form-field-error />
          </div>
        </div>
      </p-card>
    </p-tabPanel>
    <p-tabPanel header="Especialidades" *ngIf="contract.id">
      <div class="flex justify-content-end">
        <p-button label="Añadir servicio" *ngIf="allowWrite" icon="pi pi-plus"
          (click)="editContractCapability(null)"></p-button>
      </div>
      <p-table styleClass="mt-4" #grid [value]="contractCapabilities" [paginator]="true" [rows]="25"
        [totalRecords]="totalCapabilitiesRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
        [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractCapability let-columns="columns">
          <tr (dblclick)="editContractCapability(contractCapability)">
            <td>{{contractCapability.capability.name}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractCapability(contractCapability)" [rounded]="true"
                  [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractCapability(contractCapability)" [rounded]="true"
                  [text]="false"><i class="pi pi-pencil"></i></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Zonas" *ngIf="contract.id">
      <div class="flex justify-content-end pb-1">
        <p-button label="Nueva zona" (click)="editContractZone({})" styleClass="p-button-sm"
          *ngIf="allowWrite"></p-button>
      </div>
      <p-table #grid styleClass="m-1 mt-4" [value]="contractZones" [loading]="loading" [paginator]="false"
        [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractZone let-columns="columns">
          <tr (dblclick)="editContractZone(contractZone)">
            <td>{{contractZone.name}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractZone(contractZone)"
                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractZone(contractZone)" styleClass="p-button-xs"><i
                    class="pi pi-pencil"></i></p-button>
              </div>  
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              No se han encontrado zonas
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Tramitadores" *ngIf="contract.id">
      <div class="flex justify-content-end pb-1">
        <p-button label="Nuevo tramitador" (click)="editContractProcessor({})" styleClass="p-button-sm"
          *ngIf="allowWrite"></p-button>
      </div>
      <p-table #grid styleClass="m-1 mt-4" [value]="contractProcessors" [loading]="loading" [paginator]="false"
        [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Usuario</th>
            <th>% Carga</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractProcessor let-columns="columns">
          <tr (dblclick)="editContractProcessor(contractProcessor)">
            <td>{{contractProcessor.processor.name}}</td>
            <td>{{contractProcessor.maxLoadChargePercent}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractProcessor(contractProcessor)"
                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractProcessor(contractProcessor)"
                  styleClass="p-button-xs"><i class="pi pi-pencil"></i></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              No se han encontrado tramitadores
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Operarios preferentes" *ngIf="contract.id">
      <div class="flex justify-content-end pb-1">
        <p-button label="Nuevo operario" (click)="editContractOperator({})" styleClass="p-button-sm"
          *ngIf="allowWrite"></p-button>
      </div>
      <p-table #grid styleClass="m-1 mt-4" [value]="contractOperators" [loading]="loading" [paginator]="false"
        [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Operador</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractOperator let-columns="columns">
          <tr (dblclick)="editContractOperator(contractOperator)">
            <td>{{contractOperator.operator.name}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractOperator(contractOperator)"
                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractOperator(contractOperator)" styleClass="p-button-xs"><i
                    class="pi pi-pencil"></i></p-button>
              </div>  
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              No se han encontrado operarios preferentes
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="SLAs" *ngIf="contract.id">
      <div class="flex justify-content-end pb-1">
        <p-button label="Nuevo SLAs" (click)="editContractSla(null)" styleClass="p-button-sm"
          *ngIf="allowWrite"></p-button>
      </div>
      <p-table #grid styleClass="m-1 mt-4" [value]="contractSlas" [loading]="loading" [paginator]="false"
        [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Tipo de evento</th>
            <th>Horas</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractSla let-columns="columns">
          <tr (dblclick)="editContractSla(contractSla)">
            <td>{{contractSla.caseEventType?.name}}</td>
            <td>{{contractSla.hours}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractSla(contractSla)" [rounded]="true" [text]="false"
                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractSla(contractSla)" [rounded]="true" [text]="false"
                  styleClass="p-button-xs"><i class="pi pi-pencil"></i></p-button>
              </div>  
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              No se han encontrado resultados
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Cuentas de correo" *ngIf="contract.id">
      <div class="flex justify-content-end pb-1">
        <p-button label="Nuevo correo" (click)="editContractMailAccount(null)" styleClass="p-button-sm"
          *ngIf="allowWrite"></p-button>
      </div>
      <p-table #grid styleClass="m-1 mt-4" [value]="contractMailAccounts" [loading]="loading" [paginator]="false"
        [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Nombre</th>
            <th>Nombre de usuario</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractMailAccount let-columns="columns">
          <tr (dblclick)="editContractMailAccount(contractMailAccount)">
            <td>{{contractMailAccount.name}}</td>
            <td>{{contractMailAccount.username}}</td>
            <td>
              <div class="flex gap-2 justify-content-end">
                <p-button *ngIf="allowDelete" (click)="deleteContractMailAccount(contractMailAccount)" [rounded]="true"
                  [text]="false" styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                <p-button *ngIf="allowWrite" (click)="editContractMailAccount(contractMailAccount)" [rounded]="true"
                  [text]="false" styleClass="p-button-xs"><i class="pi pi-pencil"></i></p-button>
              </div>  
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              No se han encontrado resultados
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>

</form>
<p-confirmDialog></p-confirmDialog>