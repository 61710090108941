import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Nullable } from 'primeng/ts-helpers';
import { CalendarsEventsService } from 'src/app/modules/calendars/_services/calendars-events.service';
import { CalendarEvent } from 'src/app/modules/calendars/_types/calendar-event';
import { GuardShiftDate } from 'src/app/modules/guards-shifts/_types/guard-shift-date';
import { WorkerHoliday } from 'src/app/modules/workers/_types/worker-holiday';


@Component({
  selector: 'app-calendar-year',
  templateUrl: './calendar-year.component.html', 
  styleUrls: ['./calendar-year.component.scss']
})
export class CalendarYearComponent implements OnInit {  

  @Output()
  public onYearChange = new EventEmitter<any>();

  @Output()
  public onDateChange = new EventEmitter<any>();

  @Output()
  public onDayClick = new EventEmitter<any>(); 

  @Output()
  public onDateRangeSelect = new EventEmitter<any>(); 

  @Input()
  public events: CalendarEvent[] = [];

  @Input()
  public holidays: WorkerHoliday[] = [];

  @Input()
  public guardShiftDates: GuardShiftDate[] = [];

  @Input()
  public color!: string;

  public isEventsLoaded: boolean = false;

  /*@Input()
  public dateInput: Date;*/

  public monthLetters: any[] = [
    { month: 0, letter: 'Enero'},
    { month: 1, letter: 'Febrero'},
    { month: 2, letter: 'Marzo'},
    { month: 3, letter: 'Abril'},
    { month: 4, letter: 'Mayo'},
    { month: 5, letter: 'Junio'},
    { month: 6, letter: 'Julio'},
    { month: 7, letter: 'Agosto'},
    { month: 8, letter: 'Septiembre'},
    { month: 9, letter: 'Octubre'},
    { month: 10, letter: 'Noviembre'},
    { month: 11, letter: 'Diciembre'}
  ];

  public dayLetters: any[] = [
    { day: 1, letter: 'LUN', position: 1 },
    { day: 2, letter: 'MAR', position: 2 },
    { day: 3, letter: 'MIE', position: 3 },
    { day: 4, letter: 'JUE', position: 4 },
    { day: 5, letter: 'VIE', position: 5 },
    { day: 6, letter: 'SÁB', position: 6 },
    { day: 0, letter: 'DOM', position: 7 }
  ];
  public dayColumns: any[] = [];
  public start_date: Date = moment(new Date()).startOf('day').toDate();
  public end_date: Nullable<Date> = null;
  public selectedEvent: CalendarEvent = new CalendarEvent();  
  public overlayVisible: boolean = false;  

  private click_start_date!:any;
  private click_end_date!:any;

  constructor(
    private calendarsEventsService: CalendarsEventsService,
    private messageService: MessageService
  ) {    
  }

  ngOnInit(): void {        
    let newDate = moment(new Date()).date(1).startOf('day').toDate();
    /*if (this.dateInput != null) {
      this.selected_date = moment(this.dateInput).startOf('day').toDate();
      newDate = moment(this.dateInput).date(1).startOf('day').toDate();      
    }*/
    
    this.yearChange(newDate);    
  }


  changeYear(skip: number) {
    let newDate = moment(this.start_date).add(skip, 'year').date(1).startOf('year').toDate();
    
    this.yearChange(newDate);
  }

  yearChange(date: Date) {    
    this.start_date = date;
    
    let myMoment: moment.Moment = moment(this.start_date);    
    this.end_date = myMoment.endOf('year').toDate();    
    
    this.onYearChange.emit({ first_date: moment(this.start_date), end_date: moment(this.end_date) });
    //this.generateDays();
  }  

  getDayLetter(dayOfWeek: number) {
    let dayLetter = this.dayLetters.find(m => m.day == dayOfWeek);
    return dayLetter.letter;
  }

  generateDays(month: number, year: number) {

    //generamos columnas vacias
    this.dayColumns = this.dayLetters.map(m => { return { day: m, dates: [] }; });

    //relenamos con huecos hasta primer dia
    let start_date = moment().set('month',month).set('year', year).startOf('month');
    let end_date = moment().set('month', month).set('year', year).endOf('month');
    let start_day = start_date.day();
    if(start_day==0) start_day = 7;
    for (let i = 1; i < start_day; i++) {
      this.dayColumns[i - 1].dates.push({ number: " ", date: null, events: [], holidays: [], guardShiftDates: [] });
    }
    
    //asignamos fechas a dias
    while (start_date <= end_date!) {      
      let events = this.events.filter(m => moment(m.date).toDate().toISOString()==start_date.toDate().toISOString());
      let holidays = this.holidays.filter(m => moment(m.date).toDate().toISOString()==start_date.toDate().toISOString());        
      let guardShiftDates = this.guardShiftDates.filter(m => moment(m.date).toDate().toISOString()==start_date.toDate().toISOString());                          
      let date = moment(start_date.toDate());
      let day = {
        date: date,
        letter: this.getDayLetter(date.day()),
        number: date.date(),
        events: events,                        
        holidays: holidays,
        guardShiftDates: guardShiftDates,
        tooltip: events.map(m => m.description).join('\n')
      };          
      var dayLetterIdx = this.dayLetters.findIndex(m => m.day == date.day());
      this.dayColumns[dayLetterIdx].dates.push(day);
      start_date = start_date.add(1, 'day');
    }    
    return this.dayColumns;

  }

  mouseDown(date:any){
    this.click_start_date = date.date;
    this.click_end_date = date.date;
    //console.log("start", date);
  }

  mouseMove(date:any){
    this.click_end_date = date.date;
    //console.log("move", date);
  }

  mouseUp(date:any){
    this.click_end_date = date.date;
    //console.log("end", date);
    console.log(this.click_start_date,this.click_end_date)
    if(this.click_start_date.isSame(this.click_end_date)){
      console.log("asdasd");
      this.onDayClick.emit(date);
    }else{
      this.onDateRangeSelect.emit({ start: this.click_start_date, end: this.click_end_date });
    }
    this.click_start_date = null;
    this.click_end_date = null;
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp() {
    setTimeout(()=>{
      this.click_start_date = null;
      this.click_end_date = null;
    }, 300);
  }

  isInRange(date:any){
    if(date.date==null || this.click_start_date==null || this.click_end_date==null) return false;
    let in_range = date.date.isBetween(this.click_start_date, this.click_end_date) || date.date.isSame(this.click_start_date) || date.date.isSame(this.click_end_date);
    return in_range;
  }
  

}
