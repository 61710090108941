    
        <div class="grid">
            <div class="col-8 input-field">
                <label for="capabilityId">Servicios de expediente</label>
                <p-dropdown [(ngModel)]="selectedCaseService" [ngModelOptions]="{standalone:true}" [options]="caseServices"
                    (onChange)="loadJobs()" optionLabel="customName" optionValue="id" [filter]="true" filterBy="customName"
                    appendTo="body" placeholder="Selecciona un servicio" d-form-field-error></p-dropdown>
            </div>
        </div>        
        <p-table [value]="jobs"
            [(selection)]="selectedLines"
            dataKey="id" >
            <ng-template pTemplate="header">
                <tr>
                    <th>Seleccionar</th>  
                    <th>Fecha</th>
                    <th>Baremo</th>
                    <th>Revisado</th>
                    <th>Facturable</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>Total</th>
                    <th width="120"></th>
                </tr>
            </ng-template>
            <!-- Añadir mensaje cuando esté vacío -->
            <ng-template pTemplate="body" let-job>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="job"></p-tableCheckbox>
                    </td>
                    <td>
                        {{job.date | date: 'dd/MM/YY HH:mm'}}
                    </td>  
                    <td>
                        {{job.rateLine.code}} - {{job.rateLine.description}}
                    </td>   
                    <td>
                        <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': job.isReview, 'text-red-500 pi-times-circle': !job.isReview}"></i>
                    </td>
                    <td>
                        <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': job.isBillable, 'text-red-500 pi-times-circle': !job.isBillable}"></i>
                    </td>
                    <td>
                        {{job.quantity}}
                    </td>
                    <td>
                        {{job.unitPrice | currency: 'EUR'}}
                    </td>
                    <td>
                        {{job.total | currency: 'EUR'}}
                    </td>                                           
                </tr>
            </ng-template>            
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        No se han encontrado trabajos registrados
                    </td>
                </tr>
            </ng-template>
        </p-table>
    <div class=" mt-4 flex gap-2 justify-content-end">        
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
<p-confirmDialog></p-confirmDialog>