import { Component, ViewEncapsulation } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { Transaction } from '../_types/transaction';
import { TransactionsService } from '../_services/transactions.service';
import { TransactionState } from '../_types/transaction-state';
import { TransactionsStatesService } from '../_services/transactions-states.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { TransactionType } from '../_types/transaction-type';
import { TransactionsTypesService } from '../_services/transactions-types.service';
import { TransactionLine } from '../_types/transaction-line';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Rate } from '../../rates/_types/rate';
import { TransactionsLinesService } from '../_services/transactions-lines.service';
import { Case } from '../../cases/_types/case';
import { CasesService } from '../../cases/_services/cases.service';
import { ThirdParty } from '../_types/third-party';
import { ThirdPartiesService } from '../_services/third-parties.service';
import { Tax } from '../_types/tax';
import { TaxesService } from '../_services/taxes.service';
import { File } from '../../common/_types/file'
import { FilesService } from '../../common/_services/files.service';

@Component({
  selector: 'app-transactions-detail-summary',
  templateUrl: './transactions-detail-summary.component.html',
  styleUrls: ['./transactions-detail-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsDetailSummaryComponent {
  public transactionFormGroup!: RxFormGroup;

  public transaction: Transaction = new Transaction();
  public transactionLine: TransactionLine = new TransactionLine();

  public totalTransactionLines!: number;

  public transactionStates: TransactionState[] = [];
  public thirdParties: ThirdParty[] = [];
  public transactionType: TransactionType = new TransactionType();
  public thirdPartyType: string = "";
  public thirdPartyId!: string;
  public caseServiceId!: string;
  public caseId!: string;
  public loadingTaxes: boolean = false;

  public taxes: Tax[] = [];

  public cases: any;

  public type!: string;
  public typeSelected: any = {};

  public loadingTransactionLines: boolean = false;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public files: any[] = [];

  formData = {
    code: '',
    date: '',
    transactionStateId: '',
    description: '',
    price: '',
    taxPercent: ''
  };

  constructor(
    private transactionsService: TransactionsService,
    private transactionsStatesService: TransactionsStatesService,
    private transactionsTypesService: TransactionsTypesService,
    private transactionsLinesService: TransactionsLinesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private casesService: CasesService,
    private thirdPartiesService: ThirdPartiesService,
    private taxesService: TaxesService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private filesService: FilesService
  ) {

    this.transactionFormGroup = this.formBuilder.formGroup<Transaction>(this.transaction) as RxFormGroup;
  }

  async ngOnInit() {
    console.log(this.config.data);
    let id = this.config.data.id;
    this.transactionType = this.config.data.transactionType;
    this.transaction.thirdPartyType = this.config.data.thirdPartyType;
    this.transaction.thirdPartyId = this.config.data.thirdPartyId;
    this.caseServiceId = this.config.data.caseServiceId;
    this.caseId = this.config.data.caseId;

    this.loadTaxes();
    await this.loadTransactionType();
    await this.loadTransactionStates();
    await this.loadCases();
    if (id != null && id != '0') {
      await this.loadTransaction(id);
      await this.loadTransactionLines();
    } else {
      this.transaction.transactionTypeId = this.transactionType.id!;
      this.transaction.thirdPartyType = this.transactionType.thirdPartyType;
      this.transaction.date = new Date();
      this.transaction.caseId = this.caseId;      
      //Obtenemos estado inicial
      await this.loadTransactionType();

      this.transactionLine.lineNumber = 1;
      this.transactionLine.quantity = 1;
      this.transactionLine.caseServiceId = this.caseServiceId;
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadTransaction(id: string) {
    this.transaction = await firstValueFrom(this.transactionsService.get(id));
    this.transactionFormGroup.patchValue(this.transaction);
  }

  async loadTransactionLines() {
    this.loadingTransactionLines = true;
    let params: IParamsCollection = {
      sort: ["lineNumber"],
      include: ["rateLine"]
    }
    let filters: any = {
      transactionId: new Filter(FilterOperation.equals, "transactionId"),
    };

    filters.transactionId.value = this.transaction.id;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.transactionsLinesService.all(params));
    this.transactionLine = response.data[0];
    console.log(this.transactionLine);
    this.totalTransactionLines = response.meta['total'];
    this.loadingTransactionLines = false;
  }

  async loadTransactionType() {

    let params: IParamsCollection = {
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject({ code: new Filter(FilterOperation.equals, 'code', '', this.type) }).build();

    let response = await firstValueFrom(this.transactionsTypesService.all(params));
    this.transactionType = response.data[0];

    console.log(this.transactionType);

    await this.loadTransactionStates();
  }


  async loadTransactionStates() {

    let params: IParamsCollection = {
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject({
      transactionTypeId: new Filter(FilterOperation.equals, 'transactionTypeId', '', this.transactionType.id),
      isInitial: new Filter(FilterOperation.equals, 'isInitial', '', true)
    }).build();
    let response = await firstValueFrom(this.transactionsStatesService.all(params));
    this.transaction.transactionStateId = response.data[0].id!;

  }

  async loadThirdParties() {
    let params: IParamsCollection = {
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject({ thirdPartyType: new Filter(FilterOperation.equals, 'thirdPartyType', '', this.transactionType.thirdPartyType) }).build();
    let response = await firstValueFrom(this.thirdPartiesService.all(params));
    this.thirdParties = response.data;
  }

  async loadCases() {

    let params: IParamsCollection = {
    }

    let response = await firstValueFrom(this.casesService.all(params));
    this.cases = response.data;

    this.cases.forEach((option: any) => {
      option.customName = (option.reference ?? "") + " - " + option.description;
    });
  }



  async submitForm() {

    if (this.files.length > 0) {
      try {
        
          let data: File = new File();
          data.fileName = this.files[0].name;
          data.size = this.files[0].size;
          data.contentType = this.files[0].type;
          //data.path = file.name;

          //creamos el file
          let fileResponse = await firstValueFrom(this.filesService.save(data));

          //subimos el fichero al file
          let response = await firstValueFrom(this.filesService.upload(fileResponse.id as string, this.files[0]));

          //guardamos el file en el expediente
          this.transaction.fileId = fileResponse.id!;
          
          
          let transaction = await firstValueFrom(this.transactionsService.save(this.transaction));
          this.transactionLine.transactionId = transaction.id!;

          let transactionLine = await firstValueFrom(this.transactionsLinesService.save(this.transactionLine));          

          this.dynamicDialogRef.close();        
      } catch (error) {
        this.messageService.add({ closable: false, severity: 'severity', summary: "Error", detail: "Error al guardar los ficheros." });
      }

      this.messageService.add({ closable: false, severity: 'success', summary: "Transacción guardada correctamente." });
      
    } else {
      this.messageService.add({ closable: false, severity: 'error', detail: "Debes adjuntar un fichero" });
    }


  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la transacción " + this.transaction.code + "?",
      header: "Eliminar transacción",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.transactionsService.delete(this.transaction.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Transacción eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }


  async loadTaxes() {
    this.loadingTaxes = true;
    let params: IParamsCollection = {
    }

    let response = await firstValueFrom(this.taxesService.all(params));
    this.taxes = response.data;

    this.loadingTaxes = false;
  }

  calculatePrices() {

    this.transactionLine.taxAmount = this.transactionLine.price * (this.transactionLine.taxPercent / 100);

    this.transactionLine.subtotal = this.transactionLine.price + this.transactionLine.taxAmount;

  }


  async onFileSelect(event: any) {
    let files = event.files;
    for (let file of files) {
      this.files.push(file);
    }
  }

}
