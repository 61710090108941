<div class="flex gap-2 cola-4">
    <span class="w-full input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
</div>

<p-table styleClass="mt-4" #grid [value]="capabilities" [lazy]="true" (onLazyLoad)="loadData($event)"
    [loading]="loading" [(selection)]="selectedCapabilities" dataKey="id" [paginator]="true" [rows]="25"
    [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-capability let-columns="columns">
        <tr>
            <td>
                <p-tableCheckbox [value]="capability" />
            </td>
            <td>{{capability.name}}</td>
        </tr>
    </ng-template>
</p-table>
<div class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
    <p-button label="Guardar" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
</div>