import { Injectable } from "@angular/core";
import { Provider } from "../_types/provider";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class ProvidersService extends Service<Provider> {
  public override type = 'providers';
  public override url = 'providers/providers'; 

  public updateCapabilities(providerId:string, capabilitiesIds: Array<string>): Observable<void> {
    let url = this.getAPIUrl()+"/" + providerId + '/capabilities';
    console.log(url);
    return this.http.post<void>(url, capabilitiesIds);
  }
}
