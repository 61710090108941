import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Task } from "../_types/task";

@Injectable({
    providedIn: 'root',
})
export class TasksService extends Service<Task> {
    public override type = 'tasks';
    public override url = 'attendance/tasks';
}
