import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractSlaCapability } from "../_types/contract-sla-capability";

@Injectable({
  providedIn: 'root',
})
export class ContractSlaCapabilitiesService extends Service<ContractSlaCapability> {
  public override type = 'contractSlaCapabilities';
  public override url = 'attendance/contractSlaCapabilities';
}
