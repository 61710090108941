import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Contract extends Resource {
    public override type = 'contracts';

    @prop()
    public companyId!: string;
    @prop()
    @required()
    public name!: string;
    @prop()
    public code!: string;
    @prop()
    @required()
    public startDate!: Date;
    @prop()
    public endDate!: Date;
    @prop()
    public case_prefix!: string;
    @prop()
    @required()
    public billingCompanyId!: string;
    @prop()
    public billingCompanyBankAccountId!: string;
    @prop()
    public paymentMethodId!: string;
}