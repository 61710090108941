import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractZoneLine } from "../_types/contract-zone-line";

@Injectable({
    providedIn: 'root',
})
export class ContractZonesLinesService extends Service<ContractZoneLine> {
  public override type = 'contractZoneLines';  
  public override url = 'attendance/contractZoneLines'; 
}
