<form *ngIf="workerVehicleFormGroup" [formGroup]="workerVehicleFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="capabilityId">Vehículo *</label>
            <p-dropdown [options]="vehicles" formControlName="vehicleId" [required]="true" optionValue="id"
                [filter]="true" filterBy="name" optionLabel="customName" placeholder="Seleccione vehículo" appendTo="body"
                d-form-field-error />
        </div>    
        <div class="col-6 input-field ">
            <label for="startDate">Desde *</label>
            <p-calendar 
            inputId="fromDate"         
            appendTo="body"
            dateFormat="dd/mm/yy"
            formControlName="fromDate" d-form-field-error/>        
          </div>
          <div class="col-6 input-field ">
            <label for="toDate">Hasta *</label>
            <p-calendar 
            inputId="toDate"         
            appendTo="body"
            dateFormat="dd/mm/yy"
            formControlName="toDate" d-form-field-error/>
            
          </div>
    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete && workerVehicle.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
            (click)="save()"></p-button>
    </div>

</form>