import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Case } from "./case";
import { Operator } from "../../companies/_types/operator";
import { Capability } from "../../common/_types/capability";

export class CaseService extends Resource {
    public override type = 'caseServices';

    @prop()
    @required()
    public caseId!: string;

    public case: Case = new Case();

    @prop()
    @required()
    public date = new Date();

    @prop()
    public dateStart!: Date;

    @prop()
    public dateEnd!: Date;

    @prop()
    @required()
    public isConfirmed: boolean = false;

    @prop()
    @required()
    public isUrgent: boolean = false;

    @prop()
    @required()
    public isValidated: boolean = false;

    @prop()
    @required()
    public capabilityId!: string;

    public capability: Capability = new Capability();

    @prop()
    @required()
    public operatorId!: string;

    public operator: Operator = new Operator();

    @prop()
    public notes!: string;

    @prop()
    public pendingTasksDescription!: string;

    @prop()
    public realizedTasksDescription!: string;


}