
<form *ngIf="zoneFormGroup" [formGroup]="zoneFormGroup">
  <div class="card mt-2 ">

    <div class="grid">
      <div class="col-12">
        <!--<div class="flex flex-wrap gap-2">-->
        <div class="input-field">
          <label for="name">Nombre *</label>
          <input pInputText id="name" formControlName="name" d-form-field-error/>
        </div>
        <!--</div>-->
      </div>
    </div>
    <div class="mt-4 grid" *ngIf="contractZone.id">
      <h3 class="col-4 flex align-items-center justify-content-start m-0">Líneas</h3>
      <div class="col-4 col-offset-4 text-align flex flex-row-reverse flex-wrap">
        <p-button label="Nueva línea" (click)="editContractZoneLine({})" styleClass="p-button-sm"  *ngIf="allowWrite"></p-button>
      </div>                
      <p-table 
        [value]="contractZoneLines" dataKey="id" [tableStyle]="{ 'min-width': '100%' }">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                      Código postal
                    </th> 
                    <th>

                    </th>                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contractZoneLine>
                <tr (dblclick)="editContractZoneLine(contractZoneLine)">
                    <td>
                        {{contractZoneLine.value}}                            
                    </td>        
                    <td>
                      <div class="flex gap-2 justify-content-end">
                        <p-button *ngIf="allowWrite" (click)="editContractZoneLine(contractZoneLine)" styleClass="p-button-xs"><i class="pi pi-pencil"></i></p-button>
                        <p-button *ngIf="allowDelete" (click)="deleteContractZoneLine(contractZoneLine)" styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>                      
                      </div>
                    </td>            
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="2">        
                  No se han encontrado contratos
                </td>
              </tr>
              </ng-template>
    </p-table>    
    </div>
    <div class="mt-6 flex gap-2 justify-content-end ">
      <p-button label="Eliminar" *ngIf="contractZone.id&&allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>


  </div>
  </form>

  <p-dialog header="Nueva línea" [modal]="true" [(visible)]="visibleContractZoneLine" [style]="{ width: '25rem' }">    
    <form *ngIf="zoneLineFormGroup" [formGroup]="zoneLineFormGroup">
      <div class="flex align-items-center gap-3 mb-3">
          <label for="value" class="font-semibold">Valor</label>
          <input pInputText id="value" name="value" class="flex-auto" autocomplete="off" formControlName="value" d-form-field-error/>
      </div>    
      <div class="flex justify-content-end gap-2">
          <p-button label="Cancelar" severity="secondary" (click)="visibleContractZoneLine = false" />
          <p-button label="Guardar" (click)="saveContractZoneLine()" />
      </div>
    </form>
  </p-dialog>

