import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehiclesTypesListComponent } from './vehicles-types-list/vehicles-types-list.component';
import { VehiclesTypesDetailComponent } from './vehicles-types-detail/vehicles-types-detail.component';

const routes: Routes = [
    {
        path: 'vehiclesTypes',
        children: [
            { path: '', component: VehiclesTypesListComponent }
        ]
    },
    {
        path: 'vehiclesTypes/:id',
        children: [
            { path: '', component: VehiclesTypesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VehiclesTypesRoutingModule { }
