import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Worker } from '../_types/worker';
import { WorkersService } from '../_services/workers.service';
import { WorkerBankAccountsService } from '../_services/workers-bank-accounts.service';
import { WorkerAddressesService } from '../_services/workers-addresses.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { BanksService } from '../../common/_services/banks.service';
import { WorkerAddress } from '../_types/worker-address';
import { WorkerBankAccount } from '../_types/worker-bank-account';
import { firstValueFrom } from 'rxjs';
import { WorkerCapabilitiesService } from '../_services/workers-capabilities.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { WorkersCapabiliyComponent } from '../workers-capabiliy/workers-capabiliy.component';
import { CapabilitiesService } from '../../common/_services/capabilities.service';
import { ZonesService } from '../_services/zones.service';
import { Zone } from '../_types/zone';
import { WorkersZoneComponent } from '../workers-zone/workers-zone.component';
import { WorkersTimeRangesService } from '../_services/workers-time-ranges.service';
import { WorkersTimeRangeComponent } from '../workers-time-range/workers-time-range.component';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CalendarsService } from '../../calendars/_services/calendars.service';
import { CalendarsEventsService } from '../../calendars/_services/calendars-events.service';
import { CalendarEventModalComponent } from '../../calendars/detail/event-modal/calendar-event-modal.component';
import * as moment from 'moment';
import { WorkersHolidaysService } from '../_services/workers-holidays.service';
import { WorkersHolidaysModalComponent } from './holidays-modal/workers-holidays-modal.component';
import { UsersService } from '../../users/_services/users.service';
import { StringUtilsService } from 'src/app/core/services/string-utils.service';
import { User } from '../../users/_types/user';
import { CountriesService } from '../../common/_services/countries.service';
import { GuardsShiftsService } from '../../guards-shifts/_services/guards-shifts.service';
import { VehiclesService } from '../../vehicles/_services/vehicles.service';
import { Vehicle } from '../../vehicles/_types/vehicle';
import { WorkersVehicleComponent } from '../workers-vehicle/workers-vehicle.component';
import { WorkerEquipmentsService } from '../_services/workers-equipments.service';
import { WorkersEquipmentsComponent } from '../workers-equipments/workers-equipments.component';
import { VehiclesDetailComponent } from '../../vehicles/vehicles-detail/vehicles-detail.component';
import { WorkerVehiclesService } from '../_services/workers-vehicles.service';
import { WorkersVehiclesDetailComponent } from '../workers-vehicles-detail/workers-vehicles-detail.component';
import { TabViewChangeEvent } from 'primeng/tabview';
import { RolesService } from '../../roles/_services/roles.service';
import { WorkersFilesService } from '../_services/workers-files.service';
import { WorkersFileComponent } from '../workers-file/workers-file.component';
import { WorkerFile } from '../_types/worker-file';
import { AddSubworkerComponent } from './add-subworker/add-subworker.component';
import { Service } from "src/app/core/jsonapi/service";
import { FilesService } from '../../common/_services/files.service';


@Component({
  selector: 'app-workers-detail',
  templateUrl: './workers-detail.component.html',
  styleUrls: ['./workers-detail.component.scss']
})
export class WorkersDetailComponent {

  public workerFormGroup!: RxFormGroup;
  public userFormGroup!: RxFormGroup;

  public worker: Worker = new Worker();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public banks: any = [];
  public countries: any = [];
  public workerCapabilities: any = [];
  public workerEquipments: any = [];
  public zones: any[] = [];
  public roles: any[] = [];
  public equipments: any = [];
  public workerTimeRanges: any = [];
  public workers: any = [];
  public subWorkers: any = [];
  public vehicles: any = [];
  public totalVehiclesRecords: number = 0;
  public totalCapabilitiesRecords: number = 0;
  public totalEquipmentsRecords: number = 0;
  public totalSubWorkersRecords: number = 0;
  public totalWorkersFiles: number = 0;
  public calendars: any = [];
  public fromDate: any = null;
  public toDate: any = null;
  public calendarEvents: any = [];
  public workerHolidays: any = [];
  public guardsShifts: any = [];
  public files: any[] = [];
  public activeIndex: number = 0;

  constructor(
    private rolesService: RolesService,
    private workersService: WorkersService,
    private workerBankAccountsService: WorkerBankAccountsService,
    private workerAddresesService: WorkerAddressesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private banksService: BanksService,
    private workerCapabilitiesService: WorkerCapabilitiesService,
    private workerEquipmentsService: WorkerEquipmentsService,
    private dialogService: DialogService,
    private workersEquipmentsService: WorkerEquipmentsService,
    private workerVehiclesService: WorkerVehiclesService,
    private workerTimeRangesService: WorkersTimeRangesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private calendarsService: CalendarsService,
    private calendarsEventsService: CalendarsEventsService,
    private workersHolidaysService: WorkersHolidaysService,
    private usersService: UsersService,
    private stringUtilsService: StringUtilsService,
    private countriesService: CountriesService,
    private guardsShiftsService: GuardsShiftsService,
    private zonesService: ZonesService,
    private workerFilesService: WorkersFilesService,
    private filesService: FilesService

  ) {
    this.topbarService.setTitle("Detalles de operiario");
    this.topbarService.addBreadcrumb("Operarios", "/workers");

    this.workerFormGroup = this.formBuilder.formGroup<Worker>(this.worker) as RxFormGroup;
  }

  async ngOnInit() {

    this.worker.address = new WorkerAddress();
    this.worker.bankAccount = new WorkerBankAccount();

    this.loadBanks();
    this.loadCountries();

    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadWorker(id);
    }
    if (this.worker.address == null) this.worker.address = new WorkerAddress();
    //if (this.worker.bankAccount == null) this.worker.bankAccount = new WorkerBankAccount();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");

  }

  async loadWorker(id: string) {
    this.worker = await firstValueFrom(this.workersService.get(id, { include: ['user', 'bankAccount', 'address', 'workStartAddress'] }));

    //cargamos tab 0
    let firstIdx = this.worker.parentWorkerId != null ? 1 : 0;
    this.activeIndex = firstIdx;
    this.onTabChange({ index: firstIdx, originalEvent: new Event('') });

    //cargamos usuario si tiene
    if (this.worker.userId != null) {
      let user: User = await firstValueFrom(this.usersService.get(this.worker.userId));
      this.worker.user = user;
    }

    //cargamos direccion si tienes
    if (!this.worker.usePrincipalAddressAsWorkStart && this.worker.workStartAddressId == null) {
      this.worker.workStartAddress = new WorkerAddress();
    }

    //si no tiene usuario, pero tiene activo el acceso
    if (this.worker.user == null && this.worker.enableAccess) {
      this.worker.user = new User();
      this.worker.user.userName = this.stringUtilsService.RandomString(8);
      this.worker.user.temporalPassword = this.stringUtilsService.RandomString(8);
      this.worker.user.password = this.worker.user.temporalPassword;
    }
    if (!this.worker.enableAccess) {
      this.workerFormGroup.removeControl('user');
      this.workerFormGroup.updateValueAndValidity();
    }
    this.workerFormGroup.patchValue(this.worker);
  }

  async onTabChange(event: TabViewChangeEvent) {
    if (event.index == 0) {
      //bank accounts
    } else if (event.index == 1) {
      //capabilites
      this.loadCapabilities();
    } else if (event.index == 2) {
      //zones
      this.loadZones();
    } else if (event.index == 3) {
      //timeranges
      this.loadGuardShifts();
      this.loadTimeRanges();
    } else if (event.index == 4) {
      //subworkes
      this.loadWorkers();//????
      this.loadSubWorkers();
    } else if (event.index == 5) {
      //vehivcles
      this.loadVehicles();
    } else if (event.index == 6) {
      //equipment
      this.loadEquipments();
    } else if (event.index == 7) {
      //configuration
      this.loadRoles();
    } else if (event.index == 8) {
      //calendar
      this.loadCalendars();
      this.loadEvents();
    } else if (event.index == 9) {
      //notes
    } else if (event.index == 10) {
      //DOCUMENTOS
      this.loadFiles();
    }
  }

  async loadBanks() {
    let response = await firstValueFrom(this.banksService.all({ page: { size: 0 } }));
    this.banks = response.data;
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async loadGuardShifts() {
    let response = await firstValueFrom(this.guardsShiftsService.all());
    this.guardsShifts = response.data;
  }

  async loadCapabilities() {
    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };


    let params: IParamsCollection = {
      include: ['capability']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerCapabilitiesService.all(params));
    this.workerCapabilities = response.data;
    this.totalCapabilitiesRecords = response.meta['total'];
  }

  async loadEquipments() {
    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };


    let params: IParamsCollection = {
      include: ['equipment']
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerEquipmentsService.all(params));
    this.workerEquipments = response.data;
    console.log(this.workerEquipments);
    this.totalEquipmentsRecords = response.meta['total'];
  }

  async loadRoles() {
    let filters = {
      workerAllowed: new Filter(FilterOperation.equals, 'workerAllowed', "", true)
    };

    let params: IParamsCollection = {}
    params.filter = FilterBuilder.fromObject(filters).build();
    let response = await firstValueFrom(this.rolesService.all(params));
    this.roles = response.data;
  }

  async loadZones() {
    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };

    let params: IParamsCollection = {}

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.zonesService.all(params));
    this.zones = response.data;
  }

  async loadVehicles() {
    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };

    let params: IParamsCollection = {
      include: ['vehicle']
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerVehiclesService.all(params));
    console.log(response.data);
    this.vehicles = response.data;
  }

  async loadTimeRanges() {
    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };

    let params: IParamsCollection = {}

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerTimeRangesService.all(params));
    this.workerTimeRanges = response.data;

    console.log(this.workerTimeRanges);
  }

  async loadWorkers() {
    let response = await firstValueFrom(this.workersService.all());
    this.workers = response.data;
  }

  async loadSubWorkers() {
    let filters = {
      parentWorkerId: new Filter(FilterOperation.equals, 'parentWorkerId', "", this.worker.id)
    };

    let params: IParamsCollection = {}

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workersService.all(params));
    this.subWorkers = response.data;
    this.totalSubWorkersRecords = response.meta['total'];
  }

  async loadCalendars() {
    let response = await firstValueFrom(this.calendarsService.all({ page: { size: 0 } }));
    this.calendars = response.data;
    this.calendars.unshift({
      id: null,
      name: "Sin calendario"
    })
  }

  async onEnableAccessChange() {
    //si no tiene usuario preparamos datos por si lo activa
    if (this.workerFormGroup.value.enableAccess) {
      if (this.worker.userId == null) {
        this.worker.user = new User();
        this.worker.user.userName = this.stringUtilsService.RandomString(8);
        this.worker.user.temporalPassword = this.stringUtilsService.RandomString(8);
        this.worker.user.password = this.worker.user.temporalPassword;
        if (this.roles.length == 1) this.worker.user.roleId = this.roles[0].id;
      }
      this.workerFormGroup.addControl('user', this.formBuilder.formGroup<User>(this.worker.user) as RxFormGroup);
      this.workerFormGroup.patchValue({ user: this.worker.user });
      this.workerFormGroup.updateValueAndValidity();
    } else {
      this.workerFormGroup.removeControl('user');
    }
  }

  async onEnableUsePrincipalAddressAsWorkStartChange() {
    //si no tiene usuario preparamos datos por si lo activa
    if (this.workerFormGroup.value.usePrincipalAddressAsWorkStart) {
      if (this.worker.userId == null) {
        this.worker.workStartAddress = new WorkerAddress();
      }
      this.workerFormGroup.addControl('workStartAddress', this.formBuilder.formGroup<WorkerAddress>(this.worker.workStartAddress!) as RxFormGroup);
      this.workerFormGroup.patchValue({ workStartAddress: this.worker.workStartAddress });
      this.workerFormGroup.updateValueAndValidity();
    } else {
      this.workerFormGroup.removeControl('workStartAddress');
    }
  }

  yearChanged(data: any) {
    this.fromDate = data.first_date;
    this.toDate = data.end_date;
    this.loadEvents();
  }

  async loadEvents() {

    //Buscamos los del calendario
    if (typeof this.workerFormGroup.value.calendarId != "undefined" && this.workerFormGroup.value.calendarId != null) {
      let filters = {
        calendarId: new Filter(FilterOperation.equals, 'calendarId', '', this.workerFormGroup.value.calendarId),
        fromDate: new Filter(FilterOperation.greaterOrEqual, 'date', '', this.fromDate!.startOf("year").toISOString()),
        toDate: new Filter(FilterOperation.lessOrEqual, 'date', '', new Date(this.toDate!).toISOString()),
      };

      let params: IParamsCollection = {
        filter: FilterBuilder.fromObject(filters).build()
      };

      let response = await firstValueFrom(this.calendarsEventsService.all(params));

      this.calendarEvents = response.data;
    } else {
      this.calendarEvents = [];
    }

    //Vacaciones
    let filtersHolidays = {
      workerId: new Filter(FilterOperation.equals, 'workerId', '', this.worker.id),
      fromDate: new Filter(FilterOperation.greaterOrEqual, 'date', '', this.fromDate!.startOf("year").toISOString()),
      toDate: new Filter(FilterOperation.lessOrEqual, 'date', '', new Date(this.toDate!).toISOString()),
    };

    let paramsHolidays: IParamsCollection = {
      filter: FilterBuilder.fromObject(filtersHolidays).build()
    };

    let responseHolidays = await firstValueFrom(this.workersHolidaysService.all(paramsHolidays));

    this.workerHolidays = responseHolidays.data;

  }

  daySelected(data: any) {
    let date = moment(data.date);
    const ref = this.dialogService.open(WorkersHolidaysModalComponent, {
      data: {
        workerId: this.worker.id,
        event: data.holidays[0],
        date: date
      },
      header: "Vacaciones: " + date.format('DD/MM/YYYY'),
      width: "400px",
    });
    ref.onClose.subscribe(async (data: any) => {
      console.log(data);
      if (typeof data != "undefined") {
        await this.workersHolidaysService.save(data);
        this.loadEvents();
      } else {
        this.loadEvents();
      }
    });
  }

  onChangeCalendar($event: any) {
    this.loadEvents();
  }


  async save() {
    //copiamos datos de operario al usuario vionculado
    if (this.worker.user != null) {
      this.worker.user.name = this.worker.name;
      this.worker.user.email = this.worker.email;
      this.workerFormGroup.patchValue({ user: this.worker.user });
    }

    let valid = this.formValidationService.validateForm(this.workerFormGroup);
    if (valid) {
      this.worker = Object.assign(this.worker, this.workerFormGroup.value);

      //guardamos dirección
      if (this.worker.address?.addressLine1 != null) {
        let address: any = await firstValueFrom(this.workerAddresesService.save(this.worker.address));
        this.worker.addressId = address.id;
      }

      //guardamos dirección de inicio de trabajo
      if (this.worker.workStartAddress?.addressLine1 != null) {
        let workStartAddress: any = await firstValueFrom(this.workerAddresesService.save(this.worker.workStartAddress));
        this.worker.workStartAddressId = workStartAddress.id;
      }

      //guardamos cuenta bancaria
      if (this.worker.hasBankAccount && this.worker.bankAccount != null) {
        let bankAccount: any = await firstValueFrom(this.workerBankAccountsService.save(this.worker.bankAccount));
        this.worker.bankAccountId = bankAccount.id;
      }

      //guardamos operario
      let savedWorker = await firstValueFrom(this.workersService.save(this.worker, { include: ['address', 'bankAccount', 'workStartAddress'] }));
      this.worker = Object.assign(this.worker, savedWorker);

      //comprobaciones de configuraciones y usuario asignado
      await this.saveConfigurations(this.worker);

      this.messageService.add({ closable: false, severity: 'success', summary: "Operario guardado correctamente." });

      if (this.worker.id != "undefined" && this.worker.id != "") {
        this.router.navigate(['workers']);
      }
      this.loadWorker(this.worker.id!);

    } else {
      console.log(this.workerFormGroup.getErrorSummary(true));
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el operario?",
      header: "Eliminar operario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workersService.delete(this.worker.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Operario eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['workers']);
  }

  editWorkerCapability(workerCapability: any) {
    let ref = this.dialogService.open(WorkersCapabiliyComponent, {
      header: 'Editar servicio del operario',
      width: '500px',
      data: {
        workerCapabilityId: workerCapability ? workerCapability.id : null,
        workerId: this.worker.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadCapabilities();
    });
  }

  deleteWorkerCapability(workerCapability: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio del operario?",
      header: "Eliminar servicio",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerCapabilitiesService.delete(workerCapability.id).subscribe({
          next: (data: any) => {
            this.loadCapabilities();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio borrado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  editEquipment(workerEquipment: any) {
    let ref = this.dialogService.open(WorkersEquipmentsComponent, {
      header: 'Editar equipamiento del operario',
      width: '500px',
      data: {
        workerEquipmentId: workerEquipment ? workerEquipment.id : null,
        workerId: this.worker.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadEquipments();
    });
  }

  deleteWorkerEquipment(workerEquipment: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el equipamiento del operario?",
      header: "Eliminar equipamiento",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerEquipmentsService.delete(workerEquipment.id).subscribe({
          next: (data: any) => {
            this.loadEquipments();
            this.messageService.add({ closable: false, severity: 'success', detail: "Equipamiento eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }


  editZone(zone: any) {
    const ref = this.dialogService.open(WorkersZoneComponent, {
      data: {
        wokerId: this.worker.id,
        id: zone.id
      },
      header: zone.id ? zone.name : "Nueva zona",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadZones();
    });

  }

  deleteZone(zone: Zone) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la zona " + zone.name + "?",
      header: "Eliminar proveedor",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.zonesService.delete(zone.id).subscribe({
          next: (data: any) => {
            this.loadZones();
            this.messageService.add({ closable: false, severity: 'success', detail: "Zona eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  editVehicle(vehicle: any) {
    const ref = this.dialogService.open(WorkersVehiclesDetailComponent, {
      data: {
        workerId: this.worker.id
      },
      header: vehicle.id ? vehicle.name : "Nuevo vehículo",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadVehicles();
    });

  }

  assignVehicle(workerVehicle: any) {
    const ref = this.dialogService.open(WorkersVehicleComponent, {
      data: {
        workerVehicleId: workerVehicle.id,
        workerId: this.worker.id
      },
      header: "Asginar vehículos",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadVehicles();
    });
  }

  deleteVehicle(vehicle: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar el vehículo " + vehicle.vehicle.name + "?",
      header: "Desasignar vehículo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerVehiclesService.delete(vehicle.id).subscribe({
          next: (data: any) => {
            this.loadVehicles();
            this.messageService.add({ closable: false, severity: 'success', detail: "Vehículo desasignado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  async editTimeRange(timerange: any) {
    const ref = this.dialogService.open(WorkersTimeRangeComponent, {
      data: {
        workerId: this.worker.id,
        id: timerange.id
      },
      header: "Detalles de franja horaria",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      console.log("asdasd");
      this.loadTimeRanges();
    });

  }
  async deleteTimeRange(timerange: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la franja horaria?",
      header: "Eliminar franja horaria",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerTimeRangesService.delete(timerange.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Franja horaria eliminada correctamente." });
            this.loadTimeRanges();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  timeRangeFormat(time: string) {

    return time.substring(0, 5);
  }

  async editSubWorker(subWorker: any) {
    await this.router.navigate(['workers', subWorker.id]);
    window.location.reload();
  }

  addSubWorker() {
    let ref = this.dialogService.open(AddSubworkerComponent, {
      header: 'Añadir suboperario',
      width: '80%',
      data: {
        parentWorkerId: this.worker.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadSubWorkers();
    });
  }

  async saveConfigurations(worker: Worker) {
    // si enableAccess es true
    if (worker.enableAccess) {
      // si tiene usuario asignado, se activa el usuario
      if (worker.userId) {
        //activar usuario 
        let user = await firstValueFrom(this.usersService.get(worker.userId));
        user.userName = this.worker.user.userName;
        user.roleId = this.worker.user.roleId;
        user.active = true;
        await this.usersService.save(user);
      }
      //si no tiene usuario asignado, se crea un usuario y se asigna
      else {
        //crear usuario
        let user: User = new User();
        user.name = this.worker.user.name;
        user.roleId = this.worker.user.roleId;
        user.userName = this.worker.user.userName;
        user.password = this.stringUtilsService.ToMD5(this.worker.user.password);
        user.temporalPassword = this.worker.user.password;
        let newUser = await firstValueFrom(this.usersService.save(user));
        worker.userId = newUser.id ? newUser.id : "";
        await this.workersService.save(worker);
      }
    }

    // si enableAccess es false, y tiene usuario asignado, se desactiva el usuario
    if (!worker.enableAccess && worker.userId) {
      let user = await firstValueFrom(this.usersService.get(worker.userId));
      user.active = false;
      await this.usersService.save(user);
    }
  }

  async sendPassword() {
    await firstValueFrom(this.workersService.sendMailLoginData(this.worker));
    this.messageService.add({ closable: false, severity: 'success', detail: "Email enviado correctamente" });
  }

  viewUser() {
    this.router.navigate(['users', this.worker.userId]);
  }


  async loadFiles() {

    let filters = {
      workerId: new Filter(FilterOperation.equals, 'workerId', "", this.worker.id)
    };
    let params: IParamsCollection = {
      include: ['file'],
      sort: ['name'],
      filter: FilterBuilder.fromObject(filters).build()
    }

    let response = await firstValueFrom(this.workerFilesService.all(params));
    this.files = response.data;
    console.log(this.files);
    this.totalWorkersFiles = response.meta['total'];
  }

  editFile(file?: WorkerFile) {
    const ref = this.dialogService.open(WorkersFileComponent, {
      data: {
        workerId: this.worker.id,
        workerFileId: (file != null ? file.id : null)
      },
      header: file != null && file.id ? file.file.fileName : "Nuevo documento",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadFiles();
    });

  }

  deleteFile(file: WorkerFile) {
    console.log(file);
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el documento " + file.name + "?",
      header: "Eliminar documento",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.workerFilesService.delete(file.id).subscribe({
          next: (data: any) => {
            this.loadFiles();
            this.messageService.add({ closable: false, severity: 'success', detail: "Documento eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  downloadFile(id: any) {
    this.filesService.download(id);
  }

  onChangeHasBankAccount() {
    if (this.workerFormGroup.value.hasBankAccount) {
      if (this.worker.bankAccountId == null || this.worker.bankAccount == null) this.worker.bankAccount = new WorkerBankAccount();
      this.workerFormGroup.addControl('bankAccount', this.formBuilder.formGroup<WorkerBankAccount>(this.worker.bankAccount) as RxFormGroup);
      this.workerFormGroup.patchValue({ bankAccount: this.worker.bankAccount });
      this.workerFormGroup.updateValueAndValidity();
    } else {
      this.workerFormGroup.removeControl('bankAccount');
    }
  }
}
