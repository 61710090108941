import { Resource } from "src/app/core/jsonapi/resource";
import { prop } from "@rxweb/reactive-form-validators";

export class ContractSla extends Resource {
    public override type = 'contractSLAs';

    @prop()
    public contractId!: string;

    @prop()
    public caseEventTypeId!: string;

    @prop()
    public hours!: number;

    @prop()
    isNotificationEnabled: boolean = false;



}