<div class="flex gap-2 cola-4">
    <span class="w-full input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
</div>

<p-table styleClass="mt-4" #grid [value]="workers" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="phone1">Teléfono <p-sortIcon field="phone1"></p-sortIcon></th>
            <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
            <th>Tipo</th>
            <th pSortableColumn="rating">Valoración <p-sortIcon field="rating"></p-sortIcon></th>
            <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-worker let-columns="columns">
        <tr (dblclick)="edit(worker)">
            <td>{{worker.name}}</td>
            <td>{{worker.phone1}}</td>
            <td>{{worker.email}}</td>
            <td>{{worker.parentWorkerId!=null?'Suboperaio':'Operario'}}</td>
            <td><p-rating [(ngModel)]="worker.rating" [readonly]="true" [cancel]="false" /></td>
            <td>
                <i class="pi"
                    [ngClass]="{ 'text-green-500 pi-check-circle': worker.active, 'text-red-500 pi-times-circle': !worker.active }"></i>
            </td>
            <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowDelete" (click)="delete(worker)" [rounded]="true" [text]="false"
                        severity="danger"><i class="pi pi-trash"></i></p-button>
                    <p-button (click)="openChat(worker)" [rounded]="true" [text]="false">
                        <i class="pi pi-comments"></i>
                    </p-button>
                    <p-button *ngIf="allowWrite" (click)="edit(worker)" [rounded]="true" [text]="false"><i
                            class="pi pi-pencil"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>