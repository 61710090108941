import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Equipment } from "../_types/equipment";

@Injectable({
    providedIn: 'root',
})

export class EquipmentsService extends Service<Equipment> {
  public override type = 'equipment';
  public override url = 'providers/equipment'; 
    
}
