import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { RolePermission } from "../_types/role-permission";

@Injectable({
    providedIn: 'root',
})
export class RolesPermissionsService extends Service<RolePermission> {
    public override type = 'rolePermissions';
    public override url = 'auth/rolePermissions';
}