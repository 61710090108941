import { Resource } from "src/app/core/jsonapi/resource";
import { BusinessAddress } from "./business-address";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class Business extends Resource {
    public override type = 'companies';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public legalName!: string;

    @prop()
    public email!: string;

    @prop()
    public phone1!: string;

    @prop()
    public phone2!: string;

    @prop()
    @required()
    public active: boolean = true;

    @prop()
    public vatNumber!: string;

    @prop()
    public addressId!: string;

    @propObject(BusinessAddress)
    public address: BusinessAddress = new BusinessAddress();

}