import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CalendarEvent } from '../../_types/calendar-event';
import { CalendarsEventsService } from '../../_services/calendars-events.service';
import * as moment from 'moment';
import { WorkersHolidaysService } from 'src/app/modules/workers/_services/workers-holidays.service';
import { WorkerHoliday } from 'src/app/modules/workers/_types/worker-holiday';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-calendar-event-modal.component',
  templateUrl: './calendar-event-modal.component.html'
})
export class CalendarEventModalComponent {

  public calendarEventFormGroup!: RxFormGroup;
  
  public selectedEvent: CalendarEvent = new CalendarEvent;      
  
  constructor(
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,    
    private config: DynamicDialogConfig,
    private calendarsEventsService: CalendarsEventsService,
    private confirmationService: ConfirmationService,    
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.calendarEventFormGroup = this.formBuilder.formGroup<CalendarEvent>(this.selectedEvent) as RxFormGroup;
  }


  ngOnInit(): void {          

    if(typeof this.config.data.event !="undefined"){
      this.selectedEvent = this.config.data.event;
      this.calendarEventFormGroup.patchValue(this.selectedEvent);
    }else{      
      this.selectedEvent.calendarId = this.config.data.calendarId;
      this.selectedEvent.date = moment(this.config.data.date).toDate().toISOString();     
      this.calendarEventFormGroup.patchValue(this.selectedEvent);
    }        
  }

  async save(){      
    this.selectedEvent = Object.assign(this.selectedEvent, this.calendarEventFormGroup.value)    
    this.dynamicDialogRef.close(this.selectedEvent);                            
  }

  delete(){    
    this.confirmationService.confirm({
      message: "¿Quieres eliminar el evento del "+moment(this.config.data.date).format("DD/MM/yyyy")+"?",
      header: "Eliminar evento",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {        
          this.calendarsEventsService.delete(this.selectedEvent.id).subscribe({
            next: (data: any) => {
              this.dynamicDialogRef.close();
              this.messageService.add({ closable: false, severity: 'success', detail: "Evento eliminado correctamente" });   
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });            
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });    
  }

  dismiss(){
    //this.activeModal.dismiss();
  }  
}
