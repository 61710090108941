import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Language } from "../_types/language";
@Injectable({
    providedIn: 'root',
})

export class LanguagesService extends Service<Language> {
  public override type = 'languages';
  public override url = 'common/languages'; 
}
