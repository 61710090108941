import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RatesService } from '../../_services/rates.service';
import { firstValueFrom } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-rate-upload-excel',
  templateUrl: './rate-upload-excel.component.html',
  styleUrls: ['./rate-upload-excel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateUploadExcelComponent {    

  @ViewChild('fileUploader') fileUploader: FileUpload | undefined;

  public file: any;
  public errors: any = [];
  public messagesError: Message[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private ratesService: RatesService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService  ) {}

  ngOnInit(): void {    

  }  

  async onFileSelect(event: any) {
    console.log(event);
    //subimos el fichero al file
    this.file = event.currentFiles[0];    
    /*let files = event.files;
    for (let file of files) {
      this.files.push(file);      
    }*/
  }

  async importExcel(){
    let response = await firstValueFrom(this.ratesService.uploadExcel(this.config.data.rateId as string, this.file));
    console.log(response);
    if(response.length > 0){
      this.messagesError = response;
      this.file = null;
      this.fileUploader?.clear(); 
    }else{
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Fichero importado correctamente." });
      this.dynamicDialogRef.close();
    }    
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  downloadTemplate(){
    console.log("entra");
    window.open("/assets/media/plantilla.xlsx", "_blank");
  }
  
}
