import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractCapability } from "../_types/contract-capability";

@Injectable({
    providedIn: 'root',
})
export class ContractCapabilitiesService extends Service<ContractCapability> {
  public override type = 'contractCapabilities';
  public override url = 'attendance/contractCapabilities'; 
}