import { Resource } from "src/app/core/jsonapi/resource";
import { prop, required } from "@rxweb/reactive-form-validators";

export class ContractMailAccount extends Resource {
    public override type = 'contractMailAccounts';

    @prop()
    public contractId!: string;


    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public username!: string;

    @prop()
    @required()
    public password!: string;

    @prop()
    @required()
    public SMTPEnabled: boolean = true;

    @prop()
    public SMTPFrom!: string;

    @prop()
    public SMTPPort!: number;

    @prop()
    public SMTPHost!: string;

    @prop()
    public SMTPUseSSL: boolean = true;

    @prop()
    public IMAPEnabled: boolean = true;

    @prop()
    public IMAPHost!: string;

    @prop()
    public IMAPPort!: number;

    @prop()
    public IMAPUseSSL: boolean = true;
}