import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseType } from "../_types/case-type";

@Injectable({
    providedIn: 'root',
})
export class CasesTypesService extends Service<CaseType> {
    public override type = 'caseTypes';
    public override url = 'attendance/caseTypes';
}
