import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CompanyRate } from 'src/app/modules/companies/_types/company-rate';
import { CompanyRatesService } from 'src/app/modules/companies/_services/companies-rates.service';
import { CompaniesService } from 'src/app/modules/companies/_services/companies.service';
import { ContractsService } from 'src/app/modules/companies/_services/contracts.service';

@Component({
  selector: 'app-rate-company',
  templateUrl: './rate-company.component.html',
  styleUrls: ['./rate-company.component.scss']
})
export class RateCompanyComponent {
  
  public rateCompanyFormGroup!: RxFormGroup;

  public rateCompany: CompanyRate = new CompanyRate();
  public companies: any[] = [];
  public contracts: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private companyRatesService: CompanyRatesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private companiesService: CompaniesService,
    private contractsService: ContractsService
  ) {
    this.rateCompanyFormGroup = this.formBuilder.formGroup<CompanyRate>(this.rateCompany) as RxFormGroup;
  }

  ngOnInit(): void {
    console.log(this.config.data);
    if (this.config.data.rateCompanyId) {
      this.loadRateCompany(this.config.data.rateCompanyId);            
    } else {
      this.rateCompany.rateId = this.config.data.rateId;
    }
    this.loadCompanies();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadRateCompany(id: string) {
    this.rateCompany = await firstValueFrom(this.companyRatesService.get(id));
    if(this.rateCompany.contractId == null) this.rateCompany.contractId == "null"; 
    this.rateCompanyFormGroup.patchValue(this.rateCompany);

    this.loadContracts();
  }

  async loadCompanies() {
    let response = await firstValueFrom(this.companiesService.all());

    this.companies = response.data;
  }

  async loadContracts(){
    
  
      let filters = {
        companyId: new Filter(FilterOperation.equals, 'companyId',"", this.rateCompanyFormGroup.value.companyId)
      };
      
      let params: IParamsCollection = {
        filter: FilterBuilder.fromObject(filters).build()
      };
      
      let response = await firstValueFrom(this.contractsService.all(params));
      this.contracts = response.data;           
      var allContracts = {id: "null", name: "Todos"}
      this.contracts.unshift(allContracts);     
      console.log(this.rateCompanyFormGroup.value.contractId);
      if(this.rateCompanyFormGroup.value.contractId == null) this.rateCompanyFormGroup.get('contractId')?.setValue('null');            
      
            
  }

  async save() {    
    let valid = this.formValidationService.validateForm(this.rateCompanyFormGroup);
    console.log(valid);
    if (valid) {
      this.rateCompany = Object.assign(this.rateCompany, this.rateCompanyFormGroup.value);
      
      if(this.rateCompany.contractId == "null") this.rateCompany.contractId = null;
      console.log("entra");
      await this.companyRatesService.save(this.rateCompany).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Compañía aseguradora asignada correctamente' });
          this.dynamicDialogRef.close();
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al asignar la compañía aseguradora' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar la compañía aseguradora?",
      header: "Desasignar compañía aseguradora",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.companyRatesService.delete(this.rateCompany.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Compañía aseguradora desasignada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
