import { Filter } from "../filter";
import { IParamsCollection } from "../interfaces/params-collection";
import { IParamsResource } from "../interfaces/params-resource";

export class ParamsBuilder {

    public static paramsCollectionToQueryString(params:IParamsCollection): string {
        let queryString = '';
        if(params.page!=null && params.page.number!=null) queryString += '&page[number]=' + params.page.number;
        if(params.page!=null && params.page.size!=null) queryString += '&page[size]=' + params.page.size;
        if(params.fields!=null){
            let obj: any = params.fields;
            for (const property in obj) {
                if (params.fields.hasOwnProperty(property)) {
                    queryString += '&fields[' + property + ']=' + obj[property];
                }
            }
        }
        if(params.include!=null && params.include.length>0) queryString += '&include=' + params.include.join(',');
        if(params.sort!=null && params.sort.length>0) queryString += '&sort=' + params.sort.join(',');

        //preparamos filtros
        if(params.filter!=null){
            params.filter.forEach((filter: Filter) => {
                queryString += "&" + filter.toQueryString();
            });
        }

        if(queryString.length>0) queryString = '?' + queryString;
        return queryString;
    } 

    public static paramsResourceToQueryString(params:IParamsResource): string {
        let queryString = '';
        if(params.fields!=null){
            let obj: any = params.fields;
            for (const property in obj) {
                if (params.fields.hasOwnProperty(property)) {
                    queryString += '&fields[' + property + ']=' + obj[property];
                }
            }
        }
        if(params.include!=null && params.include.length>0) queryString += '&include=' + params.include.join(',');
        if(queryString.length>0) queryString = '?' + queryString;
        return queryString;
    } 
}