import { Component, Injector } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Client } from '../_types/client';
import { ClientsService } from '../_services/clients.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CountriesService } from '../../common/_services/countries.service';
import { firstValueFrom } from 'rxjs';
import { BanksService } from '../../common/_services/banks.service';
import { ClientAddress } from '../_types/client-address';
import { ClientBankAccount } from '../_types/client-bank-account';
import { ClientAddressesService } from '../_services/clients-addresses.service';
import { ClientBankAccountsService } from '../_services/clients-bank-accounts.service';
import { ClientPolicy } from '../_types/client-policy';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { ClientPolicyComponent } from '../client-policy/client-policy.component';
import { ClientPoliciesService } from '../_services/clients-policies.service';

@Component({
  selector: 'app-clients-detail',
  templateUrl: './clients-detail.component.html',
  styleUrls: ['./clients-detail.component.scss']
})
export class ClientsDetailComponent {
  public clientFormGroup!: RxFormGroup;

  public client: Client = new Client();
  public banks: any = [];

  public clientPolicies: ClientPolicy[] = [];
  public loadingClientPolicies: boolean = false;
  public totalClientPoliciesRecords: number = 0;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public countries: any = [];
  private dynamicDialogConfig?: DynamicDialogConfig;

  constructor(
    private injector:Injector,
    private clientsService: ClientsService,
    private clientAddressesService: ClientAddressesService,
    private clientBankAccountsService: ClientBankAccountsService,
    private clientPoliciesService: ClientPoliciesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService,
    private banksService: BanksService,
    private dialogService: DialogService
  ) {
    this.topbarService.setTitle("Detalles de cliente");
    this.topbarService.addBreadcrumb("Clientes", "/clients");

    this.clientFormGroup = this.formBuilder.formGroup<Client>(this.client) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCountries();
    this.loadBanks();

    let id = this.route.snapshot.params['id'];

    //intentamos obtener
    try{
      this.dynamicDialogConfig = this.injector.get(DynamicDialogConfig);
      if(this.dynamicDialogConfig.data.id!=null) id = this.dynamicDialogConfig.data.id;
    }catch(e){
      //nothign to do
    }

    if (id != null && id != '0') {
      await this.loadClient(id);
    }

    if (this.client.address == null) this.client.address = new ClientAddress();
    if (this.client.bankAccount == null) this.client.bankAccount = new ClientBankAccount();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadClient(id: string) {
    this.client = await firstValueFrom(this.clientsService.get(id, { include: ['bankAccount', 'address'] }));
    this.clientFormGroup.patchValue(this.client);
    this.loadClientPolicies();
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async loadBanks() {
    let response = await firstValueFrom(this.banksService.all());
    this.banks = response.data;
  }

  async loadClientPolicies() {
    this.loadingClientPolicies = true;
    let params: IParamsCollection = {
      include: ['company'],
    }
    let filters: any = {
      clientId: new Filter(FilterOperation.equals, "clientId"),
    };

    filters.clientId.value = this.client.id;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.clientPoliciesService.all(params));
    this.clientPolicies = response.data;
    this.totalClientPoliciesRecords = response.meta['total'];
    this.loadingClientPolicies = false;
  }


  async save() {
    let valid = this.formValidationService.validateForm(this.clientFormGroup);
    if (valid) {
      this.client = Object.assign(this.client, this.clientFormGroup.value);

      if (this.client.address != null && this.client.address.addressLine1 != null) {
        let address: any = await firstValueFrom(this.clientAddressesService.save(this.client.address));
        this.client.addressId = address.id;
      }

      //guardamos cuenta bancaria, si hay datos
      if (this.client.bankAccount != null && this.client.bankAccount.bankId != null) {
        let bankAccount: any = await firstValueFrom(this.clientBankAccountsService.save(this.client.bankAccount));
        this.client.bankAccountId = bankAccount.id;
      }

      let client = await firstValueFrom(this.clientsService.save(this.client));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Cliente guardado correctamente." });

      //si es nuevo, recargamos cliente, si no, redirigimos a la vista de cliente
      if (this.client.id != "undefined" && this.client.id != "") {
        if(this.dynamicDialogConfig){
          let ref = this.injector.get(DynamicDialogRef);
          ref.close(client);
        }else{
          this.router.navigate(['clients/' + client.id]);
        }
      }else{
        this.loadClient(client.id!);
      }

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el cliente?",
      header: "Eliminar cliente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.clientsService.delete(this.client.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Cliente eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    if(this.dynamicDialogConfig){
      let ref = this.injector.get(DynamicDialogRef);
      ref.close();
    }else{
      this.router.navigate(['clients']);
    }
  }

  async editClientPolicy(clientPolicy: ClientPolicy | null) {
    const ref = this.dialogService.open(ClientPolicyComponent, {
      header: 'Ficha de la póliza',
      width: '80%',
      data: {
        id: clientPolicy?.id ? clientPolicy.id : null,
        clientId: this.client.id
      }
    })

    ref.onClose.subscribe(() => {
      this.loadClientPolicies();
    });
  }


  async deleteClientPolicy(clientPolicyId: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la póliza?",
      header: "Eliminar póliza",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.clientPoliciesService.delete(clientPolicyId).subscribe({
          next: (data: any) => {
            this.loadClientPolicies();
            this.messageService.add({ closable: false, severity: 'success', detail: "Póliza eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

}
