import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Capability } from "../../common/_types/capability";

export class RateLine extends Resource {
    public override type = 'rateLines';

    @prop()
    @required()
    public rateId!: string;

    @prop()
    @required()
    public capabilityId!: string;

    public capability!: Capability;

    @prop()
    public code!: string;

    @prop()    
    public description!: string;

    @prop()    
    public price!: number;

    @prop()
    public freePrice: boolean = false;

    @prop()
    public uniqueService: boolean = false;    

    @prop()
    public fixedAmount?: number;

    @prop()
    public incompatibleCodes!: string;

    public displayName!: string;

}