import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TasksDetailComponent } from './tasks-detail/tasks-detail.component';

const routes: Routes = [
    {
        path: 'tasks',
        children: [
            { path: '', component: TasksListComponent },
            { path: ':guid', component: TasksDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TasksRoutingModule { }
