import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Zone } from "./zone";

export class ZoneLine extends Resource {
    public override type = 'zoneLines';

    @prop()
    @required()
    public value!: string;

    @prop()
    public normalServiceAllowed: boolean = false;

    @prop()
    public guardServiceAllowed: boolean = false;

    @prop()
    public zoneId!: string;

}