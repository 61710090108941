import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Role } from "../_types/role";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class RolesService extends Service<Role> {
  public override type = 'roles';
  public override url = 'auth/roles';

  public updatePermissions(roleId: string, permissionsIds: Array<string>): Observable<void> {
    let url = this.getAPIUrl() + "/" + roleId + '/permissions';
    return this.http.post<void>(url, permissionsIds);
  }
}