
import { Resource } from "src/app/core/jsonapi/resource";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { TransactionType } from "./transaction-type";
import { TransactionState } from "./transaction-state";
import { Case } from "../../cases/_types/case";
import { ThirdParty } from "./third-party";
import { TransactionLine } from "./transaction-line";

export class Transaction extends Resource {
    public override type = 'transactions';    

    @prop()    
    public active: boolean = true;   
    @prop()
    @required()
    public source: number = 1;    

    @prop()
    public thirdPartyType!: string ;    

    @prop()
    public caseId!: string ;    

    @propObject(Case)
    public case!: Case;
    
    
    @prop()
    @required()
    public transactionTypeId!: string ;    

    @propObject(TransactionType)
    public transactionType!: TransactionType;
    
    @prop()
    @required()
    public transactionStateId!: string ;    

    @propObject(TransactionState)
    public transactionState!: TransactionState;

    @prop()
    @required()
    public code!: string;

    @prop()
    @required()
    public date!: Date;    

    @prop()
    @required()
    public thirdPartyId!: string ;    

    @propObject(ThirdParty)
    public thirdParty!: ThirdParty;

    @prop()    
    public subtotal: number = 0;

    @prop()    
    public taxAmount: number = 0;
    
    @prop()    
    public total : number = 0;        
    
    @prop()
    public groupByCapability: boolean = false;

    @prop()
    public fileId!: string;

    @prop()  // Define el tipo como un array de strings
    public transactionLines?: TransactionLine[];    
}