import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CompanyAddress } from "../_types/company-address";
import { CompanyRate } from "../_types/company-rate";

@Injectable({
    providedIn: 'root',
})
export class CompanyRatesService extends Service<CompanyRate> {
  public override type = 'companyRates';
  public override url = 'attendance/companyRates';
}
