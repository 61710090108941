import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { ContractProcessor } from '../../_types/contract-processor';
import { ContractProcessorsService } from '../../_services/contracts-processors.service';
import { ProcessorsService } from '../../_services/processors.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-contract-processors-detail',
  templateUrl: './processors-detail.component.html',
  styleUrls: ['./processors-detail.component.css']
})
export class ContractProcessorsDetailComponent implements OnInit {
  
  public processorFormGroup!: RxFormGroup;

  public contractProcessor: ContractProcessor = new ContractProcessor();  
  public loading: boolean = false;  
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public processors: any = [];
  public processorsIds: Array<string> = [];
  

  constructor(
    private config: DynamicDialogConfig,
    private contractProcessorsService: ContractProcessorsService,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private processorsService: ProcessorsService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,            
  ) {
    this.processorFormGroup = this.formBuilder.formGroup<ContractProcessor>(this.contractProcessor) as RxFormGroup;
  }
  

  ngOnInit(): void {    

    this.contractProcessor.contractId = this.config.data.contractId;
    this.processorsIds = this.config.data.processorsIds;
            
    if(this.config.data.id!=null && this.config.data.id!='0'){
      this.loadContractProcessor(this.config.data.id);
    }
         
    this.allowWrite = true;//this.loginService.hasPermission("ZONES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("ZONES_DELETE");

    this.loadProcessors();
  }
  
  async loadContractProcessor(id: string) {
    this.contractProcessor = await firstValueFrom(this.contractProcessorsService.get(id, { include: ['processor'] }));
    this.processorFormGroup.patchValue(this.contractProcessor);   
  }

  async loadProcessors(){

    let response = await firstValueFrom(this.processorsService.all());    
    this.processors = response.data.filter((x: any) => x.id == this.contractProcessor.processorId || !this.processorsIds.some((y: any) => y == x.id));  
    

  }
      

  async save() {
    let valid = this.formValidationService.validateForm(this.processorFormGroup);
    if (valid) {

      this.contractProcessor = Object.assign(this.contractProcessor, this.processorFormGroup.value);  
                
      let contractProcessor:any = await firstValueFrom(this.contractProcessorsService.save(this.contractProcessor));
      
      this.dynamicDialogRef.close();      

      this.messageService.add({ closable: false, severity: 'success', detail: "Tramitador guardado correctamente" });
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Quieres eliminar el tramitador " + this.contractProcessor.processor?.name+ "?",
      header: "Eliminar tramitador",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractProcessorsService.delete(this.contractProcessor.id).subscribe(
          (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Tramitador eliminado correctamente" });

            this.cancel();
          },
          (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }    

}
