import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CoreModule } from 'src/app/core/core.module';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ExpertsListComponent } from './experts-list/experts-list.component';
import { ExpertsDetailComponent } from './experts-detail/experts-detail.component';
import { ExpertsRoutingModule } from './experts-routing.module';


@NgModule({
    declarations: [
        ExpertsListComponent,
        ExpertsDetailComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        CardModule,
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        PasswordModule,
        HttpClientModule,
        FormsModule,
        TableModule,
        CardModule,
        TabViewModule,
        CalendarModule,
        MessagesModule,
        ExpertsRoutingModule,
        ReactiveFormsModule,
        InputTextModule,
        InputSwitchModule,
        ConfirmDialogModule,
        DropdownModule,
        DynamicDialogModule,
        InputTextareaModule
    ]
})
export class ExpertsModule { }
