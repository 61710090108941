import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NotificationsService } from "../_services/notifications.service";
import { count, firstValueFrom } from "rxjs";
import { Notification } from "../_types/notification";
import { IParamsCollection } from "src/app/core/jsonapi/interfaces/params-collection";
import { ChatService } from "../../chat/_services/chat.service";
import { Router } from "@angular/router";
import { SidebarService } from "src/app/core/sidebar/sidebar.service";
import { FilterBuilder } from "src/app/core/jsonapi/filters-builder";
import { Filter, FilterOperation, NotFilter } from "src/app/core/jsonapi/filter";
import { NotificationsTypesService } from "../_services/notifications-types.service";
import { LocalStorageService } from "angular-web-storage";

@Component({
    selector: 'app-notifications-sidebar',
    templateUrl: './notifications-sidebar.component.html',
    styleUrls: ['./notifications-sidebar.component.scss']
})
export class NotificationsSidebarComponent {

    public user: any = {};
    public notifications: Notification[] = [];

    public notificationTypes:any[] = [];

    constructor(
        private storage: LocalStorageService,
        private notificationsTypesService: NotificationsTypesService,
        private notificationsService: NotificationsService,
        private chatService: ChatService,
        private router: Router
    ) {
        this.notificationTypes = this.notificationsTypesService.all();
        this.notificationsService.onShowSidebar.subscribe((visible:boolean) => {
            this.loadUserData();
            this.notifications = [];
            if(visible){
                this.loadNotifications();
            }
        });
    }

    loadUserData(){
        let user = this.storage.get("user");
        if(user) this.user = this.storage.get("user");
    }

    async loadNotifications() {
        let filters = {
            readedAt: new Filter(FilterOperation.equals, 'readedAt', '', null, true),
            web: new Filter(FilterOperation.equals, 'web', '', true),
            userId: new Filter(FilterOperation.equals, 'userId', '', this.user.id)
        };
        let params:IParamsCollection = {
            page: { size: 10 },
            sort: ['createdAt'],
            filter: FilterBuilder.fromObject(filters).build()
        };
        console.log(params);
        let response = await firstValueFrom(this.notificationsService.all(params));
        this.notifications = response.data;

        //contamos las notificaciones por tipo
        this.notificationTypes.forEach((type:any) => {
            type.count = this.getNotificationsForType(type).length;
        });
    }

    async executeAction(notification:Notification){
        let parts = notification.link.split(":");
        let actionType = parts[0];
        let action = parts.slice(1).join(":");
        if(actionType=="function"){
            this.run.call(this, "this." + action);
            firstValueFrom(this.notificationsService.markAsReaded(notification));
            this.notificationsService.closeNotifications();
        }else if(actionType=="route"){
            this.router.navigate([action]);
            firstValueFrom(this.notificationsService.markAsReaded(notification));
            this.notificationsService.closeNotifications();
        }
    }

    private run(pString:string){
        return eval(pString)
    }

    async openChat(code:string){
        this.chatService.openChatRoom(code);
    }

    getNotificationsForType(type:any){
        return this.notifications.filter((notification:Notification) => notification.source == type.source);
    }

    async markAsReaded(notification:Notification){
        await firstValueFrom(this.notificationsService.markAsReaded(notification));
        
        //elimina la notificación de la lista
        let index = this.notifications.indexOf(notification);
        this.notifications.splice(index, 1);
    }

    async goToNotifications(){
        this.router.navigate(["/notifications"]);
        this.notificationsService.closeNotifications();
    }
}