<form *ngIf="caseFormGroup" [formGroup]="caseFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="caseStateId">Estado</label>
            <p-dropdown formControlName="caseStateId" [options]="caseStates" optionLabel="name" optionValue="id"
                appendTo="body" [filter]="true" filterBy="name" placeholder="Selecciona un estado"
                d-form-field-error></p-dropdown>
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
</form>