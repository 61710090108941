import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from './list/companies-list.component';
import { CompaniesDetailComponent } from './detail/companies-detail.component';
import { ContractsDetailComponent } from './contracts/contracts-detail.component';

const routes: Routes = [
  {
    path: 'companies',
    children: [
      { path: '', component: CompaniesComponent }
    ]
  },
  {
    path: 'companies/:id',
    children: [
      { path: '', component: CompaniesDetailComponent }
    ]
  },
  {
    path: 'companies/:id/contracts/:contractId',
    children: [
      { path: '', component: ContractsDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
