import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { GuaranteesService } from 'src/app/modules/guarantees/_services/guarantees.service';
import { Guarantee } from 'src/app/modules/guarantees/_types/guarantee';
import { ClientPolicyGuaranteesService } from '../../_services/clients-policies-guarantees.service';
import { ClientPolicyGuarantee } from '../../_types/client-policy-guarantee';

@Component({
  selector: 'app-client-policy-guarantees',
  templateUrl: './client-policy-guarantees.component.html',
  styleUrls: ['./client-policy-guarantees.component.scss']
})
export class ClientPolicyGuaranteesComponent {
  public guarantees: Guarantee[] = [];
  public selectedGuarantees: any[] = [];
  public oldSelectedGuarantees: any[] = [];
  public clientPolicyGuarantees: any[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };

  constructor(
    private guaranteesService: GuaranteesService,
    private clientPolicyGuaranteesService: ClientPolicyGuaranteesService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  async ngOnInit() {
    let clientPolicyId = this.config.data.clientPolicyId;
    this.loadSelectedGuarantees(clientPolicyId);
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows }
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.guaranteesService.all(params));
    this.guarantees = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadSelectedGuarantees(policyId: string) {
    let params: IParamsCollection = {
      include: ['guarantee']
    }

    let filters: any = {
      clientPolicyId: new Filter(FilterOperation.equals, "clientPolicyId"),
    };

    filters.clientPolicyId.value = policyId;

    params.filter = FilterBuilder.fromObject(filters).build();
    let response = await firstValueFrom(this.clientPolicyGuaranteesService.all(params));
    this.selectedGuarantees = response.data.map(x => x.guarantee);
    this.oldSelectedGuarantees = response.data.map(x => x.guarantee);
    this.clientPolicyGuarantees = response.data;
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let guaranteeIds = this.selectedGuarantees.map(x => x.id);
    let oldGuaranteeIds = this.oldSelectedGuarantees.map(x => x.id);

    let toAdd = guaranteeIds.filter(x => !oldGuaranteeIds.includes(x));
    let toRemove = oldGuaranteeIds.filter(x => !guaranteeIds.includes(x));

    // Crear una lista de promesas para agregar utilizando map en lugar de forEach
    const addPromises = toAdd.map(guaranteeId => {
      let clientPolicyGuarantee = new ClientPolicyGuarantee();
      clientPolicyGuarantee.clientPolicyId = this.config.data.clientPolicyId;
      clientPolicyGuarantee.guaranteeId = guaranteeId;
      return firstValueFrom(this.clientPolicyGuaranteesService.save(clientPolicyGuarantee));
    });

    // Crear una lista de promesas para eliminar utilizando map en lugar de forEach
    const removePromises = toRemove.map(guaranteeId => {
      let clientPolicyGuarantee = this.clientPolicyGuarantees.find(x => x.guaranteeId == guaranteeId);
      return firstValueFrom(this.clientPolicyGuaranteesService.delete(clientPolicyGuarantee.id));
    });

    // Esperar a que todas las promesas se completen
    await Promise.all([...addPromises, ...removePromises]);

    // Una vez que todas las operaciones asíncronas hayan terminado, mostrar el mensaje y cerrar el diálogo
    this.messageService.add({ severity: 'success', summary: 'Guardado', detail: 'Cambios guardados correctamente' });
    this.dynamicDialogRef.close();

  }
}
