import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { AuthenticationsServicesService } from '../_services/authentications-services.service';
import { AuthenticationService } from '../_types/authentication-service';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-authentications-services-detail',
  templateUrl: './authentications-services-detail.component.html',
  styleUrls: ['./authentications-services-detail.component.css']
})
export class AuthenticationsServicesDetailComponent implements OnInit {
    

  public authenticationServiceFormGroup!: RxFormGroup;
  
  public authenticationService: AuthenticationService = new AuthenticationService();
  public zones: any = [];
  public countries: any = [];
  public courts: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  public activeIndex: number = 1;  
  public totalRecordsCalendar: number = 0;
  public blockDaysHolidays: any = [];
  public totalRecordsHolidays: number = 0;
  public blockDaysBlock: any = [];
  public totalRecordsBlock: number = 0;
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private authenticationsServicesService: AuthenticationsServicesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {

    this.topbarService.setTitle("Detalles del servicio de autenticación");    
    this.topbarService.addBreadcrumb("Servicios de autenticación", "/authentications-services");     

    this.authenticationServiceFormGroup = this.formBuilder.formGroup<AuthenticationService>(this.authenticationService) as RxFormGroup;
  }

  ngOnInit(): void {
        
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      if(id!=null && id!='0'){
        this.loadAuthenticationService(id);        
      }          
    });

    this.allowWrite = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_DELETE");
  }
  
  async loadAuthenticationService(id: any) {
    this.authenticationService = await firstValueFrom(this.authenticationsServicesService.get(id));
    this.authenticationServiceFormGroup.patchValue(this.authenticationService);
  }
  
  
  async save() {
    
    let valid = this.formValidationService.validateForm(this.authenticationServiceFormGroup);
    if (valid) {

      this.authenticationService = Object.assign(this.authenticationService, this.authenticationServiceFormGroup.value);         
            
      await firstValueFrom(this.authenticationsServicesService.save(this.authenticationService));

      //guardamos operario

      this.messageService.add({ closable: false, severity: 'success', summary: "Servicio de autentificación guardado correctamente." });
      this.router.navigate(["authentications-services"]);
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {

    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio de autenticación?",
        header: "Eliminar servicio de autenticación",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.authenticationsServicesService.delete(this.authenticationService.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio de autenticación eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });  
  }
  

  cancel() {
    this.router.navigate(['authentications-services']);
  }
  
}
