import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapabilitiesListComponent } from './capabilities-list/capabilities-list.component';
import { CapabilitiesDetailComponent } from './capabilities-detail/capabilities-detail.component';

const routes: Routes = [
    {
        path: 'capabilities',
        children: [
            { path: '', component: CapabilitiesListComponent },
            { path: ':guid', component: CapabilitiesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CapabilitiesRoutingModule { }
