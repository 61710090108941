import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { User } from "../../users/_types/user";

export class ChatRoomMessage extends Resource {
    public override type = 'chatRoomMessages';

    @prop()
    @required()
    public chatRoomId!: string;

    @prop()
    @required()
    public message!: string;

    @prop()
    public createdAt!: Date;

    @prop()
    public createdBy!: string;

    @propObject()
    public createdByUser?: User;

}