import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { File } from "../_types/file";
import { AsyncSubject, Observable } from "rxjs";
@Injectable({
  providedIn: 'root',
})

export class FilesService extends Service<File> {
  public override type = 'files';
  public override url = 'common/files';

  public upload(id: string, file: any): Observable<File> {

    let subject = new AsyncSubject<File>();

    console.log(file);
    let data = new FormData();
    data.append('file', file);

    let url = this.getAPIUrl() + '/' + id + '/upload';
    let headers = {
      //  'Content-Type': 'application/vnd.api+json'
    };
    this.http.put(url, data/*, { headers: headers }*/).subscribe((response: any) => {
      subject.next(response);
      subject.complete();
    });

    return subject.asObservable();
  }

  public download(id: string) {
    let url = this.getAPIUrl() + '/' + id + '/download';
    window.open(url, '_blank');
  }

}
