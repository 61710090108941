import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';

import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { RatesComponent } from './list/rates-list.component';
import { RatesDetailComponent } from './detail/rates-detail.component';
import { RatesRoutingModule } from './rates-routing.module';
import { RateLineComponent } from './detail/rate-line/rate-line.component';
import { RateCompanyComponent } from './detail/rate-company/rate-company.component';
import { RateOperatorComponent } from './detail/rate-operator/rate-operator.component';
import { RateUploadExcelComponent } from './detail/rate-upload-excel/rate-upload-excel.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [
    RatesComponent,
    RatesDetailComponent,
    RateLineComponent,
    RateCompanyComponent,
    RateOperatorComponent,
    RateUploadExcelComponent
  ],
  imports: [
    RatesRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    TableModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    CheckboxModule,
    TabViewModule,    
    CalendarModule,
    TagModule,
    InputTextareaModule,
    DropdownModule,
    DialogModule ,
    EditorModule,
    ReactiveFormsModule,
    FileUploadModule,
    MultiSelectModule
  ],
  providers: []
})
export class RatesModule { }
