<div class="grid">
    <p-card class="col-12" header="Servicios pendientes de revisar">
        <div>
            <p-table #grid [value]="caseServices" [loading]="loadingServices" [paginator]="true" [rows]="20"
                [totalRecords]="totalServicesRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[20,50,100]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Expediente</th>
                        <th>Cliente</th>
                        <th pSortableColumn="date" width="120">Fecha/Hora <p-sortIcon field="date"></p-sortIcon></th>
                        <th pSortableColumn="operatorId">Operario <p-sortIcon field="operatorId"></p-sortIcon></th>
                        <th pSortableColumn="isUrgent" width="80">Urgente <p-sortIcon field="isUrgent"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dateStart" width="120">Estado</th>
                        <th pSortableColumn="dateStart" width="90">Validado</th>
                        <th width="120"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-service let-columns="columns">
                    <tr (dblclick)="editCaseService(service)">
                        <td>
                            <a [routerLink]="['/cases', service.case.id]">{{service.case?.reference}}</a><br>
                            <small>{{service.case?.caseNumber}} - {{service.case?.company.name}}</small>
                        </td>
                        <td>{{service.case?.client?.name}}</td>
                        <td>{{service.date | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>
                            <div>{{service.operator?.name}}</div>
                            <div>
                                <a *ngIf="service.operator?.phone1!=null"
                                    (click)="op.toggle($event)">{{service.operator?.phone1}}</a>
                                <a *ngIf="service.operator?.phone2!=null"
                                    (click)="op.toggle($event)">{{service.operator?.phone2}}</a>
                                <a *ngIf="service.operator?.mail!=null"
                                    href="mailto:{{service.operator?.mail}}">{{service.operator?.mail}}</a>
                            </div>
                            <p-overlayPanel #op>
                                <div class=" flex flex-column gap-2">
                                    <span>Llamar</span>
                                    <span>Enviar enlace</span>
                                </div>
                            </p-overlayPanel>
                        </td>
                        <td>
                            <p-tag *ngIf="!service.isUrgent" severity="info" value="Normal" />
                            <p-tag *ngIf="service.isUrgent" severity="danger" value="Urgente" />
                        </td>
                        <!--<td>{{service.dateStart | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{service.dateEnd | date: 'dd/MM/yyyy HH:mm'}}</td>-->
                        <td>
                            <p-tag *ngIf="service.dateStart==null && service.dateEnd==null" severity="warning" value="Pendiente" />
                            <p-tag *ngIf="service.dateStart!=null && service.dateEnd==null" severity="warning" value="En curso" />
                            <p-tag *ngIf="service.dateEnd!=null" severity="success" value="Finalizado" />
                        </td>
                        <td>
                            <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': service.isValidated, 'text-red-500 pi-times-circle': !service.isValidated }"></i>
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowWriteService" (click)="editCaseService(service)" [rounded]="true" [text]="false"><i
                                    class="pi pi-pencil"></i></p-button>
                                <p-button (click)="openChat(service)" [rounded]="true" [text]="false">
                                    <i class="pi pi-comments"></i>
                                </p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="7">
                            No se han encontrado servicios pendientes
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>
</div>