import { Component, OnInit } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { WorkerFile } from '../_types/worker-file';
import { WorkersService } from '../_services/workers.service';
import { firstValueFrom } from 'rxjs';
import { WorkersFilesService } from '../_services/workers-files.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { FilesService } from '../../common/_services/files.service';
import { File } from 'src/app/modules/common/_types/file';

@Component({
  selector: 'app-workers-file',
  templateUrl: './workers-file.component.html',
  styleUrls: ['./workers-file.component.css'],
})
export class WorkersFileComponent implements OnInit {
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public workerFileFormGroup!: RxFormGroup;

  public workerFile: WorkerFile = new WorkerFile();

  public files: any[] = [];
  public workerervices: WorkersService[] = [];
  public workerFileCategories: WorkerFile[] = [];

  constructor(
    private formBuilder: RxFormBuilder,
    private config: DynamicDialogConfig,
    private workerFileService: WorkersFilesService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private formValidationService: FormValidatorService,
    private filesService: FilesService
  ) {
    this.workerFileFormGroup = this.formBuilder.formGroup(WorkerFile) as RxFormGroup;
  }

  async ngOnInit() {
    let workerFileId = this.config.data.workerFileId;
    if (workerFileId) {
      await this.loadWorkerFile(workerFileId);
    } else {
      this.workerFile.workerId = this.config.data.workerId;
      this.workerFileFormGroup.patchValue(this.workerFile);
    }
    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadWorkerFile(workerFileId: string) {
    this.workerFile = await firstValueFrom(
      this.workerFileService.get(workerFileId)
    );
    this.workerFileFormGroup.patchValue(this.workerFile);
  }

  async onFileSelect(event: any) {
    let files = event.files;
    for (let file of files) {
      this.files.push(file);
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(
      this.workerFileFormGroup
    );
    if (valid && (this.files.length > 0 || this.workerFile.fileId)) {
      this.workerFile = Object.assign(this.workerFile, this.workerFileFormGroup.value);
      console.log(this.workerFile);

      if (this.files.length > 0) {
        try {
          for (let file of this.files) {
            let data: File = new File();
            data.fileName = file.name;
            data.size = file.size;
            data.contentType = file.type;
            //data.path = file.name;

            //creamos el file
            let fileResponse = await firstValueFrom(
              this.filesService.save(data)
            );

            //subimos el fichero al file
            let response = await firstValueFrom(this.filesService.upload(fileResponse.id as string, file));

            //guardamos el file en el worker
            this.workerFile.fileId = fileResponse.id as string;
            await firstValueFrom(this.workerFileService.save(this.workerFile));
          }
        } catch (error) {
          this.messageService.add({
            closable: false,
            severity: 'severity',
            summary: 'Error',
            detail: 'Error al guardar los ficheros.',
          });
        }
      } else {
        //guardamos el file en el expediente
        await firstValueFrom(this.workerFileService.save(this.workerFile));
      }

      this.messageService.add({
        closable: false,
        severity: 'success',
        summary: 'Éxito',
        detail: 'Ficheros guardados correctamente.',
      });
      this.dynamicDialogRef.close();

    } else {

      this.messageService.add({
        closable: false,
        severity: 'error',
        summary: 'Error',
        detail: 'Existen errores, por favor revise el formulario de datos.',
      });
      
    }
  }
}
