<form *ngIf="calendarFormGroup" [formGroup]="calendarFormGroup">
  <p-card class="p-3" header="Datos generales">  
    <div class="grid"> 
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error/>
      </div>      
      <div class="col-2 input-field">
        <label for="active" class="mr-1">Activo</label>
        <div class="flex h-full">
          <p-inputSwitch id="active" name="active" formControlName="active" d-form-field-error></p-inputSwitch>
        </div>
      </div>
    </div>          
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete && calendar.id" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>     
  </p-card>
  <p-card class="p-3" *ngIf="calendar.id">  
    <app-calendar-year [events]="calendarEvents" (onDayClick)="daySelected($event)" (onYearChange)="yearChanged($event)"></app-calendar-year>
  </p-card>
</form>
<p-confirmDialog></p-confirmDialog>
