import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, catchError, map, throwError } from 'rxjs';
import { LocalStorageService } from 'angular-web-storage';
import { LoaderService } from '../loader/loader.service';
import { even } from '@rxweb/reactive-form-validators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private requestPendings: number = 0;

  constructor(
    private loaderService: LoaderService,
    private storage:LocalStorageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSkipedRequest = this.loaderService.isRequestSkiped(request);
    if (!isSkipedRequest) this.requestPendings++;
    this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });

    let token = this.storage.get("token");
    let headers = request.headers;
    if(token!=null) headers = headers.set('Authorization', 'Bearer ' + token);
    const clonedRequest = request.clone({ headers: headers });

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (request.method != 'GET' || !isSkipedRequest) this.requestPendings--;
          this.loaderService.removeSkipRequest(request);
          this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });
        }
        return event;
      }),catchError((error: HttpErrorResponse) => {
        this.loaderService.removeSkipRequest(request);
        if (request.method != 'GET' || !isSkipedRequest) this.requestPendings--;
        this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });
        if (error.status == 401) {
          console.log("Request unauthorized. ", request.urlWithParams.toString());
        } else if (error.status == 404) {
          console.log("Not found!", request.urlWithParams.toString());
        }
        return throwError(error);
       })
    );
  }
}
