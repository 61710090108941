import { Injectable } from "@angular/core";

 
@Injectable({
  providedIn: 'root'
})
export class HttpService {
 
  cachedAccounts: any[] = [];
 
  constructor() { }
 
  getBasePath(): string {
    
    //let api_url = environment.api_url;
    let api_url = "http://localhost:5200/";
    return api_url;    
  }  

  getBasePathCore(): string {
    
    //let api_url = environment.api_url;
    let api_url = "http://localhost:5202/"
    return api_url;    
  }  

  getBasePathProvider(): string {
    
    //let api_url = environment.api_url;
    let api_url = "http://localhost:5203/"
    return api_url;    
  }  
 
  api(relativeUrl: string) {
    return this.getBasePath() + relativeUrl;
  }
 
}