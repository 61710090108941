import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Tax } from "../_types/tax";

@Injectable({
    providedIn: 'root',
})

export class TaxesService extends Service<Tax> {
  public override type = 'taxes';
  public override url = 'invoicing/taxes'; 
    
}
