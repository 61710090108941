import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class File extends Resource {
    public override type = 'files';

    @prop()
    @required()
    public fileName!: string;

    @prop()
    public path!: string;

    @prop()
    public contentType: string = 'application/octet-stream';

    @prop()
    public size: number = 0;

    
    @prop()
    public hash!: string ;

    
    @prop()
    public hashDate!: Date;

}