import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Zone } from "../_types/zone";

@Injectable({
  providedIn: 'root',
})
export class ZonesService extends Service<Zone> {
  public override type = 'zones';
  public override url = 'providers/zones';
}
