import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Company } from "./company";
import { Contract } from "./contract";
import { Rate } from "../../rates/_types/rate";

export class CompanyRate extends Resource {
    public override type = 'companyRates';
    
    @prop()    
    @required()
    public companyId!: string;
    public company!: Company;

    @prop()    
    public rateId!: string;
    public rate!: Rate;

    @prop()    
    public contractId!: string | null;
    public contract!: Contract;

}

