import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BanksListComponent } from './banks-list/banks-list.component';
import { BanksDetailComponent } from './banks-detail/banks-detail.component';

const routes: Routes = [
    {
        path: 'banks',
        children: [
            { path: '', component: BanksListComponent }
        ]
    },
    {
        path: 'banks/:id',
        children: [
            { path: '', component: BanksDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BanksRoutingModule { }
