import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Survey } from "../_types/survey";

@Injectable({
    providedIn: 'root',
})
export class SurveysService extends Service<Survey> {
    public override type = 'surveys';
    public override url = 'attendance/surveys';

    targetTypes() {
        let targetTypes = [
            { label: 'Cliente', value: 'Client' },
            { label: 'Usuario', value: 'User' }
        ];
        return targetTypes;
    }

    getTargetType(value: string) {
        let targetTypes = this.targetTypes();
        let targetType = targetTypes.find(x => x.value == value);
        return targetType?.label;
    }
}
