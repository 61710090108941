<form *ngIf="rateFormGroup" [formGroup]="rateFormGroup">
  <p-card class="p-3" header="Datos generales">
    <div class="grid">
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error/>
      </div>
      <div class="col-2 input-field ">
        <label for="fromDate">Desde</label>
        <p-calendar inputId="fromDate" appendTo="body" dateFormat="dd/mm/yy" formControlName="fromDate"
          d-form-field-error />
      </div>
      <div class="col-2 input-field ">
        <label for="toDate">Hasta</label>
        <p-calendar inputId="toDate" appendTo="body" dateFormat="dd/mm/yy" formControlName="toDate"
          d-form-field-error />

      </div>
    </div>    
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete && rate.id" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
    </div>
  </p-card>

  <p-tabView *ngIf="rate.id">
    <p-tabPanel header="Líneas de baremo" >      
      <div class="flex justify-content-end mb-2">
        <p-button label="Cargar Excel" (click)="loadExcel()" icon="pi pi-upload" styleClass="p-button-sm mr-1"
            *ngIf="allowWrite"></p-button>        
        <p-button label="Descargar Excel" (click)="downloadExcel()" icon="pi pi-download" styleClass="p-button-sm mr-1"
        *ngIf="allowWrite"></p-button>       
        <p-button *ngIf="allowWrite" label="Añadir línea" icon="pi pi-plus" (click)="editRateLine(null)" />
      </div>

    <p-card *ngFor="let capability of rateLinesCapabilities" [header]="capability.name">      
        <p-table #grid [value]="rateLinesByCapability(capability.id)" [scrollable]="true" scrollHeight="flex">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pSortableColumn="rateLineId">Código <p-sortIcon field="rateLineId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="description">Descripción <p-sortIcon
                            field="description"></p-sortIcon>
                    </th>                                        
                    <th pSortableColumn="unitPrice">Precio <p-sortIcon field="unitPrice"></p-sortIcon>
                    <th pSortableColumn="freePrice">Precio libre <p-sortIcon field="freePrice"></p-sortIcon>
                    <th pSortableColumn="uniqueService">Servicio único <p-sortIcon field="uniqueService"></p-sortIcon>
                      <th pSortableColumn="fixedAmount">Cantidad fijada <p-sortIcon field="fixedAmount"></p-sortIcon>
                    </th>                    
                    <th width=" 100"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rateLine let-columns="columns">
                <tr (dblclick)="editRateLine(rateLine)">
                    <td>{{rateLine.code}}</td>
                    <td>{{rateLine.description}}</td>                    
                    <td>{{rateLine.price | currency:'EUR'}}</td>     
                    <td>
                      <i class="pi"
                        [ngClass]="{ 'text-green-500 pi-check-circle': rateLine.freePrice, 'text-red-500 pi-times-circle': !rateLine.freePrice }"></i>
                    </td>
                    <td>
                      <i class="pi"
                        [ngClass]="{ 'text-green-500 pi-check-circle': rateLine.uniqueService, 'text-red-500 pi-times-circle': !rateLine.uniqueService }"></i>
                    </td>        
                    <td>{{rateLine.fixedAmount}}</td>                                
                    <td>
                      <div class="flex gap-2 justify-content-end">
                        <p-button *ngIf="allowDelete" (click)="deleteRateLine(rateLine.id)" [rounded]="true"
                            [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                        <p-button *ngIf="allowWrite" (click)="editRateLine(rateLine)" [rounded]="true"
                            [text]="false"><i class="pi pi-pencil"></i></p-button>
                      </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
    <span *ngIf="rateLinesCapabilities.length == 0" >      
      No se han encontrado líneas de baremo
    </span>
    
    </p-tabPanel>    
    
    <p-tabPanel header="Compañías aseguradoras" *ngIf="type=='COMPANY'">
      <div class="flex justify-content-end pb-1">         
        <p-button label="Añadir compañía" (click)="addCompany({})" styleClass="p-button-sm"
            *ngIf="allowWrite"></p-button>        
      </div>
      <p-table styleClass="mt-4" [value]="companies" [paginator]="true" [rows]="25"
          [totalRecords]="totalCompaniesRecords" [showCurrentPageReport]="true"
          currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
          [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
          paginatorDropdownAppendTo="body">
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th pSortableColumn="company">Compañía aseguradora <p-sortIcon field="company"></p-sortIcon></th>                
                  <th pSortableColumn="contract">Contrato <p-sortIcon field="contract"></p-sortIcon></th>                
                  <th width="100"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rateCompany let-columns="columns">
              <tr (dblclick)="addCompany(rateCompany)">
                  <td>{{rateCompany.company.name}}</td>                
                  <td>{{rateCompany.contractId != null?rateCompany.contract?.name: "Todos"}}</td>    
                  <td>
                    <div class="flex gap-2 justify-content-end">
                      <p-button *ngIf="allowDelete" (click)="deleteCompany(rateCompany)" [rounded]="true"
                                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                      <p-button *ngIf="allowWrite" (click)="addCompany(rateCompany)" [rounded]="true"
                          [text]="false"><i class="pi pi-pencil"></i></p-button>                                        
                    </div>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td [attr.colspan]="2">
                      No se han encontrado compañías aseguradoras
                  </td>
              </tr>
          </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Operarios" *ngIf="type=='OPERATOR'">
      <div class="flex justify-content-end pb-1">
        <p-button label="Añadir operario" (click)="addOperator({})" styleClass="p-button-sm"
            *ngIf="allowWrite"></p-button>        
      </div>
      <p-table styleClass="mt-4" [value]="operators" [paginator]="true" [rows]="25"
          [totalRecords]="totalOperatorsRecords" [showCurrentPageReport]="true"
          currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
          [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
          paginatorDropdownAppendTo="body">
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th pSortableColumn="company">Operario <p-sortIcon field="company"></p-sortIcon></th>                                  
                  <th width="100"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rateOperator let-columns="columns">
              <tr (dblclick)="addOperator(rateOperator)">
                  <td>{{rateOperator.operator.name}}</td>                                  
                  <td>
                    <div class="flex gap-2 justify-content-end">
                      <p-button *ngIf="allowDelete" (click)="deleteOperator(rateOperator)" [rounded]="true"
                                  styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                      <p-button *ngIf="allowWrite" (click)="addOperator(rateOperator)" [rounded]="true"
                          [text]="false"><i class="pi pi-pencil"></i></p-button>                                        
                    </div>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td [attr.colspan]="2">
                      No se han encontrado operarios
                  </td>
              </tr>
          </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>