    
        <div class="grid">
            <div class="col-8 input-field">
                <label for="capabilityId">Presupuesto a la compañía</label>
                <p-dropdown [(ngModel)]="selectedTransaction" [ngModelOptions]="{standalone:true}" [options]="transactions"
                    (onChange)="loadTransactionLines()" optionLabel="customName" optionValue="id" [filter]="true" filterBy="customName"
                    appendTo="body" placeholder="Selecciona un presupuesto" d-form-field-error></p-dropdown>
            </div>
        </div>        
        <p-table styleClass="m-1 mt-4" #grid [value]="transactionLines" [loading]="loadingTransactionLines"            
            [scrollable]="true" scrollHeight="flex"
            [(selection)]="selectedLines"
            dataKey="id" 
            >
            <ng-template pTemplate="header" let-columns>
                <tr>                    
                    <th>Seleccionar</th>                    
                    <th>Baremo</th>                                            
                    <th style="text-align: right;">Cantidad</th>                        
                    <th style="text-align: right;">Precio unidad</th>      
                    <th style="text-align: right;">Descuento</th>                        
                    <th style="text-align: right;">Impuestos</th>                        
                    <th style="text-align: right;">Importe</th>                                            
                </tr>
            </ng-template> 
            <ng-template pTemplate="body" let-transactionLine let-i="rowIndex" let-columns="columns" let-index="rowIndex">
                <tr *ngIf="transactionLine.isGroup" [pReorderableRow]="i" [class.rowGroup]="transactionLine.isGroup">                    
                    <td [attr.colspan]="type=='Company'?8:7" class="rowGroup"><b>{{transactionLine.description}}</b></td>                    
                </tr>
                <tr *ngIf="!transactionLine.isGroup" [pReorderableRow]="i">
                    <td>
                        <p-tableCheckbox [value]="transactionLine"></p-tableCheckbox>
                    </td>
                    <td>{{transactionLine.rateLine?.code}} {{transactionLine.rateLine?.description}}</td>
                    
                    <td style="text-align: right;">{{transactionLine.quantity}}</td>
                    <td style="text-align: right;">{{transactionLine.price}}</td>
                    <td style="text-align: right;">{{'('+(transactionLine.discountPercent!=null?transactionLine.discountPercent:0)+'%) '}}{{transactionLine.discountAmount | currency: 'EUR' }}</td>                        
                    <td style="text-align: right;">{{'('+(transactionLine.taxPercent!=null?transactionLine.taxPercent:0)+'%) '}}{{transactionLine.taxAmount | currency: 'EUR' }}</td>
                    <td style="text-align: right;">{{transactionLine.subtotal | currency: 'EUR' }}</td>                    
                </tr>
            </ng-template>            
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td style="text-align: center;" colspan="9">
                        No se han encontrado líneas.
                    </td>
                </tr>
            </ng-template>
        </p-table>    
    <div class=" mt-4 flex gap-2 justify-content-end">        
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
<p-confirmDialog></p-confirmDialog>