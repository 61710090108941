
import { Resource } from "src/app/core/jsonapi/resource";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class VehicleType extends Resource {
    public override type = 'vehicleTypes';

    @prop()
    @required()
    public name!: string;      
}