import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { SurveyAnswer } from "../_types/survey-answer";

@Injectable({
    providedIn: 'root',
})
export class SurveysAnswersService extends Service<SurveyAnswer> {
    public override type = 'surveyAnswers';
    public override url = 'attendance/surveyAnswers';
}
