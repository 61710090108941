<form *ngIf="clientFormGroup" [formGroup]="clientFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-4 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-3 input-field">
                <label for="vatNumber">CIF *</label>
                <input pInputText formControlName="vatNumber" d-form-field-error />
            </div>
            <div class="col-2 input-field">
                <label for="active" class="mr-1">Activo</label>
                <div class="flex h-full">
                    <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="grid pt-2">
            <div class="col input-field ">
                <label for="legalName">Razón social</label>
                <input pInputText formControlName="legalName" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="phone1">Teléfono</label>
                <input pInputText formControlName="phone1" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="email">Email</label>
                <input pInputText formControlName="email" type="text" d-form-field-error />
            </div>

        </div>
        <div class="grid pt-2" formGroupName="address">
            <div class="col input-field col-3">
                <label for="name">Dirección *</label>
                <input pInputText formControlName="addressLine1" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Código postal</label>
                <input pInputText formControlName="zipCode" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Ciudad</label>
                <input pInputText formControlName="city" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">País</label>
                <p-dropdown [options]="countries" formControlName="countryCode" optionValue="code" [filter]="true" appendTo="body"
                    filterBy="name" optionLabel="name" placeholder="Seleccione país" d-form-field-error />
            </div>
        </div>
        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete && client.id" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>
    <p-tabView>
        <p-tabPanel header="Datos bancarios">
            <div class="formgrid grid pt-2" formGroupName="bankAccount">
                <div class="col input-field ">
                    <label for="name">Banco</label>
                    <p-dropdown formControlName="bankId" [options]="banks" optionLabel="name" optionValue="id" appendTo="body"
                        [filter]="true" filterBy="name" placeholder="Selecciona un banco" class="ng-dirty ng-invalid"
                        d-form-field-error />
                </div>
                <div class="col input-field ">
                    <label for="name">Nº Cuenta</label>
                    <input pInputText formControlName="accountNumber" type="text" d-form-field-error />
                </div>
                <div class="col input-field ">
                    <label for="name">Swift</label>
                    <input pInputText formControlName="swift" type="text" d-form-field-error />
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Pólizas" [disabled]="!client.id">
            <div class="flex justify-content-end mb-2">
                <p-button *ngIf="allowWrite" label="Añadir póliza" icon="pi pi-plus" (click)="editClientPolicy(null)" />
            </div>

            <p-table #grid [value]="clientPolicies" [loading]="loadingClientPolicies" [paginator]="true" [rows]="5"
                [totalRecords]="totalClientPoliciesRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[5,10,20]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="policyNumber">Número de póliza <p-sortIcon
                                field="policyNumber"></p-sortIcon></th>
                        <th pSortableColumn="company.name">Compañía <p-sortIcon field="company.name"></p-sortIcon></th>
                        <th pSortableColumn="description">Descripción <p-sortIcon field="description"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-clientPolicy let-columns="columns">
                    <tr (dblclick)="editClientPolicy(clientPolicy)">
                        <td>{{clientPolicy.policyNumber}}</td>
                        <td>{{clientPolicy.company?.name}}</td>
                        <td>{{clientPolicy.description}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>