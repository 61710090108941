import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { AuthenticationsServicesComponent } from './list/authentications-services-list.component';
import { AuthenticationsServicesDetailComponent } from './detail/authentications-services-detail.component';
import { AuthenticationsServiceRoutingModule } from './authentications-services-routing.module';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    AuthenticationsServicesComponent,
    AuthenticationsServicesDetailComponent        
  ],
  imports: [
    AuthenticationsServiceRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    CheckboxModule,
    TabViewModule,    
    CalendarModule    
  ],
  providers: []
})
export class AuthenticationsServicesModule { }
