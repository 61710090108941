import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractProcessor } from "../_types/contract-processor";

@Injectable({
    providedIn: 'root',
})
export class ContractProcessorsService extends Service<ContractProcessor> {
  public override type = 'contractProcessors';  
  public override url = 'attendance/contractProcessors'; 
}
