import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Rate } from "../../rates/_types/rate";
import { Operator } from "../../companies/_types/operator";

export class OperatorRate extends Resource {
    public override type = 'operatorRates';
    
    @prop()    
    @required()
    public operatorId!: string;
    public operator!: Operator;

    @prop()    
    public rateId!: string;
    public rate!: Rate;    

}

