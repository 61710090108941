import { Component } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { WorkersService } from '../_services/workers.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { ChatService } from '../../chat/_services/chat.service';
import { Worker } from '../_types/worker';

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss']
})
export class WorkersListComponent {
  public workers: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private workersService: WorkersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private topbarService: TopbarService,
    private chatService: ChatService
  ) {
    this.topbarService.setTitle("Operarios");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({});
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")?
  }

  async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows },
      include: ['address', 'parentWorker'],
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.workersService.all(params));
    this.workers = response.data;
    this.totalRecords = response.meta['total'];
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(worker: any) {
    this.router.navigate(['workers/' + (worker && Object.keys(worker).length > 0 ? worker.id : 0)]);
  }

  delete(worker: any) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el operario?",
        header: "Eliminar operario",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.workersService.delete(worker.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Operario eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  getSeverity(active: boolean) {
    if (active) {
      return 'success';
    } else {
      return 'danger';
    }
  }

  openChat(worker: Worker) {
    this.chatService.openChatWorker(worker);
  }
}
