import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class TransactionType extends Resource {
    public override type = 'transactionTypes';

    @prop()
    @required()
    public code!: string;

    @prop()
    @required()
    public name!: string;

    @prop()
    public active: boolean = true;
    
    @prop()
    public thirdPartyType!: string;
}