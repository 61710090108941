import { NgModule } from '@angular/core';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsSidebarComponent } from './notifications-sidebar/notifications-sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { CardModule } from 'primeng/card';
import { DatePipe } from '@angular/common';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    NotificationsSidebarComponent,
    NotificationsListComponent
  ],
  imports: [
    FormsModule,
    NotificationsRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CardModule,
    TableModule,
    ConfirmDialogModule,
    InputTextModule,
    DropdownModule,
    DatePipe,
    TabViewModule,
    ButtonModule
  ],
  exports: [
    NotificationsSidebarComponent
  ],
  providers: []
})
export class NotificationsModule {
}
