import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class AuthenticationService extends Resource {
    public override type = 'authenticationServices';
    
    @prop()
    @required()
    public name!: string;        
    @prop()
    @required()
    public clientId!: string;        
    @prop()
    @required()
    public secret!: string;        
    @prop()
    @required()
    public callbackUrl!: string;        
    @prop()
    @required()
    public openIdUrl!: string;        
    @prop()
    @required()
    public scopes!: string;        
    @prop()
    @required()
    public issuer!: string;        
    @prop()
    @required()
    public audience!: string;        
    @prop()
    @required()
    public active: boolean = true;        

}