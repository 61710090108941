<div class="pl-4 pr-4 flex flex-column h-full" *ngIf="user!=null && chatRoom!=null">
    <div #chatMessages class="chat-messages flex-grow-1">
        <div *ngFor="let message of messages" [class.chat-message-left]="user.id!=message.createdBy" [class.chat-message-right]="user.id==message.createdBy">
            <div class="chat-message">
                <div class="message-info">
                    <span class="date">{{message.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                    <span class="user-name">{{message.createdByUser?.name}}</span>
                </div>
                <div class="message">{{message.message}}</div>
            </div>
        </div>
        <div *ngIf="messages.length==0">
            Todavía no hay mensajes
        </div>
    </div>
    <div class="mt-4 flex gap-2">
        <input pInputText type="text" placeholder="Escribe tu mensaje" [(ngModel)]="message" class="flex-grow-1" (keyup.enter)="sendMessage()" />
        <p-button (click)="sendMessage()" icon="pi pi-send" styleClass="h-full" />
    </div>
</div>
<div class="pl-4 pr-4 flex flex-column h-full" *ngIf="user==null || chatRoom==null">
    <div *ngIf="user==null">
        Usuario no identificado correctamente
    </div>
    <div *ngIf="chatRoom==null">
        Cargando sala, por favor espere...
    </div>
</div>