import { Component } from '@angular/core';
import { WorkersService } from '../../_services/workers.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Worker } from '../../_types/worker';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CountriesService } from 'src/app/modules/common/_services/countries.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { firstValueFrom } from 'rxjs';
import { WorkerBankAccount } from '../../_types/worker-bank-account';

@Component({
  selector: 'app-add-subworker',
  templateUrl: './add-subworker.component.html',
  styleUrls: ['./add-subworker.component.scss']
})
export class AddSubworkerComponent {

  public workerFormGroup!: RxFormGroup;

  public worker: Worker = new Worker();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public countries: any = [];

  constructor(
    private workersService: WorkersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.workerFormGroup = this.formBuilder.formGroup<Worker>(this.worker) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCountries();

    this.worker.parentWorkerId = this.config.data.parentWorkerId;
    this.worker.bankAccount = new WorkerBankAccount();

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.workerFormGroup);
    if (valid) {
      this.worker = Object.assign(this.worker, this.workerFormGroup.value);

      let workerItem = await firstValueFrom(this.workersService.save(this.worker, { include: ['bankAccount'] }));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Suboperario guardado correctamente." });

      this.dynamicDialogRef.close();

    } else {
      console.log(this.workerFormGroup.getErrorSummary(true));
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

}
