import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { TransactionState } from "../_types/transaction-state";

@Injectable({
    providedIn: 'root',
})
export class TransactionsStatesService extends Service<TransactionState> {
    public override type = 'transactionStates';
    public override url = 'invoicing/transactionStates';
}
