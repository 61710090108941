import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuaranteesListComponent } from './guarantees-list/guarantees-list.component';
import { GuaranteesDetailComponent } from './guarantees-detail/guarantees-detail.component';

const routes: Routes = [
    {
        path: 'guarantees',
        children: [
            { path: '', component: GuaranteesListComponent }
        ]
    },
    {
        path: 'guarantees/:id',
        children: [
            { path: '', component: GuaranteesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GuaranteesRoutingModule { }
