import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { ClientBankAccount } from "./client-bank-account";
import { ClientAddress } from "./client-address";

export class Client extends Resource {
    public override type = 'clients';

    @prop()
    @required()
    public name!: string;

    @prop()
    public legalName!: string;

    @prop()
    public typeOfBusiness: number = 1;
    @prop()
    public email!: string;
    @prop()
    public phone1!: string;
    @prop()
    public active: boolean = true;
    @prop()
    @required()
    public vatNumber!: string;

    @prop()
    public bankAccountId!: string;

    @propObject(ClientBankAccount)
    public bankAccount: ClientBankAccount = new ClientBankAccount();

    @prop()
    public addressId!: string;

    @propObject(ClientAddress)
    public address: ClientAddress = new ClientAddress();

}