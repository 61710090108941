import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { TransactionType } from "./transaction-type";

export class TransactionState extends Resource {
    public override type = 'transactionStates';

    @prop()
    @required()
    public code!: string;
    
    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public transactionTypeId!: string ;    

    @propObject(TransactionType)
    public transactionType: TransactionType = new TransactionType();

    @prop()
    public active: boolean = true;

    @prop()
    public isInitial: boolean = false;
    

}