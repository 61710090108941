import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseService } from "../_types/case-service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CasesServicesService extends Service<CaseService> {
    public override type = 'caseServices';
    public override url = 'attendance/caseServices';
    
}
