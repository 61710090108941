import { prop } from "@rxweb/reactive-form-validators";
import { min } from "moment";
import { Resource } from "src/app/core/jsonapi/resource";

export class WorkerAddress extends Resource {
    public override type = 'workerAddresses';

    @prop()
    public addressLine1!: string;
    @prop()
    public addressLine2!: string;
    @prop()
    public city!: string;
    @prop()
    public zipCode!: string;
    @prop()
    public countryCode!: string;
    @prop()
    public latitude!: string;
    @prop()
    public longitude!: string;
}