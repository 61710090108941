<p-card header="Datos del servicios">
    <form *ngIf="caseServiceFormGroup" [formGroup]="caseServiceFormGroup">
        <div class="grid">
            <div class="col-4 input-field">
                <label for="date">Fecha</label>
                <p-calendar formControlName="date" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                    [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
            </div>
            <div class="col-8 input-field">
                <label for="operatorId">Operario</label>
                <p-dropdown [options]="operators" formControlName="operatorId" optionLabel="name" optionValue="id"
                    [filter]="true" filterBy="name" appendTo="body" placeholder="Selecciona un operador"
                    d-form-field-error></p-dropdown>
            </div>
            <div class="col-4 input-field">
                <label for="dateStart">Desde</label>
                <p-calendar formControlName="dateStart" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                    [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
            </div>
            <div class="col-4 input-field">
                <label for="dateEnd">Hasta</label>
                <p-calendar formControlName="dateEnd" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                    [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error appendTo="body"></p-calendar>
            </div>
            <div class="col-2 input-field">
                <label for="isUrgent">Urgente</label>
                <p-inputSwitch formControlName="isUrgent" d-form-field-error></p-inputSwitch>
            </div>
            <div class="col-2 input-field">
                <label for="isValidated">Validado</label>
                <p-inputSwitch formControlName="isValidated" d-form-field-error></p-inputSwitch>
            </div>
            <div class="col-12 input-field">
                <label for="pendingTasksDescription">Trabajos pendientes</label>
                <textarea formControlName="pendingTasksDescription" [autoResize]="true" pInputTextarea rows="1"
                    d-form-field-error></textarea>
            </div>
            <div class="col-12 input-field">
                <label for="realizedTasksDescription">Trabajos realizados</label>
                <textarea formControlName="realizedTasksDescription" [autoResize]="true" pInputTextarea rows="1"
                    d-form-field-error></textarea>
            </div>
        </div>
        <div class="mt-2 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </form>
</p-card>

<div class="mt-4" *ngIf="caseService.id">
    <p-card styleClass="p-card-content-no-padding p-card-body-pt-0">
        <ng-template pTemplate="header">
            <div class="p-card-title flex gap-2">
                <div class="flex-grow-1">Trabajos al operario</div>
                <p-button size="small" label="Añadir trabajo" icon="pi pi-plus" (click)="editJob('OPERATOR', null)"
                    *ngIf="allowWrite && !caseService.operator.hasSubmitInvoice" />
                <p-button size="small" label="Añadir factura" icon="pi pi-plus" (click)="editTransaction(null)"
                *ngIf="allowWrite && caseService.operator.hasSubmitInvoice" />
            </div>
        </ng-template>
        <div>
            <p-table [value]="operatorJobs" *ngIf="!caseService.operator.hasSubmitInvoice">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Baremo</th>
                        <th>Revisado</th>
                        <th>Facturable</th>
                        <th style="text-align: right;">Cantidad</th>
                        <th style="text-align: right;">Precio</th>
                        <th style="text-align: right;">Impuestos</th>    
                        <th style="text-align: right;">Total</th>
                        <th width="120"></th>
                    </tr>
                </ng-template>
                <!-- Añadir mensaje cuando esté vacío -->
                <ng-template pTemplate="body" let-operatorJob>
                    <tr (dblclick)="editJob('OPERATOR', operatorJob)">
                        <td>
                            {{operatorJob.date | date: 'dd/MM/YY HH:mm'}}
                        </td>
                        <td>
                            {{operatorJob.rateLine?.code}} - {{operatorJob.rateLine?.description}}
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': operatorJob.isReview, 'text-red-500 pi-times-circle': !operatorJob.isReview}"></i>
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': operatorJob.isBillable, 'text-red-500 pi-times-circle': !operatorJob.isBillable}"></i>
                        </td>
                        <td style="text-align: right;">
                            {{operatorJob.quantity}}
                        </td>
                        <td style="text-align: right;">
                            {{operatorJob.unitPrice}}
                        </td>
                        <td style="text-align: right;">
                            {{'('+(operatorJob.taxPercent!=null?operatorJob.taxPercent:0)+'%) '}}{{operatorJob.taxAmount | currency: 'EUR' }}
                        </td>
                        <td style="text-align: right;">
                            {{operatorJob.total}}
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end" style="width: 100%;">
                                <!--<p-button *ngIf="allowDelete && operatorJob.transactionId == null" (click)="deleteJob(operatorJob)" [rounded]="true" [text]="false"
                                severity="danger"><i class="pi pi-trash"></i></p-button>-->
                                <p-button *ngIf="allowWrite" (click)="editJob('OPERATOR', operatorJob)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="7" class="text-right">Total</td>
                        <td style="text-align: right;">{{operatorJobsTotal | currency: 'EUR'}}</td>
                        <td colspan="1"></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td>
                            No se han encontrado trabajos registrados
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="caseService.operator.hasSubmitInvoice">
                <p-messages severity="warn">
                    <ng-template pTemplate>
                        Este operario debe presentar factura, no olvides adjuntarla.
                    </ng-template>
                </p-messages>
                <p-table styleClass="mt-4" #grid [value]="transactionsOperator" [lazy]="true" *ngIf="transactionsOperator.length > 0"
                    [loading]="loadingTransactionsOperator" [paginator]="true" [rows]="25" [totalRecords]="totalTransactionsOperatorRecords"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                    paginatorDropdownAppendTo="body">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pSortableColumn="code">Código <p-sortIcon field="code"></p-sortIcon></th>
                            <th pSortableColumn="date">Fecha <p-sortIcon field="date"></p-sortIcon></th>
                            <th pSortableColumn="transactionState.name">Estado <p-sortIcon
                                    field="transactionState.name"></p-sortIcon></th>                            
                            <th style="text-align: right;" pSortableColumn="total">Total <p-sortIcon field="total"></p-sortIcon></th>
                            <th width="100"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transactionLine let-columns="columns">
                        <tr (dblclick)="editTransaction(transactionLine)">
                            <td>{{transactionLine.transaction.code}}</td>
                            <td>{{transactionLine.transaction.date | date:'dd/MM/yy'}}</td>
                            <td>{{transactionLine.transaction.transactionState?.name}}</td>                            
                            <td style="text-align: right;">{{transactionLine.transaction.total | currency:'EUR'}}</td>
                            <td>
                                <div class="flex gap-2 justify-content-end">
                                    <p-button *ngIf="allowWrite" (click)="downloadFile(transactionLine.transaction.fileId)" [rounded]="true"
                                        [text]="false"><i class="pi pi-download"></i></p-button>
                                    <p-button *ngIf="allowDelete" (click)="deleteTransaction(transactionLine)" [rounded]="true"
                                        [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                    <p-button *ngIf="allowWrite" (click)="editTransaction(transactionLine)" [rounded]="true"
                                        [text]="false"><i class="pi pi-pencil"></i></p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            
                            <td colspan="4" style="text-align: right;">Total: {{transactionLinesTotal | currency: 'EUR'}}</td>
                            <td colspan="1"></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="6">
                                No se ha encontrado transacciones
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </p-card>

    <p-card styleClass="mt-4 p-card-content-no-padding p-card-body-pt-0">
        <ng-template pTemplate="header">
            <div class="p-card-title flex gap-2">
                <div class="flex-grow-1">Trabajos a la compañia</div>
                <p-button size="small" label="Importar líneas presupuesto" icon="pi pi-plus"
                    (click)="importTransactionLines('OPERATOR')" />
                <p-button size="small" label="Añadir trabajo" icon="pi pi-plus" (click)="editJob('COMPANY', null)"
                    *ngIf="allowWrite" />
            </div>
        </ng-template>
        <div>
            <p-table [value]="companyJobs">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Baremo</th>
                        <th>Revisado</th>
                        <th>Facturable</th>
                        <th style="text-align: right;">Cantidad</th>
                        <th style="text-align: right;">Precio</th>
                        <th style="text-align: right;">Impuestos</th>    
                        <th style="text-align: right;">Total</th>
                        <th width="120"></th>
                    </tr>
                </ng-template>
                <!-- Añadir mensaje cuando esté vacío -->
                <ng-template pTemplate="body" let-companyJob>
                    <tr (dblclick)="editJob('COMPANY', companyJob)">
                        <td>
                            {{companyJob.date | date: 'dd/MM/YY HH:mm'}}
                        </td>
                        <td>
                            {{companyJob.rateLine.code}} - {{companyJob.rateLine.description}}
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': companyJob.isReview, 'text-red-500 pi-times-circle': !companyJob.isReview}"></i>
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': companyJob.isBillable, 'text-red-500 pi-times-circle': !companyJob.isBillable}"></i>
                        </td>
                        <td style="text-align: right;">
                            {{companyJob.quantity}}
                        </td>
                        <td style="text-align: right;">
                            {{companyJob.unitPrice | currency: 'EUR'}}
                        </td>
                        <td style="text-align: right;">
                            {{'('+(companyJob.taxPercent!=null?companyJob.taxPercent:0)+'%) '}}{{companyJob.taxAmount | currency: 'EUR' }}
                        </td>
                        <td style="text-align: right;">
                            {{companyJob.total | currency: 'EUR'}}
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end" style="width: 100%;">
                                <p-button *ngIf="allowDelete && companyJob.transactionId == null"
                                    (click)="deleteJob(companyJob)" [rounded]="true" [text]="false" severity="danger"><i
                                        class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editJob('COMPANY', companyJob)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="7" class="text-right">Total</td>
                        <td style="text-align: right;">{{companyJobsTotal | currency: 'EUR'}}</td>
                        <td colspan="1"></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td>
                            No se han encontrado trabajos registrados
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

    </p-card>

    <p-card header="Resumen económico" styleClass="mt-4">
        <div class="flex gap-4">
            <div class="widget">
                <div class="widget-value">{{operatorJobsTotal | currency: 'EUR'}}</div>
                <div class="widget-label">Total coste</div>
            </div>
            <div class="widget">
                <div class="widget-value">{{companyJobsTotal | currency: 'EUR'}}</div>
                <div class="widget-label">Total compañia</div>
            </div>
            <div class="widget" [class.widget-bg-success]="getProfitPercent()>=25"
                [class.widget-bg-warning]="getProfitPercent()<25" [class.widget-bg-danger]="getProfitPercent()<0">
                <div class="widget-value">{{getProfitPercent() | number:'1.2-2'}}</div>
                <div class="widget-label">% beneficio</div>
            </div>
            <div class="widget" [class.widget-bg-success]="getProfit()>0" [class.widget-bg-danger]="getProfit()<0">
                <div class="widget-value">{{getProfit() | currency: 'EUR'}}</div>
                <div class="widget-label">€ beneficio</div>
            </div>
        </div>
    </p-card>
</div>

