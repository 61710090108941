import { Role } from "../../roles/_types/role";
import { minLength, prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { UserType } from "./user-type";

export class User extends Resource {
    public override type = 'users';

    @prop()
    @required()
    @minLength({ value: 3 })
    public name!: string;

    @prop()
    public email!: string;

    @prop()
    public userName!: string;

    @prop()
    public password!: string;

    @prop()
    public temporalPassword!: string;

    @prop()
    public active: boolean = true;

    @prop()
    public roleId!: string;

    @prop()
    public languageId!: string;

    @prop()
    public role!: Role;

    @prop()
    public enableSIPExtension: boolean = false;

    @prop()
    public sipExtension!: string;

    @prop()
    public outboundDDIId!: string;

    @prop()
    public userTypeId!: string;

    @prop()
    public userType!: UserType;

    @prop()
    public maxServiceEvaluatedPercent!: number;

}