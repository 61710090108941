import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Client } from "../../clients/_types/client";

export class CaseState extends Resource {
    public override type = 'caseStates';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public backgroundColor!: string;

    @prop()
    @required()
    public foregroundColor!: string;

    @prop()
    public isInitial: boolean = false;

    @prop()
    public isFinal: boolean = false;


}