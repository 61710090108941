
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { ProvidersService } from '../_services/providers.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Provider } from '../_types/provider';
import { ProviderBankAccountsService } from '../_services/providers-bank-accounts.service';
import { firstValueFrom } from 'rxjs';
import { ProviderAddressesService } from '../_services/providers-addresses.service';
import { ProviderBankAccount } from '../_types/provider-bank-account.model';
import { ProviderAddress } from '../_types/provider-address';
import { BanksService } from '../../common/_services/banks.service';
import { Capability } from '../../common/_types/capability';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { Country } from '../../common/_types/country';
import { CountriesService } from '../../common/_services/countries.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';

@Component({
  selector: 'app-providers-detail',
  templateUrl: './providers-detail.component.html',
  styleUrls: ['./providers-detail.component.css']
})
export class ProvidersDetailComponent implements OnInit {
  
  public providerFormGroup!: RxFormGroup;

  public provider: Provider = new Provider();
  public totalRecords: number = 0;
  public loading: boolean = false;  
  public activeIndex: number = 1;    
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;  
  public workers: any = [];
  public zones: any = [];  
  public banks: any = [];
  public countries: Array<Country> = new Array<Country>();

  public selectedCapabilities: any[] = [];  

  public capabilities: Array<Capability> = new Array<Capability>();
  public required: boolean = true;

  constructor(
    private providersService: ProvidersService,
    private providerBankAccountsService: ProviderBankAccountsService,
    private providerAddressesService: ProviderAddressesService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private banksService: BanksService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private countriesService: CountriesService
  ) {
    this.topbarService.setTitle("Detalles de proveedor");    
    this.topbarService.addBreadcrumb("Proveedores", "/providers");  

    this.providerFormGroup = this.formBuilder.formGroup<Provider>(this.provider) as RxFormGroup;
  }

  ngOnInit(): void {
    
    this.loadBanks();  

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      if(id!=null && id!='0'){
        this.loadProvider(id);                
      }
      
      this.loadCountries();
      //si no tiene backAccount, la creamos
      if(this.provider.bankAccount==null) this.provider.bankAccount = new ProviderBankAccount();
  
      //si no tiene address, la creamos
      if(this.provider.address==null) this.provider.address = new ProviderAddress();
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
    
  }
  
  async loadProvider(id: string) {
    this.provider = await firstValueFrom(this.providersService.get(id, { include: ['bankAccount', 'address'] }));    
    this.providerFormGroup.patchValue(this.provider);
  }

  
  async loadBanks(){    
    let response = await firstValueFrom(this.banksService.all({page: { size: 0 }}));       
    this.banks = response.data;    
  }

  async loadCountries() {
    let params: IParamsCollection = {
      sort: ['name']
    };
    let response = await firstValueFrom(this.countriesService.all(params));
    this.countries = response.data;
  }
  
  async save() {        
    let valid = this.formValidationService.validateForm(this.providerFormGroup);
    if (valid) {

      this.provider = Object.assign(this.provider, this.providerFormGroup.value);      
       
          if(this.provider.address!=null){
            let address:any = await firstValueFrom(this.providerAddressesService.save(this.provider.address));
            this.provider.address.id = address.id;                
          }
    
          //guardamos cuenta bancaria
          if(this.provider.bankAccount!=null){
            let bankAccount:any = await firstValueFrom(this.providerBankAccountsService.save(this.provider.bankAccount));
            this.provider.bankAccount.id = bankAccount.id;        
          }
    
          //guardamos proveedor
                
          let provider = await firstValueFrom(this.providersService.save(this.provider, {include:['address', 'bankAccount']}));
          
          if(typeof this.provider.id != "undefined" && this.provider.id != ""){
            await firstValueFrom(this.providersService.updateCapabilities(this.provider.id!, this.selectedCapabilities));
          }
    
          this.messageService.add({ closable: false, severity: 'success', summary: "Proveedor guardado correctamente." });
          
          if(this.provider.id != "undefined" && this.provider.id != ""){
              this.router.navigate(['providers']);
          }
          this.loadProvider(provider.id!);
            
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el proveedor?",
        header: "Eliminar proveedor",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.providersService.delete(this.provider.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Proveedor eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['providers']);
  }
  
}
