import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { AsyncSubject, Observable } from "rxjs";
import { Rate } from "../_types/rate";
import { saveAs } from 'file-saver';
import * as slug from "slug";

@Injectable({
    providedIn: 'root',
})

export class RatesService extends Service<Rate> {
  public override type = 'rates';
  public override url = 'attendance/rates'; 

  public updateCapabilities(providerId:string, capabilitiesIds: Array<string>): Observable<void> {
    let url = this.getAPIUrl()+"/" + providerId + '/capabilities';
    console.log(url);
    return this.http.post<void>(url, capabilitiesIds);
  }

  public downloadExcel(rateId:string, filename:string|null) {
    let url = this.getAPIUrl()+"/" + rateId + '/downloadExcel';
    console.log(url);
    return this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      // Usar FileSaver.js para guardar el archivo
      saveAs(response, (filename!=null?slug(filename)+'.xlsx':'baremo.xlsx'));
    });
  }


  public uploadExcel(id: string, file: any): Observable<any> {

    let subject = new AsyncSubject<any>();

    console.log(file);
    let data = new FormData();
    data.append('file', file);

    let url = this.getAPIUrl() + '/' + id + '/uploadExcel';
    
    this.http.put(url, data).subscribe({
      next: (response) => {
        console.log('Resource updated successfully', response);
        subject.next(response);
        subject.complete();
         // Limpiar el mensaje de error si la solicitud fue exitosa
      },
      error: (error) => {        
        subject.error(error);
      }
    });
    return subject.asObservable();
  }

  rateLinesCompany(jobType: string, companyId: string, date: Date): Observable<object> {
    return this.http.get<object>(this.getAPIUrl()+"/"+jobType+"/" + companyId + '/'+encodeURIComponent(date.toISOString())+'/getRatesCompany');
  }
}
