<form *ngIf="contractSlaFormGroup" [formGroup]="contractSlaFormGroup">
    <div class="grid">
        <div class="col-6 input-field">
            <label for="caseEventTypeId">Tipo de evento</label>
            <p-dropdown id="caseEventTypeId" [options]="caseEventTypes" formControlName="caseEventTypeId"
                optionLabel="name" optionValue="id" appendTo="body" [showClear]="true" [filter]="true"
                filterBy="name"></p-dropdown>
        </div>
        <div class="col-3 input-field">
            <label for="hours">Horas</label>
            <p-inputNumber id="hours" formControlName="hours" [minFractionDigits]="1" [maxFractionDigits]="1" [min]="0"
                [max]="99" [step]="0.5"></p-inputNumber>
        </div>
        <div class="col-3 input-field">
            <label for="isNotificationEnabled">Notificación activa</label>
            <p-inputSwitch id="isNotificationEnabled" formControlName="isNotificationEnabled"></p-inputSwitch>
        </div>
    </div>
    <div class="mt-4 flex justify-content-between">
        <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"
            *ngIf="contractSla.id && allowDelete"></p-button>
        <div class="flex gap-2">
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </div>
</form>

<div class="mt-4" *ngIf="contractSla.id">
    <p-tabView class="mt-4">
        <p-tabPanel header="Especialidades">
            <div class="flex justify-content-end mb-2">
                <p-button *ngIf="allowWrite" label="Editar especialidades" icon="pi pi-plus"
                    (click)="editCapabilities()" />
            </div>
            <p-table [value]="contractSlaCapabilities">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nombre</th>
                    </tr>
                </ng-template>
                <!-- Añadir mensaje cuando esté vacío -->
                <ng-template pTemplate="body" let-contractSlaCapability>
                    <tr>
                        <td>{{ contractSlaCapability.capability?.name }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td>
                            No se han encontrado especialidades
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Usuarios a notificar" *ngIf="contractSlaFormGroup.get('isNotificationEnabled')?.value">
            <div class="flex justify-content-end mb-2">
                <p-button *ngIf="allowWrite" label="Editar notificaciones" icon="pi pi-plus"
                    (click)="editNotifications()" />
            </div>
            <p-table [value]="contractSlaNotifications">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nombre</th>
                    </tr>
                </ng-template>
                <!-- Añadir mensaje cuando esté vacío -->
                <ng-template pTemplate="body" let-contractSlaNotification>
                    <tr>
                        <td>{{ contractSlaNotification.user?.name }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td>
                            No se han encontrado notificaciones
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</div>