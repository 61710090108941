import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class BusinessAddress extends Resource {
    public override type = 'companyAddresses';

    @prop()
    @required()
    public addressLine1!: string;

    @prop()
    public addressLine2!: string;

    @prop()
    public city!: string;

    @prop()
    public countryCode!: string;

    @prop()
    public zipCode!: string;

    @prop()
    public latitude!: number;

    @prop()
    public longitude!: number;

}