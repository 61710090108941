import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  public changePswModal: any = {
  };
  public user: any;
  public userId: number = 0;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    
  };


  recoverPass() {
    if (this.changePswModal.email != "" && this.changePswModal.email != null && typeof this.changePswModal.email !== "undefined") {
      this.loginService.resetPassword(this.changePswModal.email).subscribe({
        next: (data: any) => {
          //Al final de todas las operaciones, se cierra la pantalla?
          console.log(data);
          this.dynamicDialogRef.close(data);
          if(data){
            this.messageService.add({ closable: false, severity: 'success', summary: "En breve recibirá un email con la nueva contraseña." });
          }else{
            this.messageService.add({ closable: false, severity: "error", detail: "Usuario no encontrado" });  
          }
          
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}