<form *ngIf="guardShiftFormGroup" [formGroup]="guardShiftFormGroup">
  <p-card class="p-3" header="Datos generales">  
    <div class="grid"> 
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText name="name" formControlName="name" d-form-field-error/>
      </div>            
      <div class="col-2 input-field ">
        <label for="startHour">Desde *</label>
        <p-calendar inputId="startHour" [timeOnly]="true" appendTo="body" formControlName="startHour"
            d-form-field-error />
    </div>
    <div class="col-2 input-field ">
        <label for="endHour">Hasta *</label>
        <p-calendar inputId="endHour" formControlName="endHour" [timeOnly]="true" appendTo="body"
            d-form-field-error />
    </div>
    <div class="col-2 input-field ">
      <label for="endHour">Color</label>
      <p-colorPicker formControlName="color"/>
    </div>
    </div>          
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete && guardShift.id" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>     
  </p-card>
  <p-card class="p-3" *ngIf="guardShift.id">  
    <app-calendar-year [guardShiftDates]="guardShiftDates" [color]="guardShift.color" (onDayClick)="daySelected($event)" (onDateRangeSelect)="rangeSelected($event)" (onYearChange)="yearChanged($event)"></app-calendar-year>
  </p-card>
</form>
<p-confirmDialog></p-confirmDialog>
<p-dialog header="Advertencia" [modal]="true" [(visible)]="visibleRangeModal" [style]="{ width: '35rem' }">  
      
    ¿Quiere incluir o excluir del turno el rango comprendido entre {{rangeStartDate | date:'dd/MM/yy'}} y el {{rangeEndDate | date:'dd/MM/yy'}}?
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar rango" *ngIf="allowDelete && guardShift.id" icon="pi pi-trash" styleClass="p-button-danger" (click)="deleteRange(); visibleRangeModal = false"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="visibleRangeModal = false"></p-button>
      <p-button label="Añadir rango" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="saveRange(); visibleRangeModal = false"></p-button>
    </div>       
</p-dialog>
