import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ContractSla } from "../_types/contract-sla";

@Injectable({
  providedIn: 'root',
})
export class ContractSlasService extends Service<ContractSla> {
  public override type = 'contractSlas';
  public override url = 'attendance/contractSlas';
}
