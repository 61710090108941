import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { RateLine } from "../../rates/_types/rate-line";
import { Transaction } from "./transaction";

export class TransactionLine extends Resource {
    public override type = 'transactionLines';
    

    @prop()
    @required()
    public transactionId!: string;

    @propObject(Transaction)
    public transaction!: Transaction;
 
    @prop()
    @required()
    public lineNumber!: number;    

    @prop()
    public isGroup: boolean = false;

    @prop()    
    public rateLineId!: string ;    

    @propObject(RateLine)
    public rateLine!: RateLine;

    @prop()        
    public description!: string ;  

    @prop()    
    public quantity!: number;

    @prop()    
    public price!: number;

    @prop()
    public discountPercent!: number; 

    @prop()
    public discountAmount!: number;
    
    @prop()
    public taxPercent!: number;

    @prop()
    public taxAmount!: number;

    @prop()    
    public subtotal!: number;     
    
    @prop()
    public caseServiceId!: string;
}