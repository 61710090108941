import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseLog } from "../_types/case-log";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CasesLogsService extends Service<CaseLog> {
    public override type = 'caseLogs';
    public override url = 'attendance/caseLogs';

    public parseHtml(template_id:string, case_id: string): Observable<object> {
        let url = this.getAPIUrl()+"/" + template_id + "/" + case_id + '/html';    
        return this.http.get<object>(url);
      }

      
}
