import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Case } from "./case";
import { AffectedAddress } from "./affected-address";

export class Affected extends Resource {
    public override type = 'affecteds';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public legalName!: string;

    @prop()
    public typeOfBusiness: number = 1;
    @prop()
    public email!: string;
    @prop()
    public phone1!: string;
    @prop()
    public active: boolean = true;
    @prop()
    @required()
    public vatNumber!: string;


    @prop()
    public addressId!: string;

    @propObject(AffectedAddress)
    public address: AffectedAddress = new AffectedAddress();

    @prop()
    @required()
    public caseId!: string;


    public case: Case = new Case();

    @prop()
    public isProblemCause: boolean = false;
}