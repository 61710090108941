import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Company } from "../../companies/_types/company";
import { Guarantee } from "../../guarantees/_types/guarantee";

export class ClientPolicyGuarantee extends Resource {
    public override type = 'clientPolicyGuarantees';

    @prop()
    public clientPolicyId!: string;

    @prop()
    public guaranteeId!: string;

    public guarantee!: Guarantee;
}