import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BusinessBankAccount } from '../_types/business-bank-account';
import { BusinessBankAccountsService } from '../_services/business-bank-accounts.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { BanksService } from '../../common/_services/banks.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-business-bank-account',
  templateUrl: './business-bank-account.component.html',
  styleUrls: ['./business-bank-account.component.scss']
})
export class BusinessBankAccountComponent {
  public businsessBankAccountFormGroup!: RxFormGroup

  public businessBankAccount: BusinessBankAccount = new BusinessBankAccount();
  public banks: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private businessBankAccountsService: BusinessBankAccountsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private banksService: BanksService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.businsessBankAccountFormGroup = this.formBuilder.formGroup<BusinessBankAccount>(this.businessBankAccount) as RxFormGroup;
  }

  ngOnInit(): void {
    if (this.config.data.businessBankAccountId) {
      this.loadBusinessBankAccount(this.config.data.businessBankAccountId);
    } else {
      this.businessBankAccount.companyId = this.config.data.companyId;
    }
    this.loadBanks();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadBusinessBankAccount(id: string) {
    this.businessBankAccount = await firstValueFrom(this.businessBankAccountsService.get(id));
    this.businsessBankAccountFormGroup.patchValue(this.businessBankAccount);
  }

  async loadBanks() {
    let response = await firstValueFrom(this.banksService.all());
    this.banks = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.businsessBankAccountFormGroup);
    if (valid) {
      this.businessBankAccount = Object.assign(this.businessBankAccount, this.businsessBankAccountFormGroup.value);
      this.businessBankAccount.accountNumber = this.businessBankAccount.accountNumber.replaceAll(' ', '');

      await this.businessBankAccountsService.save(this.businessBankAccount).subscribe({
        next: (data) => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Cuenta bancaria guardada correctamente' });
          this.dynamicDialogRef.close();
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar la cuenta bancaria' });
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Existen errores, por favor revise el formulario de datos.' });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la cuenta bancaria?",
      header: "Eliminar cuenta bancaria",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.businessBankAccountsService.delete(this.businessBankAccount.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Cuenta bancaria guardada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
