import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseEventType } from "../_types/case-event-type";

@Injectable({
  providedIn: 'root',
})
export class CaseEventTypesService extends Service<CaseEventType> {
  public override type = 'caseEventTypes';
  public override url = 'attendance/caseEventTypes';
}
