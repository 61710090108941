import { prop } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Capability extends Resource {
    public override type = 'capabilities';

    @prop()
    public name!: string;

    @prop()
    public active: boolean = true;    

}