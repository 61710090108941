<form *ngIf="authenticationServiceFormGroup" [formGroup]="authenticationServiceFormGroup">
  <p-card class="p-3" header="Datos generales">  
    <div class="grid"> 
      <div class="col-4 input-field">
        <label for="name">Nombre *</label>
        <input pInputText formControlName="name" d-form-field-error/>
      </div>
      <div class="col-4 input-field ">
        <label for="name">ID Cliente *</label>
        <input pInputText id="clientId" formControlName="clientId" d-form-field-error/>
      </div>
      <div class="col-fixed input-field flex align-items-center">
        <label for="active" class="mr-1">Activo</label>
        <p-inputSwitch id="active" name="active" formControlName="active" d-form-field-error></p-inputSwitch>
      </div>
    </div>
    <div class="formgrid grid pt-2">      
      <div class="col-4 input-field ">
        <label for="secret">Secreto *</label>
        <input pInputText id="secret" name="secret" formControlName="secret" d-form-field-error/>
      </div>
      <div class="col-4  input-field ">
        <label for="callbackUrl">URL de callback *</label>
        <input pInputText id="callbackUrl" name="callbackUrl" formControlName="callbackUrl" d-form-field-error/>
      </div>
      <div class="col-4  input-field ">
        <label for="openIdUrl">Openid url *</label>
        <input pInputText id="openIdUrl" name="openIdUrl" formControlName="openIdUrl" d-form-field-error/>
      </div>
    </div>
    <div class="formgrid grid pt-2">      
      <div class="col-4  input-field ">
        <label for="scopes">Scopes *</label>
        <input pInputText id="scopes" name="scopes" formControlName="scopes" d-form-field-error/>
      </div>
      <div class="col-4  input-field ">
        <label for="issuer">Issuer *</label>
        <input pInputText name="issuer" formControlName="issuer" d-form-field-error/>
      </div>
      <div class="col-4  input-field ">
        <label for="audience">Audiencia *</label>
        <input pInputText name="audience" formControlName="audience" d-form-field-error/>
      </div>
    </div>      
    <div class="mt-4 flex gap-2 justify-content-end">
      <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>     
</p-card>
</form>
<p-confirmDialog></p-confirmDialog>
