<div class="pb-4">
    <h5>Por favor, escriba su usuario. Le enviaremos una contraseña para acceder temporalmente.</h5>
    <div class="input-field pb-2">      
      <input id="email" type="text" style="width: 100%;" pInputText [(ngModel)]="changePswModal.email" placeholder="" autocomplete="Email" />
    </div>
  </div>
  <div class="flex gap-2 justify-content-end">
      <button pButton class="p-button-secondary" (click)="cancel()" label="Cancelar"></button>
      <div class="flex-grow-1"></div>
      <button pButton type="button" label="Recuperar contraseña" (click)="recoverPass()"></button>
  </div>