<div class="flex justify-content-center">
    <div class="questions-container">
        <h1>{{survey.name}}</h1>

        <small>
            Id de encuenta: {{surveyId}} <br>
            Tipo de entidad de origen: {{sourceType}}<br>
            Id de entidad de origen (osea que responde): {{sourceId}}<br>
            Tipo de entidad de destino: {{targetType}}<br>
            Id de entidad de destino: {{targetId}}<br>
        </small>

        <p-card *ngFor="let question of questions" [header]="question.title" styleClass="mb-4">
            <!-- si es de tipo scale5 -->
            <p-selectButton *ngIf="question.surveyQuestionType === 'Scale5'" styleClass="p-selectedbutton-scale5"
                [options]="scale5Options" [(ngModel)]="question.answer" optionLabel="label" optionValue="value" />

            <!-- si es de tipo options pintar un radio por cada option -->
            <div class="flex gap-4" *ngIf="question.surveyQuestionType === 'Options'">
                <div *ngFor="let option of question.options; let idx = index" class="input-field-radio">
                    <p-radioButton name="option.name" [value]="option.name" [(ngModel)]="question.answer"
                        inputId="answer-{{idx}}" />
                    <label for="answer-{{idx}}">{{option.name}}</label>
                </div>
            </div>

            <!-- si es de tipo text pintar un tectarea-->
            <div class="input-field">
                <textarea *ngIf="question.surveyQuestionType === 'Text'" rows="5" pInputTextarea
                    [(ngModel)]="question.answer">
                </textarea>
            </div>

        </p-card>

        <p-button label="Enviar" (click)="sendResponse()" class="mt-4"></p-button>

        <pre>{{questions | json}}</pre>
    </div>
</div>