import { Component } from '@angular/core';
import { Guarantee } from '../_types/guarantee';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GuaranteesService } from '../_services/guarantees.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { GuaranteesDetailComponent } from '../guarantees-detail/guarantees-detail.component';

@Component({
  selector: 'app-guarantees-list',
  templateUrl: './guarantees-list.component.html',
  styleUrls: ['./guarantees-list.component.scss']
})
export class GuaranteesListComponent {
  public guarantees: Guarantee[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private guaranteesService: GuaranteesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private topbarService: TopbarService,
    private dialogService: DialogService
  ) {
    this.topbarService.setTitle("Coberturas");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({} as Guarantee);
    });
    this.allowWrite = true;//this.loginService.hasPermission("COMPANIES_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("COMPANIES_DELETE")?
  }

  async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows },
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.guaranteesService.all(params));
    this.guarantees = response.data;
    this.totalRecords = response.meta['total'];
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(guarantee: Guarantee) {
    const ref = this.dialogService.open(GuaranteesDetailComponent, {
      header: 'Detalle de cobertura',
      width: '400px',
      data: {
        id: guarantee.id ? guarantee.id : null
      }
    });

    ref.onClose.subscribe((result: any) => {
      this.loadData(this.lastGridEvent);
    })
  }


  delete(id: string) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la cobertura?",
        header: "Eliminar cobertura",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.guaranteesService.delete(id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Cobertura eliminada correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
}
