import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class ContractZoneLine extends Resource {
    public override type = 'contractZoneLines';
    
    @prop()
    public contractZoneId!: string;
    @prop()
    @required()
    public value!: string;    

}