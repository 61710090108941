import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { ContractCapability } from '../../_types/contract-capability';
import { ContractCapabilitiesService } from '../../_services/contracts-capabilities.service';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';

@Component({
  selector: 'app-contract-capabilities-detail',
  templateUrl: './capabilities-detail.component.html',
  styleUrls: ['./capabilities-detail.component.scss']
})
export class ContractCapabilitiesDetailComponent {
  public contractCapabilityFormGroup!: RxFormGroup;

  public contractCapability: ContractCapability = new ContractCapability();
  public capabilities: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private contractCapabilitiesService: ContractCapabilitiesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private capabilitiesService: CapabilitiesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.contractCapabilityFormGroup = this.formBuilder.formGroup<ContractCapability>(this.contractCapability) as RxFormGroup;
  }

  ngOnInit(): void {
    if (this.config.data.contractCapabilityId) {
      this.loadContractCapability(this.config.data.contractCapabilityId);
    } else {
      this.contractCapability.contractId = this.config.data.contractId;
    }
    this.loadCapabilities();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadContractCapability(id: string) {
    this.contractCapability = await firstValueFrom(this.contractCapabilitiesService.get(id));
    this.contractCapabilityFormGroup.patchValue(this.contractCapability);
  }

  async loadCapabilities() {
    let response = await firstValueFrom(this.capabilitiesService.all());
    this.capabilities = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.contractCapabilityFormGroup);
    if (valid) {
      this.contractCapability = Object.assign(this.contractCapability, this.contractCapabilityFormGroup.value);

      await this.contractCapabilitiesService.save(this.contractCapability).subscribe({
        next: (data) => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Especialidad guardada correctamente' });
          this.dynamicDialogRef.close();
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar la especialidad' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la especialidad?",
      header: "Eliminar especialidad",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractCapabilitiesService.delete(this.contractCapability.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Especialidad eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
