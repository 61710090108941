import { AfterViewInit, EventEmitter, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoginService } from 'src/app/modules/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public onToggle: EventEmitter<boolean> = new EventEmitter();
  private _expanded: boolean = true;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private deviceService:DeviceDetectorService
  ) {
    setTimeout(() => {
      //si es mobile, lo dejamos cerrado
      if(this.deviceService.isMobile()){
        this._expanded = false;
      }else{
        this._expanded = true;
      }
    });
  }

  expand(){
    this._expanded = true;
    this.onToggle.emit(this._expanded);
  }

  collapse(){
    this._expanded = false;
    this.onToggle.emit(this._expanded);
  }

  toogle(){
    this._expanded = !this._expanded;
    this.onToggle.emit(this._expanded);
  }

  isExpanded(){
    return this._expanded;
  }

}
