import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public onLogin: EventEmitter<boolean> = new EventEmitter();
  private endpoint:string = environment.api_gw + 'auth/auth';
  
  constructor(
    private router:Router,
    private storage: LocalStorageService,
    private http:HttpClient,
    private HttpService: HttpService
  ) {
    
  }

  login(username:string, password:string):Observable<any>{
    let payload = {
      Username: username,
      Password: password
    };
    
    let observable = new Observable((observer) => {

      this.http.post(this.endpoint + '/login', payload).subscribe({
        next: (data:any) => {

          this.storage.set("token", data.accessToken);
          this.storage.set("user", data.user);
          this.storage.set("permissions", data.permissions);

          this.onLogin.emit(true);

          observer.next(data);
          
        },
        error: (error)=>{
          observer.error(error);
        }
      });
    });
    
    return observable;

  }

  logout(){
    //quitar token
    this.storage.remove('token');
    this.storage.remove('user');
    this.storage.remove('permissions');
    this.onLogin.emit(false);
    this.router.navigate(['/login'], { replaceUrl:true });
  }


  checkSavedToken(): void {
    //obtener token
    let token = this.storage.get('token');

    //TODO: verificar si es valido el token o ha expirado
    if(token!=null){
      //si esta logado y no esta accediendo a login
      if(!location.pathname.startsWith('/login')){
        setTimeout(()=>{
          this.onLogin.emit(true);
        }, 500);

        //si esta entrando a /, redirigir a home
        if(location.pathname=='/'){
          this.router.navigate(['/home']);
        }
      }else{
        //si está entrando a login, borramos sesion anterior
        this.logout();
      }
    }else{
      //reenviamos al login, salvo que ya este alli
      if(!location.pathname.startsWith('/login')){
        this.router.navigate(['/login']);
      }
    }
  }

  hasPermission(permission: string) {
    const value = this.storage.get("permissions");
    if (value !== null) {
      const aux = value.indexOf(permission)
      if (aux !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  resetPassword(email:string): Observable<any> {
    let payload = {
      username: email,
      password:""
    };
    return this.http.post<object>(this.endpoint + "/reset_password", payload);
  }

}
