import { EventEmitter, Injectable, Output } from "@angular/core";
import { CaseService } from "../../cases/_types/case-service";
import { User } from "../../users/_types/user";
import { Worker } from "../../workers/_types/worker";

@Injectable({
    providedIn: 'root',
})
export class ChatService {

    @Output() onOpenChat = new EventEmitter<any>();
    @Output() onCloseChat = new EventEmitter<string>();

    constructor(
    ) {
        ;
    }

    openChatWorker(worker: Worker) {
        let chatRoomCode = "worker:" + worker.id;
        let title = worker.name;
        this.openChatRoom(chatRoomCode, title);
    }

    openChatUser(user: User) {
        let chatRoomCode = "user:" + user.id;
        let title = user.name;
        this.openChatRoom(chatRoomCode, title);
    }

    openChatCaseService(service: CaseService, title?: string) {
        let chatRoomCode = "case-service:" + service.id;
        this.openChatRoom(chatRoomCode, title);
    }

    openChatRoom(code: string, title: string = '') {
        this.onOpenChat.emit({ code: code, title: title });
    }

    closeChatRoom() {
        this.onCloseChat.emit();
    }

}
