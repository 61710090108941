<form *ngIf="workerTimeRangeFormGroup" [formGroup]="workerTimeRangeFormGroup">
    <div class="card mt-2 ">        
        <div class="formgrid grid pt-2">
            <div class="col input-field ">
                <label for="startHour">Desde</label>
                <p-calendar inputId="startHour" [timeOnly]="true" appendTo="body" formControlName="startHour"
                    d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="endHour">Hasta</label>
                <p-calendar inputId="endHour" formControlName="endHour" [timeOnly]="true" appendTo="body"
                    d-form-field-error />
            </div>
        </div>
        <div class="flex flex-wrap justify-content-center gap-3 pt-4">
            <div class="flex align-items-center">
                <p-checkbox label="Lunes" formControlName="monday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Martes" formControlName="tuesday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Miercoles" formControlName="wednesday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Jueves" formControlName="thursday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Viernes" formControlName="friday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Sábado" formControlName="saturday" [binary]="true" value="true" />
            </div>
            <div class="flex align-items-center">
                <p-checkbox label="Domingo" formControlName="sunday" [binary]="true" value="true" />
            </div>
        </div>
        <div class="mt-6 flex gap-2 justify-content-end ">
            <p-button label="Eliminar" *ngIf="workerTimeRange.id && allowDelete" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </div>
</form>