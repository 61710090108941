import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { CompanyRate } from 'src/app/modules/companies/_types/company-rate';
import { CompanyRatesService } from 'src/app/modules/companies/_services/companies-rates.service';
import { CompaniesService } from 'src/app/modules/companies/_services/companies.service';
import { ContractsService } from 'src/app/modules/companies/_services/contracts.service';
import { OperatorRate } from '../../_types/operator-rate';
import { OperatorRatesService } from '../../_services/operators-rates.service';
import { OperatorsService } from 'src/app/modules/companies/_services/operators.service';

@Component({
  selector: 'app-rate-operator',
  templateUrl: './rate-operator.component.html',
  styleUrls: ['./rate-operator.component.scss']
})
export class RateOperatorComponent {
  
  public rateOperatorFormGroup!: RxFormGroup;

  public rateOperator: OperatorRate = new OperatorRate();
  public operators: any[] = [];  

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private operatorRatesService: OperatorRatesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private operatorsService: OperatorsService    
  ) {
    this.rateOperatorFormGroup = this.formBuilder.formGroup<OperatorRate>(this.rateOperator) as RxFormGroup;
  }

  ngOnInit(): void {
    console.log(this.config.data);
    if (this.config.data.rateOperatorId) {
      this.loadRateOperator(this.config.data.rateOperatorId);            
    } else {
      this.rateOperator.rateId = this.config.data.rateId;
    }
    this.loadOperators();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadRateOperator(id: string) {
    this.rateOperator = await firstValueFrom(this.operatorRatesService.get(id));    
    this.rateOperatorFormGroup.patchValue(this.rateOperator);    
  }

  async loadOperators() {
    let response = await firstValueFrom(this.operatorsService.all());

    this.operators = response.data;
  }  


  async save() {    
    let valid = this.formValidationService.validateForm(this.rateOperatorFormGroup);
    console.log(valid);
    if (valid) {
      this.rateOperator = Object.assign(this.rateOperator, this.rateOperatorFormGroup.value);
                  
      await this.operatorRatesService.save(this.rateOperator).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Operario asignado correctamente' });
          this.dynamicDialogRef.close();
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al asignar el operario' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar el operario?",
      header: "Desasignar operario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.operatorRatesService.delete(this.rateOperator.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Operario desasignado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
