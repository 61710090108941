import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Case } from "./case";
import { User } from "../../users/_types/user";

export enum CaseLogType {
    Other = "Other",
    Event = "Event",
    Mail = "Mail",
    Call = "Call",
    SMS = "SMS",
}

export enum CaseLogLevel {
    Info = "Info",
    Warning = "Warning",
    Danger = "Danger"
}

export class CaseLog extends Resource {
    public override type = 'caseLogs';

    @prop()
    @required()
    public caseId!: string;

    public case: Case = new Case();
    
    @prop()
    @required()
    public date: Date = new Date();

    @prop()
    @required()
    public message!: string;
    @prop()
    public caseLogType: CaseLogType = CaseLogType.Other;
    @prop()
    public caseLogLevel: CaseLogLevel  = CaseLogLevel.Info;

    
    @prop()
    public subject!: string;
    @prop()
    public template_id?: number;

}