import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { ContractZone } from '../../_types/contract-zone';
import { ContractZoneLine } from '../../_types/contract-zone-line';
import { ContractZonesService } from '../../_services/contracts-zones.service';
import { ContractZonesLinesService } from '../../_services/contracts-zones-lines.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-contract-zones-detail',
  templateUrl: './zones-detail.component.html',
  styleUrls: ['./zones-detail.component.css']
})
export class ContractZonesDetailComponent implements OnInit {
  
  public zoneFormGroup!: RxFormGroup;
  public zoneLineFormGroup!: RxFormGroup;

  public contractZone: ContractZone = new ContractZone();  
  public loading: boolean = false;  
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public contractZoneLines: any = [];
  public visibleContractZoneLine:boolean = false;
  public contractZoneLine: ContractZoneLine = new ContractZoneLine();

  constructor(
    private config: DynamicDialogConfig,
    private contractZonesService: ContractZonesService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,     
    private contractZonesLinesService: ContractZonesLinesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,            
  ) {    

    this.zoneFormGroup = this.formBuilder.formGroup<ContractZone>(this.contractZone) as RxFormGroup;
    this.zoneLineFormGroup = this.formBuilder.formGroup<ContractZoneLine>(this.contractZoneLine) as RxFormGroup;
  }
  

  ngOnInit(): void {    

    this.contractZone.contractId = this.config.data.contractId;
        
    if(this.config.data.id!=null && this.config.data.id!='0'){
      this.loadContractZone(this.config.data.id);
    }
         
    this.allowWrite = true;//this.loginService.hasPermission("ZONES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("ZONES_DELETE");
  }
  
  async loadContractZone(id: string) {
    this.contractZone = await firstValueFrom(this.contractZonesService.get(id));
    this.zoneFormGroup.patchValue(this.contractZone);   
    this.loadContractZoneLines();
  }
  
  
  async loadContractZoneLines(){

    let filters = {
      contractZoneId: new Filter(FilterOperation.equals, 'contractZoneId', '', this.contractZone.id!)
    };
    
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };

    console.log(params);
    let response = await firstValueFrom(this.contractZonesLinesService.all(params));

    this.contractZoneLines = response.data;
    
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.zoneFormGroup);
    if (valid) {

      this.contractZone = Object.assign(this.contractZone, this.zoneFormGroup.value);  
      let contractZone:any = await firstValueFrom(this.contractZonesService.save(this.contractZone));
      if(this.contractZone.id){
        this.dynamicDialogRef.close();
      }else{
        this.loadContractZone(contractZone.id);
      } 

      this.messageService.add({ closable: false, severity: 'success', detail: "Zona guardada correctamente" });
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Quieres eliminar la zona " + this.contractZone.name+ "?",
      header: "Eliminar zona",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractZonesService.delete(this.contractZone.id).subscribe(
          (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Zona eliminada correctamente" });

            this.cancel();
          },
          (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  editContractZoneLine(contracZoneLine: any) {         
    this.contractZoneLine = contracZoneLine;    
    if(contracZoneLine.id != null) {      
      this.zoneLineFormGroup.patchValue(this.contractZoneLine);   
    }
    this.visibleContractZoneLine = true;    
  }

  async saveContractZoneLine(){
    this.visibleContractZoneLine = false;
    let id:string = this.contractZone.id!;
    let valid = this.formValidationService.validateForm(this.zoneLineFormGroup);
    if (valid) {
      this.contractZoneLine = Object.assign(this.contractZoneLine, this.zoneLineFormGroup.value);  
      this.contractZoneLine.contractZoneId = id;
      await firstValueFrom(this.contractZonesLinesService.save(this.contractZoneLine));
    }
    
    this.loadContractZoneLines();
  }

  deleteContractZoneLine(contractZoneLine: any) {    
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la línea"+contractZoneLine.value+"?",
        header: "Eliminar línea",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.contractZonesLinesService.delete(contractZoneLine.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente" });
              this.loadContractZoneLines();
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
  

}
