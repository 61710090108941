import { prop } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class ContractZone extends Resource {
    public override type = 'contractZones';
    
    @prop()
    public contractId!: string;
    @prop()
    public name!: string;      

}