import { Component } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LoginService } from '../../login/login.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { AuthenticationsServicesService } from '../_services/authentications-services.service';
@Component({
  selector: 'app-authentications-services-list',
  templateUrl: './authentications-services-list.component.html',
})
export class AuthenticationsServicesComponent {
  
  public authenticationsServices: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  private lastGridEvent: any;  
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private storage: LocalStorageService,
    private authenticationsServicesService: AuthenticationsServicesService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,                
    private router: Router,
    private topbarService: TopbarService,
    private loginService: LoginService
  ) {
    this.topbarService.setTitle("Servicios de autenticación");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({});
    });    
    this.allowWrite = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_DELETE")
  }

  async loadData($event: any) {
    this.lastGridEvent = $event;

    let params:IParamsCollection = {      
      page: { number: $event.first+1, size: $event.rows }   
    }
    
    if(typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.authenticationsServicesService.all(params));
    this.authenticationsServices = response.data;
    this.totalRecords = response.meta['total'];
  }
  
  clearFilters() {
    this.filters = {};
    this.search();
  }
  
  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(authenticationService: any) {
    //if (this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_WRITE")) {      
      this.router.navigate(['authentications-services/' + (authenticationService && Object.keys(authenticationService).length > 0 ? authenticationService.id : 0)]);      
    //}
  }

  delete(authenticationService: any) {
    console.log(this.allowDelete);
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el servicio de autenticación?",
        header: "Eliminar servicio de autenticación",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.authenticationsServicesService.delete(authenticationService.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Servicio de autenticación eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
}
