import { Relationship } from "src/app/core/jsonapi/relationship";
import { Resource } from "src/app/core/jsonapi/resource";
import { Bank } from "../../common/_types/bank";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class BusinessBankAccount extends Resource {
    public override type = 'companyBankAccounts';

    @prop()
    public companyId!: string;

    @prop()
    @required()
    public bankId!: string;

    @propObject()
    public bank!: Bank;

    @prop()
    @required()
    public accountNumber!: string;

    @prop()
    @required()
    public swift!: string;

}