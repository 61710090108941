import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class CaseType extends Resource {
    public override type = 'caseTypes';

    @prop()
    @required()
    public name!: string;

}