import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ExpertAddress } from "../_types/expert-address";

@Injectable({
    providedIn: 'root',
})
export class ExpertAddressesService extends Service<ExpertAddress> {
    public override type = 'expertAddresses';
    public override url = 'attendance/expertAddresses';
}
