import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Survey extends Resource {
    public override type = 'surveys';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public active: boolean = true;

    @prop()
    @required()
    public targetType!: string;

    @prop()
    public userTypeId!: string;


}