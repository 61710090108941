import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Case } from "../_types/case";

@Injectable({
    providedIn: 'root',
})
export class CasesService extends Service<Case> {
    public override type = 'cases';
    public override url = 'attendance/cases';
}
