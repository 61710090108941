import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import * as moment from 'moment';
import { Nullable } from 'primeng/ts-helpers';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { GuardShift } from '../_types/guard-shift';
import { GuardsShiftsService } from '../_services/guards-shifts.service';
import { GuardsShiftsDatesService } from '../_services/guards-shifts-dates.service';

@Component({
  selector: 'app-guards-shifts-detail',
  templateUrl: './guards-shifts-detail.component.html',
  styleUrls: ['./guards-shifts-detail.component.css']
})
export class GuardsShiftsDetailComponent implements OnInit {
  
  public guardShiftFormGroup!: RxFormGroup;

  public guardShift: GuardShift = new GuardShift();  
  public guardShiftDates: any = [];  
  public totalRecords: number = 0;
  public loading: boolean = false;  
  public activeIndex: number = 1;  
  public color: string | undefined;
  //public totalRecordsCalendar: number = 0;
    
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public fromDate: any = null;
  public toDate: any = null;
  public rangeStartDate: any = null;
  public rangeEndDate: any = null;
  public visibleRangeModal: boolean = false;  

  constructor(
    private guardsShiftsService: GuardsShiftsService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private guardsShiftsDatesService: GuardsShiftsDatesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles del calendario");    
    this.topbarService.addBreadcrumb("Calendarios", "/calendars");     

    this.guardShift.color = "--color-primary";

    this.guardShiftFormGroup = this.formBuilder.formGroup<GuardShift>(this.guardShift) as RxFormGroup;
  }

  ngOnInit(): void {    
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      if(id!=null && id!='0'){
        this.loadGuardShift(id);        
      }    
    });

    this.allowWrite = true;//this.loginService.hasPermission("GUARDS_SHIFTS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("GUARDS_SHIFTS_DELETE");
  }
  
  async loadGuardShift(id: any) {
    this.guardShift = await firstValueFrom(this.guardsShiftsService.get(id)); 
    let start = new Date();
    start.setHours(this.guardShift.startHour.split(":")[0], this.guardShift.startHour.split(":")[1]);
    this.guardShift.startHour = start;
    let end = new Date();
    end.setHours(this.guardShift.endHour.split(":")[0], this.guardShift.endHour.split(":")[1]);
    this.guardShift.endHour = end;
    this.guardShiftFormGroup.patchValue(this.guardShift);      
  }  


  async loadDates(){
        
    //Buscamos los del calendario    
    let filters = {
      guardShiftId: new Filter(FilterOperation.equals, 'guardShiftId', '', this.guardShift.id),
      fromDate: new Filter(FilterOperation.greaterOrEqual, 'date', '', this.fromDate!.startOf("year").toISOString()),
      toDate: new Filter(FilterOperation.lessOrEqual, 'date', '', this.toDate!.toISOString()),
    };
    
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    
    let response = await firstValueFrom(this.guardsShiftsDatesService.all(params));    

    this.guardShiftDates = response.data;
  }  
  
  async save() {
   
    let valid = this.formValidationService.validateForm(this.guardShiftFormGroup);
    if (valid) {

      this.guardShift = Object.assign(this.guardShift, this.guardShiftFormGroup.value);    
      this.guardShift.startHour = new Date(this.guardShift.startHour).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second: '2-digit'});
      this.guardShift.endHour = new Date(this.guardShift.endHour).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second: '2-digit'});
            
      let guardShift = await firstValueFrom(this.guardsShiftsService.save(this.guardShift));
      
      if(this.guardShift.id != "undefined" && this.guardShift.id != ""){
        this.router.navigate(['guards-shifts']);
      }

      this.loadGuardShift(guardShift.id!);

      this.messageService.add({ closable: false, severity: 'success', summary: "Turno de guardia guardado correctamente." });
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {

    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el turno de guardia "+this.guardShift.name+"?",
        header: "Eliminar turno de guardia",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.guardsShiftsService.delete(this.guardShift.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Turno de guardia eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });  
  }
  

  cancel() {
    this.router.navigate(['guards-shifts']);
  }

  async rangeSelected(data:any){    
    this.rangeStartDate = data.start;
    this.rangeEndDate = data.end;
    this.visibleRangeModal = true;    
  }

  async saveRange(){
    if(typeof this.guardShift.id != "undefined" && this.guardShift.id != ""){
      await firstValueFrom(this.guardsShiftsDatesService.saveGuardShiftDates(this.guardShift.id!, {startDate: this.rangeStartDate, endDate: this.rangeEndDate}));
      this.loadDates();
    }
  }

  async deleteRange(){
    if(typeof this.guardShift.id != "undefined" && this.guardShift.id != ""){
      await firstValueFrom(this.guardsShiftsDatesService.deleteGuardShiftDates(this.guardShift.id!, {startDate: this.rangeStartDate, endDate: this.rangeEndDate}));
      this.loadDates();
    }
  }

  async daySelected(data: any) {    
    /*let date = moment(data.date);
    const ref = this.dialogService.open(CalendarEventModalComponent, {
      data: {
        calendarId: this.calendar.id,
        event: data.events[0],
        date: date
      },
      header: date.format('DD/MM/YYYY'),
      width: "400px",      
    });
    ref.onClose.subscribe(async (data: any) => {            
      if(typeof data != "undefined"){
        await this.calendarsEventsService.save(data);
        this.loadEvents();
      }else{
        this.loadEvents();
      }
      
      
    });*/
  }

  yearChanged(data: any){    
    this.fromDate = data.first_date;  
    this.toDate = data.end_date;    
    this.loadDates();
  }
  
}
