import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Client } from "../../clients/_types/client";
import { Processor } from "./processor";
import { CaseState } from "./case-state";
import { ClientAddress } from "../../clients/_types/client-address";
import { Company } from "../../companies/_types/company";
import { CaseType } from "../../cases-types/_types/case-type";
import { Contract } from "../../companies/_types/contract";

export class Case extends Resource {
    public override type = 'cases';

    @prop()
    public reference!: string;

    @prop()
    @required()
    public caseNumber!: string;

    @prop()
    @required()
    public caseDate: Date = new Date();

    @prop()
    @required()
    public description!: string;

    @prop()
    public notes!: string;

    @prop()
    @required()
    public caseTypeId!: string;

    @propObject(CaseType)
    public caseType!: CaseType;

    @prop()
    @required()
    public companyId!: string;

    @propObject(Company)
    public company!: Company;

    @prop()    
    @required()
    public contractId!: string;

    @propObject(Contract)
    public contract!: Contract;

    @prop()
    @required()
    public clientId!: string;

    @propObject(Client)
    public client!: Client | null;

    @prop()
    public processorId!: string;

    @propObject(Processor)
    public processor!: Processor;

    @prop()
    public caseStateId!: string;

    @propObject(CaseState)
    public caseState!: CaseState;


    @prop()
    public addressId!: string;

    @propObject(ClientAddress)
    public address!: ClientAddress;



}