import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Case } from "../_types/case";
import { CaseNote } from "../_types/case-note";

@Injectable({
    providedIn: 'root',
})
export class CasesNotesService extends Service<CaseNote> {
    public override type = 'caseNotes';
    public override url = 'attendance/caseNotes';
}
