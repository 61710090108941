import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveysListComponent } from './surveys-list/surveys-list.component';
import { SurveysDetailComponent } from './surveys-detail/surveys-detail.component';

const routes: Routes = [
    {
        path: 'surveys',
        children: [
            { path: '', component: SurveysListComponent },
            { path: ':guid', component: SurveysDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SurveysRoutingModule { }
