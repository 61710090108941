import { Component } from '@angular/core';
import { CaseService } from '../../_types/case-service';
import { Capability } from 'src/app/modules/common/_types/capability';
import { CasesServicesService } from '../../_services/cases-services.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { OperatorsService } from 'src/app/modules/companies/_services/operators.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { WorkerCapabilitiesService } from 'src/app/modules/workers/_services/workers-capabilities.service';
import { Worker } from 'src/app/modules/workers/_types/worker';
import { VehiclesTypesService } from 'src/app/modules/vehicles-types/_services/vehicles-types.service';
import { VehicleType } from 'src/app/modules/vehicles-types/_types/vehicle-type';
import { WorkerVehiclesService } from 'src/app/modules/workers/_services/workers-vehicles.service';
import { WorkerEquipmentsService } from 'src/app/modules/workers/_services/workers-equipments.service';
import { EquipmentsService } from 'src/app/modules/equipments/_services/equipments.service';
import { Equipment } from 'src/app/modules/equipments/_types/equipment';
import * as moment from 'moment';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class CasesDetailServiceComponent {
  public caseServiceFormGroup!: RxFormGroup;
  public service: CaseService = new CaseService();
  public capabilities: Capability[] = [];
  public vehiclesTypes: VehicleType[] = [];
  public equipments: Equipment[] = [];
  public duration: number = 1;
  public date: Date | null = null;
  public durationOptions: any[] = [];
  public selectedCapability: string = '';
  public selectedVehicleType: string = '';
  public selectedEquipment: string = '';
  public workers: Worker[] = [];
  public caseServices:CaseService[] = [];

  public selectedWorker!: Worker|null;
  public selectedTimeSlot: any;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public timeSlots: any[] = [];

  constructor(
    private formBuilder:RxFormBuilder,
    private casesServicesService: CasesServicesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private capabilitiesService: CapabilitiesService,
    private workerCapabilitiesService: WorkerCapabilitiesService,
    private vehiclesTypesService: VehiclesTypesService,
    private workerVehiclesService: WorkerVehiclesService,
    private equipmentsService: EquipmentsService,
    private workerEquipmentsService: WorkerEquipmentsService,
  ) {
    this.caseServiceFormGroup = this.formBuilder.formGroup<CaseService>(this.service) as RxFormGroup;
  }

  async ngOnInit() {
    this.date = new Date();
    let serviceId = this.config.data.serviceId;
    if (serviceId) {
      await this.loadService(serviceId);
    } else {
      this.service.caseId = this.config.data.caseId;
    }
    this.generateTimeSlots();
    this.loadCapabilities();
    this.loadDurations();
    this.loadVehicleTypes();
    this.loadEquipments();
  }

  async generateTimeSlots(){
    this.timeSlots = [];
    for(let i = 8; i <= 19; i++){
      this.timeSlots.push({
        label: i + ':00',
        hour: i,
        minute: 0
      });
      this.timeSlots.push({
        label: i + ':30',
        hour: i,
        minute: 30
      });
    }
  }

  async loadService(serviceId: string) {
    this.service = await firstValueFrom(this.casesServicesService.get(serviceId));
  }

  async loadCapabilities() {
    let response = await firstValueFrom(this.capabilitiesService.all());
    this.capabilities = response.data;
  }

  async loadVehicleTypes() {
    let response = await firstValueFrom(this.vehiclesTypesService.all());
    this.vehiclesTypes = response.data;
  }

  async loadEquipments() {
    let response = await firstValueFrom(this.equipmentsService.all());
    this.equipments = response.data;
  }

  async loadCaseServices(){
    let startDate = moment(this.date).startOf('day');
    let endDate = moment(this.date).endOf('day');
    let filters = {
      date1: new Filter(FilterOperation.greaterThan, 'date', '', startDate.toISOString()),
      date2: new Filter(FilterOperation.lessThan, 'date', '', endDate.toISOString())
    };
    let params: IParamsCollection = {
      include: ['case', 'case.client'],
      filter: FilterBuilder.fromObject(filters).build()
    };
    let response = await firstValueFrom(this.casesServicesService.all(params));
    this.caseServices = response.data
  }

  loadDurations() {
    this.durationOptions = Array.from({ length: 10 }, (v, k) => {
      return { label: k + 1 + (k == 0 ? ' hora' : ' horas'), value: k + 1 };
    });
  }

  async loadOperatorsByCapability() {
    let params: IParamsCollection = {
      include: ['worker']
    }
    let filters: any = {
      capabilityId: new Filter(FilterOperation.equals, "capabilityId"),
    };

    filters.capabilityId.value = this.selectedCapability;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerCapabilitiesService.all(params));
    let res = response.data.map((item: any) => item.worker);
    return res;
  }

  async loadOperatorsByVehicleType() {
    let params: IParamsCollection = {
      include: ['worker']
    }

    let filters: any = {
      vehicleTypeId: new Filter(FilterOperation.equals, "vehicle.vehicleTypeId")
    };
    if (this.date) {
      filters.fromDate = new Filter(FilterOperation.greaterOrEqual, "fromDate");
      filters.toDate = new Filter(FilterOperation.lessOrEqual, "toDate");
      filters.fromDate.value = this.date.toISOString();
      filters.toDate.value = this.date.toISOString();
    }

    filters.vehicleTypeId.value = this.selectedVehicleType;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerVehiclesService.all(params));
    let res = response.data.map((item: any) => item.worker);
    return res;
  }

  async loadOperatorsByEquipment() {
    let params: IParamsCollection = {
      include: ['worker']
    }

    let filters: any = {
      equipmentId: new Filter(FilterOperation.equals, "equipmentId"),
    };

    filters.equipmentId.value = this.selectedEquipment;
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.workerEquipmentsService.all(params));
    let res = response.data.map((item: any) => item.worker);
    return res;
  }

  async onSelectDate(){
    this.loadCaseServices();
    this.loadOperators();
  }

  async loadOperators() {

    let workersByCapability = (this.selectedCapability != '' && this.selectedCapability) ? await this.loadOperatorsByCapability() : null;
    let workersByVehicleType = (this.selectedVehicleType != '' && this.selectedVehicleType) ? await this.loadOperatorsByVehicleType() : null;
    let workersByEquipment = (this.selectedEquipment != '' && this.selectedEquipment) ? await this.loadOperatorsByEquipment() : null;

    if (workersByCapability && workersByVehicleType && workersByEquipment) {
      this.workers = workersByCapability.filter((worker: Worker) =>
        workersByVehicleType?.some((w: Worker) => w.id == worker.id) &&
        workersByEquipment?.some((w: Worker) => w.id == worker.id)
      );
    } else if (workersByCapability && workersByVehicleType) {
      this.workers = workersByCapability.filter((worker: Worker) =>
        workersByVehicleType?.some((w: Worker) => w.id == worker.id)
      );
    } else if (workersByCapability && workersByEquipment) {
      this.workers = workersByCapability.filter((worker: Worker) =>
        workersByEquipment?.some((w: Worker) => w.id == worker.id)
      );
    } else if (workersByVehicleType && workersByEquipment) {
      this.workers = workersByVehicleType.filter((worker: Worker) =>
        workersByEquipment?.some((w: Worker) => w.id == worker.id)
      );
    } else {
      this.workers = workersByCapability || workersByVehicleType || workersByEquipment || [];
    }

  }

  getTimeSlotServices(worker:Worker, timeSlot:any){
    let hour = moment(new Date()).set({hour: timeSlot.hour, minute: timeSlot.minute}).format('HH:mm');
    let services = this.caseServices.filter((service:CaseService) => {
      return service.operatorId == worker.id && moment(service.date).format('HH:mm') == hour;
    });
    return services;
  }

  clearSelectedTimeSlot(){
    this.selectedWorker = null;
    this.selectedTimeSlot = null;
  }

  selectWorkerTimeSlot(worker:Worker, timeSlot:any){
    this.selectedWorker = worker;
    this.selectedTimeSlot = timeSlot;
    this.service.operatorId = worker.id!;
    this.service.date = moment(this.date).set({hour: timeSlot.hour, minute: timeSlot.minute}).toDate();
    this.service.capabilityId = this.selectedCapability;
    this.caseServiceFormGroup.patchValue(this.service);
    console.log(this.caseServiceFormGroup.getErrorSummary(true));
    console.log(this.caseServiceFormGroup.valid);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save(){
    var services = this.getTimeSlotServices(this.selectedWorker!, this.selectedTimeSlot);
    if(services.length==0){
      let caseService = new CaseService();
      caseService.operatorId = this.selectedWorker!.id!;
      caseService.caseId = this.service.caseId;
      caseService.date = moment(this.date).set({hour: this.selectedTimeSlot.hour, minute: this.selectedTimeSlot.minute}).toDate();
      caseService.isUrgent = false;
      caseService.isValidated = false;
      caseService.isConfirmed = false;
      let response = await firstValueFrom(this.casesServicesService.save(caseService));
      this.dynamicDialogRef.close(response);
    }else{
      //ya hay servicios
    }
  }

}
