<app-loader></app-loader>
<div class="flex w-full" style="min-height: 100vh;">
    <app-sidebar *ngIf="!is_public_route && is_user_logged"></app-sidebar>
    <div class="flex flex-column w-full">
        <app-topbar *ngIf="!is_public_route && is_user_logged"></app-topbar>
        <div [class.p-4]="is_user_logged">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<p-sidebar *ngIf="!is_public_route" #notificationSidebar [(visible)]="notificationsSidebarVisible" position="right" styleClass="w-30rem p-sidebar-content-nopadding">
    <ng-template pTemplate="header">
        <h3 class="m-0">Notificaciones</h3>
    </ng-template>
    <app-notifications-sidebar></app-notifications-sidebar>
</p-sidebar>

<p-sidebar *ngIf="!is_public_route" #chatSidebar [(visible)]="chatSidebarVisible" (onHide)="onChatHide()" position="right" styleClass="w-30rem p-sidebar-content-nopadding p-sidebar-footer-nopadding">
    <ng-template pTemplate="header">
        <div>
            <h3 class="m-0">{{chatTitle}}</h3>
        </div>
    </ng-template>
    <app-chat-detail #chat></app-chat-detail>
</p-sidebar>

<p-toast></p-toast>