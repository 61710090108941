import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseFileCategory } from "../_types/case-file-category";

@Injectable({
    providedIn: 'root',
})
export class CasesFilesCategoriesService extends Service<CaseFileCategory> {
    public override type = 'caseFileCategories';
    public override url = 'attendance/caseFileCategories';
}
