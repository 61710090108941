
<form *ngIf="operatorFormGroup" [formGroup]="operatorFormGroup">
  <div class="card mt-2 ">
    <div class="grid">      
      <div class="col">
        <!--<div class="flex flex-wrap gap-2">-->
        <div class="input-field">
          <label for="name">Operador </label>
          <p-dropdown [options]="operators"                    
                    name="operatorId" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" 
                    placeholder="Seleccione operador" appendTo="body"
                    formControlName="operatorId" d-form-field-error/>  
        </div>        
      </div>
    </div>    

    
    <div class="mt-6 flex gap-2 justify-content-end ">      
      <p-button label="Eliminar" *ngIf="contractOperator.id&&allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
  </div>
</form>  

