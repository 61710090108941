import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Job } from "../_types/job";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class JobsService extends Service<Job> {
    public override type = 'jobs';
    public override url = 'attendance/jobs';    
}


