<form *ngIf="vehicleFormGroup" [formGroup]="vehicleFormGroup">
    
    <div class="grid">
        <div class="col-6">
            <div class="input-field">
                <label for="name">Nombre *</label>
                <input pInputText id="name" formControlName="name" d-form-field-error />
            </div>
        </div>
        <div class="col-6">
            <div class="input-field">
                <label for="name">Matrícula *</label>
                <input pInputText id="plate" formControlName="plate" d-form-field-error />
            </div>
        </div>                        
    </div>        
    
    <div class="grid">          
        <div class="col-6">             
            <div class="input-field">
                <label for="name">Tipo de vehículo *</label>
                <p-dropdown [options]="vehiclesTypes"                    
                    name="vehicleTypeId" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" 
                    placeholder="Seleccione tipo de vehículo" appendTo="body"
                    formControlName="vehicleTypeId" d-form-field-error/>                            
            </div>              
        </div>    
        <div class="col-6 input-field">
            <label for="active">Activo</label>
            <p-inputSwitch id="active" formControlName="active"></p-inputSwitch>
        </div>
    </div>   
    
    <div class="grid"> 
        <div class="col-12 input-field">
            <label for="notes">Notas</label>
            <textarea pInputTextarea formControlName="notes" style="field-sizing: content;" rows="5"
                d-form-field-error></textarea>
        </div>
    </div>      
</form>

<form *ngIf="workerVehicleFormGroup" [formGroup]="workerVehicleFormGroup">
    <div class="grid">
        <div class="col-6 input-field ">
            <label for="startDate">Desde *</label>
            <p-calendar 
                inputId="fromDate"         
                appendTo="body"
                dateFormat="dd/mm/yy"
                formControlName="fromDate"
                d-form-field-error/>        
        </div>
        <div class="col-6 input-field ">
            <label for="toDate">Hasta</label>
            <p-calendar 
                inputId="toDate"         
                appendTo="body"
                dateFormat="dd/mm/yy"
                formControlName="toDate"
                d-form-field-error/>
        </div>
    </div>
</form>

<div class="mt-4 flex gap-2 justify-content-end">            
    <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
    <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
</div> 

<p-confirmDialog></p-confirmDialog>