<form *ngIf="vehicleFormGroup" [formGroup]="vehicleFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-4">
                <div class="input-field">
                    <label for="name">Nombre *</label>
                    <input pInputText id="name" formControlName="name" d-form-field-error />
                </div>
            </div>
            <div class="col-4">
                <div class="input-field">
                    <label for="name">Matrícula *</label>
                    <input pInputText id="plate" formControlName="plate" d-form-field-error />
                </div>
            </div>
            <div class="col-4">             
                <div class="input-field">
                  <label for="name">Tipo de vehículo *</label>
                  <p-dropdown [options]="vehiclesTypes"                    
                    name="vehicleTypeId" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" 
                    placeholder="Seleccione tipo de vehículo" appendTo="body"
                    formControlName="vehicleTypeId" d-form-field-error/>                            
                </div>              
            </div>            
        </div>        
        
        <div class="grid">      
            <div class="col-4">             
                <div class="input-field">
                  <label for="name">Propietario *</label>
                  <p-dropdown [options]="ownerTypes"                    
                            name="OwnerType" optionLabel="name" optionValue="code" [filter]="true"
                            filterBy="name" 
                            placeholder="Seleccione propietario" appendTo="body"
                            formControlName="ownerType" d-form-field-error      
                            (onChange)="onOwnerTypeChange($event)"                     
                            />                            
                </div>              
            </div>
            <div class="col-4" *ngIf="vehicle.ownerType == 'WORKER'">             
                <div class="input-field">
                  <label for="name">Operario </label>
                  <p-dropdown [options]="workers"                    
                    name="name" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" 
                    placeholder="Seleccione operario" appendTo="body"
                    formControlName="workerId" d-form-field-error/>                            
                </div>              
            </div>
            <div class="col-4" *ngIf="vehicle.ownerType == 'COMPANY'">             
                <div class="input-field">
                  <label for="name">Sociedad </label>
                  <p-dropdown [options]="business"                    
                    name="name" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" 
                    placeholder="Seleccione empresa" appendTo="body"
                    formControlName="companyId" d-form-field-error/>                            
                </div>              
            </div>
            <div class="col-2 input-field">
                <label for="active">Activo</label>
                <p-inputSwitch id="active" formControlName="active"></p-inputSwitch>
            </div>            
        </div>   
        <div class="grid"> 
            <div class="col-12 input-field">
                <label for="notes">Notas</label>
                <textarea pInputTextarea formControlName="notes" style="field-sizing: content;" rows="5"
                    d-form-field-error></textarea>
            </div>
        </div>   
        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>
</form>
<p-confirmDialog></p-confirmDialog>