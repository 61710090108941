import { Injectable } from "@angular/core";
import { Worker } from "../_types/worker";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class WorkersService extends Service<Worker> {
  public override type = 'workers';
  public override url = 'providers/workers'; 

  public sendMailLoginData(worker:Worker):Observable<any>{
    let url = this.getAPIUrl();
      let headers = {
          'Content-Type': 'application/vnd.api+json'
      };
      url = url + '/' + worker.id + "/sendmail/logindata"
      this.loaderService.skipRequest(url);
      return this.http.post(url , null, { headers: headers });
  }

}
