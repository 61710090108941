import { Resource } from "src/app/core/jsonapi/resource";
import { Processor } from "./processor";
import { prop, required } from "@rxweb/reactive-form-validators";

export class ContractProcessor extends Resource {
    public override type = 'contractProcessors';
    
    @prop()
    public contractId!: string;
    @prop()
    @required()
    public processorId!: string;
    @prop()
    @required()
    public maxLoadChargePercent!: number;        

    @prop()
    public processor!: Processor;
}