<form *ngIf="jobFormGroup" [formGroup]="jobFormGroup">
    <div class="grid">        
        <div class="col-4 input-field">
            <label for="date">Fecha</label>
            <p-calendar formControlName="date" (onSelect)="loadRateLines()" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                [yearNavigator]="true" dateFormat="dd/mm/yy" appendTo="body" [readonlyInput]="true" d-form-field-error></p-calendar>
        </div>
        <div class="col-8 input-field">
            <label for="capabilityId">Especialidad</label>
            <p-dropdown [(ngModel)]="selectedCapability" [ngModelOptions]="{standalone:true}" [options]="capabilities"
                (onChange)="selectCapability($event)" optionLabel="name" optionValue="id" [filter]="true" filterBy="name"
                appendTo="body" placeholder="Selecciona una especialidad" d-form-field-error [disabled]="job.transactionId != null"></p-dropdown>
        </div>
        <div class="col-12 input-field">
            <label for="rateLineId">Baremo</label>
            <p-dropdown formControlName="rateLineId" [options]="rateLines" optionValue="id" optionLabel="displayName"
                appendTo="body" placeholder="Selecciona un baremo" (onChange)="onRateLineChange($event)"
                [showClear]="true" [filter]="true" d-form-field-error>                
            </p-dropdown>
        </div>
        <div class="col-12 input-field">
            <label for="description">Descripción</label>
            <textarea formControlName="description" pInputTextarea rows="5" d-form-field-error></textarea>
        </div>
        <div class="col-3 input-field">
            <label for="quantity">Cantidad</label>
            <input formControlName="quantity" pInputText type="number" d-form-field-error (keyup)="calculatePrices($event)">
        </div>
        <div class="col-3 input-field">
            <label for="unitPrice">Precio</label>
            <input formControlName="unitPrice" pInputText type="number" d-form-field-error (keyup)="calculatePrices($event)">
        </div>
        <div class="col-3 input-field">
            <label for="code">Impuestos(%)</label>
            <p-dropdown formControlName="taxPercent" [options]="taxes"
                optionLabel="name" optionValue="percent" appendTo="body" filterBy="name"
                placeholder="Seleccione opción" (onChange)="calculatePrices($event)" d-form-field-error></p-dropdown>                            
            <label class="mt-2">
                {{ jobFormGroup.get('taxAmount')?.value | currency:'EUR':'symbol':'1.2-2' }}
            </label>
        </div>
        <div class="col-3 input-field">
            <label class="mb-3" for="total">Total</label>
            <span class="mt-2">
                {{ jobFormGroup.get('total')?.value | currency:'EUR':'symbol':'1.2-2' }}                
            </span>
        </div>
        <div class="col-4 input-field">
            <label for="isReview">Revisado</label>
            <p-inputSwitch formControlName="isReview" d-form-field-error></p-inputSwitch>
        </div>
        <div class="col-4 input-field">
            <label for="isBillable">Es facturable</label>
            <p-inputSwitch formControlName="isBillable" d-form-field-error></p-inputSwitch>
        </div>
    </div>
    <div class=" mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="preSave()"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>