import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpertsListComponent } from './experts-list/experts-list.component';
import { ExpertsDetailComponent } from './experts-detail/experts-detail.component';

const routes: Routes = [
    {
        path: 'experts',
        children: [
            { path: '', component: ExpertsListComponent },
            { path: ':guid', component: ExpertsDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ExpertsRoutingModule { }
