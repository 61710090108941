import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { LoaderComponent } from './loader/loader.component';
import { CalendarYearComponent } from './calendar-year/calendar-year.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { FormFieldErrorDirective } from './directive/form-field-error.directive';
import { BadgeModule } from 'primeng/badge';


@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent,
    LoaderComponent,
    CalendarYearComponent,

    FormFieldErrorDirective
  ],
  imports: [
    CommonModule,
    PanelMenuModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    OverlayPanelModule,
    FormsModule,
    BadgeModule
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    LoaderComponent,
    CalendarYearComponent,
    
    FormFieldErrorDirective
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }]
})
export class CoreModule { }
