import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Company } from "../_types/company";

@Injectable({
    providedIn: 'root',
})

export class CompaniesService extends Service<Company> {
  public override type = 'companies';
  public override url = 'attendance/companies';   
}
