import { Component } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { LoginService } from '../../login/login.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { GuardsShiftsService } from '../_services/guards-shifts.service';
import { GuardShift } from '../_types/guard-shift';

@Component({
  selector: 'app-guards-shifts-list',
  templateUrl: './guards-shifts-list.component.html',
})
export class GuardsShiftsComponent {
  
  public guardsShifts: any = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };
  private lastGridEvent: any;  
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private guardsShiftsService: GuardsShiftsService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,                
    private router: Router,
    private topbarService: TopbarService  ) {
    this.topbarService.setTitle("Turnos de guardia");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({});
    });    
    this.allowWrite = true;//this.loginService.hasPermission("GUARDS_SHIFTS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("GUARDS_SHIFTS_DELETE")
  }

  async loadData($event: any) {
    this.lastGridEvent = $event;

    let params:IParamsCollection = {      
      page: { number: $event.first+1, size: $event.rows },
      sort: ["name"]      
    };
    
    if(typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.guardsShiftsService.all(params));
    this.guardsShifts = response.data;
    this.totalRecords = response.meta['total'];
  }
  
  clearFilters() {
    this.filters = {};
    this.search();
  }
  
  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(guardShift: any) {      
      this.router.navigate(['guards-shifts/' + (guardShift && Object.keys(guardShift).length > 0 ? guardShift.id : 0)]);          
  }

  delete(guardShift: GuardShift) {
    
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el turno de guardia "+guardShift.name+"?",
        header: "Eliminar turno de guardia",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.guardsShiftsService.delete(guardShift.id).subscribe({
            next: () => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Turno de guardia eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
}
