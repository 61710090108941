import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EquipmentsDetailComponent } from './equipments-detail/equipments-detail.component';
import { EquipmentsListComponent } from './equipments-list/equipments-list.component';

const routes: Routes = [
    {
        path: 'equipments',
        children: [
            { path: '', component: EquipmentsListComponent }
        ]
    },
    {
        path: 'equipments/:id',
        children: [
            { path: '', component: EquipmentsDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EquipmentsRoutingModule { }
