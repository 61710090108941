import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Zone } from '../_types/zone';
import { ZoneLine } from '../_types/zone-line';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ZonesService } from '../_services/zones.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ZonesLinesService } from '../_services/zones-lines.service';
import { firstValueFrom } from 'rxjs';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-workers-zone',
  templateUrl: './workers-zone.component.html',
  styleUrls: ['./workers-zone.component.scss']
})
export class WorkersZoneComponent {
  public workerZoneFormGroup!: RxFormGroup;
  public zoneLineFormGroup!: RxFormGroup;

  public zone: Zone = new Zone();
  public loading: boolean = false;
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public zoneLines: any = [];
  public visibleZoneLine: boolean = false;
  public zoneLine: ZoneLine = new ZoneLine();

  constructor(
    private config: DynamicDialogConfig,
    private zonesService: ZonesService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private zonesLinesService: ZonesLinesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.workerZoneFormGroup = this.formBuilder.formGroup<Zone>(this.zone) as RxFormGroup;
    this.zoneLineFormGroup = this.formBuilder.formGroup<ZoneLine>(this.zoneLine) as RxFormGroup;
  }


  ngOnInit(): void {

    this.zone.workerId = this.config.data.wokerId;

    if (this.config.data.id != null && this.config.data.id != '0') {
      this.loadZone(this.config.data.id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("ZONES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("ZONES_DELETE");
  }

  async loadZone(id: string) {
    this.zone = await firstValueFrom(this.zonesService.get(id));
    this.workerZoneFormGroup.patchValue(this.zone);
    this.loadZoneLines();
  }


  async loadZoneLines() {

    let filters = {
      zoneId: new Filter(FilterOperation.equals, 'zoneId', '', this.zone.id!)
    };

    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build(),
      sort: ['value']
    };

    console.log(params);
    let response = await firstValueFrom(this.zonesLinesService.all(params));


    this.zoneLines = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.workerZoneFormGroup);
    if (valid) {
      this.zone = Object.assign(this.zone, this.workerZoneFormGroup.value);
      let zone: any = await firstValueFrom(this.zonesService.save(this.zone));
      if (this.zone.id) {
        this.dynamicDialogRef.close();
      } else {
        this.loadZone(zone.id);
      }

      this.messageService.add({ closable: false, severity: 'success', detail: "Zona guardada correctamente" });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Quieres eliminar la zona " + this.zone.name + "?",
      header: "Eliminar zona",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.zonesService.delete(this.zone.id).subscribe(
          (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Zona eliminada correctamente" });

            this.cancel();
          },
          (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  editZoneLine(zoneLine: any) {
    if (zoneLine.id != null) this.zoneLine = zoneLine;
    this.zoneLineFormGroup.patchValue(this.zoneLine);
    this.visibleZoneLine = true;
  }

  async saveZoneLine() {
    let valid = this.formValidationService.validateForm(this.zoneLineFormGroup);
    if (valid) {
      this.zoneLine = Object.assign(this.zoneLine, this.zoneLineFormGroup.value);
      this.visibleZoneLine = false;
      let id: string = this.zone.id!;
      this.zoneLine.zoneId = id;
      await firstValueFrom(this.zonesLinesService.save(this.zoneLine));

      this.loadZoneLines();
    }

  }

  deleteZoneLine(zoneLine: any) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la línea" + zoneLine.value + "?",
        header: "Eliminar línea",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.zonesLinesService.delete(zoneLine.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente" });
              this.loadZoneLines();
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }


}
