import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { TransactionLine } from "../_types/transaction-line";

@Injectable({
    providedIn: 'root',
})
export class TransactionsLinesService extends Service<TransactionLine> {
    public override type = 'transactionLines';
    public override url = 'invoicing/transactionLines';
}
