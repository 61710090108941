import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { MessageService } from 'primeng/api';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom, from } from 'rxjs';
import { VehiclesTypesService } from '../../vehicles-types/_services/vehicles-types.service';
import { VehicleType } from '../../vehicles-types/_types/vehicle-type';
import { Business } from '../../business/_types/business';
import { WorkersService } from '../_services/workers.service';
import {Worker} from '../_types/worker';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Vehicle } from '../../vehicles/_types/vehicle';
import { VehiclesService } from '../../vehicles/_services/vehicles.service';
import { WorkerVehiclesService } from '../_services/workers-vehicles.service';
import { WorkerVehicle } from '../_types/worker-vehicle';

@Component({
  selector: 'app-workers-vehicles-detail',
  templateUrl: './workers-vehicles-detail.component.html',
  styleUrls: ['./workers-vehicles-detail.component.scss']
})
export class WorkersVehiclesDetailComponent {
  public vehicleFormGroup!: RxFormGroup;
  public workerVehicleFormGroup!: RxFormGroup;

  public vehicle: Vehicle = new Vehicle();
  public workerVehicle: WorkerVehicle = new WorkerVehicle();

  public vehiclesTypes: Array<VehicleType> = new Array<VehicleType>;  

  public business: Array<Business> = new Array<Business>;
  public workers: Array<Worker> = new Array<Worker>;
  public workerId: string = "";

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  public ownerTypes: any = [    
    {
      code: "WORKER", name: "Operario"
    }
  ];

  constructor(
    private vehiclesService: VehiclesService,
    private messageService: MessageService,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private vehiclesTypesService: VehiclesTypesService,
    private workersService: WorkersService,    
    public config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private workerVehiclesService: WorkerVehiclesService
  ) {
    this.topbarService.setTitle("Detalles de vehículo");
    this.topbarService.addBreadcrumb("Vehículos", "/vehicles");    

    this.vehicleFormGroup = this.formBuilder.formGroup<Vehicle>(this.vehicle) as RxFormGroup;
    delete this.vehicleFormGroup.controls['vehicleType'];
    delete this.vehicleFormGroup.controls['worker'];


    //datos por defecto
    this.workerVehicle.fromDate = new Date();
    this.workerVehicleFormGroup = this.formBuilder.formGroup<WorkerVehicle>(this.workerVehicle) as RxFormGroup;
    delete this.workerVehicleFormGroup.controls['worker'];
    delete this.workerVehicleFormGroup.controls['vehicle'];

    console.log(this.workerVehicleFormGroup);
    
  }

  async ngOnInit() {
    //console.log(this.config);
    this.workerId = this.config.data.workerId;   
    console.log(this.workerId); 
    this.vehicle.ownerType = "WORKER";
    this.vehicle.workerId = this.workerId;


    this.allowWrite = true;//this.loginService.hasPermission("VEHICLES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("VEHICLES_DELETE");

    this.loadVehiclesTypes();
    this.loadWorkers();
  }
    

  async loadVehiclesTypes(){

    let response = await firstValueFrom(this.vehiclesTypesService.all());             
    this.vehiclesTypes = response.data;  

  }
  

  async loadWorkers(){
    let response = await firstValueFrom(this.workersService.all());             
    this.workers = response.data; 
  }
    

  async save() {

    let valid = this.formValidationService.validateForm(this.vehicleFormGroup);        
    if (valid) {
      this.vehicle = Object.assign(this.vehicle, this.vehicleFormGroup.value);

      let vehicleSaved = await firstValueFrom(this.vehiclesService.save(this.vehicle))
 
      let vehicleId:string = vehicleSaved.id!;      
      this.workerVehicle = Object.assign(this.workerVehicle, this.workerVehicleFormGroup.value);
      this.workerVehicle.workerId = this.workerId;
      this.workerVehicle.vehicleId = vehicleId;

      console.log(this.workerVehicle);

      await firstValueFrom(this.workerVehiclesService.save(this.workerVehicle));

      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Vehículo asignado correctamente' });
      this.dynamicDialogRef.close();
                                    
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }  

  cancel() {
    this.dynamicDialogRef.close();
  }

}
