import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { TransactionLinesComponent } from './transactions-detail/transaction-line/transaction-line.component';
import { DragDropModule } from 'primeng/dragdrop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransactionsDetailComponent } from './transactions-detail/transactions-detail.component';
import { TransactionLinesJobsComponent } from './transactions-detail/transaction-lines-jobs/transaction-lines-jobs.component';
import { TransactionsDetailSummaryComponent } from './transactions-detail-summary/transactions-detail-summary.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TransactionsOperatorsComponent } from './transactions-operators/transactions-operators.component';


@NgModule({
    declarations: [
        TransactionsListComponent,
        TransactionsDetailComponent,
        TransactionLinesComponent,
        TransactionLinesJobsComponent,
        TransactionsDetailSummaryComponent,
        TransactionsOperatorsComponent
    ],
    imports: [
        TransactionsRoutingModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        CheckboxModule,
        TabViewModule,
        CalendarModule,
        TagModule,
        InputTextareaModule,
        DropdownModule,
        DialogModule,
        EditorModule,
        DragDropModule,
        BrowserAnimationsModule,
        FileUploadModule
    ],
    providers: []
})
export class TransactionsModule { }
