<div class="flex justify-content-center align-items-center">
  <p-button (click)="changeYear(-1)" styleClass="p-button-text" icon="pi pi-angle-left"></p-button>
  <div class="year-name ml-5 mr-5">        
    {{start_date.getFullYear()}}
  </div>
  <p-button (click)="changeYear(1)" styleClass="p-button-text" icon="pi pi-angle-right"></p-button>
</div>


<div class="flex flex-wrap mt-4">

  <div *ngFor="let monthLetter of monthLetters" class="mt-4 col-3">
    <div class="prevent-select justify-content-center align-items-center ">
      <div class="month-navigator flex p-ai-center">
        <div class="month-title">
          {{monthLetter.letter}}
        </div>        
      </div>
      <div class="p-mt-4 flex">
        <div *ngFor="let dayColumn of generateDays(monthLetter.month, start_date.getFullYear())" class="day-column">
          <div class="letter">{{dayColumn.day.letter}}</div>                   
          <div *ngFor="let date of dayColumn.dates" class="day" (mousedown)="mouseDown(date)" (mousemove)="mouseMove(date)" (mouseup)="mouseUp(date)" [ngClass]="{'day': date.date!=null, 'day-empty': date.date==null}">            
            <div class="number" 
              [class.day-in-selected-range]="isInRange(date)" 
              [class.number-event]="date.events.length>0" 
              [class.number-holiday]="date.holidays.length>0" 
              [class.number-guard-shift]="date.guardShiftDates.length>0" 
              [ngStyle]="{'background-color': color!=null && color!='' && date.guardShiftDates.length>0 ? color : ''}"
              pTooltip="{{date.tooltip}}" tooltipPosition="top">{{date.number}}</div>            
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>
<div class="flex justify-content-center legend">
  <div *ngIf="events.length>0">
    <span class="day-event"></span> Día festivo
  </div>
  <div *ngIf="holidays.length>0">
    <span class="day-holiday ml-3"></span> Vacaciones
  </div>  
</div>