import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { SurveyQuestion } from '../../_types/survey-question';
import { SurveysQuestionsService } from '../../_services/surveys-questions.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { SurveyQuestionOption } from '../../_types/survey-question-option';
import { SurveysQuestionsOptionsService } from '../../_services/surveys-questions-options.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { QuestionOptionComponent } from './question-option/question-option.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class SurveyDetailQuestionComponent {
  public surveyQuestionFormGroup!: RxFormGroup;

  public surveyQuestion: SurveyQuestion = new SurveyQuestion();
  public surveyQuestionTypes: any = [];
  public surveyQuestionOptions: SurveyQuestionOption[] = [];

  public loading: boolean = false;
  public totalRecords: number = 0;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private surveysQuestionsService: SurveysQuestionsService,
    private surveyQuestionOptionsService: SurveysQuestionsOptionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private dialogService: DialogService
  ) {
    this.surveyQuestionFormGroup = this.formBuilder.formGroup<SurveyQuestion>(this.surveyQuestion) as RxFormGroup;
    this.surveyQuestionTypes = this.surveysQuestionsService.questionTypes();
  }

  async ngOnInit() {
    let id = this.config.data.surveyQuestionId;
    if (id != null && id != '0') {
      await this.loadSurveyQuestion(id);
    } else {
      this.surveyQuestion.surveyId = this.config.data.surveyId;
      this.surveyQuestionFormGroup.patchValue(this.surveyQuestion);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadSurveyQuestion(id: string) {
    this.surveyQuestion = await firstValueFrom(this.surveysQuestionsService.get(id));
    this.surveyQuestionFormGroup.patchValue(this.surveyQuestion);

    this.loadSurveyQuestionOptions();
  }

  async loadSurveyQuestionOptions() {
    this.loading = true;

    let params: IParamsCollection = {}

    let filters: any = {
      surveyQuestionId: new Filter(FilterOperation.equals, "surveyQuestionId"),
    };

    filters.surveyQuestionId.value = this.surveyQuestion.id;
    params.filter = FilterBuilder.fromObject(filters).build();


    let response = await firstValueFrom(this.surveyQuestionOptionsService.all(params));
    this.surveyQuestionOptions = response.data;
    this.totalRecords = response.meta['total'];

    this.loading = false;
  }

  editOption(option: SurveyQuestionOption | null) {
    let ref = this.dialogService.open(QuestionOptionComponent, {
      header: "Detalles de opción",
      width: '50%',
      data: {
        surveyQuestionOptionId: option?.id || null,
        surveyQuestionId: this.surveyQuestion.id
      }
    });

    ref.onClose.subscribe(() => {
      this.loadSurveyQuestionOptions();
    });
  }

  deleteOption(option: SurveyQuestionOption) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la opción?",
      header: "Eliminar opción",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.surveyQuestionOptionsService.delete(option.id).subscribe({
          next: (data: any) => {
            this.loadSurveyQuestionOptions();
            this.messageService.add({ closable: false, severity: 'success', detail: "Opción eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.surveyQuestionFormGroup);
    if (valid) {
      this.surveyQuestion = Object.assign(this.surveyQuestion, this.surveyQuestionFormGroup.value);

      let surveyQuestionItem = await firstValueFrom(this.surveysQuestionsService.save(this.surveyQuestion));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Pregunta guardada correctamente." });
      this.cancel();
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la pregunta?",
      header: "Eliminar pregunta",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.surveysQuestionsService.delete(this.surveyQuestion.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Pregunta eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
