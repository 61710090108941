import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Task } from '../_types/task';
import { User } from '../../users/_types/user';
import { TasksService } from '../_services/tasks.service';
import { UsersService } from '../../users/_services/users.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { CasesService } from '../../cases/_services/cases.service';
import { CasesNotesService } from '../../cases/_services/cases-notes.service';
import { Case } from '../../cases/_types/case';

@Component({
  selector: 'app-tasks-detail',
  templateUrl: './tasks-detail.component.html',
  styleUrls: ['./tasks-detail.component.scss']
})
export class TasksDetailComponent {
  public taskFormGroup!: RxFormGroup;

  public task: Task = new Task();
  public users: User[] = [];
  public case!:Case;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private tasksService: TasksService,
    private usersService: UsersService,
    private casesService: CasesService,
    private casesNotesService: CasesNotesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.taskFormGroup = this.formBuilder.formGroup<Task>(this.task) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadUsers();

    let id = this.route.snapshot.params['guid'];
    if (id != null && id != '0') {
      await this.loadTask(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadTask(id: string) {
    this.task = await firstValueFrom(this.tasksService.get(id, { include: ['user'] }));

    //si tiene expediente asociado, lo cargamos
    if(this.task.caseNoteId != null){
      let caseNote = await firstValueFrom(this.casesNotesService.get(this.task.caseNoteId));
      this.case = await firstValueFrom(this.casesService.get(caseNote.caseId));
    }

    this.taskFormGroup.patchValue(this.task);
  }

  async loadUsers() {
    let response = await firstValueFrom(this.usersService.all());
    this.users = response.data;
  }

  viewCaseNote() {
    this.router.navigate(['cases', this.task.caseNote.caseId]);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.taskFormGroup);
    if (valid) {
      this.task = Object.assign(this.task, this.taskFormGroup.value);

      let taskItem = await firstValueFrom(this.tasksService.save(this.task));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Tarea guardada correctamente." });
      this.router.navigate(['tasks']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la tarea?",
      header: "Eliminar tarea",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.tasksService.delete(this.task.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Tarea eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['tasks']);
  }

}
