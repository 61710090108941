import { Resource } from "src/app/core/jsonapi/resource";
import { WorkerBankAccount } from "./worker-bank-account";
import { WorkerAddress } from "./worker-address";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { User } from "../../users/_types/user";

export class Worker extends Resource {
    public override type = 'workers';

    @prop()
    @required()
    public name!: string;
    @prop()
    public legalName!: string;
    @prop()
    public typeOfBusiness: number = 1;
    @prop()
    public notes!: string;
    @prop()
    public email!: string;
    @prop()
    public phone1!: string;
    @prop()
    public active: boolean = true;
    @prop()
    public vatNumber!: string;

    @prop()
    public bankAccountId!: string;
    @prop()
    public calendarId!: string;

    @prop()
    public parentWorkerId!: string;

    @prop()
    public parentWorker!: Worker;

    @prop()
    public hasBankAccount: boolean = false;

    @propObject(WorkerBankAccount)
    public bankAccount!: WorkerBankAccount;

    @prop()
    public addressId!: string;

    @propObject(WorkerAddress)
    public address: WorkerAddress = new WorkerAddress();

    @prop()
    public userId!: string;

    @prop()
    public enableAccess: boolean = false;

    @prop()
    public hasGuardShiftEnabled: boolean = false;

    @prop()
    public guardShiftId!: string;

    @prop()
    public usePrincipalAddressAsWorkStart: boolean = true;

    @prop()
    public manageYourAppointments: boolean = false;

    @prop()
    public hasSubmitInvoice: boolean = false;

    @prop()
    public workStartAddressId!: string;

    @prop()
    public rating!: number;

    @propObject(WorkerAddress)
    public workStartAddress?: WorkerAddress;

    @propObject(User)
    public user!: User;

}