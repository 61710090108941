import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class SurveyQuestionOption extends Resource {
    public override type = 'surveyQuestionOptions';

    @prop()
    @required()
    public surveyQuestionId!: string;

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public active: boolean = true;


}