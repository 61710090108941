import { Component } from '@angular/core';
import { Case } from '../_types/case';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { CasesService } from '../_services/cases.service';
import { Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { CasesDetailGeneralComponent } from '../cases-detail/general/general.component';
import { CasesStatesService } from '../_services/cases-states.service';
import { CaseState } from '../_types/case-state';

@Component({
  selector: 'app-cases-list',
  templateUrl: './cases-list.component.html',
  styleUrls: ['./cases-list.component.scss']
})
export class CasesListComponent {
  public cases: Case[] = [];
  public casesStates: CaseState[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'caseNumber'),
    caseStateId: new Filter(FilterOperation.equals, 'caseStateId'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private casesService: CasesService,
    private topbarService: TopbarService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private casesStatesService: CasesStatesService
  ) {
    this.topbarService.setTitle("Expedientes");
    this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
      this.edit({} as Case);
    });
    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE")
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE")
  }

  ngOnInit(): void {
    this.loadCaseStates();
  }

  async loadCaseStates(){
    let params:IParamsCollection = {}    
    let response = await firstValueFrom(this.casesStatesService.all(params));
    this.casesStates = response.data;
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows },
      include: ['company', 'client', 'processor', 'address', 'caseState', 'caseType']
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();


    let response = await firstValueFrom(this.casesService.all(params));
    this.cases = response.data;
    this.totalRecords = response.meta['total'];
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  getTimeAgo(date: Date) {
    let today = new Date();
    let diff = today.getTime() - date.getTime();
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let diffHours = diff - days * (1000 * 60 * 60 * 24);
    let hours = Math.floor(diffHours / (1000 * 60 * 60));
    let message = "";
    if(days>0) message = days + " días";
    if(hours>0) message += (message!=""?" y ":"") + hours + " hours";
    return message;
  }

  edit(caseItem: Case) {
    if(Object.keys(caseItem).length > 0){
      this.router.navigate(['cases/' + caseItem.id]);
    }else{    
        let ref = this.dialogService.open(CasesDetailGeneralComponent, {
          header: 'Datos generales',
          width: '50%',
          data: {
            
          }
        });
        ref.onClose.subscribe((data: Case) => {
          if(data!=null){
            this.router.navigate(['cases/' + data.id]);
          }
        });      
    }
    
  }

  delete(caseItem: Case) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el expediente?",
        header: "Eliminar expediente",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.casesService.delete(caseItem.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Expediente eliminado correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
}
