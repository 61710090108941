import { Component } from '@angular/core';
import { ClientPolicy } from '../_types/client-policy';
import { Company } from '../../companies/_types/company';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompaniesService } from '../../companies/_services/companies.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { ClientPolicyGuaranteesService } from '../_services/clients-policies-guarantees.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { ClientPolicyGuarantee } from '../_types/client-policy-guarantee';
import { ClientPolicyGuaranteesComponent } from './client-policy-guarantees/client-policy-guarantees.component';
import { ClientPoliciesService } from '../_services/clients-policies.service';

@Component({
  selector: 'app-client-policy',
  templateUrl: './client-policy.component.html',
  styleUrls: ['./client-policy.component.scss']
})
export class ClientPolicyComponent {
  public clientPolicyFormGroup!: RxFormGroup;

  public clientPolicy: ClientPolicy = new ClientPolicy();

  public companies: Company[] = [];
  public clientPolicyGuarantees: ClientPolicyGuarantee[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private clientPoliciesService: ClientPoliciesService,
    private companiesService: CompaniesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private clientPolicyGuaranteesService: ClientPolicyGuaranteesService,
    private dialogService: DialogService
  ) {
    this.clientPolicyFormGroup = this.formBuilder.formGroup<ClientPolicy>(this.clientPolicy) as RxFormGroup;
  }

  async ngOnInit() {
    this.loadCompanies();

    let id = this.config.data.id;
    if (id != null && id != '0') {
      await this.loadClientPolicy(id);
    } else {
      this.clientPolicy.clientId = this.config.data.clientId;
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadClientPolicy(id: string) {
    this.clientPolicy = await firstValueFrom(this.clientPoliciesService.get(id));
    this.clientPolicyFormGroup.patchModelValue(this.clientPolicy);

    this.loadClientPolicyGuarantees()
  }

  async loadCompanies() {
    let response = await firstValueFrom(this.companiesService.all());
    this.companies = response.data;
  }

  async loadClientPolicyGuarantees() {
    let params: IParamsCollection = {
      include: ['guarantee']
    }
    let filters: any = {
      clientPolicyId: new Filter(FilterOperation.equals, "clientPolicyId")
    };

    filters.clientPolicyId.value = this.clientPolicy.id;
    params.filter = FilterBuilder.fromObject(filters).build();

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.clientPolicyGuaranteesService.all(params));
    this.clientPolicyGuarantees = response.data;
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.clientPolicyFormGroup);
    if (valid) {
      this.clientPolicy = Object.assign(this.clientPolicy, this.clientPolicyFormGroup.value);


      let clientPolicy = await firstValueFrom(this.clientPoliciesService.save(this.clientPolicy));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Póliza guardada correctamente." });
      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la póliza?",
      header: "Eliminar póliza",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.clientPoliciesService.delete(this.clientPolicy.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Póliza eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  async editGuarantees() {
    const ref = this.dialogService.open(ClientPolicyGuaranteesComponent, {
      header: 'Ficha de coberturas de la póliza',
      width: '60%',
      data: {
        clientPolicyId: this.clientPolicy.id
      }
    })

    ref.onClose.subscribe(() => {
      this.loadClientPolicyGuarantees();
    });
  }
}
