import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CompanyAddress } from "../_types/company-address";

@Injectable({
    providedIn: 'root',
})
export class CompanyAddressesService extends Service<CompanyAddress> {
  public override type = 'companyAddresses';
  public override url = 'attendance/companyAddresses';
}
