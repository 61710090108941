import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CaseExpert } from "../_types/case-expert";

@Injectable({
    providedIn: 'root',
})
export class CasesExpertsService extends Service<CaseExpert> {
    public override type = 'caseExperts';
    public override url = 'attendance/caseExperts';
}
