import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ZoneLine } from "../_types/zone-line";

@Injectable({
    providedIn: 'root',
})
export class ZonesLinesService extends Service<ZoneLine> {
  public override type = 'zoneLines';
  public override url = 'providers/zoneLines'; 
}
