import { Component } from "@angular/core";

@Component({
    selector: 'app-public-survey-response-thanks',
    templateUrl: './public-survey-response-thanks.component.html',
    styleUrls: ['./public-survey-response-thanks.component.scss']
})
export class PublicSurveyResponseThanksComponent {
    constructor(
    ) {
    }

}