import { prop, required } from "@rxweb/reactive-form-validators";
import { Timestamp } from "rxjs";
import { Resource } from "src/app/core/jsonapi/resource";

export class GuardShift extends Resource {
    public override type = 'guardShifts';
    
    @prop()
    @required()
    public name!: string;  

    @prop()    
    @required()
    public startHour!: any;        
    
    @prop()    
    @required()
    public endHour!: any;     

    @prop()    
    public color!: string;     
    
}