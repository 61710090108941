import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { firstValueFrom } from 'rxjs';
import { Calendar } from '../_types/calendar';
import { CalendarsService } from '../_services/calendars.service';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { CalendarsEventsService } from '../_services/calendars-events.service';
import { CalendarEventModalComponent } from './event-modal/calendar-event-modal.component';
import * as moment from 'moment';
import { Nullable } from 'primeng/ts-helpers';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';

@Component({
  selector: 'app-calendars-detail',
  templateUrl: './calendars-detail.component.html',
  styleUrls: ['./calendars-detail.component.css']
})
export class CalendarsDetailComponent implements OnInit {
  
  public calendarFormGroup!: RxFormGroup;

  public calendar: Calendar = new Calendar();  
  public calendarEvents: any = [];  
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  private lastGridEventHoliday: any;
  private image: File = {} as File;
  public activeIndex: number = 1;  
  public totalRecordsCalendar: number = 0;
  public blockDaysHolidays: any = [];
  public totalRecordsHolidays: number = 0;
  public blockDaysBlock: any = [];
  public totalRecordsBlock: number = 0;
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public fromDate: any = null;
  public toDate: any = null;

  constructor(
    private calendarService: CalendarsService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private calendarsEventsService: CalendarsEventsService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles del calendario");    
    this.topbarService.addBreadcrumb("Calendarios", "/calendars");     

    this.calendarFormGroup = this.formBuilder.formGroup<Calendar>(this.calendar) as RxFormGroup;
  }

  ngOnInit(): void {    
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      if(id!=null && id!='0'){
        this.loadCalendar(id);        
      }          
    });

    this.allowWrite = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("AUTHENTICATIONS_SERVICES_DELETE");
  }
  
  async loadCalendar(id: any) {
    this.calendar = await firstValueFrom(this.calendarService.get(id)); 
    this.calendarFormGroup.patchValue(this.calendar);      
  }  


  async loadEvents(){
        
    //Buscamos los del calendario    
    let filters = {
      calendarId: new Filter(FilterOperation.equals, 'calendarId', '', this.calendar.id),
      fromDate: new Filter(FilterOperation.greaterOrEqual, 'date', '', this.fromDate!.startOf("year").toISOString()),
      toDate: new Filter(FilterOperation.lessOrEqual, 'date', '', this.toDate!.toISOString()),
    };
    
    let params: IParamsCollection = {
      filter: FilterBuilder.fromObject(filters).build()
    };
    
    let response = await firstValueFrom(this.calendarsEventsService.all(params));    

    this.calendarEvents = response.data;
    
                
    
  }  
  
  async save() {
   
    let valid = this.formValidationService.validateForm(this.calendarFormGroup);
    if (valid) {

      this.calendar = Object.assign(this.calendar, this.calendarFormGroup.value);    
      
      let calendar = await firstValueFrom(this.calendarService.save(this.calendar));
      
      if(this.calendar.id != "undefined" && this.calendar.id != ""){
        this.router.navigate(['calendars']);
      }

      this.loadCalendar(calendar.id!);

      this.messageService.add({ closable: false, severity: 'success', summary: "Calendario guardado correctamente." });
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {

    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el calendario?",
        header: "Eliminar calendario",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.calendarService.delete(this.calendar.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Calendario eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });  
  }
  

  cancel() {
    this.router.navigate(['calendars']);
  }

  async daySelected(data: any) {    
    console.log(data);
    let date = moment(data.date);
    const ref = this.dialogService.open(CalendarEventModalComponent, {
      data: {
        calendarId: this.calendar.id,
        event: data.events[0],
        date: date
      },
      header: date.format('DD/MM/YYYY'),
      width: "400px",      
    });
    ref.onClose.subscribe(async (data: any) => {            
      if(typeof data != "undefined"){
        await firstValueFrom(this.calendarsEventsService.save(data));
        this.loadEvents();
      }else{
        this.loadEvents();
      }
      
      
    });
  }

  yearChanged(data: any){    
    this.fromDate = data.first_date;  
    this.toDate = data.end_date;    
    this.loadEvents();
  }
  
}
