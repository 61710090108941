<form *ngIf="vehicleTypeFormGroup" [formGroup]="vehicleTypeFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-6 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>            
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>
</form>
<p-confirmDialog></p-confirmDialog>