import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { CaseType } from '../_types/case-type';
import { CasesTypesService } from '../_services/cases-types.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-cases-types-detail',
  templateUrl: './cases-types-detail.component.html',
  styleUrls: ['./cases-types-detail.component.scss']
})
export class CasesTypesDetailComponent {
  public caseTypeFormGroup!: RxFormGroup;

  public caseType: CaseType = new CaseType();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private casesTypesService: CasesTypesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalle de tipo de expediente");
    this.topbarService.addBreadcrumb("Tipos de expediente", "/casesTypes");

    this.caseTypeFormGroup = this.formBuilder.formGroup<CaseType>(this.caseType) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    if (id != null && id != '0') {
      await this.loadCaseType(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("VEHICLES_TYPES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("VEHICLES_TYPES_DELETE");


  }

  async loadCaseType(id: string) {
    this.caseType = await firstValueFrom(this.casesTypesService.get(id));
    this.caseTypeFormGroup.patchValue(this.caseType);
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.caseTypeFormGroup);
    if (valid) {
      this.caseType = Object.assign(this.caseType, this.caseTypeFormGroup.value);

      let caseType = await firstValueFrom(this.casesTypesService.save(this.caseType));

      this.messageService.add({ closable: false, severity: 'success', summary: "Tipo de expediente guardado correctamente." });


      this.router.navigate(['casesTypes']);


    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el tipo de expediente?",
      header: "Eliminar tipo de expediente",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.casesTypesService.delete(this.caseType.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Tipo de expediente eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['casesTypes']);
  }
}
