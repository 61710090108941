<form *ngIf="affectedFormGroup" [formGroup]="affectedFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-6 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-6 input-field">
                <label for="vatNumber">CIF *</label>
                <input pInputText formControlName="vatNumber" d-form-field-error />
            </div>
        </div>
        <div class="grid pt-2">
            <div class="col input-field ">
                <label for="legalName">Razón social *</label>
                <input pInputText formControlName="legalName" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="phone1">Teléfono</label>
                <input pInputText formControlName="phone1" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="email">Email</label>
                <input pInputText formControlName="email" type="text" d-form-field-error />
            </div>

        </div>
        <div class="grid pt-2" formGroupName="address">
            <div class="col input-field col-3">
                <label for="name">Dirección *</label>
                <input pInputText formControlName="addressLine1" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Código postal</label>
                <input pInputText formControlName="zipCode" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Ciudad</label>
                <input pInputText formControlName="city" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">País</label>
                <p-dropdown [options]="countries" formControlName="countryCode" optionValue="code" [filter]="true"
                    appendTo="body" filterBy="name" optionLabel="name" placeholder="Seleccione país"
                    d-form-field-error />
            </div>
        </div>
        <div class="mt-4 flex gap-2 justify-content-end">
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>

</form>
<p-confirmDialog></p-confirmDialog>