
<p-card class="p-3" header="Datos generales">
    <form *ngIf="taskFormGroup" [formGroup]="taskFormGroup">

        <div class="grid">
            <div class="col-12 input-field">
                <label for="description">Descripción *</label>
                <textarea pInputTextarea [autoResize]="true" rows="5" formControlName="description" d-form-field-error></textarea>
            </div>
            <div class="col-6 input-field">
                <label for="userId">Usuario asignado *</label>
                <p-dropdown formControlName="userId" [options]="users" optionLabel="name" optionValue="id" [filter]="true"
                    filterBy="name" placeholder="Selecciona un usuario" d-form-field-error></p-dropdown>
            </div>
            <div class="col-3 input-field">
                <label for="date">Fecha *</label>
                <p-calendar formControlName="date" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                    [yearNavigator]="true" dateFormat="dd/mm/yy" d-form-field-error></p-calendar>
            </div>
            <div class="col-3 input-field">
                <label for="isCompleted">Completada</label>
                <p-inputSwitch formControlName="isCompleted" d-form-field-error></p-inputSwitch>
            </div>
        </div>     
        <div class="mt-4 flex gap-2">
            <p-button label="Eliminar" *ngIf="allowDelete && task?.id" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
        </div>
    </form>
</p-card>


<p-messages *ngIf="case" severity="info">
    <ng-template pTemplate>
        Esta tarea está asociada con el expediente&nbsp;<a [routerLink]="['/cases', case.id]" target="_blank">{{case.caseNumber}}</a>.
    </ng-template>
</p-messages>

<p-confirmDialog></p-confirmDialog>