import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardsShiftsComponent } from './list/guards-shifts-list.component';
import { GuardsShiftsDetailComponent } from './detail/guards-shifts-detail.component';


const routes: Routes = [
  {
    path: 'guards-shifts',
    children: [
      { path: '', component: GuardsShiftsComponent }
    ]
  },
  {
    path: 'guards-shifts/:id',
    children: [
      { path: '', component: GuardsShiftsDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuardsShiftsRoutingModule { }
