import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home/home.module';
import { LoginModule } from './modules/login/login.module';
import { CoreModule } from './core/core.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { RolesModule } from './modules/roles/roles.module';
import { ProvidersModule } from './modules/providers/providers.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { CapabilitiesModule } from './modules/capabilities/capabilities.module';
import { AuthenticationsServicesModule } from './modules/authentications-services/authentications-services.module';
import { UsersModule } from './modules/users/users.module';
import { CalendarsModule } from './modules/calendars/calendars.module';
import { BusinessModule } from './modules/business/business.module';
import { CompaniesModule } from './modules/companies/companies.module';
import { JsonDateInterceptor } from './core/interceptors/http-json-date.interceptor';
import { WorkersModule } from './modules/workers/workers.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveFormConfig, RxReactiveFormsModule, minLength, required } from '@rxweb/reactive-form-validators';
import { BanksModule } from './modules/banks/banks.module';
import { GuardsShiftsModule } from './modules/guards-shifts/guards-shifts.module';
import { ClientsModule } from './modules/clients/clients.module';
import { CasesListComponent } from './modules/cases/cases-list/cases-list.component';
import { CasesDetailComponent } from './modules/cases/cases-detail/cases-detail.component';
import { CasesModule } from './modules/cases/cases.module';
import { EquipmentsModule } from './modules/equipments/equipments.module';
import { VehiclesTypesModule } from './modules/vehicles-types/vehicles-types.module';
import { VehiclesModule } from './modules/vehicles/vehicles.module';
import { GuaranteesListComponent } from './modules/guarantees/guarantees-list/guarantees-list.component';
import { GuaranteesDetailComponent } from './modules/guarantees/guarantees-detail/guarantees-detail.component';
import { GuaranteesModule } from './modules/guarantees/guarantees.module';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment } from 'src/environment/environment';
import { ChatModule } from './modules/chat/chat.module';
import { SidebarModule } from 'primeng/sidebar';
import { RatesModule } from './modules/rates/rates.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { TasksModule } from './modules/tasks/tasks.module';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { SurveysModule } from './modules/surveys/surveys.module';
import { TemplatesListComponent } from './modules/templates/templates-list/templates-list.component';
import { TemplatesDetailComponent } from './modules/templates/templates-detail/templates-detail.component';
import { TemplatesModule } from './modules/templates/templates.module';
import { TransactionsModule } from './modules/transactions/transactions.module';
import { ExpertsListComponent } from './modules/experts/experts-list/experts-list.component';
import { ExpertsDetailComponent } from './modules/experts/experts-detail/experts-detail.component';
import { ExpertsModule } from './modules/experts/experts.module';
import { CasesTypesListComponent } from './modules/cases-types/cases-types-list/cases-types-list.component';
import { CasesTypesDetailComponent } from './modules/cases-types/cases-types-detail/cases-types-detail.component';
import { CasesTypesModule } from './modules/cases-types/cases-types.module';
import { PublicSurveyResponseModule } from './modules/public/surveys/public-survey-response.module';

//configuración mqtt
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.server,
  port: environment.mqtt.port,
  protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
  path: '',
  username: environment.mqtt.username,
  password: environment.mqtt.password,
  reconnectPeriod: 10 * 1000,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    CoreModule,
    LoginModule,
    HomeModule,
    AuthenticationsServicesModule,
    ProvidersModule,
    WorkersModule,
    CalendarsModule,
    CompaniesModule,
    RolesModule,
    CapabilitiesModule,
    UsersModule,
    TasksModule,
    SurveysModule,
    TemplatesModule,
    ExpertsModule,
    CasesTypesModule,
    BusinessModule,
    BanksModule,
    ClientsModule,
    CasesModule,
    GuaranteesModule,
    ToastModule,
    CoreModule,
    GuardsShiftsModule,
    EquipmentsModule,
    VehiclesTypesModule,
    VehiclesModule,
    ChatModule,
    SidebarModule,
    RatesModule,
    NotificationsModule,
    TransactionsModule,
    PublicSurveyResponseModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    MessageService,
    DialogService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor() {
    //registramos locales
    registerLocaleData(localeEs);

    //configuramos mensajes de validación
    ReactiveFormConfig.set({
      validationMessage: {
        required: 'Este campo es requerido',
        minLength: 'Este campo debe tener al menos {{1}} caracteres'
      }
    });
  }
}
