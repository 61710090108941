import { prop, propObject, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { ExpertAddress } from "./expert-address";

export class Expert extends Resource {
    public override type = 'experts';

    @prop()
    @required()
    public name!: string;

    @prop()
    @required()
    public legalName!: string;

    @prop()
    public typeOfBusiness: string = 'Person';
    @prop()
    public email!: string;
    @prop()
    public phone1!: string;
    @prop()
    public active: boolean = true;
    @prop()
    @required()
    public vatNumber!: string;

    @prop()
    public bankAccountId!: string;

    @prop()
    public addressId!: string;

    @propObject(ExpertAddress)
    public address: ExpertAddress = new ExpertAddress();


}