export enum FilterOperation {
    equals = 'equals',
    contains = 'contains',
    lessThan = 'lessThan',
    lessOrEqual = 'lessOrEqual',
    greaterThan = 'greaterThan',
    greaterOrEqual = 'greaterOrEqual',
    startsWith = 'startsWith',
    endsWith = 'endsWith'
}

export class Filter {
    operation: FilterOperation = FilterOperation.equals;
    relationship: string = '';
    name: string = '';
    value: any = null;
    allowNull: boolean = false;

    constructor(operation: FilterOperation, name: string, relationship:string = '', value: any = null, allowNull:boolean = false) {
        this.operation = operation
        this.name = name;
        this.value = value;
        this.allowNull = allowNull;
        this.relationship = relationship;
    }
    
    public toQueryString(): string {
        let sValue = this.value;
        if(this.value != null) sValue = '\'' + sValue + '\'';
        let filterRelationship = '';
        if(this.relationship!='') filterRelationship = "[" + this.relationship + "]";
        return "filter"+filterRelationship+"=" + encodeURIComponent(this.operation + '(' + this.name + ',' + sValue + ')');
    }
}

export class NotFilter extends Filter {
    public override toQueryString(): string {
        let sValue = this.value;
        if(this.value != null) sValue = '\'' + sValue + '\'';
        return "filter=not(" + encodeURIComponent(this.operation + '(' + this.name + ',' + sValue + ')') + ")";
    }
}