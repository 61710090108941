
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Capability } from '../../common/_types/capability';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { Country } from '../../common/_types/country';
import { Rate } from '../_types/rate';
import { RatesService } from '../_services/rates.service';
import { RateLine } from '../_types/rate-line';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { RateLinesService } from '../_services/rates-lines.service';
import { RateLineComponent } from './rate-line/rate-line.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Company } from '../../companies/_types/company';
import { CompanyRatesService } from '../../companies/_services/companies-rates.service';
import { CompanyRate } from '../../companies/_types/company-rate';
import { RateCompanyComponent } from './rate-company/rate-company.component';
import { OperatorRatesService } from '../_services/operators-rates.service';
import { OperatorRate } from '../_types/operator-rate';
import { RateOperatorComponent } from './rate-operator/rate-operator.component';
import { RateUploadExcelComponent } from './rate-upload-excel/rate-upload-excel.component';

@Component({
  selector: 'app-rates-detail',
  templateUrl: './rates-detail.component.html',
  styleUrls: ['./rates-detail.component.css']
})
export class RatesDetailComponent implements OnInit {
  
  public rateFormGroup!: RxFormGroup;

  public rate: Rate = new Rate();
  public totalRecords: number = 0;
  public loading: boolean = false;  
  public activeIndex: number = 1;    
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;    

  public selectedCapabilities: any[] = [];  

  public capabilities: Array<Capability> = new Array<Capability>();
  public required: boolean = true;

  public rateLines: RateLine[] = [];
  public loadingRateLines: boolean = false;
  public totalRateLinesRecords: number = 0;
  public rateLinesCapabilities: any[] = [];
  public companies: CompanyRate[] = [];
  public operators: OperatorRate[] = [];
  public totalCompaniesRecords: number = 0;
  public totalOperatorsRecords: number = 0;
  public type: string= "";

  constructor(    
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,
    public loginService: LoginService,    
    private route: ActivatedRoute,    
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private ratesService: RatesService,
    private rateLinesService: RateLinesService,
    private dialogService: DialogService,
    private companyRatesServices: CompanyRatesService,
    private operatorRatesServices: OperatorRatesService
  ) {
         

    
  }

  ngOnInit(): void {        

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id:string|null = params.get('id');
      this.type = params.get('type')??'';
      this.topbarService.setTitle("Detalles de baremo de "+(this.type=="COMPANY"?"compañía":"operador"));   
      this.topbarService.addBreadcrumb("Baremos de " +(this.type=="COMPANY"?"compañía":"operador"), "/rates/"+this.type);  
      this.rate.typeOfRate = this.type;
      this.rateFormGroup = this.formBuilder.formGroup<Rate>(this.rate) as RxFormGroup;
      if(id!=null && id!='0'){
        this.loadRate(id);                
      }                  
        
    });
    this.allowWrite = true;//this.loginService.hasPermission("RATES_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("RATES_DELETE");
    
  }
  
  async loadRate(id: string) {
    this.rate = await firstValueFrom(this.ratesService.get(id));    
    this.rateFormGroup.patchValue(this.rate);
    this.loadRateLines();
    if(this.type=="COMPANY") this.loadCompanies();  
    if(this.type=="OPERATOR") this.loadOperators();
    
  }

  async loadRateLines(){
    this.loadingRateLines = true;
    let params: IParamsCollection = {

    }
    let filters: any = {
      rateId: new Filter(FilterOperation.equals, "rateId"),
    };

    filters.rateId.value = this.rate.id;
    params.filter = FilterBuilder.fromObject(filters).build();
    params.include = ['capability'];

    let response = await firstValueFrom(this.rateLinesService.all(params));
    this.rateLines = response.data;
    this.rateLinesCapabilities = [];
    this.rateLines.forEach((rateLine: RateLine) => {
      this.rateLinesCapabilities.push({
        id: rateLine.capabilityId,
        name: rateLine.capability?.name
      });
    });
    // quitar duplicados
    this.rateLinesCapabilities = this.rateLinesCapabilities.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        
  }

  rateLinesByCapability(capabilityId: string) {
    return this.rateLines.filter((rateLine: RateLine) => rateLine.capabilityId == capabilityId);
  }


  async loadCompanies() {
    let filters = {
      rateId: new Filter(FilterOperation.equals, 'rateId', "", this.rate.id)      
    };

    let params: IParamsCollection = {
      include: ['company', 'contract']
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.companyRatesServices.all(params));
    console.log(response.data);
    this.companies = response.data;
  }

  async loadOperators() {
    let filters = {
      rateId: new Filter(FilterOperation.equals, 'rateId', "", this.rate.id)      
    };

    let params: IParamsCollection = {
      include: ['operator']
    }

    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let response = await firstValueFrom(this.operatorRatesServices.all(params));
    console.log(response.data);
    this.operators = response.data;
  }
    
  
  async save() {        
    console.log(this.rateFormGroup);
    let valid = this.formValidationService.validateForm(this.rateFormGroup);
    if (valid) {

      this.rate = Object.assign(this.rate, this.rateFormGroup.value);                                                   
                
      let rate = await firstValueFrom(this.ratesService.save(this.rate));
      
      /*if(typeof this.rate.id != "undefined" && this.rate.id != ""){
        await firstValueFrom(this.ratesService.updateCapabilities(this.rate.id!, this.selectedCapabilities));
      }*/

      this.messageService.add({ closable: false, severity: 'success', summary: "Baremo guardado correctamente." });
      
      if(this.rate.id != "undefined" && this.rate.id != ""){
          this.router.navigate(['rates/'+this.type]);
      }
      this.loadRate(rate.id!);
            
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });    
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el baremo?",
        header: "Eliminar baremo",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.ratesService.delete(this.rate.id).subscribe({
          next: () => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Baremo eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['rates/'+this.type]);
  }

  async editRateLine(rateLine: RateLine | null) {
    let ref = this.dialogService.open(RateLineComponent, {
      header: 'Ficha de línea de baremo',
      width: '50%',
      data: {
        rateId: this.rate.id,
        rateLineId: rateLine ? rateLine.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadRateLines();
    });
  }

  async deleteRateLine(id: string) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la línea de baremo?",
      header: "Eliminar línea",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.rateLinesService.delete(id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente." });
            this.loadRateLines();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }


  addCompany(rateCompany: any){
    const ref = this.dialogService.open(RateCompanyComponent, {
      data: {
        rateCompanyId: rateCompany.id,
        rateId: this.rate.id        
      },
      header: "Asginar compañía aseguradora",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadCompanies();
    });
  }

  deleteCompany(rateCompany: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar la compañía aseguradora " + rateCompany.company?.name + "?",
      header: "Desasignar compañía aseguradora",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.companyRatesServices.delete(rateCompany.id).subscribe({
          next: (data: any) => {
            this.loadCompanies();
            this.messageService.add({ closable: false, severity: 'success', detail: "Compañía aseguradora desasignada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  addOperator(operatorRate: any){
    const ref = this.dialogService.open(RateOperatorComponent, {
      data: {
        operatorRateId: operatorRate.id,
        rateId: this.rate.id        
      },
      header: "Asginar operario",
      width: '40%'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadOperators();
    });
  }

  deleteOperator(operatorRate: any) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea desasignar el operario " + operatorRate.operator?.name + "?",
      header: "Desasignar operario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.operatorRatesServices.delete(operatorRate.id).subscribe({
          next: (data: any) => {
            this.loadOperators();
            this.messageService.add({ closable: false, severity: 'success', detail: "Operario desasignado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  loadExcel(){
    const ref = this.dialogService.open(RateUploadExcelComponent, {
      data: {        
        rateId: this.rate.id        
      },
      header: "Importar Excel",
      width: '600px'
    });
    ref.onClose.subscribe((data: any) => {
      this.loadRateLines();
    });

  }
  
  downloadExcel(){
    
    // Hacer la petición al backend para obtener el archivo Excel
    let filename = this.rate.name;
    this.ratesService.downloadExcel(this.rate.id!, filename);
  }

}
