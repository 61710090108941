<div class="login-page flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="login-form">
        <p-card styleClass="no-bg no-border bg-white-transparent">
            <div class="flex flex-column align-items-center">
                <img src="/assets/images/logo-01.png" width="70" class="mb-2">
                <b class="text-white">CUIDACASA</b>
                <p class="text-white">Gestor de operaciones</p>

                <form class="mt-4 w-full flex flex-column gap-4">
                    <input placeholder="Correo electrónico" type="text" pInputText [(ngModel)]="formData.username" [ngModelOptions]="{standalone: true}"/>
                    <p-password placeholder="Contraseña" [feedback]="false" [toggleMask]="true" [(ngModel)]="formData.password" [ngModelOptions]="{standalone: true}" (keyup.enter)="login()"/>
                </form>

                <div class="mt-4 w-full">
                    <p-button label="Acceder" (click)="login()" styleClass="w-full bg-white text-primary"></p-button>
                </div>

                <div class="mt-4 w-full flex justify-content-center">
                    <!--<p-checkbox labelStyleClass="text-white" [binary]="true" label="Recordar datos" [(ngModel)]="formData.remember"/>-->
                    <a class="text-white p-2 pr-0" (click)="recover()">Recuperar contraseña</a>
                </div>
            </div>
        </p-card>
    </div>
</div>