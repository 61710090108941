import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { AuthenticationService } from "../_types/authentication-service";

@Injectable({
    providedIn: 'root',
})

export class AuthenticationsServicesService extends Service<AuthenticationService> {
  public override type = 'authenticationsServices';
  public override url = 'auth/authenticationServices';   
  
}
