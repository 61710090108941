import { Component, ViewEncapsulation } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { Transaction } from '../_types/transaction';
import { TransactionsService } from '../_services/transactions.service';
import { TransactionState } from '../_types/transaction-state';
import { TransactionsStatesService } from '../_services/transactions-states.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { TransactionType } from '../_types/transaction-type';
import { TransactionsTypesService } from '../_services/transactions-types.service';
import { TransactionLine } from '../_types/transaction-line';
import { DialogService } from 'primeng/dynamicdialog';
import { TransactionLinesComponent } from './transaction-line/transaction-line.component';
import { Rate } from '../../rates/_types/rate';
import { TransactionsLinesService } from '../_services/transactions-lines.service';
import { Case } from '../../cases/_types/case';
import { CasesService } from '../../cases/_services/cases.service';
import { ThirdParty } from '../_types/third-party';
import { ThirdPartiesService } from '../_services/third-parties.service';
import { TransactionLinesJobsComponent } from './transaction-lines-jobs/transaction-lines-jobs.component';

@Component({
  selector: 'app-transactions-detail',
  templateUrl: './transactions-detail.component.html',
  styleUrls: ['./transactions-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsDetailComponent {
  public transactionFormGroup!: RxFormGroup;

  public transaction: Transaction = new Transaction();
  public transactionLines: TransactionLine[] = [];
  public totalTransactionLines!: number;

  public transactionStates: TransactionState[] = [];
  public thirdParties: ThirdParty[] = [];
  public transactionType: TransactionType = new TransactionType();  
  public cases: any;

  public type!: string;
  public typeSelected: any = {};

  public loadingTransactionLines: boolean = false;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;  

  constructor(
    private transactionsService: TransactionsService,
    private transactionsStatesService: TransactionsStatesService,
    private transactionsTypesService: TransactionsTypesService,
    private transactionsLinesService: TransactionsLinesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private topbarService: TopbarService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private dialogService: DialogService,
    private casesService: CasesService,
    private thirdPartiesService: ThirdPartiesService
  ) {    

    this.transactionFormGroup = this.formBuilder.formGroup<Transaction>(this.transaction) as RxFormGroup;
  }

  async ngOnInit() {
    let id = this.route.snapshot.params['id'];
    this.type = this.route.snapshot.params['type'];
    console.log(this.type)
    switch(this.type) { 
      case "outgoing_budget":{ 
        this.typeSelected.title = "Presupuestos a clientes";
        this.typeSelected.edit = "Presupuesto a cliente";
        break; 
      } 
      case "outgoing_invoice":{ 
        this.typeSelected.title = "Facturas a clientes"
        break; 
      } 
      case "outgoing_delivery_note":{ 
        this.typeSelected.title = "Albaranes a clientes"
        break; 
      } 
      case "outgoing_order":{ 
        this.typeSelected.title = "Pedidos a proveedores"
        this.typeSelected.edit = "Pedido a proveedor";
        break; 
      } 
      case "company_outgoing_budget": {
        this.typeSelected.title = "Presupuestos a compañías";
        this.typeSelected.edit = "Presupuesto a compañía";
        break;
      }
      case "incoming_invoice":{ 
        this.typeSelected.title = "Facturas de operarios"
        break; 
      } 
   }

    this.topbarService.setTitle("Detalles de "+this.typeSelected.edit);
    this.topbarService.addBreadcrumb(this.typeSelected.title, "/transactions/"+this.type);   

    await this.loadTransactionType();
    await this.loadTransactionStates();        
    await this.loadCases();    
    if (id != null && id != '0') {
      await this.loadTransaction(id);
      await this.loadTransactionLines();
    }else{
      this.transaction.transactionTypeId = this.transactionType.id!;
      this.transaction.thirdPartyType = this.transactionType.thirdPartyType;
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadTransaction(id: string) {
    this.transaction = await firstValueFrom(this.transactionsService.get(id));    
    this.transactionFormGroup.patchValue(this.transaction);
  }

  async loadTransactionType(){
    
    let params: IParamsCollection = {            
    }        
    //creamos filtros
    params.filter = FilterBuilder.fromObject({code: new Filter(FilterOperation.equals, 'code', '', this.type)}).build();

    let response = await firstValueFrom(this.transactionsTypesService.all(params));    
    this.transactionType = response.data[0]; 

    console.log(this.transactionType);
    
    await this.loadThirdParties();
  }
  

  async loadTransactionStates() {

    let params: IParamsCollection = {            
    }    

    //creamos filtros
    params.filter = FilterBuilder.fromObject({transactionTypeId: new Filter(FilterOperation.equals, 'transactionTypeId', '', this.transactionType.id)}).build();        
    let response = await firstValueFrom(this.transactionsStatesService.all(params));
    this.transactionStates = response.data;
  }

  async loadThirdParties() {
    let params: IParamsCollection = {            
    }    

    //creamos filtros
    params.filter = FilterBuilder.fromObject({thirdPartyType: new Filter(FilterOperation.equals, 'thirdPartyType', '', this.transactionType.thirdPartyType)}).build();        
    let response = await firstValueFrom(this.thirdPartiesService.all(params));    
    this.thirdParties = response.data;        
  }

  async loadCases() {

    let params: IParamsCollection = {            
    }    
        
    let response = await firstValueFrom(this.casesService.all(params));
    this.cases = response.data;
    
    this.cases.forEach((option:any) => {
      option.customName = (option.reference??"")+" - "+option.description;
    });
  }

  async loadTransactionLines() {
    this.loadingTransactionLines = true;
    let params: IParamsCollection = {
        sort: ["lineNumber"],
        include: ["rateLine"]
    }
    let filters: any = {
      transactionId: new Filter(FilterOperation.equals, "transactionId"),
    };

    filters.transactionId.value = this.transaction.id;
    params.filter = FilterBuilder.fromObject(filters).build();   
    
    let response = await firstValueFrom(this.transactionsLinesService.all(params));
    this.transactionLines = response.data;
    console.log(this.transactionLines);
    this.totalTransactionLines = response.meta['total'];
    this.loadingTransactionLines = false;
  }

  async save() {        
    let valid = this.formValidationService.validateForm(this.transactionFormGroup);
    if (valid) {
      this.transaction = Object.assign(this.transaction, this.transactionFormGroup.value);
      
      let transaction = await firstValueFrom(this.transactionsService.save(this.transaction));

      this.messageService.add({ closable: false, severity: 'success', summary: "Transacción guardada correctamente." });

      
      this.router.navigate(['transactions/'+this.type]);
    

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la transacción "+this.transaction.code+"?",
      header: "Eliminar transacción",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.transactionsService.delete(this.transaction.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Transacción eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(['transactions/'+this.type]);
  }

  // Método para guardar el nuevo orden de columnas  


  async onRowReorder(event:any){
    await this.updateOrderLines();    
  }  


  async updateOrderLines() {
    try {
      const promesas = this.transactionLines.map((line, index) => {
        line.lineNumber = index + 1; // Asigna el nuevo orden basado en el índice
        return firstValueFrom(this.transactionsLinesService.save(line)); // Retorna la promesa
      });
  
      // Espera que todas las promesas se resuelvan en paralelo
      await Promise.all(promesas);
      this.loadTransactionLines();
      console.log('Todas las líneas se guardaron correctamente');
    } catch (error) {
      console.error(`Error al guardar alguna de las líneas: ${error}`);
    }
  }

  async editTransactionLine(isGroup: boolean, isRate: boolean, transactionLine: TransactionLine | null) {    
    let ref = this.dialogService.open(TransactionLinesComponent, {
      header: 'Ficha de línea',
      width: '700px',
      data: {
        isGroup: isGroup,
        isRate: isRate,
        transactionId: this.transaction.id,
        transactionLineId: transactionLine ? transactionLine.id : null,
        lineNumber: transactionLine ? transactionLine.lineNumber : this.totalTransactionLines+1
      }
    });

    ref.onClose.subscribe(async (data: any) => {
      await this.loadTransaction(this.transaction.id!);
      await this.loadTransactionLines();
            
    });
  }

  async deleteLine(transactionLine: TransactionLine) {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la línea "+transactionLine.description+"?",
      header: "Eliminar línea",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.transactionsLinesService.delete(transactionLine.id).subscribe({
          next: (data: any) => {
            this.loadTransaction(this.transaction.id!);
            this.loadTransactionLines();
            this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }   

  totalTax(): { [key: string]: number } {
    const taxSummary: { [key: string]: number } = {};
    this.transaction.taxAmount = 0;
    this.transactionLines.filter(m => !m.isGroup && m.taxPercent != null).forEach(line => {
      const taxRateKey = line.taxPercent?.toString();
      const taxAmount = line.taxAmount;

      this.transaction.taxAmount += taxAmount;      
      if (!taxSummary[taxRateKey]) {
        taxSummary[taxRateKey] = 0;
      }
      taxSummary[taxRateKey] += taxAmount;
    });    
    return taxSummary;
  }
    

  async importJobs(){    
    console.log(this.transaction);
    let ref = this.dialogService.open(TransactionLinesJobsComponent, {
      header: 'Importar trabajos',
      width: '50%',
      data: {                
        caseId: this.transactionFormGroup.get('caseId')?.value,
        transactionId: this.transaction.id,
        lineNumber: this.totalTransactionLines+1              
      }
    });

    ref.onClose.subscribe((data: any) => {
      console.log(data);
      this.loadTransactionLines();
    });
      
  }
}
