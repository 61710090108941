<div class="flex gap-2 cola-4">
    <span class="w-full input-field p-input-icon-right">
        <input pInputText placeholder="Nombre" [(ngModel)]="filters.name.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.name.value" class="pi pi-times cursor-pointer"
            (click)="filters.name.value = null; search()"></i>
    </span>
</div>

<p-table styleClass="mt-4" #grid [value]="vehicles" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>            
            <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>            
            <th pSortableColumn="name">Matrícula <p-sortIcon field="plate"></p-sortIcon></th>            
            <th pSortableColumn="name">Tipo <p-sortIcon field="vehicleType.name"></p-sortIcon></th>                        
            <th pSortableColumn="name">Propietario</th>                                    
            <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>                        
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-vehicle let-columns="columns">
        <tr (dblclick)="edit(vehicle)">            
            <td>{{vehicle.name}}</td>            
            <td>{{vehicle.plate}}</td>            
            <td>{{vehicle.vehicleType.name}}</td> 
            <td>{{vehicle.ownerType == 'WORKER'?vehicle.worker?.name:vehicle.company?.name}}</td>                                                
            <td>
                <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': vehicle.active, 'text-red-500 pi-times-circle': !vehicle.active }"></i>
              </td>             
              <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowDelete" (click)="delete(vehicle)" [rounded]="true" [text]="false"
                        severity="danger"><i class="pi pi-trash"></i></p-button>
                    <p-button *ngIf="allowWrite" (click)="edit(vehicle)" [rounded]="true" [text]="false"><i
                            class="pi pi-pencil"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="5">        
             No se han encontrado tipos de vehículo
          </td>
        </tr>
      </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>