import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  private title:String = "";
  private breadcrumb:any[] = [];
  private actions:any[] = [];

  constructor(
    private router: Router
  ) {
    router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationStart){
        this.breadcrumb = [];
        this.actions = [];
        this.title = "";
      }
  });

  }

  setTitle(title: string) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  addBreadcrumb(label: string, url: string) {
    this.breadcrumb.push({label: label, url: url});
  }

  getBreadcrumb() {
    let items = [ ...this.breadcrumb ];
    items.push({label: this.title, url: location.pathname});
    return items;
  }

  addAction(label: string, icon: string, show_text:boolean = false, action: Function) {
    this.actions.push({label: label, icon: icon, show_text: show_text, action: action});
  }

  getActions() {
    return this.actions;
  }

  doAction(action:any){
    action.action();
  }

}
