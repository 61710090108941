import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { DDI } from '../_types/ddi';

@Injectable({
  providedIn: 'root'
})
export class DDIsService extends Service<DDI> {
  public override type = 'ddIs';
  public override url = 'communications/ddIs';
}
