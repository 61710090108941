<form *ngIf="clientPolicyFormGroup" [formGroup]="clientPolicyFormGroup">
    <div class="grid">
        <div class="col-6 input-field">
            <label for="policyNumber">Número de póliza *</label>
            <input pInputText formControlName="policyNumber" d-form-field-error />
        </div>
        <div class="col-6 input-field">
            <label for="companyId">Compañía</label>
            <p-dropdown formControlName="companyId" [options]="companies" optionLabel="name" optionValue="id"
                [filter]="true" filterBy="name" placeholder="Selecciona una compañía" d-form-field-error></p-dropdown>
        </div>
        <div class="col-12 input-field">
            <label for="description">Descripción *</label>
            <textarea pInputTextarea formControlName="description" style="field-sizing: content;"
                d-form-field-error></textarea>
        </div>
        <div class="col-12">
            <div class="flex justify-content-end mb-2">
                <p-button *ngIf="allowWrite" label="Editar coberturas" icon="pi pi-plus" (click)="editGuarantees()" />
            </div>
            <p-table [value]="clientPolicyGuarantees">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nombre</th>
                    </tr>
                </ng-template>
                <!-- Añadir mensaje cuando esté vacío -->
                <ng-template pTemplate="body" let-clientPolicyGuarantee>
                    <tr>
                        <td>{{ clientPolicyGuarantee.guarantee.name }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td>
                            No se han encontrado coberturas
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete && clientPolicy.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
</form>
<p-confirmDialog></p-confirmDialog>