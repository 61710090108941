import { ProviderBankAccount } from "./provider-bank-account.model";
import { Resource } from "src/app/core/jsonapi/resource";
import { Relationship } from "src/app/core/jsonapi/relationship";
import { ProviderAddress } from "./provider-address";
import { prop, propObject, required } from "@rxweb/reactive-form-validators";

export class Provider extends Resource {
    public override type = 'providers';

    @prop()
    @required()
    public name!: string;   

    @prop()    
    @required()
    public legalName!: string;   

    @prop()    
    public typeOfBusiness: string = "Company";  

    @prop()    
    public notes!: string;   

    @prop()    
    public email!: string;   

    @prop()    
    public phone1!: string;   

    @prop()    
    public active: boolean = false;   

    @prop()    
    @required()
    public vatNumber!: string;   
    
    
    @prop()
    public bankAccountId!: string;

    @propObject(ProviderBankAccount)
    public bankAccount: ProviderBankAccount = new ProviderBankAccount();

    @prop()
    public addressId!: string;

    @propObject(ProviderAddress)
    public address: ProviderAddress = new ProviderAddress();
    
}