import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { WorkerFile } from '../_types/worker-file';

@Injectable({
  providedIn: 'root'
})
export class WorkersFilesService  extends Service<WorkerFile> {
  public override type = 'workerFiles';
  public override url = 'providers/workerFiles';
  
  public download(id:any){
    let url = this.getAPIUrl() + 'common/files/'+id+"/download";
    window.open(url);
  }
}