import { Component } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { TransactionsService } from '../_services/transactions.service';
import { TransactionsTypesService } from '../_services/transactions-types.service';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent {
  public transactions: any = [];
  public transactionType: any = {};
  public totalRecords: number = 0;
  public loading: boolean = false;
  public type!: string;
  public typeSelected: any = {};
  private lastGridEvent: any;
  public filters: any = {
    code: new Filter(FilterOperation.contains, 'code'),
  };
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private transactionsService: TransactionsService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public topbarService: TopbarService,
    private transactionsTypesService: TransactionsTypesService
  ) {


    this.allowWrite = true;
    this.allowDelete = true;
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.type = params.get('type') ?? '';

      switch (this.type) {
        case "outgoing_budget": {
          this.typeSelected.title = "Presupuestos a clientes"
          break;
        }
        case "outgoing_invoice": {
          this.typeSelected.title = "Facturas a clientes"
          break;
        }
        case "outgoing_delivery_note": {
          this.typeSelected.title = "Albaranes a clientes"
          break;
        }
        case "outgoing_order": {
          this.typeSelected.title = "Pedidos a proveedores"
          break;
        }
        case "company_outgoing_budget": {
          this.typeSelected.title = "Presupuestos a compañías"
          break;
        }
        case "incoming_invoice": {
          this.typeSelected.title = "Facturas de operarios"
          break;
        }
      }    

      this.topbarService.setTitle(this.typeSelected.title);
      this.topbarService.addAction("Generar autofacturas", "",true, () => {
        this.transactionsOperator();
      });
      this.topbarService.addAction("Nuevo", "pi pi-plus", false, () => {
        this.edit({});
      });      
      this.loadData(this.lastGridEvent);
    });

  }

  async loadData($event: any) {

    await this.loadTransactionType();

    this.lastGridEvent = $event;

    let params: IParamsCollection = {      
      include: ['transactionState', 'thirdParty']
    }

    if(typeof $event == "undefined"){
      params.page = { number: 1, size: 25 };      
    }else{
      params.page = { number: $event.first + 1, size: $event.rows };
      if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];    
    }    

    
    

    //creamos filtros
    params.filter = FilterBuilder.fromObject({ transactionTypeId: new Filter(FilterOperation.equals, 'transactionTypeId', '', this.transactionType.id) }).build();

    let response = await firstValueFrom(this.transactionsService.all(params));
    this.transactions = response.data;
    console.log(this.transactions);
    this.totalRecords = response.meta['total'];
  }

  async loadTransactionType() {

    let params: IParamsCollection = {
    }
    //creamos filtros
    params.filter = FilterBuilder.fromObject({ code: new Filter(FilterOperation.equals, 'code', '', this.type) }).build();

    let response = await firstValueFrom(this.transactionsTypesService.all(params));
    this.transactionType = response.data[0];
    console.log(this.transactionType);
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  edit(transaction: any) {
    console.log("asd");
    this.router.navigate(['transactions/' + this.type + '/' + (transaction && Object.keys(transaction).length > 0 ? transaction.id : 0)]);
  }

  transactionsOperator(){    
    this.router.navigate(['transactions/' + this.type + '/transactions-operator/incoming_invoice']);
  }

  delete(transaction: any) {
    if (this.allowDelete) {      
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar el transacción " + transaction.code + "?",
        header: "Eliminar transacción",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.transactionsService.delete(transaction.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Transacción eliminada correctamente" });
              this.loadData(this.lastGridEvent);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
}
