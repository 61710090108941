import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Survey } from '../_types/survey';
import { SurveysService } from '../_services/surveys.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';
import { SurveyQuestion } from '../_types/survey-question';
import { SurveysQuestionsService } from '../_services/surveys-questions.service';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { DialogService } from 'primeng/dynamicdialog';
import { SurveyDetailQuestionComponent } from './question/question.component';
import { TopbarService } from 'src/app/core/topbar/topbar.service';
import { UserType } from '../../users/_types/user-type';
import { UserTypesService } from '../../users/_services/users-types.service';

@Component({
  selector: 'app-surveys-detail',
  templateUrl: './surveys-detail.component.html',
  styleUrls: ['./surveys-detail.component.scss']
})
export class SurveysDetailComponent {
  public surveyFormGroup!: RxFormGroup;

  public survey: Survey = new Survey();
  public surveyQuestions: SurveyQuestion[] = [];
  public targetTypes: any[] = [];
  public userTypes: UserType[] = [];

  public loading: boolean = false;
  public totalRecords: number = 0;

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private surveysService: SurveysService,
    private userTypesService: UserTypesService,
    private topbarService: TopbarService,
    public surveysQuestionsService: SurveysQuestionsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.topbarService.setTitle("Detalles de encuesta");
    this.topbarService.addBreadcrumb("Encuestas", "/surveys");
    this.surveyFormGroup = this.formBuilder.formGroup<Survey>(this.survey) as RxFormGroup;

    this.targetTypes = this.surveysService.targetTypes();
  }

  async ngOnInit() {
    this.loadUserTypes();

    let id = this.route.snapshot.params['guid'];
    if (id != null && id != '0') {
      await this.loadSurvey(id);
    }

    this.allowWrite = true;//this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true;//this.loginService.hasPermission("PROVIDERS_DELETE");


  }

  async loadSurvey(id: string) {
    this.survey = await firstValueFrom(this.surveysService.get(id));
    this.surveyFormGroup.patchValue(this.survey);

    this.loadSurveyQuestions();
  }

  async loadSurveyQuestions() {
    this.loading = true;

    let params: IParamsCollection = {}

    let filters: any = {
      surveyId: new Filter(FilterOperation.equals, "surveyId"),
    };

    filters.surveyId.value = this.survey.id;
    params.filter = FilterBuilder.fromObject(filters).build();


    let response = await firstValueFrom(this.surveysQuestionsService.all(params));
    this.surveyQuestions = response.data;
    this.totalRecords = response.meta['total'];

    this.loading = false;
  }

  async loadUserTypes() {
    let response = await firstValueFrom(this.userTypesService.all());
    this.userTypes = response.data;
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.surveyFormGroup);
    if (valid) {
      this.survey = Object.assign(this.survey, this.surveyFormGroup.value);

      let surveyItem = await firstValueFrom(this.surveysService.save(this.survey));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Encuesta guardada correctamente." });
      this.router.navigate(['surveys']);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la encuesta?",
      header: "Eliminar encuesta",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.surveysService.delete(this.survey.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Encuesta eliminada correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['surveys']);
  }

  editSurveyQuestion(surveyQuestion: SurveyQuestion | null) {
    let ref = this.dialogService.open(SurveyDetailQuestionComponent, {
      header: 'Ficha de pregunta',
      width: '800px',
      data: {
        surveyId: this.survey.id,
        surveyQuestionId: surveyQuestion ? surveyQuestion.id : null
      }
    });

    ref.onClose.subscribe((data: any) => {
      this.loadSurveyQuestions();
    });
  }

  deleteSurveyQuestion(surveyQuestion: SurveyQuestion) {
    if (this.allowDelete) {
      this.confirmationService.confirm({
        message: "¿Está seguro que desea eliminar la pregunta?",
        header: "Eliminar pregunta",
        acceptLabel: "Aceptar",
        rejectLabel: "Cancelar",
        acceptButtonStyleClass: "p-button-danger",
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.surveysQuestionsService.delete(surveyQuestion.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: "Pregunta eliminada correctamente." });
              this.loadSurveyQuestions();
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        }
      });
    }
  }
}
