import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationsServicesComponent } from './list/authentications-services-list.component';
import { AuthenticationsServicesDetailComponent } from './detail/authentications-services-detail.component';

const routes: Routes = [
  {
    path: 'authentications-services',
    children: [
      { path: '', component: AuthenticationsServicesComponent }
    ]
  },
  {
    path: 'authentications-services/:id',
    children: [
      { path: '', component: AuthenticationsServicesDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationsServiceRoutingModule { }
