<form *ngIf="logFormGroup" [formGroup]="logFormGroup">
  <div class="grid">
      
      <div class="col-12 input-field" >
          <label for="caseLogType">Tipo {{logFormGroup.get('caseLogType')?.value}}</label>
          <p-dropdown [options]="logTypes" formControlName="caseLogType" appendTo="body" (onChange)="changeLogType($event)"></p-dropdown>
      </div>

      <div *ngIf="logFormGroup.get('caseLogType')?.value!='Mail'" class="col-12 input-field">
        <label for="message">Notas</label>
        <textarea pInputTextarea formControlName="message"  d-form-field-error></textarea>
      </div>
      <div *ngIf="logFormGroup.get('caseLogType')?.value=='Mail'" class="col-12 input-field" >
        <label for="caseLogType">Plantilla</label>
        <p-dropdown [options]="templates" formControlName="template_id" optionLabel="name" optionValue="id" appendTo="body" (onChange)="changeTemplate($event)"></p-dropdown>
    </div>

      <div *ngIf="logFormGroup.get('caseLogType')?.value=='Mail'" class="col-12 input-field" >
          <label for="subject">Asunto</label>
          <input pInputText formControlName="subject" d-form-field-error />
      </div>

     <div *ngIf="logFormGroup.get('caseLogType')?.value=='Mail'" class="col-12 input-field">
      <label for="message">Email</label>
      <editor [init]="init" formControlName="message" d-form-field-error />
    </div>
  </div>
  <div class="mt-4 flex justify-content-between">
      <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"
          *ngIf="log.id && allowDelete"></p-button>
      <div class="flex gap-2">
          <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
          <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
              (click)="save()"></p-button>
      </div>
  </div>
</form>