import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CasesListComponent } from './cases-list/cases-list.component';
import { CasesDetailComponent } from './cases-detail/cases-detail.component';

const routes: Routes = [
    {
        path: 'cases',
        children: [
            { path: '', component: CasesListComponent }
        ]
    },
    {
        path: 'cases/:id',
        children: [
            { path: '', component: CasesDetailComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CasesRoutingModule { }
