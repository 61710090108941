import { Component } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { ContractMailAccount } from '../../_types/contract-mail-account';
import { ContractMailAccountsService } from '../../_services/contracts-mails-accounts.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-mails-detail',
  templateUrl: './mails-detail.component.html',
  styleUrls: ['./mails-detail.component.scss']
})
export class ContractMailAccountsDetailComponent {
  public contractMailAccountFormGroup!: RxFormGroup;

  public contractMailAccount: ContractMailAccount = new ContractMailAccount();

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;

  constructor(
    private contractMailAccountsService: ContractMailAccountsService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService
  ) {
    this.contractMailAccountFormGroup = this.formBuilder.formGroup<ContractMailAccount>(this.contractMailAccount) as RxFormGroup;
  }

  async ngOnInit() {
    let contractMailAccountId = this.config.data.contractMailAccountId;
    if (contractMailAccountId) {
      await this.loadContractMailAccount(contractMailAccountId);
    } else {
      this.contractMailAccount.contractId = this.config.data.contractId;
      this.contractMailAccountFormGroup.patchValue(this.contractMailAccount);
    }
    this.allowWrite = true; //this.loginService.hasPermission("PROVIDERS_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("PROVIDERS_DELETE");
  }

  async loadContractMailAccount(contractMailAccount: string) {
    this.contractMailAccount = await firstValueFrom(this.contractMailAccountsService.get(contractMailAccount));
    this.contractMailAccountFormGroup.patchValue(this.contractMailAccount);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let valid = this.formValidationService.validateForm(this.contractMailAccountFormGroup);
    if (valid) {
      this.contractMailAccount = Object.assign(this.contractMailAccount, this.contractMailAccountFormGroup.value);
      console.log(this.contractMailAccount);

      let contractMailAccount = await firstValueFrom(this.contractMailAccountsService.save(this.contractMailAccount));

      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Cuenta de correo guardada correctamente." });
      this.dynamicDialogRef.close();

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea la cuenta de correo?",
      header: "Eliminar cuenta de correo",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.contractMailAccountsService.delete(this.contractMailAccount.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Cuenta de correo eliminada correctamente." });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
}
