import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Service } from "src/app/core/jsonapi/service";
import { RateLine } from "../_types/rate-line";

@Injectable({
    providedIn: 'root',
})
export class RateLinesService extends Service<RateLine> {
  public override type = 'rateLines';
  public override url = 'attendance/rateLines'; 
  
}