import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { SurveyQuestion } from "../_types/survey-question";

@Injectable({
    providedIn: 'root',
})
export class SurveysQuestionsService extends Service<SurveyQuestion> {
    public override type = 'surveyQuestions';
    public override url = 'attendance/surveyQuestions';

    questionTypes() {
        let questionTypes = [
            { label: 'Texto', value: 'Text' },
            { label: 'Escala de 5', value: 'Scale5' },
            { label: 'Lista de opciones', value: 'Options' }
        ];
        return questionTypes;
    }

    getQuestionTypeLabel(value: string) {
        let questionTypes = this.questionTypes();
        let questionType = questionTypes.find(x => x.value == value);
        return questionType?.label;
    }
}
