<form *ngIf="calendarEventFormGroup" [formGroup]="calendarEventFormGroup">
    <div class="grid"> 
        <div class="col-12 input-field">
            <label for="description">Descripción</label>
            <input pInputText name="description" id="description" formControlName="description" />
        </div>        
    </div>          
    <div class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()" *ngIf="selectedEvent.id"></p-button>
    <div class="flex-grow-1"></div>      
    <p-button label="Guardar" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
    </div>
</form>