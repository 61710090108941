import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class Role extends Resource {
    public override type = 'roles';

    @prop()
    @required()
    public title!: string;

    @prop()
    public description!: string;

    @prop()
    public workerAllowed: boolean = false;

}