import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";
import { Operator } from "../../companies/_types/operator";
import { Client } from "../../clients/_types/client";
import { Processor } from "./processor";
import { File } from "../../common/_types/file";
import { Case } from "./case";
import { CaseFileCategory } from "./case-file-category";
import { CaseService } from "./case-service";

export class CaseFile extends Resource {
    public override type = 'caseFiles';

    @prop()
    @required()
    public source: string = "User";

    @prop()
    @required()
    public caseId!: string;

    public case!: Case;

    @prop()
    public fileId!: string;

    public file!: File;

    @prop()
    public caseServiceId!: string;

    public caseService!: CaseService;

    @prop()
    public operatorId!: string;

    public operator!: Operator;

    @prop()
    public clientId!: string;

    public client!: Client;

    @prop()
    public userId!: string;

    public user!: Processor;

    @prop()
    public notes!: string;

    @prop()
    public caseFileCategoryId!: string;

    public caseFileCategory!: CaseFileCategory;

}