import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarsComponent } from './list/calendars-list.component';
import { CalendarsDetailComponent } from './detail/calendars-detail.component';

const routes: Routes = [
  {
    path: 'calendars',
    children: [
      { path: '', component: CalendarsComponent }
    ]
  },
  {
    path: 'calendars/:id',
    children: [
      { path: '', component: CalendarsDetailComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarsRoutingModule { }
