import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ChatRoom } from "../_types/chat-room";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ChatRoomsService extends Service<ChatRoom> {
    public override type = 'chatRooms';
    public override url = 'communications/chatrooms';

    public Keepalive(chatroom:ChatRoom):Observable<any>{
        let url = this.getAPIUrl();
        let headers = {
            'Content-Type': 'application/vnd.api+json'
        };
        url = url + '/' + chatroom.id + "/keepalive"
        this.loaderService.skipRequest(url);
        return this.http.post(url , null, { headers: headers });
    }

    public Disconnect(chatroom:ChatRoom):Observable<any>{
        let url = this.getAPIUrl();
        let headers = {
            'Content-Type': 'application/vnd.api+json'
        };
        return this.http.post(url + '/' + chatroom.id + "/me/disconnect", null, { headers: headers });
    }

    public RemoveMe(chatroom:ChatRoom):Observable<any>{
        let url = this.getAPIUrl();
        let headers = {
            'Content-Type': 'application/vnd.api+json'
        };
        return this.http.post(url + '/' + chatroom.id + "/me/remove", null, { headers: headers });
    }
}
