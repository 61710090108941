import { Component } from '@angular/core';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { Capability } from 'src/app/modules/common/_types/capability';
import { ContractSlaCapabilitiesService } from '../../../_services/contracts-slas-capabilities.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { firstValueFrom } from 'rxjs';
import { ContractSlaCapability } from '../../../_types/contract-sla-capability';

@Component({
  selector: 'app-sla-capabilities',
  templateUrl: './sla-capabilities.component.html',
  styleUrls: ['./sla-capabilities.component.scss']
})
export class ContractSlaCapabilitiesComponent {
  public capabilities: Capability[] = [];
  public selectedCapabilities: any[] = [];
  public oldSelectedCapabilities: any[] = [];
  public contractSlaCapabilities: any[] = [];
  public totalRecords: number = 0;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {
    name: new Filter(FilterOperation.contains, 'name'),
  };

  constructor(
    private capabilitiesService: CapabilitiesService,
    private contractSlaCapabilitiesService: ContractSlaCapabilitiesService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef
  ) { }

  async ngOnInit() {
    let contractSlaId = this.config.data.contractSlaId;
    this.loadSelectedCapabilities(contractSlaId);
  }

  public async loadData($event: any) {

    this.lastGridEvent = $event;

    let params: IParamsCollection = {
      page: { number: $event.first + 1, size: $event.rows }
    }

    if (typeof $event.sortField != "undefined") params.sort = [($event.sortOrder > 0 ? "-" : "") + $event.sortField];

    //creamos filtros
    params.filter = FilterBuilder.fromObject(this.filters).build();

    let response = await firstValueFrom(this.capabilitiesService.all(params));
    this.capabilities = response.data;
    this.totalRecords = response.meta['total'];
  }

  async loadSelectedCapabilities(contractSLAId: string) {
    let params: IParamsCollection = {
      include: ['capability', 'contractSLA'],
    }

    let filters: any = {
      contractSLAId: new Filter(FilterOperation.equals, "contractSLAId"),
    };

    filters.contractSLAId.value = contractSLAId;

    params.filter = FilterBuilder.fromObject(filters).build();
    let response = await firstValueFrom(this.contractSlaCapabilitiesService.all(params));
    this.selectedCapabilities = response.data.map(x => x.capability);
    this.oldSelectedCapabilities = response.data.map(x => x.capability);
    this.contractSlaCapabilities = response.data;
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  async save() {
    let capabilityIds = this.selectedCapabilities.map(x => x.id);
    let oldCapabilityIds = this.oldSelectedCapabilities.map(x => x.id);

    let toAdd = capabilityIds.filter(x => !oldCapabilityIds.includes(x));
    let toRemove = oldCapabilityIds.filter(x => !capabilityIds.includes(x));

    // Crear una lista de promesas para agregar utilizando map en lugar de forEach
    const addPromises = toAdd.map(capabilityId => {
      let contractSlaCapability = new ContractSlaCapability();
      contractSlaCapability.contractSLAId = this.config.data.contractSlaId;
      contractSlaCapability.capabilityId = capabilityId;
      return firstValueFrom(this.contractSlaCapabilitiesService.save(contractSlaCapability));
    });

    // Crear una lista de promesas para eliminar utilizando map en lugar de forEach
    const removePromises = toRemove.map(capabilityId => {
      let contractSlaCapability = this.contractSlaCapabilities.find(x => x.capabilityId == capabilityId);
      return firstValueFrom(this.contractSlaCapabilitiesService.delete(contractSlaCapability.id));
    });

    // Esperar a que todas las promesas se completen
    await Promise.all([...addPromises, ...removePromises]);

    // Una vez que todas las operaciones asíncronas hayan terminado, mostrar el mensaje y cerrar el diálogo
    this.messageService.add({ severity: 'success', summary: 'Guardado', detail: 'Cambios guardados correctamente' });
    this.dynamicDialogRef.close();

  }
}
