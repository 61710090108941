import { Component, ViewChild } from '@angular/core';
import { UserAuthenticationService } from '../_types/userAuthenticationService';
import { UserAuthenticationServicesService } from '../_services/userAuthenticationService.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthenticationsServicesService } from '../../authentications-services/_services/authentications-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authentication-service',
  templateUrl: './authentication-service.component.html',
  styleUrls: ['./authentication-service.component.scss']
})
export class AuthenticationServiceComponent {
  @ViewChild("formDetails") public formDetails?: NgForm;

  public userAuthenticationService: UserAuthenticationService = new UserAuthenticationService();
  public authenticationServices: any[] = [];

  public allowWrite: boolean = false;
  public allowDelete: boolean = false;


  constructor(
    private userAuthenticationServicesService: UserAuthenticationServicesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private authenticationServicesService: AuthenticationsServicesService,
    private dynamicDialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    if (this.config.data.userAuthenticationServiceId) {
      this.loadUserAuthenticationService(this.config.data.userAuthenticationServiceId);
    } else {
      this.userAuthenticationService.userId = this.config.data.userId;
    }
    this.loadAuthenticationServices();

    this.allowWrite = true;
    this.allowDelete = true;
  }

  async loadUserAuthenticationService(id: string) {
    this.userAuthenticationService = await firstValueFrom(this.userAuthenticationServicesService.get(id));

  }

  async loadAuthenticationServices() {
    let response = await firstValueFrom(this.authenticationServicesService.all());
    this.authenticationServices = response.data;
    let uas = this.config.data.authenticationServicesExist;

    this.authenticationServices = this.authenticationServices.filter((x: any) => !uas.some((y: any) => y == x.id));
  }

  async save(form: NgForm) {
    if (form.valid) {
      await this.userAuthenticationServicesService.save(this.userAuthenticationService).subscribe({
        next: (data) => {
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Servicio de autenticación guardado correctamente' });
          this.dynamicDialogRef.close();
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar el servicio de autenticación' });
        }
      });
    } else {
      document.getElementById('form')?.classList.add('formInvalid');
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar el servicio de autenticación?",
      header: "Eliminar usuario",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.userAuthenticationServicesService.delete(this.userAuthenticationService.id).subscribe({
          next: (data: any) => {
            this.cancel();
            this.messageService.add({ closable: false, severity: 'success', detail: "Servicio de autenticación eliminado correctamente." });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    });
  }
}
