<form *ngIf="workerHolidayFormGroup" [formGroup]="workerHolidayFormGroup">
    <div class="grid"> 
        <div class="col-12 input-field">
        <label for="description">Notas</label>
        <input pInputText name="notes" id="notes" formControlName="notes" />
        </div>
    </div>          
    <div class="mt-4 flex gap-2 justify-content-end">
    <p-button label="Eliminar" icon="pi pi-trash" styleClass="p-button-danger"
        (click)="delete()" *ngIf="selectedHoliday.id"></p-button>
    <div class="flex-grow-1"></div>      
    <p-button label="Guardar" icon="pi pi-check" styleClass="p-ml-2"
        (click)="save()"></p-button>
    </div>
</form>