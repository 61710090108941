import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { ChatDetailComponent } from "./detail/chat-detail.component";
import { ButtonModule } from "primeng/button";
import { CommonModule, DatePipe } from "@angular/common";

@NgModule({
    declarations: [
        ChatDetailComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        DatePipe
    ],
    exports: [
        ChatDetailComponent
    ],
    providers: []
})
export class ChatModule { }
