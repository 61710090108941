<form #formDetails="ngForm" id="form">

    <div class="grid">
        <div class="col-12 input-field">
            <label for="authenticationServiceId">Servicio de autenticación</label>
            <p-dropdown [options]="authenticationServices"
                [(ngModel)]="userAuthenticationService.authenticationServiceId"
                name="authenticationServiceId" optionLabel="name" optionValue="id" [filter]="true"
                filterBy="name" [ngModelOptions]="{standalone: true}"
                placeholder="Seleccione servicio" appendTo="body"/>
        </div>
        <div class="col-12 input-field">
            <label for="userIdentifier">Identificador de usuario</label>
            <input pInputText name="userIdentifier" id="userIdentifier"
                [(ngModel)]="userAuthenticationService.userIdentifier" />
        </div>
        <div class="col-3 input-field">
            <label for="active">Activo</label>
            <p-inputSwitch id="active" name="active"
                [(ngModel)]="userAuthenticationService.active"></p-inputSwitch>
        </div>

    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete && userAuthenticationService.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
            (click)="save(formDetails)"></p-button>
    </div>

</form>