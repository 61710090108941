import { Component, ViewEncapsulation } from '@angular/core';
import { Capability } from 'src/app/modules/common/_types/capability';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { RateLine } from '../../_types/rate-line';
import { RateLinesService } from '../../_services/rates-lines.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';

@Component({
  selector: 'app-rate-line',
  templateUrl: './rate-line.component.html',
  styleUrls: ['./rate-line.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class RateLineComponent {
  public rateLineFormGroup!: RxFormGroup;
  public rateLine: RateLine = new RateLine();
  public otherRateLines: RateLine[] = [];
  public capabilities: Capability[] = [];  
  public selectedCapability: string = '';    
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;  
  public incompatibleCodes: any;

  constructor(
    private rateLinesService: RateLinesService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private capabilitiesService: CapabilitiesService,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { 

    this.rateLineFormGroup = this.formBuilder.formGroup<RateLine>(this.rateLine) as RxFormGroup;
  }

  async ngOnInit() {
    let rateLineId = this.config.data.rateLineId;
    console.log(rateLineId);
    if (rateLineId) {
      await this.loadRateLine(rateLineId);
    } else {
      this.rateLine.rateId = this.config.data.rateId;
    }   
     
    this.loadIncompatibleCodes(rateLineId);

    this.rateLineFormGroup.patchValue(this.rateLine);

    this.loadCapabilities();  
    

    this.allowWrite = true; //this.loginService.hasPermission("RATE_LINES_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("RATE_LINES_DELETE");
  }  

  async loadRateLine(rateLineId: string) {
    
    this.rateLine = await firstValueFrom(this.rateLinesService.get(rateLineId));
    console.log(this.rateLine);    
    console.log(this.incompatibleCodes);
  }

  async loadCapabilities() {
    let response = await firstValueFrom(this.capabilitiesService.all());
    this.capabilities = response.data;
  }    

  async loadIncompatibleCodes(rateLineId: string | null){

    let filters = {
      companyId: new Filter(FilterOperation.equals, 'rateId', "", this.config.data.rateId)
    };

    let params: IParamsCollection = {}
    //creamos filtros
    params.filter = FilterBuilder.fromObject(filters).build();

    let rateLines = await firstValueFrom(this.rateLinesService.all(params));    
    
    this.otherRateLines = rateLines.data.filter(m => m.id != rateLineId);

    this.otherRateLines.forEach((rateLine) => {
      rateLine.displayName = rateLine.code+(rateLine.description != null && rateLine.description != ""?" - "+ rateLine.description:"");
    });
    console.log(this.otherRateLines);
    if(rateLineId != null){
      this.incompatibleCodes = this.otherRateLines.filter((rateLine: RateLine) => this.rateLine.incompatibleCodes.includes(rateLine.id!));
    }
    

  }

  async save() {
    let valid = this.formValidationService.validateForm(this.rateLineFormGroup);
    if (valid) {
      this.rateLine = Object.assign(this.rateLine, this.rateLineFormGroup.value);
      if(typeof this.incompatibleCodes != "undefined" && this.incompatibleCodes.length > 0){
        this.rateLine.incompatibleCodes = this.incompatibleCodes.map((m:any) => m.id).join(',')
        console.log(this.rateLine);
      }else{
        this.rateLine.incompatibleCodes = "";
      }
      
      let rateLine = await firstValueFrom(this.rateLinesService.save(this.rateLine));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Línea guardada correctamente." });
      this.dynamicDialogRef.close(this.rateLine.id ? null : rateLine.id);

    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la línea de baremo?",
      header: "Eliminar línea",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.rateLinesService.delete(this.rateLine.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente." });
            this.cancel();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
  


}
