<form *ngIf="workerFormGroup" [formGroup]="workerFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-5 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" d-form-field-error />
            </div>
            <div class="col-2 input-field">
                <label for="vatNumber">CIF</label>
                <input pInputText formControlName="vatNumber" d-form-field-error />
            </div>
            <div class="col-1 input-field">
                <label for="active" class="mr-1">Activo</label>
                <div class="flex h-full">
                    <p-inputSwitch formControlName="active" d-form-field-error></p-inputSwitch>
                </div>
            </div>
            <div class="col-1 input-field">
                <label for="rating" class="mr-1">Valoración</label>
                <div class="flex h-full pt-2">
                    <p-rating formControlName="rating" [readonly]="true" [cancel]="false" />
                </div>
            </div>
        </div>
        <div class="grid pt-2">
            <div class="col input-field " *ngIf="!worker.parentWorkerId">
                <label for="legalName">Razón social</label>
                <input pInputText formControlName="legalName" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="phone1">Teléfono</label>
                <input pInputText formControlName="phone1" type="text" d-form-field-error />
            </div>
            <div class="col input-field ">
                <label for="email">Email</label>
                <input pInputText formControlName="email" type="text" d-form-field-error />
            </div>

        </div>
        <div class="grid pt-2" formGroupName="address">
            <div class="col input-field col-3">
                <label for="name">Dirección</label>
                <input pInputText formControlName="addressLine1" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Código postal</label>
                <input pInputText formControlName="zipCode" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">Ciudad</label>
                <input pInputText formControlName="city" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="name">País</label>
                <p-dropdown [options]="countries" formControlName="countryCode" optionValue="code" [filter]="true"
                    filterBy="name" optionLabel="name" placeholder="Seleccione país" d-form-field-error />
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>

    <p-tabView (onChange)="onTabChange($event)" [(activeIndex)]="activeIndex">
        <p-tabPanel header="Datos bancarios" [disabled]="worker.parentWorkerId != null">
            <div class="formgrid grid pt-2">
                <div class="col-2 input-field">
                    <label for="name">Activar</label>
                    <p-inputSwitch formControlName="hasBankAccount" (onChange)="onChangeHasBankAccount()"
                        d-form-field-error></p-inputSwitch>
                </div>
                <div class="col input-field"
                    *ngIf="workerFormGroup.value.hasBankAccount && workerFormGroup.value.bankAccount"
                    formGroupName="bankAccount">
                    <label for="name">Banco</label>
                    <p-dropdown formControlName="bankId" [options]="banks" optionLabel="name" optionValue="id"
                        [filter]="true" filterBy="name" placeholder="Selecciona un banco" class="ng-dirty ng-invalid"
                        d-form-field-error />
                </div>
                <div class="col input-field"
                    *ngIf="workerFormGroup.value.hasBankAccount && workerFormGroup.value.bankAccount"
                    formGroupName="bankAccount">
                    <label for="name">Nº Cuenta</label>
                    <input pInputText formControlName="accountNumber" type="text" d-form-field-error />
                </div>
                <div class="col input-field"
                    *ngIf="workerFormGroup.value.hasBankAccount && workerFormGroup.value.bankAccount"
                    formGroupName="bankAccount">
                    <label for="name">Swift</label>
                    <input pInputText formControlName="swift" type="text" d-form-field-error />
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Especialidades" [disabled]="!worker.id">
            <div class="flex justify-content-end">
                <p-button label="Añadir servicio" *ngIf="allowWrite" icon="pi pi-plus"
                    (click)="editWorkerCapability(null)"></p-button>
            </div>
            <p-table styleClass="mt-4" #grid [value]="workerCapabilities" [paginator]="true" [rows]="25"
                [totalRecords]="totalCapabilitiesRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="normalServiceAllowed">Horario normal <p-sortIcon
                                field="normalServiceAllowed"></p-sortIcon></th>
                        <th pSortableColumn="guardServiceAllowed">Horario de guardia <p-sortIcon
                                field="guardServiceAllowed"></p-sortIcon></th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-workerCapability let-columns="columns">
                    <tr (dblclick)="editWorkerCapability(workerCapability)">
                        <td>{{workerCapability.capability.name}}</td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': workerCapability.normalServiceAllowed, 'text-red-500 pi-times-circle': !workerCapability.normalServiceAllowed }"></i>
                        </td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': workerCapability.guardServiceAllowed, 'text-red-500 pi-times-circle': !workerCapability.guardServiceAllowed }"></i>
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteWorkerCapability(workerCapability)"
                                    [rounded]="true" [text]="false" severity="danger"><i
                                        class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editWorkerCapability(workerCapability)"
                                    [rounded]="true" [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Zonas" [disabled]="!worker.id">
            <div class="flex justify-content-end pb-1">
                <p-button label="Nueva zona" (click)="editZone({})" styleClass="p-button-sm"
                    *ngIf="allowWrite"></p-button>
            </div>
            <p-table #grid [value]="zones" [paginator]="false" [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="active">Activo <p-sortIcon field="active"></p-sortIcon></th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-zone let-columns="columns">
                    <tr (dblclick)="editZone(zone)">
                        <td>{{zone.name}}</td>
                        <td>
                            <i class="pi"
                                [ngClass]="{ 'text-green-500 pi-check-circle': zone.active, 'text-red-500 pi-times-circle': !zone.active }"></i>
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteZone(zone)"
                                    styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editZone(zone)" styleClass="p-button-xs"><i
                                        class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Disponibilidad" [disabled]="!worker.id">
            <p-card class="p-3" header="Horario normal">
                <div>
                    <div class="flex justify-content-end pb-1">
                        <p-button label="Nuevo" (click)="editTimeRange({})" styleClass="p-button-sm"
                            *ngIf="allowWrite"></p-button>
                    </div>
                    <p-table #grid [value]="workerTimeRanges" [paginator]="false" [scrollable]="true"
                        scrollHeight="flex">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pSortableColumn="monday">Desde <p-sortIcon field="monday"></p-sortIcon></th>
                                <th pSortableColumn="monday">Hasta <p-sortIcon field="monday"></p-sortIcon></th>
                                <th pSortableColumn="monday">Lunes <p-sortIcon field="monday"></p-sortIcon></th>
                                <th pSortableColumn="tuesday">Martes <p-sortIcon field="tuesday"></p-sortIcon></th>
                                <th pSortableColumn="wednesday">Miércoles <p-sortIcon field="wednesday"></p-sortIcon>
                                </th>
                                <th pSortableColumn="thursday">Jueves <p-sortIcon field="thursday"></p-sortIcon></th>
                                <th pSortableColumn="friday">Viernes <p-sortIcon field="friday"></p-sortIcon></th>
                                <th pSortableColumn="saturday">Sábado <p-sortIcon field="saturday"></p-sortIcon></th>
                                <th pSortableColumn="Sunday">Domingo <p-sortIcon field="sunday"></p-sortIcon></th>
                                <th width="100"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-timeRange let-columns="columns">
                            <tr (dblclick)="editTimeRange(timeRange)">
                                <td>{{timeRangeFormat(timeRange.startHour)}}</td>
                                <td>{{timeRangeFormat(timeRange.endHour)}}</td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.monday, 'text-red-500 pi-times-circle': !timeRange.monday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.tuesday, 'text-red-500 pi-times-circle': !timeRange.tuesday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.wednesday, 'text-red-500 pi-times-circle': !timeRange.wednesday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.thursday, 'text-red-500 pi-times-circle': !timeRange.thursday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.friday, 'text-red-500 pi-times-circle': !timeRange.friday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.saturday, 'text-red-500 pi-times-circle': !timeRange.saturday }"></i>
                                </td>
                                <td>
                                    <i class="pi"
                                        [ngClass]="{ 'text-green-500 pi-check-circle': timeRange.sunday, 'text-red-500 pi-times-circle': !timeRange.sunday }"></i>
                                </td>
                                <td>
                                    <div class="flex gap-2 justify-content-end">
                                        <p-button *ngIf="allowDelete" (click)="deleteTimeRange(timeRange)"
                                            styleClass="p-button-xs p-button-danger"><i
                                                class="pi pi-trash"></i></p-button>
                                        <p-button *ngIf="allowWrite" (click)="editTimeRange(timeRange)"
                                            styleClass="p-button-xs"><i class="pi pi-pencil"></i></p-button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td [attr.colspan]="9">
                                    No se han encontrado registros
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-card>
            <p-card class="p-3" header="Guardia">
                <div class="grid pt-2">
                    <div class="col-2 input-field">
                        <label for="hasGuardShiftEnabled" class="mr-3">Hace guardia</label>
                        <div class="flex h-full">
                            <p-inputSwitch formControlName="hasGuardShiftEnabled" d-form-field-error></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col input-field col-3" *ngIf="workerFormGroup.value.hasGuardShiftEnabled">
                        <label for="guardShiftId">Turno</label>
                        <p-dropdown [options]="guardsShifts" formControlName="guardShiftId" [required]="true"
                            optionValue="id" [filter]="true" filterBy="name" optionLabel="name"
                            placeholder="Seleccione turno" />
                    </div>
                </div>
            </p-card>
        </p-tabPanel>
        <p-tabPanel header="Sub-operarios" [disabled]="!worker.id || !!worker.parentWorkerId">
            <div class="flex justify-content-end mb-2">
                <p-button *ngIf="allowWrite" label="Añadir suboperario" styleClass="ml-2" icon="pi pi-plus"
                    (click)="addSubWorker()" />
            </div>
            <p-table styleClass="mt-4" [value]="subWorkers" [paginator]="true" [rows]="25"
                [totalRecords]="totalSubWorkersRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-subWorker let-columns="columns">
                    <tr (dblclick)="editSubWorker(subWorker)">
                        <td>{{subWorker.name}}</td>

                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowWrite" (click)="editSubWorker(subWorker)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Vehículos" [disabled]="!worker.id">
            <div class="flex justify-content-end pb-1">
                <p-button label="Asignar vehículo" (click)="assignVehicle({})" styleClass="p-button-sm"
                    *ngIf="allowWrite"></p-button>
                <p-button label="Nuevo vehículo" (click)="editVehicle({})" styleClass="p-button-sm ml-2"
                    *ngIf="allowWrite"></p-button>
            </div>
            <p-table styleClass="mt-4" [value]="vehicles" [paginator]="true" [rows]="25"
                [totalRecords]="totalVehiclesRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Matrícula <p-sortIcon field="plate"></p-sortIcon></th>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="name">Desde <p-sortIcon field="fromDate"></p-sortIcon></th>
                        <th pSortableColumn="name">Hasta <p-sortIcon field="toDate"></p-sortIcon></th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-vehicle let-columns="columns">
                    <tr (dblclick)="assignVehicle(vehicle)">
                        <td>{{vehicle.vehicle.plate}}</td>
                        <td>{{vehicle.vehicle.name}}</td>
                        <td>{{vehicle.fromDate | date:'dd/MM/yy'}}</td>
                        <td>{{vehicle.toDate | date:'dd/MM/yy'}}</td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteVehicle(vehicle)" [rounded]="true"
                                    styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="assignVehicle(vehicle)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="2">
                            No se han encontrado vehículos
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Equipamiento" [disabled]="!worker.id">
            <div class="flex justify-content-end pb-1">
                <p-button label="Añadir equipamiento" (click)="editEquipment({})" styleClass="p-button-sm"
                    *ngIf="allowWrite"></p-button>
            </div>
            <p-table styleClass="mt-4" [value]="workerEquipments" [paginator]="true" [rows]="25"
                [totalRecords]="totalEquipmentsRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="name">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="name">Notas <p-sortIcon field="notes"></p-sortIcon></th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-equipment let-columns="columns">
                    <tr (dblclick)="editEquipment(equipment)">
                        <td>{{equipment.equipment.name}}</td>
                        <td>{{equipment.notes}}</td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteWorkerEquipment(equipment)"
                                    [rounded]="true" styleClass="p-button-xs p-button-danger"><i
                                        class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editEquipment(equipment)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="3">
                            No se han encontrado equipamientos
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="Configuración" [disabled]="!worker.id">
            <p-card header="Acceso a la aplicación">
                <div class="grid">
                    <div class="col-2 input-field">
                        <label for="enableAccess">Activar acceso</label>
                        <p-inputSwitch formControlName="enableAccess" (onChange)="onEnableAccessChange()"
                            d-form-field-error></p-inputSwitch>
                    </div>
                    <div class="col-2 input-field"
                        *ngIf="workerFormGroup.value.enableAccess && workerFormGroup.value.user!=null"
                        formGroupName="user">
                        <label for="userName">Nombre de usuario</label>
                        <input pInputText formControlName="userName" type="text" d-form-field-error />
                    </div>
                    <div class="col-2 input-field"
                        *ngIf="workerFormGroup.value.enableAccess && workerFormGroup.value.user!=null"
                        formGroupName="user">
                        <label for="roleId">Perfil</label>
                        <p-dropdown [options]="roles" formControlName="roleId" [required]="true" optionValue="id"
                            filterBy="title" optionLabel="title" placeholder="Seleccione perfil" d-form-field-error />
                    </div>
                    <div class="col-2 input-field"
                        *ngIf="workerFormGroup.value.enableAccess && workerFormGroup.value.user!=null && worker.userId==null"
                        formGroupName="user">
                        <label for="password">Password</label>
                        <p-password type="password" formControlName="password" autocomplete="new-password"
                            [toggleMask]="true" d-form-field-error></p-password>
                    </div>
                    <div class="col-4 input-field" *ngIf="worker.userId && workerFormGroup.value.enableAccess">
                        <label>&nbsp;</label>
                        <p-button label="Ver usuario" icon="pi pi-eye" styleClass="mr-2"
                            (click)="viewUser()"></p-button>
                        <p-button label="Enviar contraseña" icon="pi pi-send" styleClass="p-button-secondary"
                            (click)="sendPassword()"></p-button>
                    </div>
                </div>
            </p-card>
            <p-card header="Dirección de salida">
                <div class="grid">
                    <div class="col-4 input-field">
                        <label for="usePrincipalAddressAsWorkStart">Usar misma dirección fiscal</label>
                        <p-inputSwitch formControlName="usePrincipalAddressAsWorkStart"
                            (onChange)="onEnableUsePrincipalAddressAsWorkStartChange()"
                            d-form-field-error></p-inputSwitch>
                    </div>
                    <div class="grid pt-2 col-12" formGroupName="workStartAddress"
                        *ngIf="!workerFormGroup.value.usePrincipalAddressAsWorkStart">
                        <div class="col input-field col-3">
                            <label for="name">Dirección *</label>
                            <input pInputText formControlName="addressLine1" type="text" d-form-field-error />
                        </div>
                        <div class="col input-field col-3">
                            <label for="name">Código postal</label>
                            <input pInputText formControlName="zipCode" type="text" d-form-field-error />
                        </div>
                        <div class="col input-field col-3">
                            <label for="name">Ciudad</label>
                            <input pInputText formControlName="city" type="text" d-form-field-error />
                        </div>
                        <div class="col input-field col-3">
                            <label for="name">País</label>
                            <p-dropdown [options]="countries" formControlName="countryCode" [required]="true"
                                optionValue="code" [filter]="true" filterBy="name" optionLabel="name"
                                placeholder="Seleccione país" d-form-field-error />
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card header="Agenda">
                <div class="grid">
                    <div class="col-4 input-field">
                        <label for="manageYourAppointments">Gestiona su agenda</label>
                        <p-inputSwitch formControlName="manageYourAppointments" d-form-field-error></p-inputSwitch>
                    </div>
                </div>
            </p-card>
            <p-card header="Facturación" *ngIf="!worker.parentWorkerId">
                <div class="grid">
                    <div class="col-4 input-field">
                        <label for="hasSubmitInvoice">Presenta factura</label>
                        <p-inputSwitch formControlName="hasSubmitInvoice" d-form-field-error></p-inputSwitch>
                    </div>
                </div>
            </p-card>
        </p-tabPanel>
        <p-tabPanel header="Calendario" [disabled]="!worker.id">
            <div class="grid justify-content-center">
                <div class="col-5 input-field">
                    <p-dropdown formControlName="calendarId" [options]="calendars" optionLabel="name" optionValue="id"
                        [filter]="true" filterBy="name" (onChange)="onChangeCalendar($event)"
                        placeholder="Seleccione un calendario" d-form-field-error />
                </div>
            </div>
            <p-card class="p-3">
                <app-calendar-year [events]="calendarEvents" [holidays]="workerHolidays"
                    (onDayClick)="daySelected($event)" (onYearChange)="yearChanged($event)"></app-calendar-year>
            </p-card>

        </p-tabPanel>
        <p-tabPanel header="Notas" [disabled]="!worker.id">
            <p-editor formControlName="notes" [style]="{ height: '320px' }">
                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <button type="button" class="ql-bold" aria-label="Negrita"></button>
                        <button type="button" class="ql-italic" aria-label="Cursiva"></button>
                        <button type="button" class="ql-underline" aria-label="Subrayado"></button>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-color" aria-label="Color"></select>
                        <select class="ql-background" aria-label="Color de fondo"></select>
                    </span>
                    <span class="ql-formats">
                        <button type="button" class="ql-list" value="ordered" aria-label="Lista de números"></button>
                        <button type="button" class="ql-list" value="bullet" aria-label="Lista de puntos"></button>
                    </span>
                    <span class="ql-formats">
                        <button type="button" class="ql-link" aria-label="Enlace"></button>
                        <select class="ql-align" aria-label="Alineación"></select>
                    </span>
                </ng-template>
            </p-editor>
        </p-tabPanel>
        <p-tabPanel header="Documentos" [disabled]="!worker.id">
            <div class="flex justify-content-end pb-1">
                <p-button label="Nuevo documento" (click)="editFile()" styleClass="p-button-sm"
                    *ngIf="allowWrite"></p-button>
            </div>
            <p-table #grid [value]="files" [paginator]="true" [scrollable]="true" scrollHeight="flex" [paginator]="true"
                [rows]="25" [totalRecords]="totalWorkersFiles" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Nombre </th>
                        <th>Fichero </th>
                        <th>BlockChain </th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-file let-columns="columns">
                    <tr (dblclick)="editFile(file)">
                        <td>{{file.name}}</td>
                        <td>{{file.file.fileName}}</td>
                        <td>
                            <p-tag icon="pi pi-check-circle" severity="success"
                                value="{{file.file.hash}} - {{file.file.hashDate | date:'dd/MM/yyyy HH:mm:ss'}}" />
                        </td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteFile(file)"
                                    styleClass="p-button-xs p-button-danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="downloadFile(file.fileId)"
                                    icon="pi pi-cloud-download" styleClass="p-button-xs"> </p-button>
                                <p-button *ngIf="allowWrite" (click)="editFile(file)" styleClass="p-button-xs"><i
                                        class="pi pi-pencil"></i></p-button>

                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>