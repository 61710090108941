import { prop, required } from "@rxweb/reactive-form-validators";
import { Resource } from "src/app/core/jsonapi/resource";

export class SurveyQuestionAnswer extends Resource {
    public override type = 'surveyAnswerQuestions';

    @prop()
    @required()
    public surveyAnswerId!: string;

    @prop()
    @required()
    public surveyQuestionId!: string;

    @prop()
    public answer!: string;

}