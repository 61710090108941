import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { GuardShift } from "../_types/guard-shift";

@Injectable({
    providedIn: 'root',
})

export class GuardsShiftsService extends Service<GuardShift> {
  public override type = 'guardsShifts';
  public override url = 'providers/guardShifts';   
  
}
