import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicSurveyResponseComponent } from './survey-response/public-survey-response.component';
import { PublicSurveyResponseThanksComponent } from './survey-response-thanks/public-survey-response-thanks.component';

const routes: Routes = [
    {
        path: 'public/surveys',
        children: [
            { path: ':surveyId/:targetType/:targetId/:sourceType/:sourceId', component: PublicSurveyResponseComponent },
            { path: 'thanks', component: PublicSurveyResponseThanksComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicSurveyResponseRoutingModule { }
