<form *ngIf="rateLineFormGroup" [formGroup]="rateLineFormGroup">    
    <div class="grid">
        <div class="col-4 input-field">
            <label for="capability">Especialidad</label>            
                <p-dropdown formControlName="capabilityId" [options]="capabilities"
                optionLabel="name" optionValue="id" appendTo="body" [filter]="true" filterBy="name"
                placeholder="Selecciona una especialidad" d-form-field-error></p-dropdown>
        </div>        
        
        <div class="col-4 input-field">
            <label for="code">Código</label>
            <input pInputText formControlName="code" inputId="code" d-form-field-error />
        </div>
        <div class="col-4 input-field">
            <label for="price">Precio</label>
            <input formControlName="price" pInputText type="number" d-form-field-error>
        </div>
        <div class="col-4 input-field">
            <label for="fixedAmount">Cantidad fija</label>
            <input formControlName="fixedAmount" pInputText type="number" d-form-field-error>
        </div>
        <div class="col-4 input-field">
            <label for="freePrice" class="mr-1">Precio libre</label>
            <div class="flex h-full">
                <p-inputSwitch id="freePrice" formControlName="freePrice"></p-inputSwitch>
            </div>
        </div>        
        <div class="col-4 input-field">
            <label for="uniqueService" class="mr-1">Servicio único</label>
            <div class="flex h-full">
                <p-inputSwitch id="uniqueService" formControlName="uniqueService"></p-inputSwitch>
            </div>
        </div>        
        <div class="col-12 input-field">
            <label for="notes">Descripción</label>
            <textarea pInputTextarea formControlName="description" style="field-sizing: content;"
                d-form-field-error></textarea>
        </div>        
        <div class="col-12 input-field">
            <label for="notes">Códigos incompatibles</label>            
            <p-multiSelect             
                [ngModelOptions]="{standalone: true}"
                [options]="otherRateLines" 
                [(ngModel)]="incompatibleCodes" 
                placeholder="Seleccion líneas"
                optionLabel="displayName" 
                display="chip"    
                appendTo="body"                                
                [showClear]="true" />
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>    
</form>