<div class="flex gap-2">
    <div class="flex-grow-1 input-field p-input-icon-right">
        <input pInputText placeholder="Contenido" [(ngModel)]="filters.content.value" (keydown.enter)="search()"
            class="w-full" />
        <i *ngIf="filters.content.value" class="pi pi-times cursor-pointer"
            (click)="filters.content.value = null; search()"></i>
    </div>
    <div class="input-field">
        <p-dropdown placeholder="Usuario a filtrar" [options]="users" optionLabel="name" optionValue="id"
            [(ngModel)]="filters.userId.value" (onChange)="search()" [showClear]="true" [filter]="true"
            filterBy="name" />
    </div>
    <div class="input-field">
        <p-dropdown placeholder="Tipo" [options]="notificationTypes" optionLabel="label" optionValue="value"
            [(ngModel)]="filters.source.value" (onChange)="search()" [showClear]="true" />
    </div>
    <div class="input-field">
        <p-dropdown placeholder="Estado" [options]="notificationStates" optionLabel="label" optionValue="value"
            [(ngModel)]="selectedState" (onChange)="search()" [showClear]="true" />
    </div>
</div>

<p-table styleClass="mt-4" #grid [value]="notifications" [lazy]="true" (onLazyLoad)="loadData($event)"
    [loading]="loading" [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
    [rowsPerPageOptions]="[25,100,250]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th width="100" pSortableColumn="createdAt">Fecha <p-sortIcon field="createdAt"></p-sortIcon></th>
            <th width="180" pSortableColumn="subject">Usuario <p-sortIcon field="user"></p-sortIcon></th>
            <th pSortableColumn="content">Contenido <p-sortIcon field="content"></p-sortIcon></th>
            <th width="100" pSortableColumn="source">Tipo <p-sortIcon field="source"></p-sortIcon></th>
            <th width="100" pSortableColumn="createdAt">Leida <p-sortIcon field="readedAt"></p-sortIcon></th>
            <th width="100"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-notification let-columns="columns">
        <tr [class.notification-unread]="notification.readedAt==null">
            <td>{{notification.createdAt | date:'dd/MM/yyyy HH:mm'}}</td>
            <td>{{notification.user?.name}}</td>
            <td>
                {{notification.subject}}<br>
                <small>{{notification.content}}</small>
            </td>
            <td>{{notificationsService.getNotificationTypeLabel(notification.source)}}</td>
            <td>
                <div *ngIf="notification.readedAt!=null">{{notification.readedAt | date:'dd/MM/yyyy HH:mm'}}</div>
                <div *ngIf="notification.readedAt==null">No leida</div>
            </td>
            <td>
                <div class="flex gap-2 justify-content-end">
                    <p-button *ngIf="allowWrite && notification.readedAt==null && notification.userId==this.user?.id" (click)="markAsReaded(notification)" [rounded]="true" [text]="false"><i
                            class="pi pi-eye-slash"></i></p-button>
                    <p-button *ngIf="allowWrite && notification.userId==this.user?.id" (click)="executeAction(notification)" [rounded]="true" [text]="false"
                        severity="secondary"><i class="pi pi-external-link"></i></p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog></p-confirmDialog>