<form *ngIf="rateCompanyFormGroup" [formGroup]="rateCompanyFormGroup">
    <div class="grid">
        <div class="col-12 input-field">
            <label for="capabilityId">Compañía aseguradora *</label>
            <p-dropdown [options]="companies" formControlName="companyId" optionValue="id"
                [filter]="true" filterBy="name" optionLabel="name" (onChange)="loadContracts()" placeholder="Seleccione compañía" appendTo="body"
                d-form-field-error />
        </div>            
        <div class="col-12 input-field">
            <label for="capabilityId">Contrato</label>            
            <p-dropdown [options]="contracts" formControlName="contractId" optionValue="id"
                [filter]="true" filterBy="name" optionLabel="name" placeholder="Seleccione contrato" appendTo="body"
                d-form-field-error />
        </div>            
    </div>

    <div class="mt-4 flex gap-2 justify-content-end">
        <p-button label="Eliminar" *ngIf="allowDelete && rateCompany.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
            (click)="save()"></p-button>
    </div>

</form>