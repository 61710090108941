import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { CalendarEvent } from "../_types/calendar-event";

@Injectable({
    providedIn: 'root',
})

export class CalendarsEventsService extends Service<CalendarEvent> {
  public override type = 'calendarsEvents';
  public override url = 'providers/calendarEvents';     
}
