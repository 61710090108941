import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { WorkerCapability } from "../_types/worker-capability";

@Injectable({
    providedIn: 'root',
})
export class WorkerCapabilitiesService extends Service<WorkerCapability> {
    public override type = 'workerCapabilities';
    public override url = 'providers/workerCapabilities';
}
