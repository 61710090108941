import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { MessageService } from 'primeng/api';
import { LocalStorageService } from 'angular-web-storage';
import { DialogService } from 'primeng/dynamicdialog';
import { RecoverComponent } from '../recover/recover.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit {

  public formData: any = {};      

  constructor(
    private router: Router,
    private loginService: LoginService,    
    private messageService: MessageService,
    private storage: LocalStorageService,
    private dialogService: DialogService
  ) { }

  ngOnInit(){
    this.formData.username = this.storage != null && this.storage.get("remember") === "true" ? this.storage.get("username") : "";
    this.formData.password = this.storage != null && this.storage.get("remember") === "true" ? this.storage.get("password") : "";
    this.formData.remember = this.storage != null && this.storage.get("remember") === "true" ? true : false;
    console.log(this.formData);
    
    if(this.formData.username != "" && this.formData.password != "" && this.formData.remember == true){
      this.login();      
    }else{
      this.loginService.logout();
    }

  }

  async login(){
    let data = await firstValueFrom(this.loginService.login(this.formData.username, this.formData.password));
    console.log(data);

    if(this.formData.remember){
      this.storage.set("username", this.formData.username);
      this.storage.set("password", this.formData.password);
      this.storage.set("remember", this.formData.remember);
    }

    this.router.navigate(['/home']);
  }

  recover() {
    const ref = this.dialogService.open(RecoverComponent, {
      data: { },
      header: "Recuperar contraseña",
      width: '420px'
    });
    ref.onClose.subscribe((data: any) => {
      //this.cancel();
    })
  }
}
