import { Component, ViewEncapsulation } from '@angular/core';
import { Capability } from 'src/app/modules/common/_types/capability';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { CapabilitiesService } from 'src/app/modules/common/_services/capabilities.service';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { FormValidatorService } from 'src/app/core/services/form-validator.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Filter, FilterOperation } from 'src/app/core/jsonapi/filter';
import { IParamsCollection } from 'src/app/core/jsonapi/interfaces/params-collection';
import { FilterBuilder } from 'src/app/core/jsonapi/filters-builder';
import { TransactionLine } from '../../_types/transaction-line';
import { TransactionsLinesService } from '../../_services/transactions-lines.service';
import { Rate } from 'src/app/modules/rates/_types/rate';
import { RatesService } from 'src/app/modules/rates/_services/rates.service';
import { TaxesService } from '../../_services/taxes.service';
import { Tax } from '../../_types/tax';
import { RateLine } from 'src/app/modules/rates/_types/rate-line';
import { RateLinesService } from 'src/app/modules/rates/_services/rates-lines.service';
import { TransactionsService } from '../../_services/transactions.service';

@Component({
  selector: 'app-transaction-line',
  templateUrl: './transaction-line.component.html',
  styleUrls: ['./transaction-line.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TransactionLinesComponent {
  public transactionLineFormGroup!: RxFormGroup;
  public transactionLine: TransactionLine = new TransactionLine();
  public transaction: any = {};
  public allowWrite: boolean = false;
  public allowDelete: boolean = false;
  public loadingRates: boolean = false;
  public taxes: Tax[] = [];
  public loadingTaxes: boolean = false;
  public isRate: boolean = false;

  public capabilities: Capability[] = [];
  public selectedCapability: string | undefined;

  public rateLines: RateLine[] = [];
  public allRateLines: any;

  public rateLineSelected: any = {
    freePrice: true
  };

  constructor(
    private transactionLinesService: TransactionsLinesService,
    private transactionsService: TransactionsService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private formBuilder: RxFormBuilder,
    private formValidationService: FormValidatorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private ratesService: RatesService,
    private taxesService: TaxesService
  ) {

    this.transactionLineFormGroup = this.formBuilder.formGroup(TransactionLine) as RxFormGroup;
  }

  async ngOnInit() {

    //this.loadRates();
    await this.loadTaxes();
    
    let transactionLineId = this.config.data.transactionLineId;
    let isGroup = this.config.data.isGroup;
    this.isRate = this.config.data.isRate;
    console.log(this.isRate);
    if (transactionLineId) {
      await this.loadTransactionLine(transactionLineId);      
    } else {
      this.transactionLine.transactionId = this.config.data.transactionId;
      this.transactionLine.lineNumber = this.config.data.lineNumber;
      this.transactionLine.isGroup = isGroup;
      if(this.isRate){
        let transactionId = this.config.data.transactionId;
        this.transaction.taxPercent = this.taxes.filter(m => m.default)[0].percent;
        this.transaction = await firstValueFrom(this.transactionsService.get(transactionId));                
        await this.loadRateLines();        
      }  
      this.transactionLineFormGroup.patchValue(this.transactionLine);
    }

    this.allowWrite = true; //this.loginService.hasPermission("TRANSACTION_LINES_WRITE");
    this.allowDelete = true; //this.loginService.hasPermission("TRANSACTION_LINES_DELETE");
  }

  async loadTransactionLine(transactionLineId: string) {

    if(this.isRate){
      let transactionId = this.config.data.transactionId;
      this.transaction = await firstValueFrom(this.transactionsService.get(transactionId));    
      console.log(this.transaction);            
      await this.loadRateLines();        
    }  

    let params: IParamsCollection = {
      
    }

    if(this.isRate){
      params.include = ['rateLine'];
    }
    this.transactionLine = await firstValueFrom(this.transactionLinesService.get(transactionLineId, params));
    console.log(this.transactionLine);
    if (this.isRate) {      
      if (this.transactionLine.rateLine) {
        await this.loadRateLines();
        this.selectedCapability = this.transactionLine.rateLine.capabilityId;        
        this.selectCapability({
          value: this.selectedCapability
        });
      }
    }

    this.transactionLineFormGroup.patchValue(this.transactionLine);

  }

  async loadRateLines() {    
    this.allRateLines = await firstValueFrom(this.ratesService.rateLinesCompany("COMPANY", this.transaction.thirdPartyId, this.transaction.date));    
    this.capabilities = this.allRateLines.map((m: any) => m.capability).filter((capability: any, index: any, self: any) =>
      index === self.findIndex((p: any) => p.id === capability.id)
    );

  }

  selectCapability(capability: any) {    
    this.rateLines = this.allRateLines.filter((m: any) => m.capabilityId == capability.value)
    this.rateLines.forEach((rateLine) => {
      rateLine.displayName = rateLine.code + (rateLine.description != null && rateLine.description != "" ? " - " + rateLine.description : "");
    });    
    if (this.transactionLine.rateLineId != null) {
      this.rateLineSelected = this.allRateLines.find((m: any) => m.id == this.transactionLine.rateLineId);
    }

  }

  selectRateLine(e: any) {
    this.transactionLine.price = this.rateLines.find((rl: any) => rl.id == e.value)?.price || 0;
    this.transactionLineFormGroup.patchValue({ unitPrice: this.transactionLine.price });
  }

  async loadTaxes() {
    this.loadingTaxes = true;
    let params: IParamsCollection = {
    }

    let response = await firstValueFrom(this.taxesService.all(params));
    this.taxes = response.data;

    this.loadingTaxes = false;
  }

  calculatePrices(event: any) {
    console.log(this.transactionLineFormGroup);
    const precioTotalSinDescuento = this.transactionLineFormGroup.value.quantity * this.transactionLineFormGroup.controls["price"].value;

    const descuento = precioTotalSinDescuento * (this.transactionLineFormGroup.value.discountPercent / 100);
    this.transactionLineFormGroup.controls["discountAmount"].setValue(descuento);
    
    const precioConDescuento = precioTotalSinDescuento - descuento;
    
    const impuesto = precioConDescuento * (this.transactionLineFormGroup.value.taxPercent / 100);
    this.transactionLineFormGroup.controls["taxAmount"].setValue(impuesto);
    
    const precioFinal = precioConDescuento + impuesto;
    this.transactionLineFormGroup.controls["subtotal"].setValue(precioFinal);


    console.log(descuento);
  }


  async save() {    
    let valid = this.formValidationService.validateForm(this.transactionLineFormGroup);
    if (valid) {
      this.transactionLine = Object.assign(this.transactionLine, this.transactionLineFormGroup.getRawValue());
      console.log(this.transactionLine);
      let transactionLine = await firstValueFrom(this.transactionLinesService.save(this.transactionLine));
      this.messageService.add({ closable: false, severity: 'success', summary: "Éxito", detail: "Línea guardada correctamente." });
      this.dynamicDialogRef.close(this.transactionLine.id ? null : transactionLine.id);      
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: "Error", detail: "Existen errores, por favor revise el formulario de datos." });
    }
  }

  async delete() {
    this.confirmationService.confirm({
      message: "¿Está seguro que desea eliminar la línea de transacción?",
      header: "Eliminar línea",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      acceptButtonStyleClass: "p-button-danger",
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.transactionLinesService.delete(this.transactionLine.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: "Línea eliminada correctamente." });
            this.cancel();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }  


  // Asignar el precio unitario de la línea de tarifa al cambiar la línea de tarifa
  onRateLineChange(e: any) {
    this.rateLineSelected = this.allRateLines.find((rateLine: any) => rateLine.id == e.value);    
    this.transactionLineFormGroup.controls['price'].setValue(this.rateLineSelected?.price || 0);    
    if(!this.rateLineSelected.freePrice){
      this.transactionLineFormGroup.get('price')?.disable();
    }else{
      this.transactionLineFormGroup.get('price')?.enable();
    }
    if(this.rateLineSelected.fixedAmount != null){
      this.transactionLineFormGroup.controls['quantity'].setValue(this.rateLineSelected.fixedAmount);
      this.transactionLineFormGroup.get('quantity')?.disable();
    }else{
      this.transactionLineFormGroup.controls['quantity'].setValue(1);
      this.transactionLineFormGroup.get('quantity')?.enable();
    }

    this.calculatePrices(e);
  }

}
