import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { AffectedAddress } from "../_types/affected-address";

@Injectable({
    providedIn: 'root',
})
export class AffectedAddressesService extends Service<AffectedAddress> {
    public override type = 'affectedAddresses';
    public override url = 'attendance/affectedAddresses';
}
