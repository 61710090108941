import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Observable } from "rxjs";
import { Contract } from "../_types/contract";

@Injectable({
    providedIn: 'root',
})

export class ContractsService extends Service<Contract> {
  public override type = 'contracts';
  public override url = 'attendance/contracts'; 

  public updateCapabilities(contractId:string, capabilitiesIds: Array<string>): Observable<void> {
    let url = this.getAPIUrl()+"/" + contractId + '/capabilities';    
    return this.http.post<void>(url, capabilitiesIds);
  }
}
