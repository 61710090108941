import { Injectable } from '@angular/core';
import { Service } from 'src/app/core/jsonapi/service';
import { ThirdParty } from '../_types/third-party';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartiesService extends Service<ThirdParty> {
  public override type = 'thirdParties';
  public override url = 'invoicing/thirdParties'; 
}
