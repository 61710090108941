import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { ClientAddress } from "../_types/client-address";

@Injectable({
    providedIn: 'root',
})
export class ClientAddressesService extends Service<ClientAddress> {
    public override type = 'clientAddresses';
    public override url = 'attendance/clientAddresses';
}
