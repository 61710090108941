
<form *ngIf="processorFormGroup" [formGroup]="processorFormGroup">
  <div class="card mt-2 ">
    <div class="grid">
      <div class="col">
        <!--<div class="flex flex-wrap gap-2">-->
        <div class="input-field">
          <label for="name">Tramitador *</label>
          <p-dropdown 
            name="processorId"
            [options]="processors"             
            optionLabel="name" 
            optionValue="id"
            [filter]="true"
            filterBy="name"             
            placeholder="Seleccione usuario"
            appendTo="body"             
            formControlName="processorId" d-form-field-error/>            
        </div>        
      </div>
      <div class="col">
        <!--<div class="flex flex-wrap gap-2">-->
        <div class="input-field">
          <label for="name">% de carga *</label>
          <p-inputNumber 
            name="maxLoadChargePercent"            
            inputId="percent" 
            suffix="%" 
            [min]="0" 
            [max]="100"
            formControlName="maxLoadChargePercent" d-form-field-error/>            
        </div>        
      </div>
    </div>    

    
    <div class="mt-6 flex gap-2 justify-content-end ">
      <p-button label="Eliminar" *ngIf="contractProcessor.id&&allowDelete" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
      <div class="flex-grow-1"></div>
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
      <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
    </div>
  </div>
</form>  

