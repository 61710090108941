<form *ngIf="roleFormGroup" [formGroup]="roleFormGroup">
    <p-card class="p-3" header="Datos generales">

        <div class="grid">
            <div class="col-10 input-field">
                <label for="title">Nombre *</label>
                <input pInputText formControlName="title" [(ngModel)]="role.title" d-form-field-error />
            </div>
            <div class="col-2 input-field">
                <label for="workerAllowed">Activo en operario</label>
                <p-inputSwitch formControlName="workerAllowed" [(ngModel)]="role.workerAllowed"></p-inputSwitch>
            </div>
            <div class="col-12 input-field">
                <label for="description">Descripción</label>
                <textarea pInputTextarea rows="3" formControlName="description" [(ngModel)]="role.description"
                    d-form-field-error></textarea>
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete && role.id" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>

    </p-card>

</form>

<div class="grid">
    <p-panel class="col-4" header="{{item.feature}}" [toggleable]="true" *ngFor="let item of permissionsByFeature">
        <div class="grid">
            <div class="col-12" *ngFor="let permission of item.permissions">
                <p-checkbox [(ngModel)]="selectedPermissions" [label]="permission[0]" name="capability"
                    [value]="permission[4]" />
            </div>
        </div>
    </p-panel>
</div>

<p-confirmDialog></p-confirmDialog>