<form *ngIf="businessFormGroup" [formGroup]="businessFormGroup">
    <p-card class="p-3" header="Datos generales">
        <div class="grid">
            <div class="col-6 input-field">
                <label for="name">Nombre *</label>
                <input pInputText formControlName="name" id="name" d-form-field-error />
            </div>
            <div class="col-6 input-field">
                <label for="legalName">Nombre Legal *</label>
                <input pInputText formControlName="legalName" id="legalName" d-form-field-error />
            </div>
            <div class="col-4 input-field ">
                <label for="vatNumber">VAT</label>
                <input pInputText id="emavatNumberil" formControlName="vatNumber" type="text" />
            </div>
            <div class="col-2 input-field">
                <label for="active" class="mr-1">Activo</label>
                <div class="flex h-full">
                    <p-inputSwitch id="active" formControlName="active"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div class="grid pt-2">
            <div class="col-4 input-field ">
                <label for="phone1">Teléfono 1</label>
                <input pInputText id="phone1" formControlName="phone1" type="text" />
            </div>
            <div class="col-4 input-field ">
                <label for="phone2">Teléfono 2</label>
                <input pInputText id="phone2" formControlName="phone2" type="text" />
            </div>
            <div class="col-4 input-field ">
                <label for="name">Email</label>
                <input pInputText id="email" formControlName="email" type="text" />
            </div>
        </div>
        <div class="grid pt-2" formGroupName="address">
            <div class="col input-field col-3">
                <label for="addressLine1">Dirección *</label>
                <input pInputText formControlName="addressLine1" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="zipCode">Código postal</label>
                <input pInputText formControlName="zipCode" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="city">Ciudad</label>
                <input pInputText formControlName="city" type="text" d-form-field-error />
            </div>
            <div class="col input-field col-3">
                <label for="countryCode">País</label>
                <p-dropdown [options]="countries" formControlName="countryCode" [required]="true" optionValue="code"
                    [filter]="true" filterBy="name" optionLabel="name" placeholder="Seleccione país" />
            </div>
        </div>

        <div class="mt-4 flex gap-2 justify-content-end">
            <p-button label="Eliminar" *ngIf="allowDelete && business.id" icon="pi pi-trash"
                styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-secondary" (click)="cancel()"></p-button>
            <p-button label="Guardar" *ngIf="allowWrite" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
        </div>
    </p-card>

    <p-tabView *ngIf="business.id">
        <p-tabPanel header="Cuentas Bancarias">
            <div class="flex justify-content-end">
                <p-button label="Añadir cuenta bancaria" *ngIf="allowWrite" icon="pi pi-plus" styleClass=""
                    (click)="editBankAccount(null)"></p-button>
            </div>

            <p-table styleClass="m-1 mt-4" #grid [value]="businessBankAccounts" [loading]="loading" [paginator]="true"
                [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
                [rowsPerPageOptions]="[5,10,25,50]" [scrollable]="true" scrollHeight="flex"
                paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="bankId">Banco <p-sortIcon field="bankId" /></th>
                        <th pSortableColumn="accountNumber">IBAN <p-sortIcon field="accountNumber" /></th>
                        <th pSortableColumn="swift">Swift <p-sortIcon field="swift" /></th>
                        <th [width]="150"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-businessBankAccount let-columns="columns">
                    <tr (dblclick)="editBankAccount(businessBankAccount)">
                        <td>{{businessBankAccount.bank.name}}</td>
                        <td>{{businessBankAccount.accountNumber | uppercase}}</td>
                        <td>{{businessBankAccount.swift}}</td>
                        <td>
                            <div class="flex gap-2 justify-content-end">
                                <p-button *ngIf="allowDelete" (click)="deleteBankAccount(businessBankAccount)"
                                    [rounded]="true" [text]="false" severity="danger"><i class="pi pi-trash"></i></p-button>
                                <p-button *ngIf="allowWrite" (click)="editBankAccount(businessBankAccount)" [rounded]="true"
                                    [text]="false"><i class="pi pi-pencil"></i></p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</form>
<p-confirmDialog></p-confirmDialog>