import { EventEmitter, Injectable, Output } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { Notification } from "../_types/notification";
import { AsyncSubject, firstValueFrom, Observable } from "rxjs";
import { ResponseResource } from "src/app/core/jsonapi/responses/response-resource";

@Injectable({
    providedIn: 'root',
})
export class NotificationsTypesService {

    private notificationTypes:any[] = [
        {source: "Chat", icon: "pi pi-comments", label: "Chats", selected: true, count: 1},
        {source: "Task", icon: "pi pi-file-edit", label: "Tareas", selected: false, count: 0},
        {source: "Case", icon: "pi pi-briefcase", label: "Expedientes", selected: false, count: 0},
    ];

    all():any[]{
        return this.notificationTypes;
    }

}
