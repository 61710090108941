import { Injectable } from "@angular/core";
import { Service } from "src/app/core/jsonapi/service";
import { SurveyQuestionAnswer } from "../_types/survey-question-answer";

@Injectable({
    providedIn: 'root',
})
export class SurveysQuestionAnswersService extends Service<SurveyQuestionAnswer> {
    public override type = 'surveyAnswerQuestions';
    public override url = 'attendance/surveyAnswerQuestions';
}
